/* eslint-disable react/no-unescaped-entities */
export function HeroPrivacySection() {
  return (
    <div
      className="text-sm text-blue200 leading-5 pt-10"
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <p>
        We respect your privacy and are committed to protecting your privacy
        rights. Please take the time to read this Privacy Policy carefully. We
        want to be clear about how we use information, and about how you can
        protect your privacy. To ensure transparency, this Privacy Policy
        describes our information-handling practices when you access the
        Protofire website.
      </p>

      <p>
        If you have any questions, please feel free to contact us at:
        <a href="mailto:dpo@altoros.com"> dpo@altoros.com</a>.
      </p>

      <p>
        References in this Policy to “Protofire”, “we,” and “us” refer to
        Altoros LLC, and references to “you”, “your”, “customer” or “user” are
        to the person who use the Services. We offer a wide range of software
        development services. We refer to all of these products, together with
        our other services and websites as "Services" in this policy.
      </p>

      <p>
        By accessing the Services you signify acceptance to the terms of this
        Privacy Policy. Where we require your consent to process your personal
        information, we will ask for your consent to the collection, use, and
        disclosure of your personal information as described further below.
      </p>

      <p>
        IF YOU DO NOT AGREE WITH THIS POLICY PLEASE STOP USING RELATED SERVICES
        IMMEDIATELY.
      </p>
    </div>
  );
}

export default HeroPrivacySection;
