/// <reference types="react-scripts" />
import { Link } from "react-router-dom";
import { useState } from "react";

import { ReactComponent as Sclose } from "../assets/images/sclose.svg";
import { ReactComponent as Sopen } from "../assets/images/sopen.svg";

import Socials from "../socials/socials";

export const servicepages = [
  {
    name: "Smart Contracts",
    link: "/services/smart-contract",
  },
  {
    name: "Decentralized Applications",
    link: "/services/decentralized-apps",
  },
  {
    name: "Developer Tools",
    link: "/services/developer-tools",
  },
  {
    name: "Integrations",
    link: "/services/integrations",
  },
  {
    name: "DevOps",
    link: "/services/dev-ops",
  },
  // {
  //   name: "Wallets",
  //   link: "/services/wallets",
  // },
  {
    name: "Safe Deployment",
    link: "/services/safe-deployment",
  },
  {
    name: "TVL Solutions",
    link: "/services/tvl-solutions",
  },
];

function MobileNav({
  open,
  setOpen,
  displayMobileMenu,
  setDisplayMobileMenu,
}: {
  open: boolean;
  setOpen: Function;
  displayMobileMenu: boolean;
  setDisplayMobileMenu: Function;
}) {
  const [active, setActive] = useState("home");
  const [dropDown, setDropDown] = useState(false);

  const handleOpen = () => {
    setDropDown(!dropDown);
  };
  const handleClose = () => {
    setDropDown(!dropDown);
  };
  const handleClick = (page: string) => {
    setDisplayMobileMenu(!displayMobileMenu);
    setOpen(!open);
    setActive(page);
  };

  return (
    <div className="z-20 h-screen flex flex-col py-10">
      <ul
        className="  text-lg  text-blue200 tracking-widest  
        border-b border-blue300 grow"
        style={{
          fontSize: "16px",
          fontFamily: "Spartan-SemiBold",
        }}
      >
        <li className="py-2">
          <Link
            to="/about"
            className={`block py-2 pr-4 pl-3   hover:text-blue100 
                  md:p-0 ${active === "about" ? "text-blue100" : ""}`}
            onClick={() => handleClick("about")}
          >
            ABOUT
          </Link>
        </li>
        <li className="py-2 hover:text-blue100 ">
          <Link
            to="/services"
            className={` py-2 pr-1 pl-3  inline  hover:text-blue100 
                  md:p-0 ${
                    active === "services" || dropDown ? "text-blue100" : ""
                  }`}
            onClick={() => handleClick("services")}
          >
            SERVICES{" "}
          </Link>
          {dropDown && <Sopen className="inline mb-2" onClick={handleOpen} />}
          {!dropDown && (
            <Sclose className=" inline mb-2" onClick={handleClose} />
          )}

          {dropDown &&
            servicepages.map((pages) => (
              <li
                key={pages.name}
                className="py-2 pl-4 tracking-wide"
                style={{
                  fontSize: "14px",
                  fontFamily: "Spartan-Medium",
                }}
              >
                <Link
                  to={pages.link}
                  className={`block  hover:text-blue100 
                            md:p-0 ${active === pages.name ? "text-blue" : ""}`}
                  onClick={() => handleClick(pages.name)}
                >
                  {pages.name}
                </Link>
              </li>
            ))}
        </li>

        <li className="py-2 hover:text-blue100 ">
          <Link
            to="/projects"
            className={`block py-2 pr-4 pl-3   hover:text-blue100 
                  md:p-0 ${active === "projects" ? "text-blue" : ""}`}
            onClick={() => handleClick("projects")}
          >
            PROJECTS
          </Link>
        </li>
        <li className="py-2 hover:text-blue100 ">
          <Link
            to="/careers"
            className={`block py-2 pr-4 pl-3   hover:text-blue100 
                  md:p-0 ${active === "careers" ? "text-blue" : ""}`}
            onClick={() => handleClick("careers")}
          >
            CAREERS
          </Link>
        </li>
        <li className="py-2">
          <a
            href="https://protofire.io/community"
            target="_blank"
            className={`block py-2 pr-4 pl-3   hover:text-blue100 
                  md:p-0 ${active === "community" ? "text-blue" : ""}`}
            onClick={() => setActive("community")}
            rel="noreferrer"
          >
            COMMUNITY
          </a>
        </li>
      </ul>
      <div className=" py-10">
        <button
          type="button"
          className="dark:underline focus:ring-4 hover:text-primary
            
              text-sm px-5 py-2.5 text-center mr-3 md:mr-0 "
          style={{
            background: "linear-gradient(to right, #F54703 50%, #FAC902 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          CONTACT US
        </button>
        <div className="flex  flex-wrap">
          <Socials />
        </div>
      </div>
    </div>
  );
}

export default MobileNav;
