/* eslint-disable react/no-unescaped-entities */

function AnalyticsPartners() {
  return (
    <div
      className="text-sm text-blue200 leading-5 pt-10"
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <h2 className="text-blue100 text-2xl">2.2.1. Analytics partners</h2>
      <p>
        The services outlined below help us to monitor and analyze both web
        traffic and user behavior.
      </p>
      <p>
        <span className="text-blue100 mr-3 ">
          Google Analytics (Google LLС.):
        </span>
        Google Analytics is a web analysis service provided by Google Inc.
        (Hereinafter in this document referred to as Google). Google utilizes
        the data collected to track and examine user behavior, to prepare
        reports, and share insights with other Google services. Google may use
        the data collected to contextualize and personalize the advertisements
        launched via Google’s advertising network. The service is subject to
        Google’s privacy policy{" "}
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Google’s Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">
          Google Tag Manager (Google LLС.):
        </span>
        Google Tag Manageris a web service designed to optimize the Google
        Analytics management process. The service is provided by Google Inc. and
        is subject to the company’s privacy policy{" "}
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Google’s Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">
          Meta Pixel (Meta Platforms, Inc.) Meta Pixel:
        </span>
        is an analytics service that binds data gathered from the Facebook
        advertising network with actions performed on Protofire websites. The
        service is provided by Meta Platforms, Inc. and is subject to the
        company’s privacy policy{" "}
        <a
          href="https://www.facebook.com/about/privacy/"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Meta Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">Hotjar (Hotjar Ltd.): </span>
        Hotjar is a web analysis service that records user behavior without
        collecting private user data. The service provides insights to improving
        website navigation and addressing customer needs. The service is
        provided by Hotjar Limited and is subject to the company’s privacy
        policy{" "}
        <a
          href="https://www.hotjar.com/legal/policies/privacy"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Hotjar’s Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">
          Albacross (Albacross Nordic AB):
        </span>
        Albacross is an account-based marketing platform designed to boost lead
        generation and advertising efficiency. In terms of personal data
        collected, Albacross gathers information about the IP-address from which
        you visited our website. In addition, the service collects technical
        information that enables Albacross to differentiate between visitors
        using the same IP-address. Albacross stores the information about a
        domain—extracted from the form input—to correlate the IP-address with
        your employer. The service is provided by Albacross Nordic AB and is
        subject to the company’s privacy policy{" "}
        <a
          href="https://albacross.com/privacy-policy/"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Albacross Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">
          Google AdWords Tools (Google AdWords Conversion Tracking/ Dynamic
          Remarketing / User List / DoubleClick) (Google LLС):
        </span>
        Google AdWords conversion tracking and other Google Ads services are
        analytic instruments, connect data from the Google AdWords advertising
        network with actions taken on Protofire websites. The services are
        provided by Google Inc. and are subject to the company’s privacy policy{" "}
        <a
          href="https://www.google.com/intl/en/policies/privacy/"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Google’s Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">Hubspot (HubSpot, Inc.): </span>
        Hubspot is an inbound marketing and sales platform that helps companies
        attract visitors, convert leads, and close customers. It provides tools
        for social media marketing, content management, web analytics, landing
        pages and search engine optimization. The service is provided by
        HubSpot, Inc. and is subject to the company’s privacy policy{" "}
        <a
          href="https://legal.hubspot.com/privacy-policy"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Hubspot’s Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">
          LiftCertain (LiftCertain LLC):{" "}
        </span>
        is a b2b lead generation SaaS platform focused on identifying a
        website’s visitors through identity resolution. The service is provided
        by LiftCertain LLC and is subject to the company’s privacy policy{" "}
        <a
          href="https://liftcertain.com/privacy-policy/"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          LiftCertain's Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">
          Market Resource Partners, LLC:{" "}
        </span>
        Market Resource Partners, LLC: is a marketing and sales solution that
        provides integrated, multi-channel communications strategies,
        self-service client technology platforms and complete, in-depth data
        analytics. The service is provided by MRP and is subject to the
        company’s privacy policy{" "}
        <a
          href="https://www.mrpfd.com/privacy-policy/"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          MRP’s Privacy Policy
        </a>
      </p>
    </div>
  );
}

export default AnalyticsPartners;
