/* eslint-disable react/no-unescaped-entities */
function GeneralData() {
  return (
    <div
      className="text-sm text-blue200 leading-5 py-10"
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <h2 className="text-blue100 text-4xl">
        8. General data processing and data storage
      </h2>
      <p>
        Our processing means any operation or set of operations which is
        performed on personal data or on sets of personal data, such as
        collection, recording, organization, structuring, storage, adaptation or
        alteration, retrieval, consultation, use, disclosure by transmission,
        dissemination or otherwise making available, alignment or combination,
        restriction, erasure or destruction, support, maintenance, etc.
      </p>
      <p>
        The retention period of storing data varies on its type. As the
        retention period expires, we either delete or anonymize personal data
        collected. In case data was transferred to a backup storage and,
        therefore, cannot be deleted, we continue to store it in a secure
        fashion, but do not use it for any purpose. In all the other cases, we
        proceed with the deletion of data.
      </p>
      <p>
        The information available through our websites that was collected by
        third parties is subject to the privacy policies of these third parties.
        In this case, the retention period of storing data is also subject to
        the privacy policies of these third parties.
      </p>
      <p>
        To prevent spam, we keep track of spam and swindler accounts, which may
        be blocked through filtering at the server level.
      </p>
      <p>
        Requests containing words, which may be treated as spam-related or which
        may promote the distribution of misleading information, are filtered at
        the server level, as well as by company employees manually.
      </p>
      <p>
        Data storage on our servers, as well as on cloud services provided by
        Google, Amazon, Hubspot and on other services, inter alia Drift.com or
        other stipulated in this policy.
      </p>
      <p>We do not make automated decisions, including profiling.</p>
    </div>
  );
}

export default GeneralData;
