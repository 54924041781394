/* eslint-disable @typescript-eslint/no-shadow */
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "../tab.css";
import { useState } from "react";
import { ReactComponent as JoinUs } from "../../assets/images/joinUs.svg";
import { ReactComponent as JoinUsMobile } from "../../assets/images/joinUsMobile.svg";
import { ReactComponent as OvalCareerMobile2 } from "../../assets/career/ovalCareerMobile2.svg";
import { ReactComponent as OvalCareerBig } from "../../assets/career/ovalCareerBig.svg";
import { ReactComponent as BgTop } from "../../assets/career/bgTop.svg";
import { ReactComponent as BgMiddle } from "../../assets/career/bgMiddle.svg";

import ContactUs from "../../contactUs/contactUs";
import { tabs, whatWeProvide, headings } from "./content";

const description = {
  first: `TVL, or Total Value Locked, is a DeFi-vital metric that is used to measure the overall health of a DeFi protocol. 
  Its calculation and growth remain to be the key characteristics identifying the success of an ecosystem.`,
  second: `To help protocols grow their TVL, Protofire develops solutions aimed at reaching project-specific goals that will 
  positively influence the project’s growth.`,
};

function TvlSolutions() {
  const [activeTab, setActiveTab] = useState(tabs[0].tabName);

  return (
    <div className="lg:px-0 py-10  md:px-10">
      <Helmet>
        <title>TVL Solutions Development | Protofire</title>
        <meta
          name="description"
          content="Do you need your protocol to grow and increase TVL? Our team can help you develop the best strategies to improve your TVL. Contact us for details."
        />
        <meta
          name="keywords"
          content="tvl, stable coin, defi solution, auditing procedure, defi crypto, best database software, best database management software"
        />
      </Helmet>
      <section
        className="lg:flex md:flex lg:px-60 md:px-20 text-blue200 relative z-10
        text-xs  pt-10 sm:px-5"
        style={{
          fontFamily: "Spartan-Medium",
        }}
      >
        <Link to="/" className=" mr-1 text-blue200">
          Home
        </Link>
        /
        <Link to="/services" className="ml-1 mr-1 text-blue200">
          Services
        </Link>
        /
        <Link to="/services" className="ml-1 text-blue100">
          TVL Solutions
        </Link>
      </section>
      <div className="relative lg:block md:hidden sm:hidden z-1 ">
        <OvalCareerBig className=" absolute ovalBigServicePages1" />
      </div>
      <div className="relative lg:hidden md:hidden sm:block z-1 ">
        <OvalCareerMobile2 className=" absolute bgOvalServicePages2" />
      </div>
      <section
        className="sm:w-full sm:text-3xl 
       sm:px-5 md:px-10 lg:px-60 py-10 text-blue100"
      >
        <p className="sm:text-3xl text-4xl pt-10 text-blue100">
          {" "}
          TVL Solutions
        </p>
        <p
          className=" text-blue200 text-base m:w-full leading-6"
          style={{ fontFamily: "Spartan-Medium" }}
        >
          {description.first}
        </p>
        <p
          className=" text-blue200 text-base m:w-full leading-6 pb-10"
          style={{ fontFamily: "Spartan-Medium" }}
        >
          {description.second}
        </p>
        <div
          className=" lg:w-3/5 md:w-4/5 sm:w-full text-blue200 text-sm relative z-10"
          style={{
            fontFamily: "Spartan-SemiBold",
          }}
        >
          <p className=" text-2xl text-blue100 pt-2">
            {" "}
            {headings.problem.title}
          </p>
          <div className="dotList">
            {headings.problem.list1.map((item) => (
              <p key={item} className="leading-5 ">
                {item}
              </p>
            ))}
          </div>
          <p className="leading-5 pt-5 pb-1">
            {headings.problem.firstParagraph}
          </p>
          <div className="dotList">
            {headings.problem.list2.map((item) => (
              <li key={item} className="leading-5 ">
                {item}
              </li>
            ))}
          </div>
        </div>
        <div
          className=" py-10 lg:w-3/5 md:w-4/5 sm:w-full text-blue200 text-sm relative z-10"
          style={{
            fontFamily: "Spartan-SemiBold",
          }}
        >
          <p className=" text-2xl text-blue100 pt-2">
            {" "}
            {headings.solution.title}
          </p>
          <p className="leading-5 pt-2 ">{headings.solution.firstParagraph}</p>
          <div className="dotList">
            {headings.solution.list1.map((item) => (
              <li key={item} className="leading-5">
                {item}
              </li>
            ))}
          </div>
        </div>
      </section>
      <div className="lg:mx-40 md:mx-0 sm:mx-0 relative ">
        <section
          className="bg-darkBackground lg:px-20  relative z-10
        text-sm text-blue100 lg:py-10 sm:py-10 sm:w-full"
          style={{
            fontFamily: "Spartan-SemiBold",
          }}
        >
          <div className="lg:grid lg:grid-cols-5">
            <p className=" text-2xl lg:px-0 md:px-10 sm:px-5 lg:col-span-2">
              {" "}
              What we can provide:{" "}
            </p>
            <div
              className="grid lg:grid-cols-3 sm:grid-cols-2 md:grid-cols-3 
             lg:py-0 gap-5 lg:gap-10 lg:col-span-4"
            >
              {whatWeProvide.map((item) => (
                <div className="lg:py-5 lg:p-0 icons lg:ml-5 lg:text-center sm:px-5 md:px-10">
                  <img
                    src={item.icone}
                    alt={item.name}
                    className="lg:m-auto sm:w-full"
                  />
                  <p className="pt-2"> {item.name}</p>
                  <p
                    className="text-blue200"
                    style={{ fontFamily: "Spartan-Medium" }}
                  >
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      <div className="relative lg:block md:hidden sm:hidden z-1 ">
        <BgTop className=" absolute bgtop" />
      </div>
      <div className="relative lg:block md:hidden sm:hidden z-10 ">
        <BgMiddle className=" absolute bgMiddle" />
      </div>
      <section className="pt-20 ">
        <Tabs
          className=" text-sm text-blue200"
          style={{
            fontFamily: "Spartan-SemiBold",
          }}
        >
          <TabList className=" m-auto lg:px-60 sm:px-2 sm:text-xs md:px-10">
            {" "}
            {tabs.map((tab) => (
              <Tab key={tab.tabName} onClick={() => setActiveTab(tab.tabName)}>
                {tab.tabName}
              </Tab>
            ))}
          </TabList>

          {tabs.map((tab) => (
            <TabPanel
              className="bg-darkBackground lg:px-60 sm:px-5 md:px-10 pb-0"
              key={tab.tabName}
            >
              <div className="lg:w-3/5 md:w-3/5 sm:w-full ">
                <h2 className="text-2xl text-blue100">{tab.tabName}</h2>
                <p className="leading-6">{tab.tabDrescriptionP1}</p>
                <p className="leading-6">{tab.tabDrescriptionP2}</p>
                <p className="leading-6">{tab.tabDrescriptionP3}</p>
              </div>
              <div className="lg:pt-2 lg:w-3/5 md:w-3/5 sm:w-full">
                <p className="text-blue100 text-lg py-2">
                  {" "}
                  {tab.protofireCan.head}
                </p>
                <p className="text-blue200"> {tab.protofireCan.title}</p>
                <div className="dotList">
                  {tab.protofireCan.list.map((item) => (
                    <li key={item} className="leading-5">
                      {item}
                    </li>
                  ))}
                </div>
              </div>
              <div className="lg:pt-8 lg:w-3/5 md:w-3/5 sm:w-full ">
                <p className="text-blue100 text-lg pt-2">
                  {" "}
                  {tab.protofireCan2?.head}
                </p>
                <p className="text-blue200 py-1"> {tab.protofireCan2?.title}</p>
                <div className="dotList">
                  {tab.protofireCan2?.list?.map((item) => (
                    <li key={item} className="leading-6">
                      {item}
                    </li>
                  ))}
                  <p className="text-blue200 py-1 leading-6">
                    {" "}
                    {tab.protofireCan2?.firstParagraph}
                  </p>
                </div>
              </div>

              <TabList className="lg:inline-flex md:inline-flex text-blue200 sm:hidden">
                <p className="pr-5  text-blue100">
                  {" "}
                  Learn more about other services:{" "}
                </p>
                {tabs.map((tab) =>
                  tab.tabName !== activeTab ? (
                    <Tab onClick={() => setActiveTab(tab.tabName)}>
                      {tab.tabName}{" "}
                      {tab.tabName !== "Insurance Pool & Safety Module" && (
                        <span className="ml-3">|</span>
                      )}
                    </Tab>
                  ) : (
                    ""
                  )
                )}
              </TabList>
            </TabPanel>
          ))}
        </Tabs>
      </section>
      <section className=" py-20 lg:px-60 md:px-10 sm:px-5">
        <p
          style={{ fontFamily: "Spartan-SemiBold", fontSize: "14px" }}
          className="tracking-wider text-blue200 lg:px-0 md:px-0"
        >
          {" "}
          JOIN US AT ANY STAGE WITH YOU PROJECT
        </p>
        <p className="text-3xl text-blue100 mt-3 lg:px-0 md:px-0">
          {" "}
          Our lean execution philosophy
        </p>
        <JoinUs className="w-full mt-5 h-full sm:hidden lg:block md:block" />
        <JoinUsMobile className="lg:hidden md:hidden mt-10 w-full h-full" />
      </section>
      <section className="lg:px-40 m-auto">
        <ContactUs />
      </section>
    </div>
  );
}

export default TvlSolutions;
