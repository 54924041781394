/* eslint-disable react/no-unescaped-entities */
function ContactSection() {
  return (
    <div
      className="text-sm text-blue200 leading-5 pb-10"
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <h2 className="text-blue100 text-4xl">Contact us</h2>
      <p>
        If you have questions or concerns about how your information is handled,
        please direct your inquiry to Altoros LLC, which we have appointed as
        responsible for facilitating such inquiries.
      </p>

      <p>
        E-Mail:{" "}
        <a href="mailto:dpo@altoros.com" className="text-blue">
          {" "}
          dpo@altoros.com
        </a>{" "}
      </p>

      <p>Altoros LLC, 1607 Ponce de Leon Avenue, GM-06, San Juan, PR, 00909</p>
      <p>Register number: 416164</p>
    </div>
  );
}

export default ContactSection;
