import makerDAO from "../../assets/projectsImages/makerDAONew.png";
import makerDAO1 from "../../assets/projectsImages/makerDAO1.png";
import makerDAO2 from "../../assets/projectsImages/makerDAO2.png";
import m1 from "../../assets/services/65.png";
import m11 from "../../assets/services/66.png";
import m2 from "../../assets/services/81.png";
import m22 from "../../assets/services/82.png";
// eslint-disable-next-line import/prefer-default-export
export const project = {
  name: "MakerDAO Governance Analytics Dashboard",
  hashtags: ["Dashboard", "DeFi", "Governance", "Interface", "Monitor"],
  description: `Protofire built Maker Governance Analytics Dashboard. 
  It provides users with a single location to track the latest updates and view 
  historical data. Through the dashboard, it is possible to access information 
  crucial for voting and polling, such as a stability fee, debt ceiling, 
  multi-collateral DAI assets, etc. In addition, the dashboard allows for 
  monitoring overall engagement metrics in the MakerDAO system`,
  thumbnail: makerDAO,
  thumbnailAlt: `Protofire built Maker Governance Analytics Dashboard for MakerDAO.`,
  aboutProject: {
    title: "About the project",
    lists: [
      [
        `An analytics dashboard for the MakerDAO Governance`,
        "https://mkrgov.science/",
      ],
      [
        `Users have a single location to track the latest updates and 
      view historical data`,
      ],
      [`The dashboard is intuitive and with an easy-to-navigate interface`],
      [
        `Through the dashboard, it is possible to access information crucial for 
      oting and polling, such as a stability fee, debt ceiling, multi-collateral 
      DAI assets, etc`,
      ],
      [`Monitoring of overall engagement metrics in the MakerDAO system`],
    ],
    sellVolume: ["$16B", "MakerDAO TVL (Q1/2022)"],
    batches: ["U$9.5B", "DAI outstanding (Q1/2022)"],
  },
  theClient: {
    title: "The client/partner",
    firstParagraph: `Launched in 2014, Maker is a decentralized autonomous 
    organization (DAO) building a protocol on top of the Ethereum blockchain. 
    The protocol allows for lending and borrowing digital assets with no middlemen 
    involved. In MakerDAO, token holders perform governance through polling and
     voting for protocol changes, while managing DeFi risks. One of the unique 
     goals behind MakerDAO was to create its own stablecoin - DAI - that is backed 
     by crypto collaterals and stored publicly on the Ethereum blockchain`,
  },
  theProblem: {
    title: "The problem",
    firstParagraph: [
      `MakerDAO team proposed that someone build their governance dashboard 
      through a grant:`,
      `https://forum.makerdao.com/t/taken-a-governance-dashboard/87`,
    ],
    secondParagraph: [
      `“We’re looking for someone to build out a governance 
    dashboard that will provide the community a single location to view historical 
    data related to voting and polling and to track overall engagement metrics in 
    the system”. The dashboard should contain several specific metrics, as well as 
    the code, must be open-sourced and permissively licensed.`,
      `https://github.com/protofire/maker-governance-dashboard/`,
    ],

    thirdParagraph: [
      `On-chain governance is a system for managing and implementing changes 
      to cryptocurrency blockchains. It includes rules for instituting changes.
       Developers propose changes through code updates and each node or 
       participant votes.
`,
    ],
  },
  theMedia: {
    title: "Media",
    firstMedia: {
      text: "Protofire Introduces Maker Governance Analytics Dashboard v.1:  ",
      link: "https://medium.com/protofire-blog/protofire-introduces-maker-governance-analytics-dashboard-v-1-d96c19a1effc",
    },
  },
  theSolution: {
    title: "The solution",
    firstParagraph: `The MakerDAO dashboard was delivered as requested:`,
    lists: [
      `Users can have a clear and precise view of the MakerDAO governance`,
      `Users can make well-informed decisions on their investments`,
      `Well-documented open-source codebase enables new developers to 
      interact with the protocol and process it quickly`,
      `The data that is exhibited is related to voting and polling for system 
      parameters, such as Stability Fee, debt ceiling, Multi-Collateral
       DAI assets, etc
`,
    ],
    secondParagraph: `The solution’s functionality allows users to track:`,
    list2: [
      `Polls and votes`,
      `The total stake weight per option/per vote`,
      `Changes in the total stake over time for active polls and votes`,
      `he number of voters per poll/vote`,
      `Changes in the voters over time`,
      `The Gini coefficient of Maker over time`,
      `The time spent on an Executive Vote to pass`,
      `The number of votes not being ratified`,
    ],
  },
  technologyStack: {
    title: "Technology stack ",
    lauguages: ["React", "Node.js", "Python", "AWS", "CloudFormation"],
    database: [
      "Redis / ElastiCache",
      "RDS / PostgreSQL",
      "L1 blockchains (full nodes)",
    ],
    tools: ["Storybook", "Styled Components", "Jest Rust"],
  },
  code: {
    text: "Code",
    codes: [
      {
        text: "MakerDAO Governance Dashboard repository",
        link: "https://github.com/protofire/maker-governance-dashboard/",
      },
    ],
  },
  images: [
    {
      original: makerDAO1,
      originalAlt:
        "Protofire built Maker Governance Analytics Dashboard for MakerDAO to provide its users with a single location to track the latest updates and view historical data.",
      thumbnail: makerDAO1,
      thumbnailAlt:
        "Protofire built Maker Governance Analytics Dashboard for MakerDAO to provide its users with a single location to track the latest updates and view historical data.",
    },
    {
      original: makerDAO2,
      originalAlt:
        "Protofire built Maker Governance Analytics Dashboard for MakerDAO to let users access information crucial for voting and polling, such as a stability fee, debt ceiling, and multi-collateral DAI assets.",
      thumbnail: makerDAO2,
      thumbnailAlt:
        "Protofire built Maker Governance Analytics Dashboard for MakerDAO to let users access information crucial for voting and polling, such as a stability fee, debt ceiling, and multi-collateral DAI assets.",
    },
  ],
  ourTrackRecord: [
    [
      m1,
      m11,
      "https://medium.com/protofire-blog/protofire-introduces-livepeer-tools-2-0-f5b748a56eb2",
      "newWindow",
      "Protofire developed Livepeer Tools Dashboard for Livepeer.tools2.0 to provide statistical calculations, account summaries, and a variety of status notifications for Delegates.",
    ],
    [
      m2,
      m22,
      "https://medium.com/protofire-blog/monitoring-pending-transactions-in-polkadot-with-mempool-explorer-36e88e8a41ed",
      "newWindow",
      "We delivered Mempool Explorer to Polkadot which monitors transaction hashes, block numbers, extrinsic types, and other temporal data.",
    ],
  ],
};
