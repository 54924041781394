import { Link } from "react-router-dom";
import logo from "../../components/assets/solhint/solhint-logo.png";
import protofireLogo from "../../components/assets/images/protofire.svg";
import downloadIcon from "../../components/assets/solhint/icons/document-upload.svg";
import SocialMedia from "../../components/projects/solhint/socialMedia/socialMedia";

function SolhintLayoutHeader() {
  return (
    <header>
      <nav className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between py-8 flex-col lg:flex-row">
          <div className="mx-auto lg:mx-0 flex justify-center sm:justify-start flex-col lg:flex-row items-center">
            <div className="flex flex-shrink-0 items-center mb-4 lg:mb-0">
              <img
                className="h-8 w-auto pr-8 border-r-[1px] border-r-solhintGray"
                src={logo}
                alt="Solhint"
              />
              <Link to="/" className="ml-8">
                <img
                  className="h-6 w-auto"
                  src={protofireLogo}
                  alt="Protofire"
                />
              </Link>
            </div>
            <div className="hidden lg:ml-10 sm:block">
              <div className="flex space-x-4">
                <a
                  href="#download"
                  className="text-white hover:text-white hover:underline px-3 py-2 font-Inter"
                  aria-current="page"
                >
                  <img
                    className="inline mr-2 mb-1"
                    src={downloadIcon}
                    alt="Download"
                  />
                  Download
                </a>
                <a
                  href="#about"
                  className="text-white hover:text-white hover:underline px-3 py-2 font-Inter"
                >
                  About
                </a>
                <a
                  href="#contact"
                  className="text-white hover:text-white hover:underline px-3 py-2 font-Inter"
                >
                  Contact
                </a>
              </div>
            </div>
          </div>
          <div className="flex">
            <SocialMedia />
          </div>
        </div>
      </nav>
    </header>
  );
}

export default SolhintLayoutHeader;
