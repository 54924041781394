// eslint-disable-next-line import/prefer-default-export

import gnosis from "../assets/projects/gnosis.png";
import cowswap from "../assets/projects/cowswap.png";
import swarmMarket from "../assets/projects/swarmMarket.png";
import makerDAO from "../assets/projects/makerDAO.png";
import aragonDAO from "../assets/projects/aragonDAO.png";
import armanino from "../assets/projects/armanino.png";
import graph from "../assets/projects/graph.png";
import chainlink from "../assets/projects/chainlink.png";
import gnosisConditional from "../assets/projects/gnosisConditional.png";
import kyberDAO from "../assets/projects/kyberDAO.png";
import opyn from "../assets/projects/opyn.png";
import filecoin from "../assets/projects/filecoin.png";
import oxLauch from "../assets/projects/oxLauch.png";
import solhint from "../assets/projects/solhint.png";
import chainlinkDevTools from "../assets/projects/chainlink-dev-tooling.png";
import singleButtonSolution from "../assets/projects/single-button-solution.png";
import monitoringForGiga from "../assets/projects/monitoring-for-giga.png";

export const indexContent = {
  hashtags: [
    "ALLTAGS",
    "API",
    "Audit",
    "Big Data Protocol",
    "Chainlink",
    "Dashboard",
    "dApp",
    "DeFi",
    "DevOps",
    "DEX",
    "Explorer",
    "Foundry",
    "Governance",
    "Insurance",
    "Interface",
    "Integration",
    "KYC",
    "Lauch Kit",
    "Monitor",
    "Node Management",
    "Oracle",
    "OTC",
    "Payments",
    "Platform",
    "SDK",
    "Smart Contract",
    "Solution",
    "Subgraph",
    "Wallet",
  ],
};

export const cards = [
  {
    hashtags: ["Chainlink", "Foundry"],
    cards: [chainlinkDevTools],
    cardAlt: `Chainlink Developer Tools`,
    page: "/projects/chainlink-dev-tools",
  },
  {
    hashtags: ["Big Data Protocol", "Solution"],
    cards: [singleButtonSolution],
    cardAlt: `Single-button Solution for Big Data Protocol`,
    page: "/projects/single-button-solution",
  },
  {
    hashtags: ["Big Data Protocol", "Solution"],
    cards: [monitoringForGiga],
    cardAlt: `Monitoring Platform for Giga`,
    page: "/projects/monitoring-for-giga",
  },
  {
    hashtags: ["Explorer", "Node Management"],
    cards: [solhint],
    cardAlt: `Solhint is a Solidity linter that helps to write high-quality smart contracts code, securely and bug-free. Contact Protofire for similar tool development.`,
    page: "/projects/solhint",
  },
  {
    hashtags: ["dApp", "intergace", "Smart Contract", "Wallet"],
    cards: [gnosis],
    cardAlt: `Protofire developed Gnosis Safe Multisig, a customized crypto wallet on Ethereum that requires a predefined number of signatures to confirm transactions to prevent unauthorized access to the assets stored.`,
    page: "/projects/gnosis-safe-wallet",
  },
  {
    hashtags: ["dApp", "DeFi", "DEX", "Interface", "SDK"],
    cards: [cowswap],
    cardAlt: `Protofire helped CoW Protocol to rebrand, develop, and test the CowSwap DEX's interface built on top of the protocol for trading tokens via gas-less orders.`,
    page: "/projects/cowswap-dex",
  },
  {
    hashtags: ["Subgraph", "DevOps", "Node Management"],
    cards: [graph],
    cardAlt: `As a Subgraphs and Indexer Node on The Graph, Protofire assists The Graph in onboarding Dapps to the networ through validation of of smart contracts and on-chain data and subgraph development.`,
    page: "/projects/the-graph-subgraphs",
  },
  {
    hashtags: ["Oracle", "Integration", "API", "DevOps"],
    cards: [chainlink],
    cardAlt: `Protofire works with Chainlink to bring oracle infrastructure to Web3 ecosystems through testing integrations on EVM and non-EVM blockchains, and developling Chainlink External Adapters that connect smart contracts to premium web APIs.`,
    page: "/projects/chainlink-integration",
  },
  {
    hashtags: ["dApp", "Dashboard", "DeFi", "DEX", "KYC", "OTC"],
    cards: [swarmMarket],
    cardAlt: `Protofire developed the architecture, smart contracts, and front end for Swarm Markets DEX to provide compliant multi-asset DeFi infrastructure.`,
    page: "/projects/swarm-dex",
  },
  {
    hashtags: ["API", "Explorer", "DevOps", "Node Management"],
    cards: [filecoin],
    cardAlt: `Protofire works with the Protocol Labs developing open-source solutions to improve the Filecoin network growth and Filecoin node infrastructure.`,
    page: "/projects/filecoin-node-infrastructure",
  },
  {
    hashtags: ["Dashboard", "Monitor", "Interface", "Governance", "DeFi"],
    cards: [makerDAO],
    cardAlt: `Protofire built Maker Governance Analytics Dashboard for MakerDAO to provide users with a single location to track the latest updates and view historical data.`,
    page: "/projects/makerdao-dashboard",
  },
  {
    hashtags: ["Platform"],
    cards: [armanino],
    cardAlt: `Protofire assists Armanino in the development of solutions under the TrustExplorer project for real-time audit, asset management, data query service, and treasury management.`,
    page: "/projects/armanino-platform",
  },
  {
    hashtags: ["Platform", "Audit", "Insurance", "Interface", "Integration"],
    cards: [opyn],
    cardAlt: `Protofire supported Opyn in the development of the user interface of their Decentralized Insurance Platform to enable option sellers and buyers to earn premiums on collateral liquidation.`,
    page: "/projects/opyn-platform",
  },

  {
    hashtags: ["dApp", "Dashboard", "Payments"],
    cards: [aragonDAO],
    cardAlt: `Protofire developed a Payroll dApp for AragonDAO that allows users to incorporate new employees, request and pre-authorize payments in different tokens, and view all the payroll information.`,
    page: "/projects/aragon-dapp",
  },
  {
    hashtags: ["dApp", "Explorer", "Interface"],
    cards: [gnosisConditional],
    cardAlt: `Protofire helped Gnosis to develop Conditional Tokens Explorer for the Gnosis ecosystem.`,
    page: "/projects/gnosis-explorer",
  },
  {
    hashtags: ["Smart Contract", "Governance"],
    cards: [kyberDAO],
    cardAlt: `Protofire created a trustless smart contract proxy for the KyberDAO governance enabling a user to become a pool operator and perform token staking/voting without the need to create new smart contracts.`,
    page: "/projects/kyber-smart-contract",
  },
  {
    hashtags: ["Lauch Kit"],
    cards: [oxLauch],
    cardAlt: `Protofire developed a repository that contains an open-source, free-to-use 0x relayer template which helps to start a project.`,
    page: "/projects/ox-launch-kit",
  },
];
