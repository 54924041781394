import { useState } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Protofire } from "../assets/images/protofire.svg";
import { ReactComponent as Github } from "../assets/images/github.svg";
import { ReactComponent as Linkedin } from "../assets/images/linkedin.svg";
import { ReactComponent as Medium } from "../assets/images/medium.svg";
import { ReactComponent as Twitter } from "../assets/images/twitter.svg";
import { ReactComponent as Discord } from "../assets/images/discord.svg";
import { ReactComponent as Instagram } from "../assets/images/instagram.svg";
import { ReactComponent as Mirror } from "../assets/images/mirror.svg";
import { ReactComponent as Youtube } from "../assets/images/youtube.svg";

import MobileFooter from "./mobileFooter";
import PrivacyPolicy from "../privacyPolicy/privacyPolicy";
import getCurrentYear from "../../utils/utils";

function Footer() {
  const [modalOpen, setModalOpen] = useState(false);
  const currentYear = getCurrentYear();
  return (
    <div className="">
      <nav
        className="sm:hidden lg:block lg:px-10 md:px-10 
       pt-3  py-2 z-10   w-full bg-background "
      >
        <div className=" flex flex-wrap justify-between items-center mx-auto">
          <div className="flex md:order-2 z-10">
            <a
              href="https://github.com/protofire"
              target="_blank"
              rel="noreferrer"
              className="text-blue100 py-2 pr-3 pl-3  hover:scale-150"
            >
              <Github />
            </a>
            <a
              href="https://www.linkedin.com/company/protofire-io/"
              target="_blank"
              rel="noreferrer"
              className="text-blue100 py-2 pr-3 pl-3  hover:scale-150"
            >
              <Linkedin />
            </a>
            <a
              href="https://medium.com/protofire-blog"
              target="_blank"
              rel="noreferrer"
              className="text-blue100 py-2 pr-3 pl-3 hover:scale-150"
            >
              <Medium />
            </a>
            <a
              href="https://twitter.com/protofire"
              target="_blank"
              rel="noreferrer"
              className="text-blue100 py-2 pr-3 pl-3 hover:scale-150"
            >
              <Twitter />
            </a>
            <a
              href="https://discord.gg/y5TE8TcPGn"
              target="_blank"
              rel="noreferrer"
              className="text-blue100 py-2 pr-3 pl-3 hover:scale-150"
            >
              <Discord />
            </a>
            <a
              href="https://www.instagram.com/protofire.io/"
              target="_blank"
              rel="noreferrer"
              className="text-blue100 py-2 pr-3 pl-3 hover:scale-150"
            >
              <Instagram />
            </a>
            <a
              href="https://mirror.xyz/protofiredao.eth"
              target="_blank"
              rel="noreferrer"
              className="text-blue100 py-2 pr-3 pl-3 hover:scale-150"
            >
              <Mirror />
            </a>
            <a
              href="https://www.youtube.com/channel/UCAQJ1nXk7fNNnbbZuJzoH5w"
              target="_blank"
              rel="noreferrer"
              className="text-blue100 py-2 pr-3 pl-3 hover:scale-150"
            >
              <Youtube />
            </a>
          </div>
          <div
            className="hidden justify-between items-center w-full md:flex 
            md:w-auto md:order-1 z-10"
            id="mobile-menu-4"
          >
            <Link className="flex items-center mr-10 pr-10" to="/">
              <Protofire className="sm:w-4/5" />
            </Link>
            <ul
              className="flex text-xs flex-col mt-4 md:flex-row text-blue200
            md:space-x-8 md:mt-0 md:font-medium tracking-widest"
              style={{
                fontSize: "10px",
                fontFamily: "Spartan-SemiBold",
              }}
            >
              <li>
                <Link
                  to="/about"
                  className="block py-2 pr-4 pl-3  border-gray-100 
                  md:p-0 hover:text-blue100 "
                >
                  ABOUT
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className="block py-2 pr-4 pl-3  border-gray-100 
                  md:p-0 hover:text-blue100"
                >
                  SERVICES
                </Link>
              </li>
              <li>
                <Link
                  to="/projects"
                  className="block py-2 pr-4 pl-3  border-gray-100 
                  md:p-0 hover:text-blue100 "
                >
                  PROJECTS
                </Link>
              </li>
              <li>
                <Link
                  to="/careers"
                  className="block py-2 pr-4 pl-3   border-gray-100 
                  hover:text-blue100
                  md:p-0 "
                >
                  CAREERS
                </Link>
              </li>
              <li>
                <a
                  href="https://protofire.io/community"
                  target="_blank"
                  className="block py-2 pr-4 pl-3   hover:text-blue100 md:p-0"
                  rel="noreferrer"
                >
                  COMMUNITY
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="py-3 mb-10">
          <p
            className="text-xs text-blue200 float-left"
            style={{
              fontFamily: "Spartan-Thin",
            }}
          >
            {currentYear}. Protofire. All rights reserved
          </p>
          <PrivacyPolicy modalOpen={modalOpen} setModalOpen={setModalOpen} />
          <button
            className="float-right pr-5 cursor-pointer textGradient"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            Privacy Policy
          </button>
        </div>
      </nav>
      <div className="px-10 sm:px-5 pb-10 lg:hidden">
        <MobileFooter />
      </div>
    </div>
  );
}

export default Footer;
