import aboutGroup from "../assets/about/aboutGroup.png";
import armaninoGrey from "../assets/about/armaninoGrey.png";
import armaninoWhite from "../assets/about/armaninoWhite.png";
import gnosisGrey from "../assets/about/gnosisGrey.png";
import gnosisWhite from "../assets/about/gnosisWhite.png";
import graphGrey from "../assets/about/graphGrey.png";
import graphWhite from "../assets/about/graphWhite.png";
import fileGrey from "../assets/about/filecoin-logo-grey.png";
import fileWhite from "../assets/about/filecoin-logo-white.png";
import mobileAbout from "../assets/about/mobileAbout.png";

// eslint-disable-next-line import/prefer-default-export
export const content = {
  name: "About Protofire",
  description: `Protofire is a team of blockchain experts who ignite exponential growth in TVL and user adoption for L1 and L2 blockchain networks and DeFi protocols, focusing on measurable results and sustained success.`,
  description2: `We build comprehensive long-term growth solutions through strategic planning, seamless compatibility, and innovative marketing initiatives.`,
  ourMission: {
    title: "Our Mission",
    list: [
      "1B users and",
      "$750B+ of TVL (Total Value Locked)",
      "into crypto ecosystems by developing 10%+ of the early code base at 50 major crypto economies within 10 years.",
    ],
  },
  ourVision: {
    title: "Our Vision",
    list: [
      `Protofire aims to establish long-term partnerships with the projects we are part of, acting 
      as ambassadors for the community, and participating in the network by running nodes and staking.`,
    ],
  },
  protofireExpertise: {
    title: "Protofire Expertise",
    firstP: `We have a proven track record of delivering innovative solutions in blockchain 
    development, contributing hands-on coding to supercharge developer adoption and network 
    usage. Our expertise covers all aspects of blockchain development, including smart contract 
    development, DeFi, oracle integrations, subgraph development, and more.
    `,
  },
  first: {
    title:
      "We have capitalized wide experience over all blockchain processes such as: ",
    firstList: [
      "Node infrastructure  management",
      "Backend & frontend development ",
      "Smart contract development",
      "DeFi",
    ],
    secondList: [
      "Oracles and integrations",
      "Subgraph development",
      "Multisig and mobile wallets",
      "Developer tools",
    ],
  },
  second: {
    title:
      "Protofire helps DAOs and networks to solve their most important problems:",
    list: [
      "Growing the community of users around the network",
      "Allowing users to invest and stake in the network safely",
      "Connecting the network to other networks",
      "Promoting integration between a network and outside data",
      "Providing tools for developers to build on the network",
    ],
  },
  aboutImage: aboutGroup,
  aboutImageAlt:
    "Join the Protofire team to work on the most challenging projects in the Web3 ecosystem.",
  mobileAbout,
};

export const tableContent = [
  {
    otherDev: "Limited experience over a few existing blockchain solutions",
    protofire: "Expertise over the whole blockchain development process",
    key: 0,
  },
  {
    otherDev: "Short-term projects",
    protofire: "Long-term partnerships",
    key: 1,
  },
  {
    otherDev: "Centralized structure",
    protofire: "DAO structure",
    key: 2,
  },
  {
    otherDev: "Company team",
    protofire: "Exclusive team",
    key: 3,
  },
  {
    otherDev: "Financed in dollars",
    protofire: "Financed with project’s tokens",
    key: 4,
  },
  {
    otherDev: "Lack of integration with the ecosystem",
    protofire:
      "Partnership and experience with the major networks in the ecosystem",
    key: 5,
  },
];

export const otherDev = tableContent.map(function (item) {
  return item.otherDev;
});

// eslint-disable-next-line func-names
export const protofire = tableContent.map(function (item) {
  return item.protofire;
});

export const tabs = [
  {
    name: "gnosis",
    logo: [gnosisGrey, gnosisWhite],
    key: 1,
    active: true,
    description:
      "Protofire has been contributing to numerous projects and updates in the Gnosis ecosystem since 2019.",
    list: [
      {
        head: "Gnosis Safe Wallet",
        body: `We have helped with the development of the web, desktop, and mobile 
        versions of the Gnosis Safe Wallet, the most trusted multisignature wallet 
        in the ecosystem.`,
        repo: {
          name: "View More",
          link: "https://gnosis-safe.io/app/welcome",
        },
      },
      {
        head: "Gnosis Safe Apps",
        body: `We have delivered Safe Apps on Gnosis Safe, such as Synthetix Mintr Safe App.`,
        repo: {
          name: "",
          link: "",
        },
      },
      {
        head: "SDK",
        body: `We have created a devkit to improve the synchronization of the code in the Gnosis apps.`,
        repo: {
          name: "",
          link: "",
        },
      },
      {
        head: "Conditional Tokens Framework",
        body: `We have taken part in the creation of the interface for the 
        Gnosis Conditional Tokens framework.`,
        repo: {
          name: "View More",
          link: "https://medium.com/protofire-blog/protofire-contributes-to-the-development-of-the-conditional-token-explorer-5fb4f305c1f4",
        },
      },
      {
        head: "Deployment, Maintenance and Support",
        body: `Our Gnosis Safe team has deployed Gnosis Safe in several networks including Moonbeam and Evmos.`,
        repo: {
          name: "View More",
          link: "https://multisig.moonbeam.network/",
        },
      },
      {
        head: "Gnosis Chain Validator",
        body: `We are a Gnosis Chain and Gnosis Beacon Chain validator.`,
        repo: {
          name: "",
          link: "",
        },
      },
      {
        head: "CowSwap DEX",
        body: `We have helped the CoW team to rebrand the Gnosis Protocol to the CowSwap DEX.`,
        repo: {
          name: "View More",
          link: "https://cowswap.exchange/",
        },
      },
      {
        head: "CoW Protocol Subgraph",
        body: `We have deployed the CoW subgraph in The Graph network.`,
        repo: {
          name: "",
          link: "",
        },
      },
      {
        head: "CoW Protocol Explorer",
        body: `We have updated the CoW explorer to align it with the protocol’s most relevant functionality.`,
        repo: {
          name: "View More",
          link: "https://explorer.cow.fi/",
        },
      },
    ],
  },
  {
    name: "graph",
    logo: [graphGrey, graphWhite],
    key: 2,
    active: false,
    description:
      "Since 2019, Protofire has delivered a variety of solutions to The Graph ecosystem and its subgraphs.",
    list: [
      {
        head: "The Graph incentivized testnet (Mission Control)",
        body: `Mission Control is an initiative to create a testnet where Indexers can contribute and make 
        improvements to the network before The Graph was launched on the mainnet.`,
        repo: {
          name: "",
          link: "",
        },
      },
      {
        head: "The Graph Indexer Node",
        body: `The idea of this project is to develop and implement a recovery plan for Ethereum nodes in case 
        of their breaching, compromising, or other crises.`,
        repo: {
          name: "",
          link: "",
        },
      },
      {
        head: "The Graph Subgraphs",
        body: `Protofire provides implementation services for The Graph to help build open-source subgraphs for 
        the ecosystem. Protofire has already successfully deployed more than 50 subgraphs.`,
        repo: {
          name: "",
          link: "",
        },
      },
    ],
    comment: {
      body: `We have been working with Protofire for over a year, and they are a 
      huge asset to the community. They understand DeFi and Web3 protocols in 
      detail and continue to find creative ways to build new features and products 
      to push the space forward. We are excited that Protofire has chosen to make 
      The Graph a core part of their stack and recommend them highly to any teams 
      looking to accelerate product development.`,
      author: "Yaniv Tal",
      title: "Co-Founder of The Graph and Edge & Node, CEO of Geo",
    },
  },
  {
    name: "filecoin",
    logo: [fileGrey, fileWhite],
    key: 3,
    active: false,
    description:
      "Since 2020, Protofire has been a long-term partner of Filecoin. Our dev team assists Filecoin in the development of tools and features for their network.",
    list: [
      {
        head: "Filecoin Glif",
        body: `Protofire has formed GLIF, a dedicated team that has been working with the 
        Filecoin team since the very first days of Filecoin’s testnet and mainnet. The GLIF 
        team is responsible for the creation of interoperable tools designed specifically for 
        the Filecoin network.`,
        repo: {
          name: "View More",
          link: "https://www.glif.io/",
        },
      },
      {
        head: "Filecoin GLIF Nodes",
        body: `To let more projects join the Filecoin network, the GLIF community provides public 
        and private node infrastructure services.`,
        repo: {
          name: "View More",
          link: "https://lotus.filecoin.io/developers/glif-nodes/",
        },
      },
      {
        head: "Filecoin Glif API",
        body: `The GLIF API Node is a publicly available hosted endpoint of Lotus, which is known 
        to be the most popular client for the Filecoin network.`,
        repo: {
          name: "View More",
          link: "https://api.node.glif.io/",
        },
      },
      {
        head: "Open-RPC playground for Lotus API",
        body: `The open-RPC playground for Lotus API defines a standard, programming 
        language-agnostic interface description for JSON-RPC APIs.`,
        repo: {
          name: "View More",
          link: "https://playground.open-rpc.org/?url=https://api.node.glif.io",
        },
      },
      {
        head: "Filecoin CID Checker",
        body: `The Filecoin CID Checker is a website and API service for listing all 
        CIDs along with their current status in the latest state tree.`,
        repo: {
          name: "View More",
          link: "https://filecoin.tools/",
        },
      },
    ],
  },
  {
    name: "armanino",
    logo: [armaninoGrey, armaninoWhite],
    key: 4,
    active: false,
    description: `Protofire has been collaborating with the Armanino team to deliver 
    core solutions to their project since 2019.`,
    list: [
      {
        head: "TrustExplorer Real-Time Audit",
        body: `A blockchain-based platform for financial accounting, 
        auditing, and reporting in real time. The solution allows users to dynamically 
        pull data on a circulating supply of tokens, as well as aggregate a total balance 
        of assets supporting the tokens.
        `,
        repo: {
          name: "View More",
          link: "https://www.armaninollp.com/software/trustexplorer/real-time-attest/",
        },
      },
      {
        head: "TrustExplorer Proof of Reserves",
        body: `A tool (platform) that provides users of centralized 
        exchanges with transparency and assurance over digital asset reserves held custodial 
        on their behalf.`,
        repo: {
          name: "",
          link: "",
        },
      },
      {
        head: "TrustExplorer Trusted Node",
        body: `A service that the participants of the Hedera Hashgraph network 
        can use to access transactional data. Meanwhile, financial statement auditors can acquire 
        information related to balance sheet accounting of material assets stored in a blockchain.`,
        repo: {
          name: "",
          link: "",
        },
      },
      {
        head: "TrustExplorer Treasury",
        body: `Treasury is a token management solution (dashboard) that provides all participants 
        with accurate information on token ownership and distribution in an easily digestible format. 
        That promotes growth of token usage, expands the user base and builds trust in the marketplace.
        `,
        repo: {
          name: "View More",
          link: "https://protofire.io/projects/armanino-platform",
        },
      },
    ],
    comment: {
      body: `The applications we design and deliver to clients provide assurance 
      over 10’s of billions of dollars to marketplaces, retail and institutional 
      customers, as well as regulators across the globe. The stakes are high and 
      the information produced from our applications must also meet multiple strict 
      professional CPA standards. Protofire has been a long-term partner that has 
      risen to this challenge; their team have provided high continuity, 
      collaborative spirit, excellent communication and outstanding 
      customer service.`,

      author: "Noah Buxton",
      title: "Partner, Practice Leader",
    },
  },
];
