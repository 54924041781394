/// <reference types="react-scripts" />
import gnosisThumbnail from "../../assets/projectsImages/gnosisSafeNew.png";
import cowswapThumbnail from "../../assets/projectsImages/cowswapDEXNew.png";
import thegraphThumbnail from "../../assets/projectsImages/theGraphNew.png";
import chainlinkThumbnail from "../../assets/projectsImages/chainLinkNew.png";
import swarmThumbnail from "../../assets/projectsImages/swarmNew.png";
import filecoinThumbnail from "../../assets/projectsImages/filecoinNew.png";
import makerdaoThumbnail from "../../assets/projectsImages/makerDAONew.png";
import armaninoThumbnail from "../../assets/projectsImages/armaninoNew.png";
import opynThumbnail from "../../assets/projectsImages/opynNew.png";
import aragondaoThumbnail from "../../assets/projectsImages/aragonNew.png";
import gnosisctThumbnail from "../../assets/projectsImages/gnosisNew.png";
import kyberdaoThumbnail from "../../assets/projectsImages/kyberswapNew.png";
import zeroxThumbnail from "../../assets/projectsImages/0xLaunchNew.png";
import singleButtonThumbnail from "../../assets/projectsImages/big-data-protocol.png";
import monitoringForGigaThumbnail from "../../assets/projectsImages/monitoring-platform-giga.png";

// Logo assets
import gnosisLogo from "../../assets/projectsImages/gnosis-safe.svg";
import cowswapLogo from "../../assets/projectsImages/cowswap.svg";
import thegraphLogo from "../../assets/projectsImages/the-graph.svg";
import chainlinkLogo from "../../assets/projectsImages/chainlink.svg";
import swarmLogo from "../../assets/projectsImages/swarm.svg";
import filecoinLogo from "../../assets/projectsImages/filecoin.svg";
import makerdaoLogo from "../../assets/projectsImages/makerdao.svg";
import armaninoLogo from "../../assets/projectsImages/armanino.svg";
import opynLogo from "../../assets/projectsImages/opynLogo.png";
import aragondaoLogo from "../../assets/projectsImages/aragondao.svg";
import gnosisctLogo from "../../assets/projectsImages/gnosisct.svg";
import kyberdaoLogo from "../../assets/projectsImages/kyberdao.svg";
import zeroxLogo from "../../assets/projectsImages/0x.svg";
import bigDataLogo from "../../assets/projectsImages/big-data.svg";
import gigaLogo from "../../assets/projectsImages/giga.svg";

// eslint-disable-next-line import/prefer-default-export
export const proContent = [
  {
    title: "Chainlink Developer Tools",
    label: "Chainlink Developer Tools",
    description: `The Chainlink Developer Tools, developed by Protofire, simplify interactions with Chainlink services and facilitate local testing of dApps that integrate them.`,
    firstLine: [],
    secondLine: [],
    thirdLine: [],
    screenshot: chainlinkThumbnail,
    screenshotAlt: `The Chainlink Developer Tools, developed by Protofire, simplify interactions with Chainlink services and facilitate local testing of dApps that integrate them.`,
    clientLogo: chainlinkLogo,
    clientLogoAlt: "Chainlink Developer Tools",
    tags: ["Chainlink", "Foundry"],
    page: "/projects/chainlink-dev-tools",
    key: 1,
  },
  {
    title: "Single-button Solution for Big Data Protocol",
    label: "Single-button Solution for Big Data Protocol",
    description: `Protofire has developed a Single-button solution for Big Data Protocol to help accelerate the deployment and testing of new Ocean features for its data marketplace.`,
    firstLine: [],
    secondLine: [],
    thirdLine: [],
    screenshot: singleButtonThumbnail,
    screenshotAlt: `Protofire has developed a Single-button solution for Big Data Protocol to help accelerate the deployment and testing of new Ocean features for its data marketplace.`,
    clientLogo: bigDataLogo,
    clientLogoAlt: "Single-button Solution for Big Data Protocol",
    tags: ["Big Data Protocol", "Solution"],
    page: "/projects/single-button-solution",
    key: 2,
  },
  {
    title: "Monitoring Platform for Giga",
    label: "Monitoring Platform for Giga",
    description: `Protofire has built a platform for Giga, an organization of which Unicef takes part of, that has as a goal to connect every school to the internet to empower people and help students develop.`,
    firstLine: [],
    secondLine: [],
    thirdLine: [],
    screenshot: monitoringForGigaThumbnail,
    screenshotAlt: `Protofire has built a platform for Giga, an organization of which Unicef takes part of, that has as a goal to connect every school to the internet to empower people and help students develop.`,
    clientLogo: gigaLogo,
    clientLogoAlt: "Monitoring Platform for Giga",
    tags: ["Big Data Protocol", "Solution"],
    page: "/projects/monitoring-for-giga",
    key: 3,
  },
  {
    title: "Gnosis Safe",
    label: "Gnosis Safe",
    description: `Protofire has worked alongside Gnosis Safe as a partner on 
      the development of the Gnosis Safe technology. Gnosis Safe 
      Multisig is a customizable crypto wallet that requires a 
      predefined number of signatures to confirm transactions 
      to provide greater security`,
    firstLine: ["$100B", "total value locked (TVL) (Q1/2022)"],
    secondLine: ["48+", "thousand total safes created (Q1/2022)"],
    thirdLine: ["458+", "thousand total transactions made (Q1/2022)"],
    screenshot: gnosisThumbnail,
    screenshotAlt: `Protofire has worked alongside Gnosis Safe as a partner on the development of the Gnosis Safe technology. Gnosis Safe Multisig is a customizable crypto wallet that requires a predefined number of signatures to confirm transactions to provide greater security.`,
    clientLogo: gnosisLogo,
    clientLogoAlt: "Gnosis Safe",
    tags: ["dApp", "Interface", "Smart Contract", "Wallet"],
    page: "/projects/gnosis-safe-wallet",
    key: 4,
  },
  {
    title: "CoW Protocol",
    label: "Cowswap DEX",
    description: `Protofire has been working in the rebranding, development, and testing of a web trading interface built on top of the CoW Protocol. Protofire has assisted in the development of the SDK, the Explorer, the Batch Viewer integration, UI & UX improvements, and The Graph integration.
    `,
    firstLine: ["$14.036B", "Total Volume (USD) (Q1/2022)"],
    secondLine: ["346,3K", "Total Trades (Q1/2022)"],
    thirdLine: ["2.6K", "Traded distinct tokens (Q1/2022)"],
    screenshot: cowswapThumbnail,
    screenshotAlt: `Protofire has been working in the rebranding, development, and testing of a web trading interface built on top of the CoW Protocol. Protofire has assisted in the development of the SDK, the Explorer, the Batch Viewer integration, UI & UX improvements, and The Graph integration.`,
    clientLogo: cowswapLogo,
    clientLogoAlt: "Cowswap DEX",
    tags: ["dApp", "DeFi", "DEX", "Interface", "SDK"],
    page: "/projects/cowswap-dex",
    key: 5,
  },
  {
    title: "The Graph",
    label: "The Graph Subgraphs and Indexer Node",
    description: `We assist The Graph on implementing the protocol for its clients. Our team helps to validate smart contracts and on-chain data, define the roadmap for subgraph development and adoption for new projects. We have deployed more than 30 subgraphs so far.`,
    firstLine: [],
    secondLine: [],
    thirdLine: [],
    screenshot: thegraphThumbnail,
    screenshotAlt: `Protofire assists The Graph in implementing the protocol for its clients. The Protofire team helps to validate smart contracts and on-chain data and define the roadmap for subgraph development and adoption for new projects. We have deployed more than 30 subgraphs so far.`,
    clientLogo: thegraphLogo,
    clientLogoAlt: "The Graph Subgraphs and Indexer Node",
    tags: ["DevOps", "Node Management", "Subgraph"],
    page: "/projects/the-graph-subgraphs",
    key: 6,
  },
  {
    title: "Chainlink",
    label: "Chainlink Integration and External Adapters",
    description: `We have a team that works alongside Chainlink to bring oracle infrastructure to several projects. We have promoted Chainlink Node deployment, tested integrations on many blockchains, developed External Adapters that connect smart contracts to premium web APIs, etc.`,
    firstLine: ["$22B", "Total Value Secured (Q1/2022)"],
    secondLine: ["1025+", "Total Oracle Networks (Q1/2022)"],
    thirdLine: ["2B", "On-chain Data Points (Q1/2022)"],
    screenshot: chainlinkThumbnail,
    screenshotAlt: `Protofire works with Chainlink to bring oracle infrastructure to Web3 projects and has promoted Chainlink Node deployment, tested integrations on many blockchains, developed External Adapters that connect smart contracts to premium web APIs.`,
    clientLogo: chainlinkLogo,
    clientLogoAlt: "Chainlink Integration and External Adapters",
    tags: ["API", "DevOps", "Integration", "Oracle"],
    page: "/projects/chainlink-integration",
    key: 7,
  },
  {
    title: "Swarm",
    label: "Swarm Markets DEX",
    description: `Protofire team has worked on the architecture, smart contracts, and front end for Swarm. KYC services are inbuilt within the platform. The team has implemented several DEX functionalities - swapping, pooling, Multi-Tier permissioning system and an OTC trading module.`,
    firstLine: [],
    secondLine: [],
    thirdLine: [],
    screenshot: swarmThumbnail,
    screenshotAlt: `Protofire team has worked on the architecture, smart contracts, and front end for the Swarm Markets DEX. KYC services are inbuilt within the platform. The Protofire team has implemented several DEX functionalities - swapping, pooling, a multi-tier permissioning system, and an OTC trading module.`,
    clientLogo: swarmLogo,
    clientLogoAlt: "Swarm Markets DEX",
    tags: ["dApp", "Dashboard", "DeFi", "DEX", "KYO", "OTC"],
    page: "/projects/swarm-dex",
    key: 8,
  },
  {
    title: "Filecoin",
    label: "Filecoin Node Infrastructure",
    description: `Protofire has been working together with the Filecoin team since before they launched the Mainnet. We have helped to provide node infrastructure for Filecoin solutions. We have also developed the CID Checker, which provides a list of all CIDs along with their status.`,
    firstLine: ["3.9K+", "Active Storage Providers (Q1/2022)"],
    secondLine: ["303K", "24h FIL Production (block rewards) (Q1/2022)"],
    thirdLine: [],
    screenshot: filecoinThumbnail,
    screenshotAlt: `Protofire has provided node infrastructure for Filecoin solutions and has developed the CID Checker, which provides a list of all CIDs along with their status.`,
    clientLogo: filecoinLogo,
    clientLogoAlt: `Filecoin Node Infrastructure`,
    tags: ["API", "DevOps", "Explorer", "Node Management"],
    page: "/projects/filecoin-node-infrastructure",
    key: 9,
  },
  {
    title: "MakerDAO",
    label: "MakerDAO Governance Dashboard",
    description: `Protofire built Maker Governance Analytics Dashboard. It provides users with a single location to track the latest updates and view historical data. Through the dashboard, it is possible to access information crucial for voting and polling, as well as monitoring metrics.
    `,
    firstLine: ["$16B", "MakerDAO TVL (Q1/2022)"],
    secondLine: ["9.5B", "DAI outstanding (Q1/2022)"],
    thirdLine: ["458+", "thousand total transactions made (Q1/2022)"],
    screenshot: makerdaoThumbnail,
    screenshotAlt: `Protofire has built the Maker Governance Analytics Dashboard. It provides users with a single location to track the latest updates and view historical data. Through the dashboard, it is possible to access information crucial for voting and polling, as well as monitoring metrics.`,
    clientLogo: makerdaoLogo,
    clientLogoAlt: `MakerDAO Governance Dashboard`,
    tags: ["Dashboard", "DeFi", "Governance", "Interface", "Monitor"],
    page: "/projects/makerdao-dashboard",
    key: 10,
  },
  {
    title: "Armanino TrustExplorer",
    label: "Armanino TrustExplorer",
    description: `Protofire built Maker Governance Analytics Dashboard. It provides users with a single location to track the latest updates and view historical data. Through the dashboard, it is possible to access information crucial for voting and polling, as well as monitoring metrics.
    `,
    firstLine: [],
    secondLine: [],
    thirdLine: [],
    screenshot: armaninoThumbnail,
    screenshotAlt: `Armanino TrustExplorer
Protofire assisted Armanino in the development of solutions under the TrustExplorer project: a blockchain-based assurance platform (Real-Time Audit), a tool to manage digital asset reserves (Proof of Reserves), a data query service (Trusted Node), and a dashboard to show token ownership and distribution (Treasury).
`,
    clientLogo: armaninoLogo,
    clientLogoAlt: `Armanino TrustExplorer`,
    tags: ["Platform"],
    page: "/projects/armanino-platform",
    key: 11,
  },
  {
    title: "Opyn",
    label: "Opyn Decentralized Insurance Platform",
    description: `We have supported Opyn on the development of the user interface of their Decentralized Insurance Platform, as well as integrated it with a backend and Web3 calls. The team worked out the platform’s architecture and implemented its business logic.`,
    firstLine: ["$98M+", "total value locked (TVL) (Q3/2022)"],
    secondLine: ["$00.13B+", "Total Notional Volume (Q3/2022)"],
    thirdLine: ["", ""],
    screenshot: opynThumbnail,
    screenshotAlt: `Opyn Decentralized Insurance Platform
Protofire has supported Opyn in the development of the user interface of their Decentralized Insurance Platform, as well as integrated it with a backend and Web3 calls. The team worked out the platform’s architecture and implemented its business logic.
`,
    clientLogo: opynLogo,
    clientLogoAlt: `Opyn Decentralized Insurance Platform`,
    tags: ["Audit", "Insurance", "Integration", "Interface", "Platform"],
    page: "/projects/opyn-platform",
    key: 12,
  },
  {
    title: "AragonDAO",
    label: "AragonDAO Payroll dApp",
    description: `The Aragon application allows users to incorporate new employees (integrated with the identity and finance apps), request and pre-authorize payments in different tokens, as well as view all the payroll information, including historic payments, balance, and statistics.`,
    firstLine: ["1.7K+", "Number of DAOs using Aragon (Q1/2022)"],
    secondLine: ["41K+", "Number of users (Q1/2022)"],
    thirdLine: ["81%", "Amount of approved proposals (Q1/2022)"],
    screenshot: aragondaoThumbnail,
    screenshotAlt: `Protofire has developed AragonDAO Payroll dApp that allows users to incorporate new employees, request and pre-authorize payments in different tokens, as well as view all the payroll information, including historic payments, balance, and statistics.`,
    clientLogo: aragondaoLogo,
    clientLogoAlt: `AragonDAO Payroll dApp`,
    tags: ["dApp", "Dashboard", "Payments"],
    page: "/projects/aragon-dapp",
    key: 13,
  },
  {
    title: "Gnosis Conditional Tokens Explorer",
    label: "Gnosis Conditional Tokens Explorer",
    description: `Protofire contributed to the development of CTE's interface alongside the Gnosis team. It was built with a focus on providing a good user experience. We also created hierarchical and custom components with specialized functions.`,
    firstLine: ["21", "Unique Oracles (Q1/2022)"],
    secondLine: ["465", "Total Conditions (Q1/2022)"],
    thirdLine: ["408", "Total Number of Unique Addresses (Q1/2022)"],
    screenshot: gnosisctThumbnail,
    screenshotAlt: `Protofire contributed to the development of Gnosis Conditional Tokens Explorer’s interface and created hierarchical and custom components.`,
    clientLogo: gnosisctLogo,
    clientLogoAlt: `Gnosis Conditional Tokens Explorer`,
    tags: ["dApp", "Explorer", "Interface"],
    page: "/projects/gnosis-explorer",
    key: 14,
  },
  {
    title: "KyberDAO",
    label: "Kyber DAO Pool Master Smart Contract Proxy",
    description: `In partnership with Kyber Network, Protofire created a trustless smart contract proxy for the KyberDAO governance. This service enables anyone to become a pool operator and perform token staking/voting without the need to create new smart contracts.`,
    firstLine: [],
    secondLine: [],
    thirdLine: [],
    screenshot: kyberdaoThumbnail,
    screenshotAlt: `In partnership with Kyber Network, Protofire created a trustless smart contract proxy for the KyberDAO governance enabling anyone to become a pool operator and perform token staking and voting with no need to create new smart contracts.`,
    clientLogo: kyberdaoLogo,
    clientLogoAlt: `KyberDAO Pool Master Smart Contract Proxy`,
    tags: ["Governance", "Smart Contract"],
    page: "/projects/kyber-smart-contract",
    key: 15,
  },
  {
    title: "0x Launch Kit",
    label: "0x Launch Kit",
    description: `Protofire has developed a repository that contains an open-source, free-to-use 0x relayer template that one can use as a starting point for one’s own project. The 0x Launch Kit serves as a way to “launch a 0x relayer in under a minute”.`,
    firstLine: ["$145B", "Volume To Date (Q1/2022)"],
    secondLine: ["1.3M", "Unique Users (Q1/2022)"],
    thirdLine: [],
    screenshot: zeroxThumbnail,
    screenshotAlt: `Protofire has developed 0x Launch Kit, a repository that contains an open-source, free-to-use 0x relayer template that one can use to start a project of their own in less than a minute.`,
    clientLogo: zeroxLogo,
    clientLogoAlt: `0x Launch Kit`,
    tags: ["Launch Kit"],
    page: "/projects/ox-launch-kit",
    key: 16,
  },
];
