import { useState } from "react";
import { Link } from "react-router-dom";
import AboutTable from "./AboutTable";
import { tableContent, youHave, canHave } from "./tableContent";
import { ReactComponent as Close } from "../../assets/images/closeGray.svg";
import { ReactComponent as Open } from "../../assets/images/openYellow.svg";
import { ReactComponent as OpenWhite } from "../../assets/images/white-arrow-up.svg";

import { ReactComponent as ArrowYellow } from "../../assets/images/arrowYellow.svg";

function Index() {
  const [firstDiv, setFirstDiv] = useState(false);
  const [secondDiv, setSecondDiv] = useState(false);
  return (
    <div
      className=" lg:px-10 bg-darkBackground z-20 relative lg:p-20 lg:mt-20 sm:mt-10 
      lg:ml-20 sm:py-10 md:px-10 "
    >
      <h1 className="text-3xl text-blue100 py-4 sm:px-5 "> About Protofire</h1>
      <p
        className="text-sm text-blue200 lg:w-2/3 md:w-2/3 sm:px-5"
        style={{
          fontFamily: " Spartan-Medium",
        }}
      >
        Protofire DAO propels Total Value Locked (TVL) and user adoption for
        DeFi and web3 projects. Harnessing our in-house and third-party
        components, we slash costs and elevate quality. We craft long-term
        growth solutions that nurture sustained success through strategic
        planning, seamless compatibility, and innovative marketing.
      </p>
      <p
        className="text-sm text-blue200 lg:w-2/3 md:w-2/3 sm:px-5"
        style={{
          fontFamily: " Spartan-Medium",
        }}
      >
        Protofire DAO specializes in empowering top-tier Layer 1 and Layer 2
        blockchain networks, as well as pioneering DeFi protocols, delivering
        measurable results that resonate with the end users.
      </p>
      <div className="lg:block md:block sm:hidden">
        <AboutTable rows={tableContent} />
      </div>
      <div className="lg:hidden md:hidden sm:block">
        <div className="border-b border-blue300 ">
          <div className="flex sm:px-5">
            <p
              className={`flex-auto text-sm tracking-widest 
            ${firstDiv ? "text-blue100" : "text-blue200 "}`}
            >
              WHAT YOU HAVE
            </p>
            {firstDiv && (
              <OpenWhite
                onClick={() => setFirstDiv(!firstDiv)}
                className="cursor-pointer mt-5"
              />
            )}
            {!firstDiv && (
              <Open
                onClick={() => setFirstDiv(!firstDiv)}
                className="cursor-pointer mt-5"
              />
            )}
          </div>
          {firstDiv && (
            <ul
              className="mobileArrowList text-blue200 text-xs "
              style={{
                fontFamily: " Spartan-Medium",
              }}
            >
              {youHave.map((item, index) => (
                <li
                  className={`  ${index % 2 === 0 ? "bg-background" : ""}`}
                  key={item}
                >
                  {item}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="">
          <div className="flex  sm:px-5">
            <p
              className={`flex-auto text-sm tracking-widest 
            ${secondDiv ? "textGradient" : "text-blue200 "}`}
            >
              WHAT YOU CAN HAVE
            </p>
            {secondDiv && (
              <Close
                onClick={() => setSecondDiv(!secondDiv)}
                className="cursor-pointer mt-5"
              />
            )}
            {!secondDiv && (
              <Open
                onClick={() => setSecondDiv(!secondDiv)}
                className="cursor-pointer mt-5"
              />
            )}
          </div>
          {secondDiv && (
            <ul
              className="mobileArrowList2 text-blue100 text-xs "
              style={{
                fontFamily: " Spartan-Medium",
              }}
            >
              {canHave.map((item, index) => (
                <li
                  className={`  ${index % 2 === 0 ? "bg-background" : ""}`}
                  key={item}
                >
                  {item}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="mt-5">
        <Link
          className="textGradient pt-5 mx-5 tracking-wider "
          style={{ fontSize: "12px", lineHeight: "4", fontFamily: "Spartan" }}
          to="/about"
        >
          ABOUT US <ArrowYellow className="inline  ml-2 mb-1" />
        </Link>
      </div>
    </div>
  );
}

export default Index;
