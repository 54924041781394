import { useState } from "react";
import { Link } from "react-router-dom";

import PrivacyPolicy from "../privacyPolicy/privacyPolicy";
import Socials from "../socials/socials";
import getCurrentYear from "../../utils/utils";

function MobileFooter() {
  const [modalOpen, setModalOpen] = useState(false);
  const currentYear = getCurrentYear();

  return (
    <div className=" border-t border-blue300 pt-5 ">
      <ul
        className="flex  text-xs flex-col mt-4 md:flex-row  md:space-x-6 text-blue200
             md:mt-0 md:font-medium tracking-widest"
        style={{
          fontSize: "10px",
          fontFamily: "Spartan-SemiBold",
        }}
      >
        <li className="m-0 py-2">
          <Link
            to="/about"
            className="block py-2 border-gray-100 pl-0
                  md:p-0  hover:text-blue100"
          >
            ABOUT
          </Link>
        </li>
        <li className="m-0 py-2">
          <Link
            to="/services"
            className="block py-2 border-gray-100 
                  md:p-0 hover:text-blue100"
          >
            SERVICES
          </Link>
        </li>
        <li className="m-0 py-2">
          <Link
            to="/projects"
            className="block py-2 border-gray-100 
                  md:p-0 hover:text-blue100"
          >
            PROJECTS
          </Link>
        </li>
        <li className="m-0 py-2">
          <Link
            to="/careers"
            className="block py-2  border-gray-100 
                  
                  md:p-0 hover:text-blue100"
          >
            CAREERS
          </Link>
        </li>
        <li className="m-0 py-2">
          <a
            href="https://protofire.io/community"
            target="_blank"
            className="block py-2   hover:text-blue100 md:p-0"
            rel="noreferrer"
          >
            COMMUNITY
          </a>
        </li>
      </ul>
      <div className="flex md:order-2 z-10 pt-5 flex-wrap">
        <Socials />
      </div>
      <div className="py-3  mb-10">
        <PrivacyPolicy modalOpen={modalOpen} setModalOpen={setModalOpen} />
        <button
          className=" pr-5 cursor-pointer textGradient"
          type="button"
          onClick={() => setModalOpen(!modalOpen)}
        >
          Privacy Policy
        </button>
      </div>
      <p
        className="text-xs text-blue200 float-left"
        style={{
          fontFamily: "Spartan-Thin",
        }}
      >
        {currentYear}. Protofire. All rights reserved
      </p>
    </div>
  );
}

export default MobileFooter;
