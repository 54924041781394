import linkedin from "../../assets/images/linkedIn.png";

function OurTeamMobile({
  name,
  linkedIn,
  role,
  image,
  description,
  imageAlt,
  borderRadius,
}: {
  name: string;
  linkedIn: string;
  role: string;
  image: any;
  description: string;
  imageAlt: string;
  borderRadius: string;
}) {
  return (
    <div className="teamMember">
      <div key={name}>
        <img
          src={image}
          alt={imageAlt}
          className="w-full"
          style={{ borderRadius }}
        />
        <div>
          <p
            className="text-base text-blue100 pt-3 pb-0 mb-2"
            style={{
              fontFamily: "Spartan-SemiBold",
              fontSize: "16px",
            }}
          >
            {name}
          </p>
          <p
            className="text-sm text-blue200 m-0 p-0"
            style={{
              fontFamily: "Spartan-Medium",
            }}
          >
            {role}
          </p>
          <p
            className="text-xs text-blue200 m-0 p-0"
            style={{
              maxWidth: "183px",
              fontFamily: "Spartan-Medium",
            }}
          >
            {description}
          </p>
          <div className="flex pt-2 ">
            <a href={`${linkedIn}`} target="_blank" rel="noreferrer">
              <img src={linkedin} alt="linkedin" className="mr-2" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurTeamMobile;
