import { Link } from "react-router-dom";
import map from "../../assets/images/map.png";

import { listOfCountries1, listOfCountries2 } from "./listOfCountries";

function Countries() {
  return (
    <div>
      <div
        className="lg:px-20  m-auto md:w-full md:px-10 mb-10 sm:hidden 
      md:block lg:block"
      >
        <div className="lg:px-10">
          <div className="grid grid-cols-2 ">
            <div className="">
              <div className="grid lg:grid-cols-4 md:grid-cols-3 gap-20 text-sm">
                <div>
                  <p className="gradient text-lg"> 8 </p>
                  <p className="tracking-wider text-blue200 py-2"> TIMEZONES</p>
                </div>
                <div>
                  <p className="gradient text-lg "> 23 </p>
                  <p className="tracking-wider text-blue200 py-2"> COUNTRIES</p>
                </div>
                <div>
                  <p className="gradient text-lg "> 16+ </p>
                  <p className="tracking-wider text-blue200 py-2"> LANGUAGES</p>
                </div>
              </div>
              <div className="mt-4 ">
                <img
                  src={map}
                  alt="Protofire is an international Web3 collective present in Argentina, Belarus, Brazil, Chile, Colombia, France, Georgia, India, Ireland, Italy, Latvia, Moldova, Nigeria, Peru, Poland, Romania, Sweden, Ukraine, United Arab Emirates, United Kingdom, 
United States, Uruguay, and Venezuela."
                />
              </div>
              <div className="">
                <Link
                  to="/about"
                  className="btn text-sm text-blue100 py-6 tracking-wider 
            inline-block tex px-10 mt-5"
                >
                  {" "}
                  MORE ABOUT US
                </Link>
              </div>
            </div>
            <div
              className="grid grid-cols-3 lg:ml-40 md:ml-20 text-xs mt-2 text-blue100 "
              style={{
                fontFamily: "Spartan-Medium",
              }}
            >
              <ul className="whatWeDoList ">
                {listOfCountries1.map((country) => (
                  <li className="leading-4" key={country}>
                    {country}{" "}
                  </li>
                ))}
              </ul>
              <ul className="whatWeDoList col-span-2 pl-10">
                {listOfCountries2.map((country) => (
                  <li className="leading-4" key={country}>
                    {country}{" "}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="lg:hidden sm:block  md:hidden">
          <div className="grid grid-cols-2  text-sm">
            <div>
              <p className="gradient text-lg"> 8 </p>
              <p className="tracking-widest text-blue200 "> TIMEZONES</p>
            </div>
            <div>
              <p className="gradient  text-lg"> 24 </p>
              <p className="tracking-widest text-blue200 "> COUNTRIES</p>
            </div>
            <div>
              <p className="gradient text-lg "> 16+ </p>
              <p className="tracking-widest  text-blue200 "> LANGUAGES</p>
            </div>
          </div>
          <div className="">
            <Link
              to="/about"
              className="btn text-sm text-blue100 py-6 tracking-wider 
            inline-block tex px-10 mt-5"
              style={{ fontSize: "12px", fontFamily: "Spartan" }}
            >
              {" "}
              MORE ABOUT US
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Countries;
