import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { generateUniqueID } from "web-vitals/dist/modules/lib/generateUniqueID";
import { content, project } from "./content";

import ContactUs from "../../contactUs/contactUs";
import "../project.css";
import "../../services/tab.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { ReactComponent as OvalCareerMobile2 } from "../../assets/career/ovalCareerMobile2.svg";
import { ReactComponent as OvalCareerBig } from "../../assets/career/ovalCareerBig.svg";
import { ReactComponent as BgMiddle } from "../../assets/career/bgMiddle.svg";

function ChainlinkDevTools() {
  const [active, setActive] = useState("page-about");

  useEffect(() => {
    const observer = new IntersectionObserver(function (entries) {
      const ele: IntersectionObserverEntry[] = entries.filter(
        (entry) => entry.isIntersecting
      );
      if (ele.length > 0) {
        const ele1 = ele[0].target;
        setActive(ele1.id);
      }
    });
    document
      .querySelectorAll(".myCount [id^=page]")
      .forEach((ele) => observer.observe(ele));
  }, []);

  return (
    <div className="lg:px-0  md:px-10 py-20">
      <Helmet>
        <title>Chainlink Developer Tools | Protofire</title>
        <meta
          name="description"
          content="The Chainlink Developer Tools, developed by Protofire, simplify interactions with Chainlink services and facilitate local testing of dApps that integrate them."
        />
        <meta
          name="keywords"
          content="Chainlink Dev Tools, Smart contract development tools, Blockchain development toolset, Decentralized application tools, Ethereum smart contract solutions, Blockchain development platform, Solidity development tools, Smart contract auditing tools, Blockchain integration solutions, Decentralized oracle tools, Blockchain API development, Smart contract testing tools, Ethereum dApp development tools, Blockchain SDK solutions, Decentralized data solutions"
        />
      </Helmet>
      <section
        className="lg:flex md:flex lg:px-60 md:px-20 text-blue200 relative z-10
        text-xs   sm:px-5 "
        style={{
          fontFamily: "Spartan-Medium",
        }}
      >
        <Link to="/" className=" mr-1 text-blue200">
          Home
        </Link>
        /
        <Link to="/projects" className="ml-1 mr-1 text-blue200 ">
          Projects
        </Link>
        /
        <Link to="/projects/chainlink-dev-tools" className="ml-1 text-blue100 ">
          Chainlink Developer Tools
        </Link>
      </section>
      <div className="relative lg:block md:hidden sm:hidden z-1 ">
        <OvalCareerBig className=" absolute ovalBigProjectPages1" />
      </div>
      <div className="relative lg:hidden md:hidden sm:block z-1 ">
        <OvalCareerMobile2 className=" absolute bgOvalProjectPages2" />
      </div>
      <section
        className="md:pl-10 lg:px-0 
       lg:pb-20 sm:px-5 py-10 lg:pl-60 m-auto relative z-10"
      >
        <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-10">
          <div className="lg:w-5/6 sm:w-full md:w-full">
            <h1 className="text-blue100 text-4xl md:text-5xl xl:text-6xl">
              {project.name}
            </h1>
            <div className="flex flex-wrap text-xs text-blue ">
              {project.hashtags.map((hashtag) => (
                <span
                  key={hashtag}
                  className=" py-1 px-3 border border-blue my-2 mr-3 rounded-3xl"
                >
                  {hashtag}{" "}
                </span>
              ))}
            </div>
            {project.description.map((p) => (
              <p
                className="my-[35px] text-blue200 leading-7"
                style={{
                  fontFamily: "Spartan-Medium",
                }}
                key={generateUniqueID()}
              >
                {p}
              </p>
            ))}
          </div>
          <div className="w-full lg:block sm:hidden md:block">
            <img
              src={project.thumbnail}
              alt={project.thumbnailAlt}
              className="w-full"
            />{" "}
          </div>
        </div>
      </section>

      <div
        className="lg:px-60  md:px-10 sm:px-5 pb-20 grid lg:grid-cols-3  z-10
      md:grid-cols-3 sm:grid-cols-1 relative  overflow-auto bg-darkBackground"
        style={{
          height: "600px",
        }}
      >
        <div className="col-span-2  myCount">
          {content.map((section) => (
            <section className="py-5" id={section.anchor} key={section.anchor}>
              <div className=" lg:col-span-2 md:col-span-2">
                <h3 className="text-blue100 text-3xl py-2">{section.title}</h3>
                {section.description.map((paragraph) => (
                  <p
                    className="text-blue200 py-2 text-sm leading-6"
                    style={{
                      fontFamily: "Spartan-Medium",
                    }}
                    key={generateUniqueID()}
                  >
                    {paragraph}
                  </p>
                ))}
                {section.lists && (
                  <div
                    className="dotList text-sm leading-5 "
                    style={{
                      fontFamily: "Spartan-Medium",
                    }}
                  >
                    <ul>
                      {section.lists.map((list) => (
                        <li className=" text-blue200" key={generateUniqueID()}>
                          {list}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </section>
          ))}
          <section className="py-5" id="page-stack" key="page-stack">
            <div className=" lg:col-span-2 md:col-span-2">
              <p className="text-blue100 text-3xl py-3">
                {project.technologyStack.title}{" "}
              </p>
              <div
                className="grid lg:grid-cols-4 leading-5
      md:grid-cols-2 sm:grid-cols-1 text-blue200"
                style={{
                  fontFamily: "Spartan-Medium",
                }}
              >
                <div>
                  <p className="text-sm">LANGUAGES</p>
                  <div className="dotList text-sm">
                    {project.technologyStack.lauguages.map((list) => (
                      <li className="pb-1 text-blue200" key={list}>
                        {list}
                      </li>
                    ))}
                  </div>
                </div>
                <div>
                  <p className="text-sm">DATABASE</p>
                  <div className="dotList text-sm">
                    {project.technologyStack.environment.map((list) => (
                      <li className="pb-1 text-blue200" key={list}>
                        {list}
                      </li>
                    ))}
                  </div>
                </div>
                <div>
                  <p className="text-sm">NETWORK & PROTOCOL</p>
                  <div className="dotList text-sm">
                    {project.technologyStack.network.map((list) => (
                      <li className="pb-1 text-blue200" key={list}>
                        {list}
                      </li>
                    ))}
                  </div>
                </div>
                <div>
                  <p className="text-sm">INFRASTRUCTURE LAYER</p>
                  <div className="dotList text-sm">
                    {project.technologyStack.infrastructure.map((list) => (
                      <li key={list} className="pb-1 text-blue200">
                        {list}
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="pb-5" id="page-code" key="page-code">
            <div className=" text-sm">
              <p className="text-blue100 text-3xl py-3">{project.code.text} </p>
              {project.code.codes.map((list) => (
                <div
                  className="py-2 "
                  key={list.text}
                  style={{
                    fontFamily: "Spartan-Medium",
                  }}
                >
                  <a
                    href={list.link}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue text-sm"
                  >
                    {list.text}
                  </a>
                </div>
              ))}
            </div>
          </section>
        </div>
        <aside
          className="text-xs  text-blue100 lg:ml-20 py-10 md:ml-20
          sticky top-5 right-5  h-60 sm:hidden lg:block md:block"
        >
          <ul className="border-l border-blue300 pl-5">
            {content.map((section) => (
              <li className="pb-2" key={section.anchor}>
                <a
                  className={` ${active === section.anchor ? "text-blue" : ""}`}
                  href={`#${section.anchor}`}
                  onClick={() => setActive(section.anchor)}
                >
                  {section.title.toUpperCase()}
                </a>
              </li>
            ))}
            <li className="pb-2">
              <a
                className={` ${active === "page-stack" ? "text-blue" : ""}`}
                href="#page-stack"
                onClick={() => setActive("page-stack")}
              >
                TECHNOLOGY STACK
              </a>
            </li>
            <li className="pb-2">
              <a
                className={` ${active === "page-code" ? "text-blue" : ""}`}
                href="#page-code"
                onClick={() => setActive("page-code")}
              >
                CODE
              </a>
            </li>
          </ul>
        </aside>
      </div>
      <div className="relative lg:block md:hidden sm:hidden z-10 ">
        <BgMiddle className=" absolute bgMiddleProject" />
      </div>
      <section
        className="lg:bg-square-bg md:bg-square-bg sm:bg-mobile-bg
       bg-contain bg-center z-10 globeProject "
      >
        <div
          className="bg-circle-bg relative 
          h-full m-auto 
        sm:w-full lg:w-1/3 md:w-2/3  bg-cover bg-center bg-no-repeat z-0 "
        >
          <div
            className="text-center m-0, w-full top-1/2 left-1/2 absolute"
            style={{
              transform: "translate(-50%, -50%)",
            }}
          >
            <h1 className="text-blue100 lg:text-2xl md:text-2xl  leading-10 sm:text-2xl ">
              <span
                style={{
                  fontFamily: "Spartan-Light",
                }}
              >
                Have you been thinking of developing{" "}
              </span>
              a Decentralized Exchange?
            </h1>
            <p className="text-blue100 pt-5 Spartan-Medium text-sm w-9/12 m-auto">
              {" "}
              <a
                href="#contact"
                className="pr-2 underline  "
                style={{
                  background:
                    "linear-gradient(to right, #F54703 50%, #FAC902 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Get in touch with us
              </a>
              so that we can discuss your ideas.
            </p>
          </div>
          {/* <CircleBg /> */}
        </div>
      </section>
      <section className="lg:w-5/6 m-auto" id="contact">
        <ContactUs />
      </section>
    </div>
  );
}

export default ChainlinkDevTools;
