import { Link } from "react-router-dom";
import protofireLogo from "../../components/assets/community/protofire.svg";
import getCurrentYear from "../../utils/utils";

function CommunityLayoutFooter() {
  const currentYear = getCurrentYear();

  return (
    <footer
      className="mx-auto max-w-7xl px-2 sm:px-6 pt-2 sm:pt-6 lg:pt-16"
      style={{ fontFamily: "RobotoMono-Regular" }}
    >
      <div className="flex items-center justify-between py-8 flex-col lg:flex-row border-solhintText">
        <div className="mx-auto lg:mx-0 flex justify-center sm:justify-start flex-col lg:flex-row items-center">
          <div className="flex flex-shrink-0 items-center mb-4 lg:mb-0">
            <Link to="/">
              <img className="h-8 w-auto" src={protofireLogo} alt="Protofire" />
            </Link>
          </div>
          <div className="lg:ml-10 flex flex-wrap justify-center space-x-4">
            <Link to="/about" className="hover:underline px-3 py-2">
              About
            </Link>
            <Link to="/services" className="hover:underline px-3 py-2">
              Services
            </Link>
            <Link to="/projects" className="hover:underline px-3 py-2">
              Projects
            </Link>
            <a
              href="https://medium.com/protofire-blog"
              className="hover:underline px-3 py-2"
              target="_blank"
              rel="noreferrer"
            >
              Blog
            </a>
            <Link to="/contact-us" className="hover:underline px-3 py-2">
              Contact
            </Link>
          </div>
        </div>
        <div className="flex">
          <a
            href="#hero"
            className="bg-gradient-to-r from-[#f54703] to-[#fac902] text-transparent bg-clip-text text-[24px] leading-[32px]"
            rel="noreferrer"
            style={{ fontFamily: "Epilogue-Bold" }}
          >
            GO UP ↑
          </a>
        </div>
      </div>
      <p className="text-solhintText text-[16px] leading-[24px] m-0 pb-8 text-right">
        Crafted with ❤️ by Proto🔥 team! {currentYear}. © Protofire. All rights
        reserved.
      </p>
    </footer>
  );
}

export default CommunityLayoutFooter;
