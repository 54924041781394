/* eslint-disable react/no-unescaped-entities */
function DataWeGather() {
  return (
    <div
      className="text-sm text-blue200 leading-5 pt-10"
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <h2 className="text-blue100 text-4xl">
        2. Data we gathered from our website’s users
      </h2>
      <p>
        When you visit our website, we collect usage statistics and other data,
        which helps us to estimate the efficiency of the content delivered.
        Processing data gathered from our website also helps us to provide
        better user experience and improve the products and services we offer.
        We collect information through the use of “cookies,” scripts, tags,
        Local Shared Objects (Flash cookies), web beacons, and other related
        methods.
      </p>
      <h2 className="text-blue100 text-4xl pt-10">
        2.1. We collect the following categories of data:
      </h2>
      <p>
        Cookies and similar technologies (e.g., web beacons, pixels, ad tags and
        device identifiers) Usage data, user behavior collected by cookies
      </p>
      <p className="text-lg text-blue100">What is a cookie?</p>
      <p>
        <a
          href="https://en.wikipedia.org/wiki/HTTP_cookie"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          HTTP cookie
        </a>{" "}
        is a small piece of data that we send to your browser when you visit our
        website. After your computer accepts it or “takes the cookie” it is
        stored on your computer as an identification tag. Cookies are generally
        employed to measure website usage (e.g., the number of visitors and the
        duration of a visit) and efficiency (e.g., topics of interest to our
        visitors). Cookied can also be used to personalize a user experience on
        our website. If necessary, users can turn off cookies via browser
        settings.
      </p>
      <h2 className="text-blue100 text-4xl pt-10">
        2.2. How we process the data gathered
      </h2>
      <p>
        Protofire and third-party providers we partner with (e.g., our
        advertising and analytics partners) use cookies and other tracking tools
        to identify users across different services and devices and ensure
        better user experience. Please see the list of them below.
      </p>
    </div>
  );
}

export default DataWeGather;
