import { ReactComponent as DesignRush } from "../../assets/images/designRush.svg";

function WhoTalk() {
  return (
    <div className="text-center">
      <h1 className="lg:text-3xl sm:text-xl py-4 text-blue100">
        Who&apos;s talking about us?
      </h1>
      <div className="flex justify-center">
        <a
          href="https://www.designrush.com/agency/profile/protofire"
          target="_blank"
          className="hover:opacity-80"
          rel="noreferrer"
        >
          <DesignRush />
        </a>
      </div>
    </div>
  );
}

export default WhoTalk;
