/* eslint-disable react/no-unescaped-entities */
function CustomerData() {
  return (
    <div
      className="text-sm text-blue200 leading-5 py-10"
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <h2 className="text-blue100 text-4xl pb-10">
        5. Data we gather via e-mails, messengers, widgets, and phones
      </h2>
      <div>
        <h2 className="text-blue100 text-3xl ">
          5.1. We collect the following categories of data
        </h2>
        <p>
          When you interact with us via any other means and tools, we gather the
          following information about you:
        </p>
        <ul className="dotList">
          <li>Name/surname of contact persons;</li>
          <li>Positions;</li>
          <li>Phone numbers;</li>
          <li>E-mails;</li>
          <li>Skype I;D</li>
          <li>Location;</li>
          <li>Сompany name;</li>
          <li>Any other information you provided to us from your request;</li>
          <li>History of our communication, call duration.</li>
        </ul>
        <p>
          The information about a customer call is stored in our internal system
          and includes a full call recording (starting the moment a connection
          was established), a voice recording if any available, a phone number,
          and a call duration.
        </p>
      </div>

      <div>
        <h2 className="text-blue100 text-3xl pt-10">
          5.2. How we process the data gathered
        </h2>
        <p>
          All the requests acquired via e-mail are stored within a business
          Gmail account of Protofire located at Google's server. The information
          about the request is further transferred and stored in internal CRM
          either by employees of Protofire manually or automatically for further
          processing according to our purposes. We may maintain and support the
          system, in which we store collected data.
        </p>
      </div>
      <div>
        <h2 className="text-blue100 text-3xl pt-10">
          5.3. Purposes and legal basis for data processing
        </h2>
        <ol className="">
          <li>
            When you contact us via any other means to get an expert’s take on
            your project / our services or to make any kind of a request, we
            process your data in order to enter into a contract, to comply with
            our contractual obligations (to render Services), or answer to your
            request.
          </li>
          <li>
            This way, we may use your personal information to provide services
            to you, as well as process transactions related to the services you
            inquired from us. For example, we may use your name or an e-mail
            address to send an invoice or to establish communication throughout
            the whole service delivery life cycle. We may also use your personal
            information you shared with us to connect you with other of our team
            members seeking your subject matter expertise. In case you use
            multiple services offered by our company, we may analyze your
            personal information and your online behavior on our resources to
            deliver an integrated experience. For example, to simplify your
            search across a variety of our services to find a particular one or
            to suggest relevant product information as you navigate across our
            websites. With an aim to enhance our productivity and improve our
            collaboration, what is our legitimate interest, we may use your
            personal data—such as an e-mail, name, job title, or activity taken
            on our resources—to provide information we believe may be of
            interest to you. Additionally, we may store the history of our
            communication for the legitimate purposes of maintaining customer
            relations and/or service delivery.
          </li>
          <li>
            If you communicate with us for any other purpose we process data
            with a legitimate interest to prevent spam and restrict direct
            marketing of third-party companies. Our interactions are aimed at
            driving engagement and maximizing value you get through our
            services.These interactions may include information about our new
            commercial offers, white papers, newsletters, content, and events we
            believe may be relevant to you or your initial request.
          </li>
        </ol>
      </div>
      <div>
        <h2 className="text-blue100 text-3xl pt-10">
          5.4. Data retention period
        </h2>
        <p>
          We set a retention period for the data collected to 6 years. This data
          may be further used to contact you if we want to send you anything
          relevant to your initial request.
        </p>
      </div>
      <div>
        <h2 className="text-blue100 text-3xl pt-10">5.5. Data recipients</h2>
        <p>
          We do not share data with third parties, apart from the cases
          described in the General data processing section and cases stipulated
          in our third partners privacy policies.
        </p>
      </div>
    </div>
  );
}

export default CustomerData;
