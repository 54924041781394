import { Link } from "react-router-dom";
import "../home.css";
import { ReactComponent as ArrowBlue } from "../../assets/images/arrowblue.svg";

function WhatWeDo({
  number,
  title,
  list,
  page,
}: {
  number: number;
  title: string;
  list: string[];
  page: string;
}) {
  return (
    <div
      className={`lg:pr-10 pt-5 relative ${number === 7 ? "col-span-2" : ""}`}
    >
      <p className="text-blue300 text-2xl m-0 sm:hidden lg:block md:block">
        {" "}
        0{number}
      </p>
      <h6
        className="text-blue100  pt-2"
        style={{
          fontFamily: "Spartan-SemiBold",
        }}
      >
        {title}
      </h6>
      <ul className="whatWeDoList">
        {list.map((item) => (
          <li
            className="text-blue200 leading-5 "
            style={{
              fontFamily: "Spartan-Medium",
              fontSize: "12px",
            }}
            key={item}
          >
            {item}
          </li>
        ))}
      </ul>
      <Link
        className="text-blue pt-5"
        style={{ fontSize: "12px", lineHeight: "4" }}
        to={page}
      >
        MORE <ArrowBlue className="inline  ml-2 mb-1" />
      </Link>
    </div>
  );
}

export default WhatWeDo;
