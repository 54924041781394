/* eslint-disable react/no-unescaped-entities */
function EmailData() {
  return (
    <div
      className="text-sm text-blue200 leading-5 pt-10"
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <h2 className="text-blue100 text-4xl pb-10">
        4. Data we gather from our chatbot
      </h2>
      <div>
        <h2 className="text-blue100 text-3xl ">
          4.1. We collect the following categories of data
        </h2>
        <p>
          When you interact with us via any other means and tools, we gather the
          following information about you:
        </p>
        <ul className="dotList">
          <li>Name/surname;</li>
          <li>Position;</li>
          <li>E-mail;</li>
          <li>Location;</li>
          <li>Сompany name;</li>
          <li>Any other information you provided to us from your request.</li>
        </ul>
      </div>
      <div>
        <h2 className="text-blue100 text-3xl pt-10">
          4.2. How we process the data gathered
        </h2>
        <p>
          The information gathered is transferred to our CRM or Hubspot. Later,
          it may be used to contact you with something relevant to your initial
          request, provide further information related to the topic you
          requested, and deliver quality service.
        </p>
        <p>
          y sharing personal information with us, you are giving consent for us
          to rightfully use and process in any way your data, including for the
          following business purposes:
        </p>
        <ul>
          <li>
            Send any updates regarding services you have shown interest in or
            provide further information related to the topic you requested.
          </li>
          <li>
            Contact and communicate with you regarding your initial request.
          </li>
          <li>
            To get your consent to further contact you regarding any other
            services you might be interested in
          </li>
          <li>
            Maintenance and support activities of our CRM system and related
            activities, etc.
          </li>
        </ul>
        <p>
          All the information gathered via chatbot is processed by the following
          services:
        </p>
        <ul className="dotList">
          <li>
            WordPress (
            <a
              href="https://wordpress.org/about/privacy/"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            )
          </li>
          <li>
            Hubspot (
            <a
              href="https://legal.hubspot.com/privacy-policy"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            )
          </li>
          <li>
            Gmail services that deliver notifications about the filled out
            contact forms to our employees (
            <a
              href="https://cloud.google.com/security/compliance/privacy-shield/"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Google Privacy Policy
            </a>
            )
          </li>
          <li>
            Drift.com, Inc. (
            <a
              href="https://www.drift.com/privacy-policy/"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            )
          </li>
        </ul>
      </div>
      <div>
        <h2 className="text-blue100 text-3xl pt-10">
          4.3. Purposes and legal basis for data processing
        </h2>
        <p>
          If you share personal data via chatbot to get an expert’s take on your
          project or to get familiar with the services our company delivers, we
          process your data in order to enter into a contract and to comply with
          our contractual obligations (to render Services), or answer to your
          request. This way, we may use your personal information to provide
          services to you, as well as process transactions related to the
          services you inquired from us. For example, we may use your name or an
          e-mail address to send an invoice or to establish communication
          throughout the whole service delivery life cycle. We may also use your
          personal information you shared with us to connect you with other of
          our team members seeking your subject matter expertise. In case you
          use multiple services offered by our company, we may analyze your
          personal information and your online behavior on our resources to
          deliver an integrated experience. For example, to simplify your search
          across a variety of our services to find a particular one or to
          suggest relevant product information as you navigate across our
          websites.
        </p>
        <p>
          With an aim to enhance our productivity and improve our
          collaboration—under our legitimate interest—we may use your personal
          data (e.g., an e-mail, name, job title, or activity taken on our
          resources) to provide information we believe may be of interest to
          you. Additionally, we may store the history of our communication for
          the legitimate purposes of maintaining customer relations and/or
          service delivery, as well as we may maintain and support the system in
          which we store collected data.
        </p>
        <p>
          If you share personal data via chatbot for any other purpose we
          process data with a legitimate interest to prevent spam and restrict
          direct marketing of third-party companies. Our interactions are aimed
          at driving engagement and maximizing value you get through our
          services.These interactions may include information about our new
          commercial offers, white papers, newsletters, content, and events we
          believe may be relevant to you.
        </p>
      </div>
      <div>
        <h2 className="text-blue100 text-3xl pt-10">
          4.4. Data retention period
        </h2>
        <p>
          We set a retention period for your data collected from communication
          with us via chatbot to 6 years. This data may be further used to
          contact you if we want to send you anything relevant to your initial
          request (e.g., updated information on your initial request, etc).
        </p>
      </div>
      <div>
        <h2 className="text-blue100 text-3xl pt-10">4.5. Data recipients</h2>
        <p>
          We do not transfer data to third parties, apart from the cases
          described in the General data processing section and this section.
        </p>
      </div>
    </div>
  );
}

export default EmailData;
