import { Link } from "react-router-dom";
import SocialMedia from "../../components/projects/solhint/socialMedia/socialMedia";
import getCurrentYear from "../../utils/utils";
import logo from "../../components/assets/solhint/solhint-logo.png";
import protofireLogo from "../../components/assets/images/protofire.svg";
import downloadIcon from "../../components/assets/solhint/icons/document-upload.svg";

function SolhintLayoutFooter() {
  const currentYear = getCurrentYear();

  return (
    <footer
      className="mx-auto max-w-7xl px-2 sm:px-6 pt-2 sm:pt-6 lg:pt-16"
      style={{ fontFamily: "Inter" }}
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 py-12 border-t-[1px] border-b-[1px] border-[#282a37]">
        <div className="mx-auto lg:mx-0 mb-8 flex justify-center sm:justify-start flex-col">
          <div className="flex flex-shrink-0 items-center mb-4">
            <img
              className="h-8 w-auto pr-8 border-r-[1px] border-r-solhintGray"
              src={logo}
              alt="Solhint"
            />
            <Link to="/" className="ml-8">
              <img className="h-6 w-auto" src={protofireLogo} alt="Protofire" />
            </Link>
          </div>
          <div className="sm:block">
            <div className="flex space-x-4">
              <a
                href="#download"
                className="text-white hover:text-white hover:underline pr-3 py-2 font-Inter"
                aria-current="page"
              >
                <img
                  className="inline mr-2 mb-1"
                  src={downloadIcon}
                  alt="Download"
                />
                Download
              </a>
              <a
                href="#about"
                className="text-white hover:text-white hover:underline px-3 py-2 font-Inter"
              >
                About
              </a>
              <a
                href="#contact"
                className="text-white hover:text-white hover:underline px-3 py-2 font-Inter"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
        <div>
          <h3
            className="text-white text-[24px] leading-[30px] mb-[20px]"
            style={{ fontFamily: "Outfit-Bold" }}
          >
            Press Kit
          </h3>
          <p className="text-solhintText text-[16px] leading-[24px]">
            Download the press kit for your announcement and branding purposes.
            We appreciate your respect for our project and will be glad to
            collaborate with you to further build and expand your initiative.
          </p>
          <a
            href="https://drive.google.com/file/d/1EdNYPyOflhwUN6aNJl6IMmqLDvr7DbZu/view?usp=sharing"
            className="bg-gradient-to-r from-[#3356ff] to-[#b32dfe] text-transparent bg-clip-text"
            target="_blank"
            rel="noreferrer"
          >
            DOWNLOAD PRESS KIT
          </a>
        </div>
      </div>
      <div className="flex items-center justify-between py-8 flex-col lg:flex-row">
        <div>
          <p className="text-solhintText text-[16px] leading-[24px] m-0">
            © {currentYear} Solhint. All rights reserved.
          </p>
        </div>
        <div className="flex">
          <SocialMedia />
        </div>
      </div>
    </footer>
  );
}

export default SolhintLayoutFooter;
