import { useEffect } from "react";
import Modal from "react-modal";
import PrivacyIntro from "./sections/introduction";
import DataWeGather from "./sections/dataWeGather";
import AnalyticsPartners from "./sections/analyticsPartners";
import AdvertisingPartners from "./sections/advertisingPartners";
import OtherWidgets from "./sections/otherWidgets";
import Purposes from "./sections/purposes";
import DataFromOurWeb from "./sections/dataFromOurWeb";
import EmailData from "./sections/emailData";
import CustomerData from "./sections/customerData";
import EventData from "./sections/eventData";
import GeneralData from "./sections/generalData";
import YourRights from "./sections/yourRights";
import DataSecurity from "./sections/dataSecurity";
import DataTransfer from "./sections/dataTransfer";
import GeneralDescription from "./sections/generalDescription";
import ContactSection from "./sections/contactSection";
import DataFromAttendees from "./sections/dataFromAtendees";
import { HeroPrivacySection } from "./sections/heroSection";
import { ReactComponent as Cancel } from "../assets/images/cancel.svg";

import "./privacy.css";

function PrivacyPolicy({
  modalOpen,
  setModalOpen,
}: {
  modalOpen: boolean;
  setModalOpen: Function;
}) {
  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
    }
    if (!modalOpen) {
      document.body.style.overflow = "auto";
    }
  }, [modalOpen]);
  return (
    <Modal
      isOpen={modalOpen}
      contentLabel="Minimal Modal Example"
      className="Modal"
      overlayClassName="Overlay"
      onRequestClose={() => setModalOpen(!modalOpen)}
    >
      <div className="lg:w-6/6 m-auto md:w-5/6 sm:px-5 py-10 bg-darkBackground">
        <p className="text-2xl text-blue100 text-center">
          Protofire Privacy Policy
        </p>
        <button
          onClick={() => setModalOpen(!modalOpen)}
          type="button"
          className="fixed top-6 lg:right-16 sm:right-2 md:right-14 "
        >
          {" "}
          <Cancel />
        </button>
        <HeroPrivacySection />
        <PrivacyIntro />
        <DataWeGather />
        <AnalyticsPartners />
        <AdvertisingPartners />
        <OtherWidgets />
        <Purposes />
        <DataFromOurWeb />
        <EmailData />
        <CustomerData />
        <EventData />
        <DataFromAttendees />
        <GeneralData />
        <YourRights />
        <DataSecurity />
        <DataTransfer />
        <GeneralDescription />
        <ContactSection />
      </div>
    </Modal>
  );
}

export default PrivacyPolicy;
