/* eslint-disable react/no-unescaped-entities */
function GeneralDescription() {
  return (
    <div
      className="text-sm text-blue200 leading-5 py-10"
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <h2 className="text-blue100 text-4xl">12. General description</h2>
      <p>
        We may supplement or amend this policy by additional policies and
        guidelines from time to time. We will post any privacy policy changes on
        this page. We encourage you to review our privacy policy whenever you
        use our services to stay informed about our data practices and the ways
        you can help to protect your privacy.
      </p>
      <p>
        Our services are not directed to individuals under 16. We do not
        knowingly collect personal information from individuals under 16. If we
        become aware that an individual under 16 has provided us with personal
        information, we will take measures to delete such information.
      </p>
      <p>
        If you disagree with any changes to this privacy policy, you will need
        to stop using our services.
      </p>
    </div>
  );
}

export default GeneralDescription;
