/* eslint-disable import/prefer-default-export */
import ideation from "../../assets/services/ideation.png";
import quality from "../../assets/services/quality.png";
import prototyping from "../../assets/services/prototyping.png";
import deployment from "../../assets/services/deployment.png";
import development from "../../assets/services/development.png";
import maintenance from "../../assets/services/maintenance.png";
import dAppps1 from "../../assets/services/banners/bzx-smart-contracts-front.png";
import dAppps11 from "../../assets/services/banners/bzx-smart-contracts-back.png";
import sc2 from "../../assets/services/banners/kyberdao_smart_contract-front.png";
import sc22 from "../../assets/services/banners/kyberdao_smart_contract-back.png";
import dexes1 from "../../assets/services/banners/colu_smart_contract-front.png";
import dexes11 from "../../assets/services/banners/colu_smart_contract-back.png";

export const tabs = [
  {
    tabName: "Smart Contracts",
    tabDrescription: `A smart contract is an agreement between two or more parties 
      stored on a blockchain, such as Ethereum, Solana, or Cardano. The agreement 
      has predefined rules and conditions that are automatically executed when met, 
      guaranteed by the consensus of the entire network. They cannot be altered unless 
      approved by the network.
    `,

    tabBenefits: [
      "Transaction accuracy",
      "Transparency of processes",
      "Asset security",
      "Performance autonomy",
      "High efficiency and productivity",
      "Continuous storage",
      "Trustless operations",
    ],
    smartC: [
      {
        title: "Ethereum Development (Solidity)",
        desc: `Protofire has developed more than 100 projects in the Ethereum 
          ecosystem, with dozens more under production. They were developed 
          alongside several of the most respected protocols, such as Maker, 
          Gnosis, Chainlink, The Graph, among other protocols. We have built 
          long-term partnerships with them, and we intend to continue to help 
          them grow their ecosystems.`,
      },
      {
        title: "Solana Development",
        desc: `Solana offers a scalable platform for smart contract development, 
          boasting faster and more cost-effective transactions than Ethereum while 
          still maintaining a high level of decentralization. Protofire is equipped 
          to deliver top-notch Solana development services.`,
      },
      {
        title: "Cardano Development",
        desc: `Cardano is a science-based, peer-reviewed PoS project that provides 
          unparalleled security and sustainability to decentralized applications and 
          systems. Protofire's team is equipped with the skills and knowledge to 
          deliver top-notch Cardano development services.`,
      },
    ],

    tabTrackRecord: [
      [
        dAppps1,
        dAppps11,
        "#",
        "sameWindow",
        "Protofire helped to integrate Augur with bZx smart contracts that facilitate on-chain margin lending, monitoring, and liquidation of token trades.",
      ],
      [
        sc2,
        sc22,
        "/projects/kyber-smart-contract",
        "sameWindow",
        "Protofire created a trustless smart contract proxy for the KyberDAO governance which enables anyone to become a pool operator and perform token staking and voting without the need to create new smart contracts.",
      ],
    ],
  },
  {
    tabName: "Stablecoins",
    tabDrescription: `Stablecoins are cryptocurrencies that attempt to peg their 
      market value to some external reference. They tend to be pegged to either a 
      fiat currency such as the U.S. dollar, to commodities such as gold, to digital 
      assets such as tokens, and some are completely uncollateralized – algorithmically 
      defined. They achieve their price stability via collateralization (backing) or 
      through algorithmic mechanisms of buying and selling the reference asset or its 
      derivatives.`,
    tabBenefits: [
      "Tend to be of stable value compared to non-stable coins",
      "Asset-backed currencies",
      "Act as a bridge from fiat to crypto use",
      "Potentially regulated currency",
    ],
    tabTrackRecord: [],
  },
  {
    tabName: "Auditing",
    tabDrescription: `The auditing process for a smart contract focuses on scrutiny 
      of the code used for underwriting the terms and conditions in the smart contract.
      With the help of such an audit, smart contract developers can easily identify 
      the vulnerabilities and bugs before the deployment of smart contracts.
    `,
    tabBenefits: [
      "Increased productivity and efficiency",
      "Reduced risk of security breaching",
      "Reduced risk of vulnerabilities",
      "Architecture and code optimization for the solution",
      "Greater system reliability resulting in higher trust and value ",
    ],
    tabTrackRecord: [
      [
        dexes1,
        dexes11,
        "https://medium.com/protofire-blog/auditing-the-colu-local-network-token-438b51694ea3",
        "newWindow",
        "Protofire developed a dApp for the Colu Network to manage communities that helps to turn financial transactions into new sources of wealth for a community.",
      ],
    ],
  },
  {
    tabName: "Benchmarking",
    tabDrescription: `Benchmarking implies the utilization of existing 
      open-source technologies to develop other solutions. In the case 
      of an open-source protocol, the code is freely available to be forked. 
      Blockchain forks are essentially a split in the blockchain network. 
      This means that anyone can propose improvements and/or change the code 
      and adapt it for different purposes.
    `,

    tabBenefits: [
      "A wide selection of codes to choose from for a solution",
      "The code has already been thoroughly tested and audited",
      "The code has proved to be effective and in high demand",
      "Rapid setup and deployment",
    ],
    tabTrackRecord: [],
  },
];

export const whatWeProvide = [
  {
    name: "Ideation",
    icon: ideation,
    description: `
      We conceptualize and ideate the architecture, implementation, 
      and productivity of your smart contract solution.
    `,
  },
  {
    name: "Prototyping",
    icon: prototyping,
    description: `
      We build a prototype of the solution for you to see what it 
      may look like and make necessary changes.
   `,
  },
  {
    name: "Development",
    icon: development,
    description: `
      We develop the solution based on the agreed prototype under 
      your supervision, known schedule, and budget.
    `,
  },
  {
    name: "Quality Assurance",
    icon: quality,
    description: `
      We carry out multi-stage quality assurance procedures to make 
      sure all tools work as expected.
    `,
  },
  {
    name: "Deployment",
    icon: deployment,
    description: `
      We deploy the solution to the designated environment under your 
      control, following the technical requirements.
  `,
  },
  {
    name: "Maintenance & Support",
    icon: maintenance,
    description: `
      We maintain the solution, its features, and related infrastructure 
      and will trigger you if certain decisions are required.
    `,
  },
];
