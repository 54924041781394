import oxLunch from "../../assets/projectsImages/0xLaunchNew.png";
import oxLunch1 from "../../assets/projectsImages/0xLaunchNew1.png";

// eslint-disable-next-line import/prefer-default-export
export const project = {
  name: "0x Launch Kit (Reference Implementation)",
  hashtags: ["Launch Kit"],
  description: `Protofire has developed a repository that contains an open-source, 
  free-to-use 0x relayer template that one can use as a starting point for one’s 
  own project. The 0x Launch Kit serves as a way to “launch a 0x relayer in under 
  a minute”.`,
  thumbnail: oxLunch,
  thumbnailAlt:
    "0x Launch Kit, a repository with an open-source, free-to-use 0x relayer template, was created by the Protofire team to let anyone use it as a starting point for one’s own project in less than a minute.",
  aboutProject: {
    title: "About the project",
    lists: [
      `Fully functional working reference for any developer or organization 
      to launch a relayer in under a minute`,
      `Production-ready code`,
      `It features a neat and tidy UI, ensuring unsurpassed user experience`,
      `ERC-20 and ERC-721 relayer interface`,
    ],
    sellVolume: ["$130.4B", "0x Protocol, Volume To Date (Q1/2022)"],
    batches: [
      "1.2M",
      "0x Protocol, Cumulative No of Unique Users, To Date (Q1/2022)",
    ],
  },
  theClient: {
    title: "The client/partner",
    firstParagraph: `0x is an open protocol that provides an infrastructure to 
    enable the decentralized exchange of assets on the Ethereum blockchain. The 
    0x protocol employs off-chain transaction relay with on-chain settlement via 
    its Ethereum-based smart contract pipeline. The ZRX token functions as a 
    governance token that enables ecosystem stakeholders to coordinate upgrades 
    to the protocol.`,
  },
  theProblem: {
    title: "The problem",
    firstParagraph: `Developing a solution from scratch using 0x can be challenging 
    and time-consuming. Protofire and the 0x team worked together on a reference 
    implementation called the 0x Launch Kit. The Kit includes a simple, extensible 
    0x relayer codebase that can be forked or used as reference material to create 
    your own marketplace. The Launch Kit addresses many issues and simplifies 
    integrating with 0x, reducing the effort needed to add decentralized token trading
     to your project.`,
  },
  theMedia: {
    title: "Media",
    firstMedia: {
      text: "Introducing the 0x Launch Kit Launch a relayer in under a minute:",
      link: "https://blog.0xproject.com/introducing-the-0x-launch-kit-4acdc3453585?gi=177b3c74478b",
    },
  },
  theSolution: {
    title: "The solution",
    firstParagraph: `This repo contains a Docker image that will run both codebases 
    simultaneously for easy deployment, but you can also clone or fork each repo 
    independently`,
    lists: [
      [`Quickly launch a market for your community token`],
      [
        `Seamlessly create an in-game marketplace for digital items and collectibles`,
      ],
      [
        `Enable trading of any ERC-20 or ERC-721 asset The Launch Kit is split 
        into two separate repos:`,
      ],
      [
        `0x Launch Kit Frontend: ERC-20 and ERC-721 relayer UIs `,
        `https://github.com/0xProject/0x-launch-kit-frontend`,
      ],
      [
        `0x API: An API that supports the Standard Relayer API specification`,
        `https://github.com/0xProject/0x-api`,
      ],
    ],
  },
  code: {
    text: "Code",
    codes: [
      {
        text: "0x Launch Kit",
        link: "https://github.com/0xProject/0x-launch-kit",
      },
      {
        text: "0x Launch Kit Frontend",
        link: "https://github.com/0xProject/0x-launch-kit-frontend",
      },
    ],
  },
  images: [
    {
      original: oxLunch1,
      originalAlt:
        "A repository with an open-source, free-to-use 0x relayer template, or the 0x Launch Kit, was created by the Protofire team to let anyone start a project of their own in less than a minute.",
      thumbnail: oxLunch1,
      thumbnailAlt:
        "A repository with an open-source, free-to-use 0x relayer template, or the 0x Launch Kit, was created by the Protofire team to let anyone start a project of their own in less than a minute.",
    },
  ],
  ourTrackRecord: [],
};
