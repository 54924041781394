// eslint-disable-next-line import/prefer-default-export
export const listOfCountries1 = [
  "Argentina",
  "Belarus",
  "Brazil",
  "Chile",
  "Colombia",
  "France",
  "Georgia",
  "India",
  "Ireland",
  "Italy",
  "Latvia",
  "Moldova",
];

export const listOfCountries2 = [
  "Nigeria",
  "Peru",
  "Poland",
  "Romania",
  "Sweden",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Venezuela",
];
