/* eslint-disable react/no-unescaped-entities */
function PrivacyIntro() {
  return (
    <div
      className="text-sm text-blue200 leading-5 pt-10"
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <h2 className="text-blue100 text-4xl">1. Introduction</h2>
      <p>
        Protofire is committed to protecting the privacy rights of data
        subjects.
      </p>
      <p>
        This policy refers to the data we collect when you use our services or
        communicate with us. Examples include visiting our website, downloading
        our white papers and other materials, responding to our e-mails, and
        attending our events. This policy also explains your rights with respect
        to data we collect about you. Data privacy of our employees is regulated
        in a separate local act and is not regulated by this policy.
      </p>
      <p>
        Your information is controlled by Protofire. If you have any questions
        or concerns about how your information is handled, please direct an
        inquiry to us at <a href="mailto:dpo@altoros.com">dpo@altoros.com</a>,
        with overall responsibility for the day-to-day implementation of this
        policy.
      </p>
    </div>
  );
}

export default PrivacyIntro;
