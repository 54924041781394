/* eslint-disable react/no-unescaped-entities */
function DataTransfer() {
  return (
    <div
      className="text-sm text-blue200 leading-5 "
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <h2 className="text-blue100 text-4xl">11. Data transfer outside EEA</h2>
      <p>
        We collect information worldwide and primarily store this information in
        the United States and Ireland. We transfer, process, and store your
        information outside of your country of residence across regions wherever
        we or our third-party service providers operate for the purpose of
        delivering our services to you and for maintenance and support purposes.
        Whenever we transfer your information, we take precaution measures to
        protect it. Thus, the data by third party providers may be transferred
        to different countries globally for processing. These data transfers
        fall under the terms and privacy policies of these providers and (or)
        under standard data protection clauses.
      </p>
      <p>
        The data collected by Protofire may be transferred across our offices.
      </p>
    </div>
  );
}

export default DataTransfer;
