/* eslint-disable react/no-unescaped-entities */
function YourRights() {
  return (
    <div
      className="text-sm text-blue200 leading-5 "
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <h2 className="text-blue100 text-4xl">9. Your rights</h2>
      <p>
        Below, you will find a list of the rights you are subject to. Please
        note that some of the enlisted rights may be limited for the requests,
        which expose personal information of another individual who is subject
        to the very same rights for privacy. In such a case, we will not be able
        to satisfy your request for data deletion if it contains information we
        are eligible to keep by law.
      </p>
      <ol>
        <li>
          <span
            className="text-blue100"
            style={{
              fontFamily: "Spartan",
            }}
          >
            The right to be informed and to access information.
          </span>{" "}
          You have legal rights to access your personal data, as well as request
          if we use this data for any purpose. Complying with our general
          policy, we will provide you with a free copy of your personal
          information in use within a month after we receive your request. We
          will send your information in use via a password-protected PDF file.
          For excessive or repeated requests, we are eligible to charge a fee.
          In case of numerous or complex requests, we are eligible to prolong
          our response time by as much as two additional months. Under such
          circumstances, you will be informed about the reasons of these
          extensions. In case, we refuse to address a particular request, we
          will explain why it happens and provide you with a list of further
          actions you are eligible to proceed. If shall you wish to take further
          action, we will require two trusted IDs from you to prove your
          identity. You may forward your requests to our Data Protection Officer
          ({" "}
          <a
            href="mailto:dpo@altoros.com"
            target="_blank"
            className="text-blue"
            rel="noreferrer"
          >
            dpo@altoros.com
          </a>
          ). Please provide information about the nature of your request to help
          us process your enquiry.
        </li>
        <li>
          <span
            className="text-blue100"
            style={{
              fontFamily: "Spartan",
            }}
          >
            The right for rectification.
          </span>{" "}
          In case you believe, we store any of your personal data, which is
          incorrect or incomplete, you may request us to correct or supplement
          it. You also have the right to introduce changes to your information
          by logging into your account with us.
        </li>
        <li>
          <span
            className="text-blue100"
            style={{
              fontFamily: "Spartan",
            }}
          >
            The right to erase, or“the right to be forgotten.
          </span>{" "}
          Under this principle, you may request us to delete or remove your
          personal data if there is no solid reason for your data to continue
          processing. If you would like us to remove you from our database,
          please e-mail{" "}
          <a
            href="mailto:dpo@altoros.com"
            target="_blank"
            className="text-blue"
            rel="noreferrer"
          >
            dpo@altoros.com
          </a>
          . The right to be forgotten may be brought into force under the
          following reasons:
          <ul className="dotList2">
            <p>
              Data, which no longer has a relation to its original purpose for
              collection.
            </p>
            <p>
              You withdraw consent with respect to the original reason data was
              processed under, and there is no other reason for us to continue
              to store and process your personal data.
            </p>
            <p>
              You have objections to processing your personal data, and there
              are no overriding legitimate reasons for us to continue to process
              it.
            </p>
            <p>
              Your personal data has been unlawfully processed. Your personal
              data has to be deleted to comply with a legal obligation in a
              European Union or a Member State law to which Protofire is
              subject.
            </p>
            <p>
              Your personal data has to be deleted to comply with a legal
              obligation in a European Union or a Member State law to which
              Protofire is subject.
            </p>
            <p>
              Your personal data has been collected in relation to the offer of
              information society services.
            </p>
          </ul>
        </li>
        <li>
          <span
            className="text-blue100"
            style={{
              fontFamily: "Spartan",
            }}
          >
            The right to restrict processing.
          </span>{" "}
          Under this right, you may request us to limit processing your personal
          data. In this regard, we are eligible to store information that is
          sufficient to identify which data you want to be blocked, but cannot
          process it further. The right to restrict processing applies to the
          following cases:
          <ul className="dotList2">
            <p>
              Where you contest the accuracy of your personal data, we will
              restrict data processing until we have verified the accuracy of
              your personal data.
            </p>
            <p>
              Where you have objected to data processing under legitimate
              interests, we will consider whether our legitimate interests
              override yours.
            </p>
            <p>
              When data processing is unlawful, you oppose data deletion and
              request restriction instead.
            </p>
            <p>
              If we no longer need your personal data, but you require this data
              to establish, exercise, or defend a legal claim.
            </p>
            <p>
              If we have disclosed your personal data in question to third
              parties, we will inform them about the restriction on data
              processing, unless it is impossible or involves disproportionate
              effort to do so.
            </p>
            <p>
              We will inform you if we decide to lift a restriction on data
              processing.
            </p>
          </ul>
        </li>
        <li>
          <span
            className="text-blue100"
            style={{
              fontFamily: "Spartan",
            }}
          >
            The right to object.
          </span>{" "}
          You are eligible to object to processing your personal data based on
          legitimate interests (including profiling) and direct marketing
          (including profiling). The objection must be on “grounds relating to
          his or her particular situation.” We will inform you of your right to
          object in the first communication you receive from us. We will stop
          processing your personal data for direct marketing purposes, as soon
          as we receive an objection.
        </li>
        <li>
          <span
            className="text-blue100"
            style={{
              fontFamily: "Spartan",
            }}
          >
            The right to data portability.
          </span>{" "}
          You are eligible to obtain your personal data, which is processed by
          Protofire, to use it for your own purposes. It means you have the
          right to receive your personal data—that you have shared with us—in a
          structured machine readable format, so you can further transfer the
          data to a different data controller. This right applies in the
          following circumstances:
          <ul className="dotList2">
            <p>Where you have provided the data to Protofire.</p>
            <p>
              Where data processing is carried out, because you have given
              Protofire your consent to do so.
            </p>
            <p>
              Where data processing is carried out to develop a contract between
              you and Protofire.
            </p>
            <p>
              Where data processing is carried out automatically. (No membership
              data is processed using automated means, so this right does not
              apply).
            </p>
          </ul>
        </li>
        <li>
          <span
            className="text-blue100"
            style={{
              fontFamily: "Spartan",
            }}
          >
            Withdrawal of consent.
          </span>{" "}
          If we process your personal data based on your consent (as indicated
          at the time of collection of such data), you have the right to
          withdraw your consent at any point in time. Please note, that if you
          exercise this right, you may have to then provide your consent on a
          case-by-case basis for the use or disclosure of certain personal data,
          if such use or disclosure is necessary to enable you to utilize some
          or all of our services.
        </li>
        <li>
          <span
            className="text-blue100"
            style={{
              fontFamily: "Spartan",
            }}
          >
            Right to file a complaint.
          </span>{" "}
          You have the right to file a complaint about manipulations applied to
          your data by Protofire with the supervisory authority of your country
          or a European Union Member State.
        </li>
      </ol>
    </div>
  );
}

export default YourRights;
