import partner1 from "../assets/career/1.png";
import partner2 from "../assets/career/2.png";
import partner3 from "../assets/career/3.png";
import partner4 from "../assets/career/4.png";
import partner5 from "../assets/career/5.png";
import partner6 from "../assets/career/6.png";
import partner7 from "../assets/career/7.png";
import partner8 from "../assets/career/8.png";
import { ReactComponent as Agile } from "../assets/career/agile.svg";
import { ReactComponent as Growth } from "../assets/career/career_growth.svg";
import { ReactComponent as Education } from "../assets/career/education.svg";
import { ReactComponent as feedback } from "../assets/career/feedback.svg";
import { ReactComponent as group1 } from "../assets/career/group1.svg";
import { ReactComponent as group2 } from "../assets/career/group2.svg";
import { ReactComponent as learning } from "../assets/career/learning.svg";
import { ReactComponent as vote } from "../assets/career/vote.svg";
import { ReactComponent as Flexible } from "../assets/career/flexible.svg";
import { ReactComponent as Surprises } from "../assets/career/surprises.svg";
import { ReactComponent as Referral } from "../assets/career/referral.svg";
import { ReactComponent as Physical } from "../assets/career/physical.svg";

// eslint-disable-next-line import/prefer-default-export
export const content = [
  {
    icon: Agile,
    desc: "Global team and startup environment",
  },
  {
    icon: Flexible,
    desc: "Flexible remote-first policy",
  },
  {
    icon: Education,
    desc: "Various projects for you to make an impact",
  },
  {
    icon: feedback,
    desc: "Regular feedback",
  },
  {
    icon: group1,
    desc: "Profit shares to each team member",
  },
  {
    icon: group2,
    desc: "Home office stipend to suit your needs",
  },
  {
    icon: learning,
    desc: "30 days of paid time off",
  },
  {
    icon: Physical,
    desc: "Physical and mental health support program",
  },
  {
    icon: Growth,
    desc: "Career development and learning opportunities",
  },
  {
    icon: vote,
    desc: "Transparent company structure where everyone has a vote",
  },
  {
    icon: Referral,
    desc: "Referral program",
  },
  {
    icon: Surprises,
    desc: "More surprises when you join!",
  },
];

export const sections = {
  ourGoal: {
    title: "Our Goal",
    list: [
      "1B users and",
      "$750B+ of TVL (Total Value Locked) into crypto ecosystems by developing 10%+ of the early code base at 50 major crypto economies within 10 years.",
    ],
  },

  ourValues: {
    title: "Our Values",
    list: [
      {
        heading: "We are a continuous improvement & learning organization.",
        description:
          "We learn from successes and failures, making the most of the lessons learned.",
      },
      {
        heading:
          "We are a psychologically safe organization and encourage people to make their own decisions.",
        description:
          "We show openness to possible failure and admit mistakes openly and share lessons learned widely.",
      },
      {
        heading:
          "We address our responsibilities proactively and autonomously.",
        description:
          "We encourage a culture of continuous feedback and suggestions, healthy discussion and debate, as well as taking accountability for results and behavior.",
      },
      {
        heading: "Everybody is a shareholder.",
        description:
          "We drive business as shareholders. We have skin in the game being passionate about what we do, raising the bar, and striving to exceed expectations.",
      },
    ],
  },
  knowUs: {
    title: "You may know us as:",
    list: [
      "Creators of Solhint and eth-cli",
      "Partners of Chainlink, the Graph, Gnosis, and other major protocols and networks",
      "Part of the Safe Guardians",
      "A unique organization that gives back a fair share of the value created by members",
    ],
  },
};

export const partners = [
  partner1,
  partner2,
  partner3,
  partner4,
  partner5,
  partner6,
  partner7,
  partner8,
];
