import { ReactComponent as OvalDark } from "../../assets/images/ovalDark.svg";
import { ReactComponent as MobileOval } from "../../assets/images/mobileOval1.svg";

function Numbers() {
  return (
    <div
      className=" lg:w-9/12 pt-10  relative z-10 "
      style={{
        margin: "auto",
      }}
    >
      <div className="ovalDarkDiv z-0 ">
        <OvalDark className="ovalDark z-0 sm:hidden lg:block md:block" />
      </div>
      <MobileOval className="z-0 absolute mobileOval1 md:hidden lg:hidden" />
      <div className="z-30 relative">
        <h1 className="text-3xl py-4 text-blue100 ">
          {" "}
          Some numbers that matter{" "}
        </h1>
      </div>
      <div
        className="grid  lg:grid-cols-3 sm:grid-cols-1 
      md:grid-cols-3 lg:gap-10 z-10 md:gap-5 "
      >
        <div className="mr-20  z-10">
          <hr className="text-3xl w-7 border-4 my-5 text-yellow" />
          <p className="text-4xl  text-blue100">100+</p>
          <p className="text-xs tracking-widest  text-blue200">
            PROJECTS DELIVERED
          </p>
        </div>
        <div className="mr-20 relative z-20">
          <hr className="text-3xl w-7 border-4 my-5 text-orange" />
          <p className="text-4xl text-blue100">50+</p>
          <p className="text-xs tracking-widest  text-blue200">
            PARTNERS AND CLIENTS
          </p>
        </div>
        <div className="mr-20  z-10">
          <hr className="text-3xl w-7 border-4 my-5 text-primary" />
          <p className="text-4xl text-blue100">80+</p>
          <p className="text-xs tracking-widest  text-blue200">
            BLOCKCHAIN SPECIALISTS
          </p>
        </div>
      </div>
    </div>
  );
}

export default Numbers;
