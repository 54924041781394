import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import notFound from "../assets/images/404-image.png";
import Socials from "../socials/socials";
import { ReactComponent as External } from "../assets/images/external-icon.svg";

import "./index.css";

function NotFound() {
  return (
    <div className="sm:p-5 md:px-10 lg:px-40">
      <Helmet>
        <title>404 Page not found </title>
      </Helmet>
      <main className="fit flex flex-col justify-center items-center">
        <img
          src={notFound}
          alt="Looking for a blockchain development team for your project? The Protofire team will help you get your Web3 project delivered the way you need and on time, following the industry’s best practices."
          className="image-size"
        />
        <div className="flex flex-row items-center mt-8">
          <h1 className="lg:text-7xl md:text-5xl sm:text-3xl text-blue100 tracking-wide lg:mr-12 md:mr-6 sm:mr-4">
            404
          </h1>
          <p
            className="uppercase text-blue200 m-0 lg:text-xl md:text-base sm:text-tiny lg:tracking-wider md:tracking-wide leading-6"
            style={{ fontFamily: "Spartan-Light" }}
          >
            Page Not Found
          </p>
        </div>
        <div
          className="flex flex-col text-blue200 lg:w-[700px]"
          style={{ fontFamily: "Spartan-Light" }}
        >
          <p
            className="p-2.5 m-0 sm:text-tiny md:text-base"
            style={{ fontFamily: "Spartan-SemiBold" }}
          >
            It seems you&apos;re lost or there is some internal mistake.
          </p>
          <p className="p-2.5 m-0 sm:text-tiny md:text-base">
            If you&apos;re lost, please feel free to &nbsp;
            <Link to="/" className="underline text-error">
              visit our homepage
            </Link>
            , lurk around our{" "}
            <Link to="/services" className="underline text-error">
              services
            </Link>{" "}
            or join our &nbsp;
            <a
              href="https://protofire.io/community"
              className="underline text-error"
              target="_blank"
              rel="noreferrer"
            >
              community
            </a>
            &nbsp;
            <External className="inline-block" /> !
          </p>
          <p className="p-2.5 m-0 sm:text-tiny md:text-base">
            If you think we&apos;re hiding the information you were looking for,
            we can fix this in seconds. Just send us an email at &nbsp;
            <a href="mailto:contact@protofire.io">contact@protofire.io</a>
          </p>
          <p className="p-2.5 m-0 sm:text-tiny md:text-base">
            In the meantime, don&apos;t forget to follow us on our social media:
          </p>
          <div className="sm:flex items-start md:order-2 z-10 flex-wrap">
            <Socials />
          </div>
        </div>
      </main>
    </div>
  );
}

export default NotFound;
