/* eslint-disable import/prefer-default-export */

import dexes1 from "../../assets/services/banners/chainlink_integration-front.png";
import dexes11 from "../../assets/services/banners/chainlink_integration-back.png";
import ideation from "../../assets/services/ideation.png";
import quality from "../../assets/services/quality.png";
import prototyping from "../../assets/services/prototyping.png";
import deployment from "../../assets/services/deployment.png";
import development from "../../assets/services/development.png";
import maintenance from "../../assets/services/maintenance.png";
import toolkit1 from "../../assets/services/banners/0x_launch_kit-front.png";
import toolkit2 from "../../assets/services/banners/0x_launch_kit-back.png";
import dex1 from "../../assets/services/banners/cowswap_dex-front.png";
import dex11 from "../../assets/services/banners/cowswap_dex-back.png";
import dex2 from "../../assets/services/banners/poa_network_token-front.png";
import dex22 from "../../assets/services/banners/poa_network_token-back.png";
import dexes2 from "../../assets/services/banners/filecoin_node-front.png";
import dexes22 from "../../assets/services/banners/filecoin_node-back.png";
import web1 from "../../assets/services/banners/gnosis_conditional_tokens-front.png";
import web11 from "../../assets/services/banners/gnosis_conditional_tokens-back.png";
import sol1 from "../../assets/services/banners/solhint_v3-front.png";
import sol11 from "../../assets/services/banners/solhint_v3-back.png";
import sol2 from "../../assets/services/banners/solhint_v2-front.png";
import sol22 from "../../assets/services/banners/solhint_v2-back.png";
import sol3 from "../../assets/services/banners/solhint_v1-front.png";
import sol33 from "../../assets/services/banners/solhint_v1-back.png";
import dex3 from "../../assets/services/banners/swarm_markets-dex-front.png";
import dex33 from "../../assets/services/banners/swarm_markets-dex-back.png";

export const tabs = [
  {
    tabName: "SDKs",
    tabDrescription: `Software development kits (SDKs) are collections of 
      software development tools compiled in one installable package. They 
      facilitate the creation of applications by having a compiler, debugger, 
      and perhaps a software framework. SDKs are usually specific to a 
      hardware platform and operating system combination.`,
    tabBenefits: [
      "Rapid integration",
      "Fast and cost-effective development",
      "High quality of end products",
      "Full control over your application",
      "Reduced risk of failure",
    ],
    tabTrackRecord: [
      [
        dex1,
        dex11,
        "/projects/cowswap-dex",
        "sameWindow",
        "Protofire helped CoW Protocol to develop the CowSwap DEX for trading tokens via gas-less orders and helped to build the CoW SDK and the Explorer, along with the integration of the Batch Viewer and The Graph.",
      ],
      [
        dex2,
        dex22,
        " https://www.poa.network",
        "newWindow",
        "Token Wizard, a serverless open-source tool to create and crowdsale contracts, was created by the Protofire development team.",
      ],
      [
        dex3,
        dex33,
        "/projects/swarm-dex",
        "sameWindow",
        "Protofire team has worked on the Swarm Markets DEX, which provides compliant multi-asset DeFi infrastructure, to enable its swapping and pooling, as well as a Multi-Tier permissioning system.",
      ],
    ],
  },
  {
    tabName: "APIs",
    tabDrescription: `API is the acronym for Application Programming Interface, 
      which is a software intermediary that allows two applications to talk to 
      each other. Each time you use an app like Facebook, send an instant 
      message or check the weather on your phone, you’re using an API.`,
    tabBenefits: [
      "Increased productivity",
      "Cost reduction",
      "Improved connectivity",
      "Innovation encouragement",
      "Enhanced customer experience",
      "Data collection for intelligence analytics",
      "Promotion of new product capabilities",
    ],
    tabTrackRecord: [
      [
        dexes1,
        dexes11,
        "/projects/chainlink-integration",
        "sameWindow",
        "Protofire has tested a variety of Chainlink integrations and developed External Adapters for Chainlink that connect smart contracts to premium web APIs.",
      ],
      [
        dexes2,
        dexes22,
        "/projects/filecoin-node-infrastructure",
        "sameWindow",
        "Protofire works with the Protocol Labs developing open-source solutions to improve the Filecoin network growth and Filecoin node infrastructure.",
      ],
    ],
  },
  {
    tabName: "Toolkits",
    tabDrescription: `Toolkits, also known as Launch Kits, are software development 
      frameworks that make writing-specific applications easy to use. A toolkit is a 
      set of tools that makes it easier to build, launch, and manage applications. 
      Toolkits encompass learning tools, ready-made app files, sample applications, 
      workplace source code, and documentation.
    `,

    tabBenefits: [
      "Easy to use for rapid development",
      "Reduced development cost",
      "High quality of code",
      "High quality of implementation",
    ],
    tabTrackRecord: [
      [
        toolkit2,
        toolkit1,
        "/projects/ox-launch-kit",
        "sameWindow",
        "Protofire has developed 0x Launch Kit, a repository that contains an open-source, free-to-use 0x relayer template that one can use to start a project of their own in less than a minute.",
      ],
    ],
  },
  {
    tabName: "App Components",
    tabDrescription: `Application components are essential for building 
      blocks of an app. Each component is an entry point through which the 
      system or a user can enter an application. Some components are 
      dependent on other components.`,
    tabBenefits: [
      "Improved app usability and user experience",
      "Higher responsiveness and performance ",
      "Automated processes",
      "Opportunities for task management",
      "Customer self-service",
      "Data collection, aggregation, and analysis",
    ],
    tabTrackRecord: [
      [
        web1,
        web11,
        "/projects/gnosis-explorer",
        "sameWindow",
        "Protofire created hierarchical and custom components for the Gnosis’ Conditional Tokens Explorer.",
      ],
    ],
  },
  {
    tabName: "Solhint",
    tabDrescription: `Developed by Protofire with Ethereum Foundation grants, 
      Solhint is an advanced linter for detecting syntax-related security 
      vulnerabilities in the Solidity-based code underlying smart contracts 
      in Ethereum. This open-source solution provides security and style guide 
      validation by using a wide range of rules. 

      Solhint is integrated seamlessly into most mainstream integrated 
      development environments, or IDEs. The solution is flexibly 
      configurable and extendable while staying unobtrusive. Solhint’s 
      user experience is similar to any other language linters. 
      Solhint is an active project of Protofire, it is 
      maintained and regularly updated with new features 
      by the development team.`,

    tabBenefits: [
      "Style guide validation tools",
      "Shareable configuration",
      "Custom plugins",
      "Seamless integration in most IDEs",
      "Autofix support",
      "Open-source solution",
    ],
    tabTrackRecord: [
      [
        sol1,
        sol11,
        "https://medium.com/protofire-blog/solhint-v3-0-autofix-support-and-new-integrations-8af3f802b99c",
        "newWindow",
        "Developed by Protofire, Solhint v3.0 is an advanced linter with auto-fix support and a variety of integrations for detecting syntax-related security vulnerabilities in the Solidity-based code.",
      ],
      [
        sol2,
        sol22,
        "https://medium.com/protofire-blog/welcome-solhint-v2-0-shareable-configs-and-custom-plugins-ef9fac5dabb5",
        "newWindow",
        "Developed by Protofire, Solhint v2.0 is an advanced linter with shareable configurations and custom plugins, that is used for detecting syntax-related security vulnerabilities in the Solidity-based code.",
      ],
      [
        sol3,
        sol33,
        "https://medium.com/protofire-blog/solhint-an-advanced-linter-for-ethereums-solidity-c6b155aced7b",
        "newWindow",
        "Protofire has developed Solhint, an advanced linter for detecting syntax-related security vulnerabilities in the Solidity-based code.",
      ],
    ],
  },
  {
    tabName: "Ethereum CLI",
    tabDrescription: `Eth-cli is a collection of CLI tools and commands that help 
      users to develop dApps for Ethereum and interact with contracts deployed in 
      any blockchain network. Eth-cli helps users to learn the under-the-hood mechanisms
       of Ethereum and start developing right away. Eth-cli allows users to fetch data 
       from the blockchain, start an interactive REPL connected to a node, and call methods 
       on deployed contracts, all through a command line. This toolset enables users to 
       interact with contracts in any network regardless of how they were initially deployed. 
       The solution is under continuous development and feature list expansion.
      `,
    tabBenefits: [
      "An easy way to learn Ethereum development",
      "Easy to use and flexible ",
      "Can be applied to any contract on any blockchain",
    ],
    tabTrackRecord: [],
  },
];

export const whatWeProvide = [
  {
    name: "Ideation",
    icone: ideation,
    description: `We conceptualize and ideate the architecture, implementation, and productivity of your developer tool.`,
  },
  {
    name: "Prototyping",
    icone: prototyping,
    description: `We build a prototype of a tool for you to see what it may look like and make necessary changes.`,
  },
  {
    name: "Development",
    icone: development,
    description: `We develop the tool based on the agreed prototype under your supervision, known schedule, and budget.`,
  },
  {
    name: "Quality Assurance",
    icone: quality,
    description: `We carry out quality assurance procedures to make sure all features of the solution work as intended.`,
  },
  {
    name: "Deployment",
    icone: deployment,
    description: `We deploy your developer tool to the designated environment under your control, following the technical requirements.`,
  },
  {
    name: "Maintenance & Support",
    icone: maintenance,
    description: `We maintain the solution, its features, and related infrastructure and will trigger you if certain decisions are required.`,
  },
];
