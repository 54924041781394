import { Helmet } from "react-helmet";
import { project } from "./content";
import githubIcon from "../../assets/solhint/icons/github.svg";
import checkIcon from "../../assets/solhint/icons/check-icon.svg";
import solhintHelps from "../../assets/solhint/solhint-helps.png";
import about from "../../assets/solhint/about.png";
import NotesIcon from "../../assets/solhint/icons/notes.svg";
import DiscordIcon from "../../assets/solhint/icons/discord.svg";
import ContactForm from "./contact-form/contact-form";

function Solhint() {
  return (
    <main>
      <Helmet>
        <title>Solhint | Protofire</title>
        <meta
          name="description"
          content="Solhint is a Solidity linter that helps to write high-quality smart contracts code, securely and bug-free. Contact Protofire for similar tool development."
        />
        <meta
          name="keywords"
          content="Solhint, Solidity linter, Solidity code analysis tool, Solidity security auditing, Solidity best practices, Ethereum smart contract linter, Ethereum smart contract security, Solidity code quality, Solidity code style guide, Solidity static analysis, Solidity code validation, Solidity code formatting, Solidity code linting, Solidity code checking, Solidity code enforcement"
        />
      </Helmet>
      <section
        id="download"
        className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 text-center sm:py-16 lg:py-32"
        style={{ fontFamily: "Outfit-Medium" }}
      >
        {project.hero.heroTextRows.map((row) => (
          <h1 className="text-white text-[60px] leading-[66px]" key={row}>
            {row}
          </h1>
        ))}
        <div className="inline-block mb-4">
          <h1 className="text-[60px] leading-[66px] bg-gradient-to-r from-[#fc683a] to-[#fec847] text-transparent bg-clip-text inline-block">
            {project.hero.highlightedText}
          </h1>
          <hr className="bg-gradient-to-r from-[#fc683a] to-[#fec847] h-1" />
        </div>
        <div className="my-8">
          {project.hero.descriptionRows.map((row) => (
            <p
              className="text-solhintText my-0 text-[18px] leading-[30px]"
              style={{ fontFamily: "Inter" }}
              key={row}
            >
              {row}
            </p>
          ))}
        </div>
        <div
          className="flex justify-center flex-row flex-wrap gap-4"
          style={{ fontFamily: "Inter" }}
        >
          <a
            className="text-white bg-gradient-to-r from-[#2f57ff] to-[#b52efe] rounded-[5px] w-[220px] h-[60px] leading-[60px] inline-block"
            href="https://www.npmjs.com/package/solhint"
            target="_blank"
            rel="noreferrer"
          >
            Get on NPM
          </a>
          <a
            className="text-white rounded-[5px] w-[220px] h-[60px] leading-[60px] inline-block border-white border-solid border-2"
            href="https://github.com/protofire/solhint"
            target="_blank"
            rel="noreferrer"
          >
            <img className="inline mr-2 mb-1" src={githubIcon} alt="GitHub" />
            Get on GitHub
          </a>
        </div>
      </section>
      <section
        className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-16"
        style={{ fontFamily: "Outfit-Medium" }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-[56px]">
          <div className="mb-16">
            <h4 className="text-white text-[24px] leading-[34px] mb-12">
              {project.brandLogo.usedByTitle}
            </h4>
            <div className="grid align-middle grid-cols-1 md:grid-cols-2 gap-x-[70px] gap-y-[40px]">
              {project.brandLogo.usedBy.map((company) => (
                <img
                  src={company.logo}
                  alt={company.title}
                  key={company.title}
                />
              ))}
            </div>
          </div>
          <div className="mb-16">
            <h4 className="text-white text-[24px] leading-[34px] mb-12">
              {project.brandLogo.trustedByTitle}
            </h4>
            <div className="grid align-middle grid-cols-1 md:grid-cols-2 gap-x-[70px] gap-y-[40px]">
              {project.brandLogo.trustedBy.map((company) => (
                <img
                  className="self-center"
                  src={company.logo}
                  alt={company.title}
                  key={company.title}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
      <section
        className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 pb-2 sm:pb-6 lg:pb-16 bg-solhint-looper2 bg-no-repeat"
        style={{ fontFamily: "Inter" }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-[56px]">
          <div>
            <img src={solhintHelps} alt="Solhint Helps" />
          </div>
          <div className="self-center">
            <h3
              className="text-white text-[42px] leading-[48px] mb-8"
              style={{ fontFamily: "Outfit-Bold" }}
            >
              {project.solhintHelps.title}
            </h3>
            {project.solhintHelps.advantages.map((advantage) => (
              <div
                className="text-[18px] leading-[30px] text-solhintText mb-5"
                key={advantage}
              >
                <img
                  className="inline-block mr-3"
                  src={checkIcon}
                  alt={advantage}
                />{" "}
                {advantage}
              </div>
            ))}
          </div>
        </div>
      </section>
      <section
        className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-16"
        style={{ fontFamily: "Inter" }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-[125px] gap-y-[25px]">
          {project.stats.map((stat) => (
            <div
              className="flex flex-row gap-x-[12px] items-center"
              key={stat.title}
            >
              <h4
                className="text-white text-[60px] leading-[66px]"
                style={{ fontFamily: "Outfit-Bold" }}
              >
                {stat.value}
              </h4>
              <div className="text-solhintText text-[18px] leading-[30px] w-[100px]">
                {stat.title}
              </div>
            </div>
          ))}
        </div>
      </section>
      <section
        id="about"
        className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-2 sm:py-6 lg:py-16 bg-solhint-looper3 bg-no-repeat bg-right-bottom"
        style={{ fontFamily: "Inter" }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-[20%]">
          <div>
            <h3
              className="text-white text-[42px] leading-[48px] mb-8"
              style={{ fontFamily: "Outfit-Bold" }}
            >
              {project.about.title}
            </h3>
            {project.about.descriptionParagraphs.map((paragraph) => (
              <p
                className="text-solhintText text-[18px] leading-[30px]"
                key={paragraph}
              >
                {paragraph}
              </p>
            ))}
          </div>
          <div>
            <img src={about} alt="About Solhint" />
          </div>
        </div>
      </section>
      <section
        className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-16 bg-solhint-looper4 bg-no-repeat bg-contain bg-[center_100px]"
        style={{ fontFamily: "Inter" }}
      >
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-[32px]">
          {project.moreInfo.map((feature) => (
            <div key={feature.title}>
              <img src={feature.icon} alt={feature.title} />
              <h3
                className="text-white text-[20px] leading-[30px] mt-[20px] inline-block"
                style={{ fontFamily: "Outfit-Medium" }}
              >
                {feature.title}
              </h3>
              {feature.paragraphs.map((paragraph) => {
                if (Array.isArray(paragraph)) {
                  return (
                    <ul
                      className="text-solhintText text-[16px] leading-[24px]"
                      key={paragraph[0]}
                    >
                      {paragraph.map((item) => (
                        <li className="list-disc" key={item}>
                          {item}
                        </li>
                      ))}
                    </ul>
                  );
                }
                return (
                  <p
                    className="text-solhintText text-[16px] leading-[24px] list-disc list-inside"
                    key={paragraph}
                  >
                    {paragraph}
                  </p>
                );
              })}
              <a
                href={feature.link}
                className="bg-gradient-to-r from-[#3356ff] to-[#b32dfe] text-transparent bg-clip-text"
                target="_blank"
                rel="noreferrer"
              >
                Learn more →
              </a>
            </div>
          ))}
        </div>
        <div
          className="flex flex-row flex-wrap gap-4 mt-16"
          style={{ fontFamily: "Inter" }}
        >
          <a
            className="text-white text-center rounded-[5px] w-[240px] h-[60px] leading-[60px] inline-block border-white border-solid border-2"
            href="https://github.com/protofire/solhint/releases"
            target="_blank"
            rel="noreferrer"
          >
            <img className="inline mr-2 mb-1" src={NotesIcon} alt="GitHub" />
            Release Notes
          </a>
          <a
            className="text-white text-center rounded-[5px] text-[16px] w-[240px] h-[60px] leading-[60px] inline-block border-white border-solid border-2"
            href="https://discord.com/invite/4TYGq3zpjs"
            target="_blank"
            rel="noreferrer"
          >
            <img className="inline mr-2 mb-1" src={DiscordIcon} alt="Discord" />
            Ask on Discord
          </a>
        </div>
      </section>
      <section
        className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-16"
        style={{ fontFamily: "Inter" }}
      >
        <div className="bg-gradient-to-r from-[#3356ff] to-[#b32dfe] rounded-lg p-8 text-center">
          <img
            className="mx-auto h-[60px] mb-8"
            src={project.donate.img}
            alt={project.donate.title}
          />
          <h3
            className="text-white text-[20px] leading-[30px]"
            style={{ fontFamily: "Outfit-Medium" }}
          >
            {project.donate.title}
          </h3>
          <p className="text-white mb-8 text-[18px] leading-[28px]">
            {project.donate.description}
          </p>
          <a
            className="text-center text-[16px] leading-[24px] bg-white px-5 py-4 inline-block"
            href="https://etherscan.io/address/0xA81705c8C247C413a19A244938ae7f4A0393944e"
            target="_blank"
            rel="noreferrer"
            style={{ fontFamily: "Outfit-Medium" }}
          >
            Donate
          </a>
        </div>
      </section>
      <section
        id="contact"
        className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-16"
        style={{ fontFamily: "Inter" }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8">
          <div className="mb-8">
            <h3
              className="text-white text-[36px] leading-[44px] mb-[20px]"
              style={{ fontFamily: "Outfit-Bold" }}
            >
              {project.contact.title}
            </h3>
            <p className="text-solhintText my-0 text-[20px] leading-[30px]">
              {project.contact.description}
            </p>
          </div>
          <div>
            <ContactForm />
          </div>
        </div>
      </section>
      <section
        className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-16"
        style={{ fontFamily: "Inter" }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 items-center">
          <div>
            <img src={project.audit.img} alt={project.audit.title} />
          </div>
          <div className="mb-8">
            <h3
              className="text-white text-[36px] leading-[44px] mb-[20px]"
              style={{ fontFamily: "Outfit-Bold" }}
            >
              {project.audit.title}
            </h3>
            <p className="text-solhintText my-0 mb-10 text-[20px] leading-[30px]">
              {project.audit.description}
            </p>
            <a
              href="mailto:luis.medeiros@protofire.io"
              className="text-white bg-gradient-to-r from-[#2f57ff] to-[#b52efe] rounded-[5px] text-[16px] leading-[24px] py-4 px-10 inline-block cursor-pointer"
            >
              Request Now
            </a>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Solhint;
