import gnosisSafe1 from "../../assets/projectsImages/gnosisSafe1.png";
import gnosisSafeNew from "../../assets/projectsImages/gnosisSafeNew.png";
import gnosisSafe2 from "../../assets/projectsImages/gnosisSafe2.png";
import gnosisSafe3 from "../../assets/projectsImages/gnosisSafe3.svg";
import gnosisSafe4 from "../../assets/projectsImages/gnosisSafe4.png";

import gno1 from "../../assets/services/57.png";
import gno11 from "../../assets/services/58.png";
import gno2 from "../../assets/services/59.png";
import gno22 from "../../assets/services/60.png";
import gno3 from "../../assets/services/61.png";
import gno33 from "../../assets/services/62.png";
// eslint-disable-next-line import/prefer-default-export
export const project = {
  name: "Gnosis Safe Multisig Wallet",
  hashtags: ["dApp", "Interface", "Smart Contract", "Wallet"],
  description: `Protofire has worked alongside Gnosis Safe as a partner on the 
  development of the Gnosis Safe technology. Gnosis Safe Multisig is a customizable 
  crypto wallet running on Ethereum that requires a predefined number of signatures 
  to confirm transactions to prevent unauthorized access to the assets stored. DeFi 
  apps can be accessed and user funds can be put to work from inside the safe 
  interface. There are currently dozens of billions in funds stored in safes.`,
  thumbnail: gnosisSafeNew,
  thumbnailAlt: `Protofire helped Gnosis Safe to develop the Gnosis Safe technology. Gnosis Safe Multisig is a customizable crypto wallet running on Ethereum.`,
  aboutProject: {
    title: "About the project",
    lists: [
      `Safe technology built by experts`,
      `Usage of non-custodial wallets`,
      `Financial management tool`,
      `Multisignature for teams and individuals`,
      `Versions: Web, Desktop, and Mobile apps`,
      `Gnosis Safe supports ETH, ERC20 (Tokens) and ERC721 (NFTs)`,
      `Users can see the value of their assets in several currencies`,
      `A balance between usability, security, and self-custody`,
    ],
    sellVolume: ["$100B", "Total value locked (TVL) (Q1/2022)"],
    batches: ["48K+", "Total safes created (Q1/2022)"],
    totalTrades: ["458K+", "Total transactions made (Q1/2022)"],
  },
  theClient: {
    title: "The client/partner",
    firstParagraph: `Protofire team has worked alongside Gnosis Safe as a partner 
    on the development of the first version of the Gnosis Safe multisig. Gnosis Safe
    turned out to become one of the most trusted platforms to manage digital assets 
    in the Ethereum ecosystem.`,
  },
  theProblem: {
    title: "The problem",
    firstParagraph: `According to Elliptic, overall losses caused by DeFi exploits 
    have totaled $12 billion in 2021. Security measures in the ecosystem are still 
    limited. The reasons for such hacks to succeed are the usage of:`,
    lists: [
      "Poor security systems;",
      "Unsafe wallets",
      "Software developed by amateurs",
      "Lack of enterprise management tools",
      "Users don’t control their own assets",
    ],
  },
  theSolution: {
    title: "The solution",
    firstParagraph: `To promote greater security for users of the blockchain ecosystems, 
      Gnosis Safe provides a multisignature transaction platform. The solution 
      is tailored for teams and individuals seeking greater security and money 
      management options. A few features are:`,
    lists: [
      [`Users can invest in DeFi directly from the interface`],
      [`DeFi investments include earn, borrow, invoice, payroll, etc`],
      [
        `Open source: It means that it can be upgraded to a network's needs. Also, 
        it means that code can be verified and audited by anyone`,
      ],
      [
        `Batched transactions: The ability to batch multiple 
        transactions into one to reduce costs`,
      ],
      [
        `Safe Module: Safe allows modular extensions to its multi-signature execution flow`,
      ],
      [
        `Safe Guard: Security logic extension that enforces additional 
        validation checks on accounts`,
      ],
      [`Gasless signatures`],
      [
        `Synthetix Mintr Safe App: The team at Protofire delivered the Synthetix
         Mintr Safe App - a dApp to mint synthetic assets by stacking SNX tokens
          directly from the Gnosis Safe Multisig wallet`,
        "https://blog.synthetix.io/staking-snx-gnosis-safe-wallets/",
      ],
    ],
  },
  theMedia: {
    title: "Media",
    firstMedia: {
      text: "Protofire Secures Assets and Transactions in Moonbeam with a Fork of Gnosis Safe:",
      link: "https://medium.com/protofire-blog/protofire-secures-assets-and-transactions-in-moonbeam-with-a-fork-of-gnosis-safe-26b185a30cd1",
    },
    secondMedia: {
      text: "Protofire Deploys a Fork of Gnosis Safe Multisig into Moonriver to Enforce Security: ",
      link: "https://medium.com/protofire-blog/protofire-deploys-a-fork-of-gnosis-safe-multisig-into-moonriver-to-enforce-security-13e8630d9569",
    },
  },
  technologyStack: {
    title: "Technology stack",
    lauguages: ["React", "Node.js", "Python", "AWS", "CloudFormation"],
    database: [
      "Redis / ElastiCache",
      "RDS / PostgreSQL",
      "L1 blockchains (full nodes)",
    ],
    tools: ["Storybook", "Styled Components", "Jest Rust"],
  },
  images: [
    {
      original: gnosisSafe1,
      originalAlt: `Protofire has built Gnosis Safe Multisig, a customizable crypto wallet running on Ethereum allowing usage of non-custodial wallets.`,
      thumbnail: gnosisSafe1,
      thumbnailAlt: `Protofire has built Gnosis Safe Multisig, a customizable crypto wallet running on Ethereum allowing usage of non-custodial wallets.`,
    },
    {
      original: gnosisSafe2,
      originalAlt: `Protofire has built Gnosis Safe Multisig, a customizable crypto wallet running on Ethereum with multisignature for teams and individuals.`,
      thumbnail: gnosisSafe2,
      thumbnailAlt: `Protofire has built Gnosis Safe Multisig, a customizable crypto wallet running on Ethereum with multisignature for teams and individuals.`,
    },
    {
      original: gnosisSafe3,
      originalAlt: `Protofire has built Gnosis Safe Multisig, a customizable crypto wallet running on Ethereum which supports ETH, ERC20 (Tokens) and ERC721 (NFTs).`,
      thumbnail: gnosisSafe3,
      thumbnailAlt: `Protofire has built Gnosis Safe Multisig, a customizable crypto wallet running on Ethereum which supports ETH, ERC20 (Tokens) and ERC721 (NFTs).`,
    },
    {
      original: gnosisSafe4,
      originalAlt: `Protofire has built Gnosis Safe Multisig, a customizable crypto wallet running on Ethereum which supports ETH, ERC20 (Tokens) and ERC721 (NFTs).`,
      thumbnail: gnosisSafe4,
      thumbnailAlt: `Protofire has built Gnosis Safe Multisig, a customizable crypto wallet running on Ethereum which supports ETH, ERC20 (Tokens) and ERC721 (NFTs).`,
    },
  ],
  ourTrackRecord: [
    [
      gno1,
      gno11,
      "https://multisig.moonbeam.network/welcome",
      "newWindow",
      "Protofire has successfully deployed Gnosis Safe multisig on Moonbeam and Moonriver networks.",
    ],
    [
      gno2,
      gno22,
      "https://safe.evmos.org/welcome",
      "newWindow",
      "Protofire has successfully deployed a fork of Gnosis Safe multisig on the Evmos network.",
    ],
    [
      gno3,
      gno33,
      "https://medium.com/protofire-blog/the-gooddollar-wallet-to-deliver-universal-basic-income-worldwide-2148fe3d7492",
      "newWindow",
      "Protofire and GoodDollar developed a GoodDollar crypto wallet with the ultimate purpose of reducing wealth inequality using blockchain technology.",
    ],
  ],
};
