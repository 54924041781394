import chainlink from "../../assets/projectsImages/chainLinkNew.png";

export const project = {
  name: "Chainlink Developer Tools",
  hashtags: ["Chainlink", "Foundry"],
  description: [
    "The Chainlink Developer Tools, developed by Protofire, simplify interactions with Chainlink services and facilitate local testing of dApps that integrate them.",
    "Protofire has developed the Chainlink Developer Tools, which include toolkits and plugins for modern Web3 frameworks, such as Hardhat and Foundry. These tools simplify interactions with Chainlink services, including Data Feeds, VRF, Functions, and Automations. They also facilitate local testing of dApps that integrate Chainlink, helping with the setup and management of local Chainlink nodes and simulations.",
  ],
  thumbnail: chainlink,
  thumbnailAlt: `The Chainlink Developer Tools, developed by Protofire, simplify interactions with Chainlink services and facilitate local testing of dApps that integrate them.`,
  technologyStack: {
    title: "Technology stack",
    lauguages: ["Typescript", "Solidity Scripting", "Solidity", "Bash + make"],
    environment: ["Postgres as a part of Chainlink nodes"],
    network: ["EVM-compatible networks"],
    infrastructure: ["Docker", "Chainlink oracles"],
  },
  code: {
    text: "Code",
    codes: [
      {
        text: "Developer Tooling for Oracle Contracts Explained by Dmitrii Kotelkin at SmartCon 2023",
        link: "https://www.youtube.com/watch?v=r8S0pomjuvM&t=1s",
      },
      {
        text: "Hardhat Chainlink Plugin",
        link: "https://docs.chain.link/quickstarts/hardhat-plugin",
      },
      {
        text: "Foundry Chainlink Toolkit",
        link: "https://docs.chain.link/quickstarts/foundry-chainlink-toolkit",
      },
    ],
  },
  ourTrackRecord: [],
};

export const content = [
  {
    title: "About the project",
    anchor: "page-about",
    description: [
      "Chainlink Developer Tools include the Hardhat Plugin and the Foundry Toolkit. These standalone applications utilize TypeScript in Hardhat, along with Forge, Make, and Solidity Scripting in Foundry. They offer functionality for interacting with Chainlink services, spinning up local Chainlink nodes, creating Chainlink Jobs, and deploying and interacting with related Smart Contracts. All functionalities are accessible both directly in the code of a project utilizing a specific framework and via CLI commands.",
    ],
    lists: [
      `Multi Solidity compiler support.`,
      `Local testing.`,
      `Convenient interaction with Chainlink services.`,
    ],
  },
  {
    title: "Hardhat Chainlink Plugin",
    anchor: "page-hardhat-plugin",
    description: [
      "The Hardhat Chainlink Plugin allows the integration of the Chainlink functionality into your web3 development workflow through seamless interaction between Chainlink services and Hardhat-based projects. The plugin supports Data Feeds, VRF, Automation and Functions. Developers can use the plugin’s sandbox to spin up a local Chainlink node, run local tests and simulations against it.",
    ],
  },
  {
    title: "Foundry Chainlink Toolkit",
    anchor: "page-foundry-plugin",
    description: [
      "The Foundtry Chainlink toolkit provides atomic methods to interact with smart contracts of the Chainlink services: Data Feeds, VRF, Automation and Functions. This plugin allows to easily integrate Chainlink functionality into the web3 development workflow of existing Foundry projects. Developers can use this plugin to spin up a local cluster of Chainlink nodes, run local tests and simulations against it.",
    ],
  },
  {
    title: "The client/partner",
    anchor: "page-client",
    description: [
      "Chainlink is a decentralized oracle network that enables smart contracts on blockchain platforms to securely interact with real-world data, external APIs, and traditional payment systems.",
    ],
  },
  {
    title: "The problem",
    anchor: "page-problem",
    description: [
      "Developers who relied on Chainlink tools within their projects faced a lack of convenient, streamlined methods for smooth development and operation.",
      "The integration of Chainlink tools with the Foundry and Hardhat frameworks has solved this challenge offering ready-made functionality for Chainlink node developers.",
    ],
  },
  {
    title: "The solution",
    anchor: "page-solution",
    description: [
      "Chainlink Developer Tools introduce unified methods and a Command-Line Interface using TypeScript, Forge, and Solidity Scripting that encompass functionalities of Chainlink, Hardhat and Foundry. This consolidation smooths the development process, allowing developers to interact with Chainlink services without the need to switch between separate frameworks and tools.",
      "These Developer Tools are designed so that they can be launched through CLI and integrated into the project code using the corresponding framework.",
      "Chainlink Developer Tools documentation covers step-by-step instructions and use cases, enabling developers to leverage Chainlink features effortlessly.",
    ],
  },
];
