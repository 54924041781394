import chainlink from "../../assets/projectsImages/monitoring-platform-giga.png";

export const project = {
  name: "Monitoring Platform for Giga",
  hashtags: ["Big Data Protocol", "Solution"],
  description: [
    "Protofire has built a platform for Giga, an organization of which Unicef takes part of, that has as a goal to connect every school to the internet to empower people and help students develop.",
    "For that, Protofire has created a platform to connect governments, internet service providers and schools, so we could keep track of the service students are obtaining and governments can pay providers that satisfy the terms on their end of the contract.",
  ],
  thumbnail: chainlink,
  thumbnailAlt: `Protofire has built a platform for Giga, an organization of which Unicef takes part of, that has as a goal to connect every school to the internet to empower people and help students develop.`,
  technologyStack: {
    title: "Technology stack",
    lauguages: ["Typescript"],
    environment: ["Microsoft Azure"],
    technologies: [
      "Azure Static Web Apps",
      "GitHub Actions",
      "Ether.js",
      "Blocknative",
      "Gnosis Safe",
      "Metamask",
    ],
    database: ["PostgreSQL", "Azure Blob Storage"],
  },
  code: {
    text: "Code",
    codes: [
      {
        text: "Developer Tooling for Oracle Contracts Explained by Dmitrii Kotelkin at SmartCon 2023",
        link: "https://www.youtube.com/watch?v=r8S0pomjuvM&t=1s",
      },
      {
        text: "Hardhat Chainlink Plugin",
        link: "https://docs.chain.link/quickstarts/hardhat-plugin",
      },
      {
        text: "Foundry Chainlink Toolkit",
        link: "https://docs.chain.link/quickstarts/foundry-chainlink-toolkit",
      },
    ],
  },
  ourTrackRecord: [],
};

export const content = [
  {
    title: "About the project",
    anchor: "page-about",
    description: [],
    lists: [
      `The Giga team has approached to Protofire to build a system that ensures transparency around the delivery of Internet services between Brazilian/Botswana governments, schools, and providers.`,
    ],
  },
  {
    title: "The client/partner",
    anchor: "page-client",
    description: [
      "Launched in 2019 as an international initiative, the Giga organization aims to connect every school in the world to the Internet by 2030. Thus far, the initiative has provided internet access to over 2 million students in more than 130 countries.",
    ],
  },
  {
    title: "The problem",
    anchor: "page-problem",
    description: [
      "Under the project, the team at Protofire had to address the following issues:",
    ],
    lists: [
      "As the platform was to gather real-time data around the Internet connection (speed, latency, etc.) from 110,000 schools, performance was crucial.",
      "To protect sensitive information (contracts, payments, etc.), ensuring security was key.",
      "The API utilized in the previous projects for other countries to gather metrics (e.g., bandwidth and latency) returned inconsistent data. For instance, download speed could return as a numerical value or a word. On top, it was impossible to capture uptime.",
    ],
  },
  {
    title: "The solution",
    anchor: "page-solution",
    description: [
      "Partnering with Protofire, the organization developed a platform that facilitates the contract creation and payments between Internet service providers and Brazilian/Botswana governments. The system also enables 110,000 school in these countries to monitor the quality of service provided.",
      "Thanks to the architectural approach and CI/CD pipelines, the customer can easily expand the prototype and roll out new features in a matter of minutes.",
      "In addition to cryptocurrency payments, the integration of Metamask ensured that the transactions are permanently recorded in a blockchain, allowing governments to quickly audit budget allocation.",
    ],
  },
];
