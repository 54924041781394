import { Helmet } from "react-helmet";
import ContactUs from "./contactUs";

function Index() {
  return (
    <div className="py-20 lg:px-40 md:px-10 sm:px-10">
      <Helmet>
        <title>Contact Us | Protofire</title>
        <meta
          name="description"
          content="We help decentralized protocols grow their ecosystems. Contact us if you want to design, develop and deploy your blockchain project with Protofire. "
        />
        <meta
          name="keywords"
          content="blockchain development, blockchain design, blockchain deployment"
        />
      </Helmet>
      <ContactUs />
    </div>
  );
}

export default Index;
