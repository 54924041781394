import { useState } from "react";

import { otherDev, protofire } from "./content";
import { ReactComponent as Close } from "../assets/images/closeGray.svg";
import { ReactComponent as Open } from "../assets/images/openYellow.svg";
import { ReactComponent as CloseWhite } from "../assets/images/white-arrow-up.svg";

function Index() {
  const [firstDiv, setFirstDiv] = useState(false);
  const [secondDiv, setSecondDiv] = useState(false);
  return (
    <div className="">
      <div className=" ">
        <div className="flex sm:px-5 ">
          <p
            className={`flex-auto text-sm tracking-widest 
            ${firstDiv ? "text-blue100" : "text-blue200 "}`}
          >
            OTHER DEV SHOPS
          </p>
          {firstDiv && (
            <CloseWhite
              onClick={() => setFirstDiv(!firstDiv)}
              className="cursor-pointer mt-5"
            />
          )}
          {!firstDiv && (
            <Open
              onClick={() => setFirstDiv(!firstDiv)}
              className="cursor-pointer mt-5"
            />
          )}
        </div>
        {firstDiv && (
          <ul
            className="mobileArrowList text-blue200 text-xs "
            style={{
              fontFamily: " Spartan-Medium",
            }}
          >
            {otherDev.map((item, index) => (
              <li
                className={`  ${index % 2 === 0 ? "bg-background" : ""}`}
                key={item}
              >
                {item}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="">
        <div className="px-5  ">
          <div className=" flex border-t border-blue300">
            <p
              className={`flex-auto text-sm tracking-widest 
            ${secondDiv ? "textGradient" : "text-blue200 "}`}
            >
              PROTOFIRE
            </p>
            {secondDiv && (
              <Close
                onClick={() => setSecondDiv(!secondDiv)}
                className="cursor-pointer mt-5"
              />
            )}
            {!secondDiv && (
              <Open
                onClick={() => setSecondDiv(!secondDiv)}
                className="cursor-pointer mt-5"
              />
            )}
          </div>
        </div>
        {secondDiv && (
          <ul
            className="mobileArrowList2 text-blue100 text-xs "
            style={{
              fontFamily: " Spartan-Medium",
            }}
          >
            {protofire.map((item, index) => (
              <li
                className={`  ${index % 2 === 0 ? "bg-background" : ""}`}
                key={item}
              >
                {item}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default Index;
