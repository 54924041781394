import { useState, useEffect, useMemo } from "react";

type RandomTitleProps = {
  className?: string;
};

function RandomTitle({ className }: RandomTitleProps) {
  const [title, setTitle] = useState("Front-end and dApps development");
  const [fadeIn, setFadeIn] = useState(true);

  const titles = useMemo(
    () => [
      "Front-end and dApps development",
      "Smart contracts audit and development",
      "Dev tools development",
      "DevOps and IaaS",
      "Oracles, monitors, bridges and other integrations",
      "Safe multisig deployment",
      "Indexing, Subgrpahs aaS",
      "Cross-chain solutions",
      "Protocol fork / deployment / maintenance",
      "Deploying/porting new chains",
      "Insurance and Risk management",
      "Dashboards and Governance implementation",
      "Community growth, management and activation",
      "Developer adoption marketing",
      "Conferences and side events support",
      "PR and crisis management",
      "RPC Nodes Hosting",
      "Stablecoin (CDP) protocols development, fork and deploy, based on Liquity/Prisma code base",
      "Staking modules based on ve8020 Balancer pools",
      "Lending/Borrowing protocols development, fork and deploy",
      "Incentivized Validators",
    ],
    []
  );

  useEffect(() => {
    const changeTitle = () => {
      const randomIndex = Math.floor(Math.random() * titles.length);
      setTitle(titles[randomIndex]);
      setFadeIn(true);
    };

    const interval = setInterval(() => {
      setFadeIn(false);
      setTimeout(changeTitle, 1000);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [titles]);

  return (
    <h1
      className={`${
        fadeIn ? "opacity-100" : "opacity-0"
      } transition-opacity duration-1000 ease-in-out ${className} sm:h-[10rem] sm:w-full md:h-auto md:w-auto overflow-hidden md:mt-4`}
    >
      {title}
    </h1>
  );
}

RandomTitle.defaultProps = {
  className: "",
};

export default RandomTitle;
