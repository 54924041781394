import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ImageGallery from "react-image-gallery";
import { project } from "./content";
import legend from "../../assets/about/legend.png";
import { ReactComponent as OvalCareerMobile2 } from "../../assets/career/ovalCareerMobile2.svg";
import { ReactComponent as OvalCareerBig } from "../../assets/career/ovalCareerBig.svg";
import { ReactComponent as BgMiddle } from "../../assets/career/bgMiddle.svg";

import ContactUs from "../../contactUs/contactUs";
import "../project.css";
import "../../services/tab.css";
import "react-image-gallery/styles/css/image-gallery.css";

function Platform() {
  const [active, setActive] = useState("page1");

  useEffect(() => {
    const observer = new IntersectionObserver(function (entries) {
      const ele: IntersectionObserverEntry[] = entries.filter(
        (entry) => entry.isIntersecting
      );
      if (ele.length > 0) {
        const ele1 = ele[0].target;
        setActive(ele1.id);
      }
    });
    document
      .querySelectorAll(".myCount [id^=page]")
      .forEach((ele) => observer.observe(ele));
  }, []);

  return (
    <div className="lg:px-0  md:px-10 py-20">
      <Helmet>
        <title>TrustExplorer for Armanino | Protofire</title>
        <meta
          name="description"
          content="We assist Armanino in the development of their accounting, auditing, and reporting solutions for TrustExplorer. Have a similar project? Let’s collaborate."
        />
        <meta
          name="keywords"
          content="accounting solutions, project accounting, financial audit, business audit, accounting and auditing, auditing service, payroll services"
        />
      </Helmet>
      <section
        className="lg:flex md:flex lg:px-60 md:px-10 text-blue200 relative z-10
        text-xs   sm:px-5"
        style={{
          fontFamily: "Spartan-Medium",
        }}
      >
        <Link to="/" className=" mr-1 text-blue200">
          Home
        </Link>
        /
        <Link to="/projects" className="ml-1 mr-1 text-blue200 ">
          Projects
        </Link>
        /
        <Link to="/services" className="ml-1 text-blue100 ">
          Platform
        </Link>
      </section>
      <div className="relative lg:block md:hidden sm:hidden z-1 ">
        <OvalCareerBig className=" absolute ovalBigProjectPages1" />
      </div>
      <div className="relative lg:hidden md:hidden sm:block z-1 ">
        <OvalCareerMobile2 className=" absolute bgOvalProjectPages2" />
      </div>
      <section
        className="md:pl-10 lg:px-0 
       lg:pb-20 sm:px-5 py-10 lg:pl-60 m-auto relative z-10"
      >
        <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-10">
          <div className="lg:w-5/6 sm:w-full md:w-full">
            <h1 className="text-blue100 text-4xl md:text-5xl xl:text-6xl">
              {project.name}
            </h1>
            <div className="flex flex-wrap text-xs text-blue ">
              {project.hashtags.map((hashtag) => (
                <span
                  key={hashtag}
                  className=" py-1 px-3 border border-blue my-2 mr-3 rounded-3xl"
                >
                  {hashtag}{" "}
                </span>
              ))}
            </div>
            <p
              className="py-5 text-blue200 leading-7"
              style={{
                fontFamily: "Spartan-Medium",
              }}
            >
              {project.description}
            </p>
          </div>
          <div className="w-full  lg:block sm:hidden md:block">
            <img
              src={project.thumbnail}
              alt={project.thumbnailAlt}
              className="w-full"
            />{" "}
          </div>
        </div>
      </section>

      <div
        className="lg:px-60  md:px-10 sm:px-5 pb-20 grid lg:grid-cols-3  z-10
      md:grid-cols-3 sm:grid-cols-1 relative  overflow-auto bg-darkBackground"
        style={{
          height: "600px",
        }}
      >
        <div className="col-span-2  myCount">
          <section className="pt-5" id="page1">
            <div className=" lg:col-span-2 md:col-span-2">
              <p className="text-blue100 text-3xl ">
                {project.theClient.title}{" "}
              </p>
              <p
                className="text-blue200 py-2 text-sm leading-6"
                style={{
                  fontFamily: "Spartan-Medium",
                }}
              >
                {project.theClient.firstParagraph}{" "}
              </p>
            </div>
          </section>
          {project.entry.map((item, index) => (
            <section className="pt-5" id={`page${index + 2}`} key={item.title}>
              <p className="text-blue100 text-2xl ">{item.title} </p>
              <div>
                <p className="text-blue100 text-lg ">
                  {item.aboutProject.title}:
                </p>
                <div
                  className="dotList text-sm  leading-5"
                  style={{
                    fontFamily: "Spartan-Medium",
                  }}
                >
                  {item.aboutProject.lists.map((list) => (
                    <li className="pb-1 text-blue200" key={list}>
                      {list}
                      <a
                        href={item.aboutProject.site}
                        className="text-blue ml-1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        View More
                      </a>
                    </li>
                  ))}
                </div>
              </div>
              <div className="py-2">
                <p className="text-blue100 text-lg pt-3">
                  {item.theProblem.title}:
                </p>
                <div
                  className="dotList text-sm leading-5"
                  style={{
                    fontFamily: "Spartan-Medium",
                  }}
                >
                  {item.theProblem.lists.map((list) => (
                    <li className="pb-1 text-blue200" key={list}>
                      {list}
                    </li>
                  ))}
                </div>
              </div>
              <div className="">
                <p className="text-blue100 text-lg pt-3">
                  {item.theSolution.title}:
                </p>
                <div
                  className="dotList text-sm leading-5"
                  style={{
                    fontFamily: "Spartan-Medium",
                  }}
                >
                  {item.theSolution.lists.map((list) => (
                    <li className="pb-1 text-blue200" key={list}>
                      {list}
                    </li>
                  ))}
                </div>
              </div>
            </section>
          ))}
          <section
            className="pt-5 text-sm leading-6 w-5/6 m-auto"
            id="testimonial"
            style={{
              fontFamily: "Spartan-Medium",
            }}
          >
            <fieldset className="md:p-5 sm:p-5 lg:p-10 my-5 border-blue border text-blue200">
              <legend>
                <img src={legend} alt="legend" />{" "}
              </legend>
              <p>{project.comment.body}</p>
              <p className="pt-8 text-blue100">{project.comment.author}</p>
              <p>{project.comment.title}</p>
            </fieldset>
          </section>
        </div>
        <aside
          className="text-xs  text-blue200 lg:ml-20 py-10 md:ml-20 
          sticky top-5 right-5  h-60 sm:hidden lg:block md:block"
        >
          <ul className="border-l border-blue300 pl-5">
            <li className="pb-2">
              <a
                className={` ${active === "page1" ? "text-blue" : ""}`}
                href="#page1"
                onClick={() => setActive("page1")}
              >
                {" "}
                The Client/Partner
              </a>
            </li>
            <li className="pb-2">
              <a
                className={` ${active === "page2" ? "text-blue" : ""}`}
                href="#page2"
                onClick={() => setActive("page2")}
              >
                {" "}
                TrustExplorer Real-Time
              </a>
            </li>
            <li className="pb-2">
              <a
                className={` ${active === "page3" ? "text-blue" : ""}`}
                href="#page3"
                onClick={() => setActive("page3")}
              >
                {" "}
                TrustExplorer Proof of Reserves
              </a>
            </li>
            <li className="pb-2">
              <a
                className={` ${active === "page4" ? "text-blue" : ""}`}
                href="#page4"
                onClick={() => setActive("page4")}
              >
                {" "}
                TrustExplorer Trusted Node
              </a>
            </li>{" "}
            <li className="pb-2">
              <a
                className={` ${active === "page5" ? "text-blue" : ""}`}
                href="#page5"
                onClick={() => setActive("page5")}
              >
                {" "}
                TrustExplorer Treasury
              </a>
            </li>
          </ul>
        </aside>
      </div>
      <section
        className="lg:px-40 w-3/4 m-auto lg:py-10 md:py-20 sm:py-10 
      sm:hidden lg:block md:block"
      >
        <p className="text-blue100 text-3xl pt-3">Images</p>
        <div>
          <ImageGallery items={project.images} thumbnailPosition="bottom" />
        </div>
      </section>
      <div className="relative lg:block md:hidden sm:hidden z-10 ">
        <BgMiddle className=" absolute bgMiddleProject" />
      </div>
      <section
        className="lg:bg-square-bg md:bg-square-bg sm:bg-mobile-bg
       bg-contain bg-center z-10 globeProject"
      >
        <div
          className="bg-circle-bg relative 
          h-full m-auto 
        sm:w-full lg:w-1/3 md:w-2/3  bg-cover bg-center bg-no-repeat z-0 "
        >
          <div
            className="text-center m-0, w-full top-1/2 left-1/2 absolute"
            style={{
              transform: "translate(-50%, -50%)",
            }}
          >
            <h1 className="text-blue100 lg:text-2xl md:text-2xl  leading-10 sm:text-2xl ">
              <span
                style={{
                  fontFamily: "Spartan-Light",
                }}
              >
                In case you need a complete{" "}
              </span>
              platform development solution,
            </h1>
            <p className="text-blue100 pt-5 Spartan-Medium text-sm w-9/12 m-auto">
              {" "}
              <a
                href="#contact"
                className="pr-2 underline  "
                style={{
                  background:
                    "linear-gradient(to right, #F54703 50%, #FAC902 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Get in touch with us
              </a>
              so that we can discuss your ideas.
            </p>
          </div>
          {/* <CircleBg /> */}
        </div>
      </section>
      <section className="lg:px-40 pt-5 m-auto" id="contact">
        <ContactUs />
      </section>
    </div>
  );
}

export default Platform;
