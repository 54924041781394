import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import protofireLogo from "../../components/assets/images/protofire.svg";
import Socials from "../../components/socials/socials";

function CommunityLayoutHeader() {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const scrollCheck = () => {
      setScroll(window.scrollY > 50);
    };

    window.addEventListener("scroll", scrollCheck);

    return () => window.removeEventListener("scroll", scrollCheck);
  }, []);

  return (
    <header
      className={`h-[105px] mb-[-105px] lg:sticky top-0 z-50 bg-opacity-100 transition duration-300 ease-in-out ${
        scroll
          ? "bg-gradient-to-r from-[#121329] to-[#20146d]"
          : "bg-transparent"
      }`}
      style={{ fontFamily: "RobotoMono-Medium" }}
    >
      <nav className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between py-8 flex-col lg:flex-row">
          <div className="mx-auto lg:mx-0 flex justify-center sm:justify-start flex-col lg:flex-row items-center">
            <div className="flex flex-shrink-0 items-center mb-4 lg:mb-0">
              <Link to="/">
                <img
                  className="h-8 w-auto"
                  src={protofireLogo}
                  alt="Protofire"
                />
              </Link>
            </div>
            <div className="hidden lg:ml-10 sm:block">
              <div className="flex space-x-4">
                <a
                  href="#whoweare"
                  className="text-white hover:text-white hover:underline px-3 py-2"
                  aria-current="page"
                >
                  Who We Are
                </a>
                <a
                  href="#perks"
                  className="text-white hover:text-white hover:underline px-3 py-2"
                >
                  Perks
                </a>
                <a
                  href="#partners"
                  className="text-white hover:text-white hover:underline px-3 py-2"
                >
                  Partners
                </a>
              </div>
            </div>
          </div>
          <div className="flex">
            <Socials />
          </div>
        </div>
      </nav>
    </header>
  );
}

export default CommunityLayoutHeader;
