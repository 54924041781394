import graph from "../../assets/projectsImages/theGraphNew.png";
import graph1 from "../../assets/projectsImages/graph1.png";
import graph2 from "../../assets/projectsImages/graph2.png";
import graph3 from "../../assets/projectsImages/graph3.png";

// eslint-disable-next-line import/prefer-default-export
export const project = {
  name: "The Graph Subgraphs and Indexer Node",
  hashtags: ["DevOps", "Node Management", "Subgraph"],
  description: `Protofire provides ongoing assistance to onboard Dapps to The Graph. 
  For instance, our team helps to validate smart contracts and on-chain data, define 
  the roadmap for subgraph development and adoption, as well as deploy new projects 
  on multiple EVM networks. Protofire has already built over 50 subgraphs for 
  different prominent projects in the ecosystem.`,
  thumbnail: graph,
  thumbnailAlt: `Protofire provides ongoing assistance to onboard Dapps to The Graph, validate smart contracts and on-chain data, define the roadmaps for subgraph development, and deploy new projects on multiple EVM networks.`,
  aboutProject: {
    title: "About the project",
    lists: [
      `Definition of the roadmap for subgraph development and adoption`,
      `Ongoing implementation of subgraphs for different protocols`,
      `Validation of smart contracts and on-chain data`,
      `Deployment of new projects on the test and main Ethereum/IPFS network`,
      `Open-source subgraphs to be queried by any developer`,
      `Subgraph toolkits that facilitate bootstrapping of new subgraphs`,
    ],
  },
  theClient: {
    title: "The client/partner",
    firstParagraph: `The Graph is a decentralized protocol to index and query 
    data on Ethereum and IPFS using the GraphQL language. Anyone can build and 
    publish open APIs, called subgraphs, making data easily accessible.`,
  },
  theProblem: {
    title: "The problem",
    firstParagraph: `Before The Graph, teams had to develop and operate proprietary 
    indexing servers. This required significant engineering and hardware resources 
    and broke the important security properties required for decentralization. `,
    lists: [],
  },
  theSolution: {
    title: "The solution",
    firstParagraph: ``,
    firstListTitle: "",
    lists: [
      [
        `With the Graph protocol, end users can access historical and real-time data in the 
        GraphQL explorer, explore the network status, and build new types of apps`,
      ],
      [
        `All data is stored and processed on open networks with verifiable integrity`,
      ],
      [`The Graph makes querying this data fast, reliable, and secure`],
      [`Open-source subgraphs to be queried by any developer`],
      [`Subgraph toolkits that facilitate bootstrapping of new subgraphs`],
      [
        `An open network produces fast, cheap, and reliable access data for 
        the crypto economy Also:`,
      ],
    ],
    secondListTitle: "Main Roles in the Ecosystem:",
    list2: [
      `Indexer Node: Indexers are node operators in The Graph Network that 
        stake Graph Tokens (GRT) in order to provide indexing and query processing 
        services. Protofire has been a node operator for The Graph since its 
        inception`,
      `Delegators: Delegators stake GRT with an Indexer(s) in order to earn a portion 
      of Indexer rewards and fees, without having to personally run a Graph Node.`,
      `Curators: Curators use GRT to signal to Indexers which subgraphs are valuable 
      to index. Curators will typically be developers but could also be end users 
      supporting a valuable service they rely upon.`,
    ],
  },
  theMedia: {
    title: "Media",
    list: [
      {
        text: "Protofire Provides Implementation Services in The Graph Partnership:",
        link: "https://medium.com/protofire-blog/protofire-provides-implementation-services-in-the-graph-partnership-7e07591031d7",
      },
      {
        text: "Introduction to The Graph Protocol ",
        link: "https://thegraph.com/blog/the-graph-network-in-depth-part-1",
      },
      {
        text: "Subgraph Development, Part 1: Understanding and Aggregating Data: ",
        link: "https://medium.com/protofire-blog/subgraph-development-part-1-understanding-and-aggregating-data-ef0c9a61063d ",
      },
      {
        text: "Subgraph Development, Part 2: Handling Arrays and Identifying Entities: ",
        link: "https://medium.com/protofire-blog/subgraph-development-part-2-handling-arrays-and-identifying-entities-30d63d4b1dc6 ",
      },
      {
        text: "Protofire Contributes to Mission Control — The Graph’s Incentivized Testnet: ",
        link: "https://medium.com/protofire-blog/protofire-contributes-to-mission-control-the-graphs-incentivized-testnet-4c2062f1d9dc ",
      },
      {
        text: "The Graph: Building a subgraph for HumanityDAO (Part 1):",
        link: "https://medium.com/protofire-blog/the-graph-building-a-subgraph-for-humanitydao-part-1-9b3f1c3feb8a",
      },
      {
        text: "The Graph: Building a subgraph for HumanityDAO (Part 2):",
        link: "https://medium.com/protofire-blog/the-graph-building-a-subgraph-for-humanitydao-part-2-fc870c1a98fa",
      },
    ],
    lastMedia: {
      text: "Code",
      codes: [
        {
          text: "Subgraph Repositories",
          link: " https://github.com/orgs/protofire/repositories?q=subgraph&type=all",
        },
        {
          text: "Subgraph Toolkit",
          link: "https://github.com/protofire/subgraph-toolkit",
        },
        {
          text: "Subgraph Starter-kit",
          link: "https://github.com/protofire/react-subgraph-starter-kit",
        },
      ],
    },
  },
  comment: {
    body: `We have been working with Protofire for over a year, and they are a huge 
    asset to the community. They understand DeFi and Web3 protocols in detail and 
    continue to find creative ways to build new features and products to push the 
    space forward. We are excited that Protofire has chosen to make The Graph a 
    core part of their stack and recommend them highly to any teams looking to 
    accelerate product development.`,

    author: "Yaniv Tal",
    title: "Co-Founder of The Graph and Edge & Node, CEO of Geo",
  },
  technologyStack: {
    title: "Technology stack",
    lauguages: ["Solidity", "Assemblyscript", "Python", "GraphQL"],
    database: ["RAWS/GCP/Azure", "SQL", "Docker "],
    tools: ["Github", "GIT", "Terraform", "Kubernetes"],
  },
  images: [
    {
      original: graph1,
      originalAlt:
        "Protofire onboards dApps to The Graph validates smart contracts and on-chain data and deploys new projects on multiple EVM networks.",
      thumbnail: graph1,
      thumbnailAlt:
        "Protofire onboards dApps to The Graph validates smart contracts and on-chain data and deploys new projects on multiple EVM networks.",
    },
    {
      original: graph2,
      originalAlt:
        "Protofire onboards decentralized apps to The Graph validates smart contracts and on-chain data, defines the roadmaps for subgraph development, and deploys new projects on multiple EVM networks.",
      thumbnail: graph2,
      thumbnailAlt:
        "Protofire onboards decentralized apps to The Graph validates smart contracts and on-chain data, defines the roadmaps for subgraph development, and deploys new projects on multiple EVM networks.",
    },
    {
      original: graph3,
      originalAlt:
        "Protofire onboards dAapps to The Graph, validates smart contracts and deploys new projects on multiple EVM networks.",
      thumbnail: graph3,
      thumbnailAlt:
        "Protofire onboards dAapps to The Graph, validates smart contracts and deploys new projects on multiple EVM networks.",
    },
  ],
  ourTrackRecord: [],
  deliveries: [
    "Aragon",
    "Everest Ropsten",
    "Maker DAO Governance",
    "Uniswap",
    "Bull Run Babes",
    "Listing NFT Order Information",
    "GRT Supply ",
    "Crypto Pepes",
    "Instadapp DSA",
    "Makersplace",
    "Augur v2",
    "Avastars",
    "Compound Governance",
    "EulerBeats",
    "Set Protocol",
    "AutoGlyphs",
    "Bloom",
    "Meme ltd",
    "Kyber",
    "Axie Inifinty",
    "Multi Collateral Dai",
    "Dai ERC20",
    "Ray",
    "Curve Gauges",
    "dYdX",
    "Curve",
    "Nexus",
    "Uma",
    "LoopRing 3.6",
    "Superrare",
    "Eth Block Art",
    "Audius",
  ],
};
