/* eslint-disable import/prefer-default-export */
// import dAppps1 from "../../assets/services/deApp/1.png";
// import dAppps11 from "../../assets/services/deApp/11.png";
import ideation from "../../assets/services/ideation.png";
import quality from "../../assets/services/quality.png";
import prototyping from "../../assets/services/prototyping.png";
import deployment from "../../assets/services/deployment.png";
import development from "../../assets/services/development.png";
import maintenance from "../../assets/services/maintenance.png";
import automation1 from "../../assets/services/banners/48_network_templates-front.png";
import automation2 from "../../assets/services/banners/48_network_templates-back.png";
import dexes2 from "../../assets/services/banners/filecoin_node-front.png";
import dexes22 from "../../assets/services/banners/filecoin_node-back.png";
import dexes3 from "../../assets/services/banners/algorand-front.png";
import dexes33 from "../../assets/services/banners/algorand-back.png";
import dexes1 from "../../assets/services/banners/the_graph_indexer-front.png";
import dexes11 from "../../assets/services/banners/the_graph_indexer-back.png";
import autom1 from "../../assets/services/banners/poa_network_deployment-front.png";
import autom11 from "../../assets/services/banners/poa_network_deployment-back.png";
import autom2 from "../../assets/services/banners/polkadot_failover-front.png";
import autom22 from "../../assets/services/banners/polkadot_failover-back.png";

export const tabs = [
  {
    tabName: "Node Infrastructure Management",
    tabDrescription: `Nodes are critical aspects of blockchain security. A cryptocurrency 
      node is a participant in a blockchain network. Node service providers run distributed 
      node clients behind the scenes for you, so you don't have to. Some services offer you 
      to run a dedicated node of your own, while others use load balancers to distribute 
      activity across nodes. A node service must be easy to integrate with, involving one-line 
      changes in your code to swap out your self-hosted node, or even switch between the services.
    `,

    tabUseCases: [
      "Bridge relayer operations",
      "Validator operations and network governance",
      "Delegating and staking",
      "Network endpoints operations",
      "Decentralization of infrastructure (on the cloud, regional, and zone levels)",
      "Business continuity, disaster recovery",
      "Incident management and resolution ",
    ],
    tabBenefits: [
      "Ensuring faster deployment",
      "Stabilizing the work environment",
      "Significant improvement in product quality",
      "Automation in repetitive tasks",
      "Continuous delivery of software",
      "Fast and reliable problem-solving techniques",
    ],
    tabTrackRecord: [
      [
        dexes2,
        dexes22,
        "/projects/filecoin-node-infrastructure",
        "sameWindow",
        "Protofire develops open-source solutions and node infrastructure for Filecoin. Protofire has also developed the CID Checker for Filecoin, which provides a list of all CIDs along with their status, and builds open-source solutions and node infrastructure for the Filecoin ecosystem.",
      ],
      [
        dexes3,
        dexes33,
        "https://auth.algorand.chain.love/",
        "newWindow",
        "We run publicly available RPC nodes for the Algorand Mainnet.",
      ],
      [
        dexes1,
        dexes11,
        "/projects/the-graph-subgraphs",
        "sameWindow",
        "Protofire is one of the Indexer Nodes in The Graph Network that stake Graph Tokens and provide indexing and query processing services.",
      ],
    ],
  },
  {
    tabName: "Automation in the Cloud",
    tabDrescription: `Cloud automation refers to the implementation of tools and processes
     in order to reduce the manual intervention associated with provisioning, configuring, 
     and managing public, private, and hybrid cloud environments.
    `,

    tabUseCases: [
      `Building complex multi-cloud and cross-cloud blockchain 
      infrastructure from scratch`,
      "Making improvements to existing setups",
      "Dockerized and/or K8s cloud deployments",
      "IaC solutions",
      "CI/CD (Jenkins, Circle CI, GitLab CI, GitHub actions)",
      "Automation tooling (Ansible, Terraform)",
      "Custom Image packing",
      "Custom solutions for monitoring (Grafana, Prometheus, ELK, etc.)",
    ],
    tabBenefits: [
      "Agility and business scalability",
      "Simplified processes",
      "Faster deployment and easy cloud management",
      "Reduced IT infrastructure costs",
      "Streamlined IT focus",
      "Improved network security and resilience",
    ],
    tabTrackRecord: [
      [
        automation2,
        automation1,
        "https://medium.com/protofire-blog/protofire-releases-monitoring-templates-for-multicloud-blockchains-740b2b19127f",
        "newWindow",
        "Protofire created 48 configuration templates to set up monitoring for cross-cloud blockchain networks in a few hours rather than weeks.",
      ],
      [
        autom1,
        autom11,
        "https://www.poa.network/",
        "newWindow",
        "Protofire shares POA Network Deployment Playbooks for deploying POA Network nodes on EC2 or any Linux.",
      ],
      [
        autom2,
        autom22,
        "https://github.com/protofire/polkadot-failover-mechanism",
        "newWindow",
        "Protofire shares a Polkadot Failover Mechanism for operators running Polkadot nodes to optimize uptime without jeopardizing being slashed due to issues such as double signing.",
      ],
    ],
  },
];

export const whatWeProvide = [
  {
    name: "Designing infrastructure architecture",
    icone: ideation,
    description: `We outline the idea, scope and requirements for your project and advise on effective adjustments.`,
  },
  {
    name: "Prototyping",
    icone: prototyping,
    description: `We create a prototype of your solution or share a ready-made similar solution with you to try it and make necessary changes.`,
  },
  {
    name: "Development (CI/CD pipelines and containerization)",
    icone: development,
    description: `We develop the solution and set up its infrastructure under your supervision, known schedule, and budget.`,
  },
  {
    name: "Network & Protocol bootstrapping",
    icone: quality,
    description: `We carry out quality assurance procedures to make sure all features of the solution work as intended.`,
  },
  {
    name: "Monitoring configuration",
    icone: deployment,
    description: `We deploy the solution to the designated environment under your control, following technical requirements.`,
  },
  {
    name: "Maintenance and Support",
    icone: maintenance,
    description: `We maintain the solution and the infrastructure and will trigger you if certain decisions are required. `,
  },
];
