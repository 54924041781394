/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";
import PrivacyPolicy from "../privacyPolicy/privacyPolicy";
import { ReactComponent as Linkedin } from "../assets/images/linkedin.svg";
import { ReactComponent as Telegram } from "../assets/images/telegram.svg";
/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint-disable-next-line jsx-a11y/label-has-associated-control

import alejandro from "../assets/images/alejandro.png";

type ContactProps = {
  contactName: string;
  linkedin: string;
  telegram: string;
  contactRole: string;
  image: string;
};

function ContactUs({
  contactName = "Alejandro Losa",
  linkedin = "https://www.linkedin.com/in/alejandrolosa",
  telegram = "https://t.me/alemood",
  contactRole,
  image = alejandro,
}: Partial<ContactProps>) {
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    telegram: "",
    fullMessage: "",
  });

  const [modalOpen, setModalOpen] = useState(false);

  const [contactViaMessengers, setContactViaMessengers] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [receiveNews, setReceiveNews] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.currentTarget;
    setForm({ ...form, [name]: value });
  };
  const handleMessengers = () => {
    setContactViaMessengers(!contactViaMessengers);
  };
  const handlePrivacy = () => {
    setPrivacyPolicy(!privacyPolicy);
  };
  const handleReceiveNews = () => {
    setReceiveNews(!receiveNews);
  };
  const isValid = Object.values(form).some((value) => value.length > 0);
  const notify = (message: string) => toast(message);

  // eslint-disable-next-line consistent-return
  const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (!isValid) return notify("At least one fields should be filled");
    const webhookUrl = `${process.env.REACT_APP_WEBHOOK_URL}`;

    const formData = {
      text: `Name: ${form.name} \n
      TelegramId: ${form.telegram} \n
      Phone: ${form.phone} \n
      Email: ${form.email} \n
      Full Message: ${form.fullMessage} \n
      Prefer via messengers ${contactViaMessengers ? "yes" : "no"} \n
      Agree to receive new research papers: ${receiveNews ? "yes" : "no"} \n
      ${window.location.href}`,
    };
    if (!privacyPolicy)
      return notify("not sent, please accept  privacy policy");

    const res = await axios.post(webhookUrl, JSON.stringify(formData), {
      withCredentials: false,
      transformRequest: [
        (data, headers: any) => {
          delete headers.post["Content-Type"];
          return data;
        },
      ],
    });

    if (res.status === 200) {
      notify("Message Successfully sent");
      ReactGA.event({
        category: "Button",
        action: `Contact form submitted by  ${form.name}`,
      });
      setForm({
        name: "",
        phone: "",
        email: "",
        telegram: "",
        fullMessage: "",
      });
    } else {
      notify("Not sent, please check your entries");
    }
  };

  return (
    <div
      className="bg-darkBackground bg-contact-bg bg-contain bg-right-top bg-no-repeat mb-10  lg:p-20 md:p-10 sm:p-5"
      style={{
        marginTop: "10px",
        fontFamily: "Spartan-Medium",
      }}
    >
      <p
        className="text-3xl text-blue100 my-5 sm:text-4xl sm:mb-0 "
        style={{
          fontFamily: "Spartan",
        }}
      >
        {" "}
        Contact Us
      </p>
      <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 ">
        <form>
          <ToastContainer />
          <div className="grid xl:grid-cols-2 xl:gap-6">
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="name"
                id="firstName"
                className="block py-3.5 px-0 w-full text-sm text-blue100  bg-darkBackground
              border-0 border-b border-blue200  appearance-none  focus:outline-none 
              focus:ring-0 focus:border-yellow peer"
                placeholder="First/Full Name "
                onChange={handleChange}
                value={form.name}
                required
              />
              <label
                htmlFor="firstName"
                className="peer-focus:font-medium absolute text-sm text-blue200  
              duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] 
              peer-focus:left-0 
              peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
              peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                First/Full Name
              </label>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="email"
                name="email"
                id="email"
                className="block py-3.5 px-0 w-full text-sm text-blue100  bg-darkBackground
              border-0 border-b border-blue200  appearance-none  focus:outline-none
              focus:ring-0 focus:border-yellow peer"
                placeholder="Email "
                onChange={handleChange}
                value={form.email}
                required
              />
              <label
                htmlFor="email"
                className="peer-focus:font-medium absolute text-sm text-blue200
              duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]
              peer-focus:left-0
              peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0
              peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Email
              </label>
            </div>
          </div>
          <div className="grid xl:grid-cols-2 xl:gap-6">
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="tel"
                // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                name="phone"
                id="phone"
                className="block py-3.5 px-0 w-full text-sm text-blue100  bg-darkBackground
              border-0 border-b border-blue200  appearance-none  focus:outline-none 
              focus:ring-0 focus:border-yellow peer"
                placeholder="Phone (763-97-54) "
                onChange={handleChange}
                value={form.phone}
                required
              />
              <label
                htmlFor="phone"
                className="peer-focus:font-medium absolute text-sm text-blue200
              duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] 
              peer-focus:left-0 
              peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
              peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Phone number (123-456-7890)
              </label>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="telegram"
                id="lastName"
                className="block py-3.5 px-0 w-full text-sm text-blue100  bg-darkBackground
              border-0 border-b border-blue200  appearance-none  focus:outline-none
              focus:ring-0 focus:border-yellow peer"
                placeholder="Telegram ID "
                onChange={handleChange}
                value={form.telegram}
                required
              />
              <label
                htmlFor="lastName"
                className="peer-focus:font-medium absolute text-sm text-blue200
              duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]
              peer-focus:left-0
              peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0
              peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Telegram ID
              </label>
            </div>
          </div>

          <div className=" relative z-0 w-full ">
            <textarea
              name="fullMessage"
              id="fullMessage"
              className="block py-3.5 px-0 w-full text-sm text-blue100  bg-darkBackground
              border-0 border-b border-blue200  appearance-none  focus:outline-none 
              focus:ring-0 focus:border-yellow peer"
              placeholder="How can we help you? "
              onChange={handleChange}
              value={form.fullMessage}
              required
            />
            <label
              htmlFor="fullMessage"
              className="peer-focus:font-medium absolute text-sm text-blue200
              duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] 
              peer-focus:left-0 
              peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
              peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              How can we help you
            </label>
          </div>
          <div>
            <div className="flex items-center my-5 mt-10 ">
              <div>
                <input
                  id="checkbox-2"
                  onChange={handleMessengers}
                  checked={contactViaMessengers}
                  type="checkbox"
                  className="w-4 h-4 text-blue200  rounded
              focus:ring-yellow
              focus:ring-2  ring-offset-yellow accent-yellow
              "
                />
              </div>
              <label
                htmlFor="checkbox-2"
                className={`ml-2 text-sm ${
                  contactViaMessengers ? "text-blue100" : "text-blue200"
                }`}
              >
                I prefer to be contacted via messengers
              </label>
            </div>

            <div className="flex items-center my-5 ">
              <div>
                <input
                  id="privacy"
                  type="checkbox"
                  checked={privacyPolicy}
                  onChange={handlePrivacy}
                  className="w-4 h-4 text-blue200  rounded
              focus:ring-yellow
              focus:ring-2  ring-offset-yellow accent-yellow
              "
                />
              </div>
              <label
                htmlFor="privacy"
                className={`ml-2 text-sm ${
                  privacyPolicy ? "text-blue100" : "text-blue200"
                }`}
              >
                I accept the{" "}
                <button
                  type="button"
                  className="text-yellow "
                  onClick={() => setModalOpen(!modalOpen)}
                >
                  Privacy Policy
                </button>
              </label>
            </div>

            <div className="flex items-center my-5 ">
              <div>
                <input
                  id="checkbox-1"
                  onChange={handleReceiveNews}
                  checked={receiveNews}
                  type="checkbox"
                  className="w-4 h-4 text-blue200  rounded
              focus:ring-yellow  
              focus:ring-2  ring-offset-yellow accent-yellow
              "
                />
              </div>
              <label
                htmlFor="checkbox-1"
                className={`ml-2 text-sm ${
                  receiveNews ? "text-blue100" : "text-blue200"
                }`}
              >
                I agree to receive new research papers announcements as well as
                information about Protofire services and special offers
              </label>
            </div>
          </div>
          <PrivacyPolicy modalOpen={modalOpen} setModalOpen={setModalOpen} />
          <button
            type="submit"
            className="my-5 px-10 py-5  tracking-wider text-blue100 btn"
            onClick={handleSubmit}
            style={{ fontSize: "12px", fontFamily: "Spartan" }}
          >
            SEND MESSAGE
          </button>
        </form>
        <div
          className="sm:order-first sm:mb-10 lg:order-last md:order-first
        lg:ml-40 md:ml-40 bg-contact-bg-photo bg-right-bottom bg-no-repeat "
        >
          <div className="sm:ml-0 ml-40 mt-20 sm:mt-10 ">
            <div className="h-50">
              <img
                src={image}
                alt="Protofire helps Web2 companies and Web3 ecosystems to grow TVL and advance their node infrastructure. If you need a blockchain solution developed, contact Protofire for details contact@protofire.io"
                className="w-ful"
              />
            </div>
            <p className="text-blue100 my-3 text-lg sm:text-lg md:text-lg">
              {contactName}
            </p>
            {contactRole && (
              <p className="text-sm text-blue200 sm:text-sm md:text-sm">
                {contactRole}
              </p>
            )}
            <p className="text-sm text-blue200 sm:text-sm md:text-sm flex gap-4">
              <a href={linkedin} target="_blank" rel="noreferrer">
                <Linkedin />
              </a>
              <a href={telegram} target="_blank" rel="noreferrer">
                <Telegram />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
