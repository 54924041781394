import filecoin from "../../assets/projectsImages/filecoinNew.png";
import filecoin1 from "../../assets/projectsImages/filecoin1.png";
import filecoin2 from "../../assets/projectsImages/filecoin2.png";

// eslint-disable-next-line import/prefer-default-export
export const project = {
  name: "Filecoin Node Infrastructure",
  hashtags: ["API", "DevOps", "Explorer", "Node Management"],
  description: `Protofire has received several grants from Protocol Labs and has been 
  working together with the Protocol Labs team (as contributors to Glif tools) 
  since before the launch of the Filecoin Mainnet. As part of the Glif team we 
  are continuously developing open-source solutions with the intention of improving
   the Filecoin community and promoting network growth. As such, we have helped to 
   provide node infrastructure for Filecoin solutions. We have also developed the 
   CID Checker, which provides a list of all CIDs along with their status.`,
  thumbnail: filecoin,
  thumbnailAlt:
    "Protofire develops open-source solutions and node infrastructure for Filecoin.",
  aboutProject: {
    title: "About the project",
    lists: [
      [
        `Contributing with DevOps expertise in the form of node infrastructure management
`,
      ],
      [`Public and dedicated nodes`, "https://api.node.glif.io/"],
      [
        "Providing a public API for the most popular client for the Filecoin network",
        "https://lotus.filecoin.io/developers/glif-nodes/",
      ],
      [
        "A website and API service that can list all CIDs along with their status ",
        "https://filecoin.tools/",
      ],
    ],
    sellVolume: ["3.9K", "Active Storage Providers (Q1/2022)"],
    batches: [
      "304K FIL ",
      "24h FIL Production (block rewards) (~U$7 million dollars per day) (Q1/2022)",
    ],
  },
  theClient: {
    title: "The client/partner",
    firstParagraph: `Filecoin is an open-source, public cryptocurrency and digital 
    payment system intended to be a blockchain-based cooperative digital storage 
    and data retrieval method. Filecoin builds on top of InterPlanetary File System 
    (IPFS), an open-source protocol allowing users to rent unused hard drive space. 
    Users pay hyper-competitive prices to storage providers for file storage and 
    retrieval. The Filecoin network works as a dynamic distributed cloud storage 
    network for data.`,
  },
  theProblem: {
    title: "The problem",
    firstParagraph: `Growing a blockchain network effectively implies several 
    difficulties:`,
    lists: [
      "Attracting users.",
      "Allowing users to connect to the network",
      "Letting users utilize the application easily.",
    ],
    secondParagraph: `A way of dealing with that is by providing the required 
    infrastructure for that to happen. We have provided that by building a public 
    and dedicated node infrastructure and management of it, as well as a CID 
    (Content Identifier of Data) explorer of the data that a user wants to store on 
    the Filecoin network.`,
  },
  theSolution: {
    title: "The solution",
    firstParagraph: `Filecoin Glifs: Glifs are interoperable tools for the 
    Filecoin network`,
    lists: [
      [
        `Glif Nodes: Public and dedicated Filecoin node infrastructure`,
        `https://api.node.glif.io/`,
      ],
      [
        `Filecoin CID Checker: A website and API service that can list 
        all CIDs along with their current status in the latest state tree`,
        "https://filecoin.tools/",
      ],
    ],
  },
  technologyStack: {
    title: "Technology stack ",
    lauguages: ["Typescript", "Node.js", "React"],
    database: ["MongoDB"],
    tools: [],
  },
  code: {
    text: "Code",
    codes: [
      {
        text: "Chart for hosting Lotus Node clients",
        link: "https://github.com/glifio/filecoin-chart",
      },
      {
        text: "Filecoin CID Checker",
        link: "https://github.com/protofire/filecoin-CID-checker",
      },
    ],
  },
  images: [
    {
      original: filecoin1,
      originalAlt:
        "Protofire develops open-source solutions and node infrastructure for Filecoin to help them build user base and grow TVL.",
      thumbnail: filecoin1,
      thumbnailAlt:
        "Protofire develops open-source solutions and node infrastructure for Filecoin to help them build user base and grow TVL.",
    },
    {
      original: filecoin2,
      originalAlt:
        "Protofire has developed the CID Checker for Filecoin, which provides a list of all CIDs along with their status, and builds open-source solutions and node infrastructure for the Filecoin ecosystem.",
      thumbnail: filecoin2,
      thumbnailAlt:
        "Protofire has developed the CID Checker for Filecoin, which provides a list of all CIDs along with their status, and builds open-source solutions and node infrastructure for the Filecoin ecosystem.",
    },
  ],
  ourTrackRecord: [],
};
