/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ContactUs from "../contactUs/contactUs";
import { indexContent, cards } from "./indexContent";
import { ReactComponent as OvalCareerMobile2 } from "../assets/career/ovalCareerMobile2.svg";
import { ReactComponent as OvalCareerBig } from "../assets/career/ovalCareerBig.svg";

function Projects() {
  const [showCards, setShowCards] = useState(cards);
  const [activeHashtag, setActiveHashtag] = useState<string>("");

  const cardsFilter = (filterOption: string) => {
    if (filterOption === "ALLTAGS") {
      setShowCards(cards);
    } else {
      const filtered = cards.filter((card) =>
        card.hashtags.includes(filterOption)
      );
      setShowCards(filtered);
    }
  };

  const handleClick = (event: any) => {
    const currentHashtag = event.currentTarget.textContent;

    setActiveHashtag(currentHashtag.trim());
    cardsFilter(currentHashtag.trim());
  };

  return (
    <div className="lg:px-40 m-auto md:px-10  py-20">
      <Helmet>
        <title>Our Partnerships & Blockchain Projects | Protofire</title>
        <meta
          name="description"
          content="We work with all blockchains and protocols and develop dApps, DEXes, smart contracts, and wallets. Need to integrate an oracle or a subgraph? Contact us."
        />
        <meta
          name="keywords"
          content="blockchain development, dex crypto, develop a blockchain, block chain dev, block chain transaction, blockchain development, blockchain dev, dapps, crypto wallet application"
        />
      </Helmet>
      <div
        className="flex lg:px-20 md:px-10 sm:px-5 text-blue200 text-xs "
        style={{
          fontFamily: "Spartan-Medium",
        }}
      >
        <Link to="/" className=" mr-1 text-blue200">
          Home
        </Link>
        /
        <Link to="/projects" className="ml-1 text-blue100 ">
          Projects
        </Link>
      </div>
      <div className="relative lg:block md:hidden sm:hidden z-1 ">
        <OvalCareerBig className=" absolute ovalBigProject1" />
      </div>
      <div
        className="sm:pt-10 lg:py-10 px-20 grid lg:grid-cols-2 lg:px-20 md:px-10 sm:px-5
      sm:grid-cols-1 md:grid-cols-1 gap-10 relative z-20 "
      >
        <div>
          <p className="text-4xl text-blue100">Our contributions</p>
          <p
            className="text-blue200 text-sm leading-7"
            style={{
              fontFamily: "Spartan-Medium",
            }}
          >
            {" "}
            We work with customers who believe in technical possibilities
            provided by the blockchain technology. We bring your vision to
            reality through a combination of technical and management expertise
          </p>
        </div>
        <div>{}</div>
      </div>

      <div className="relative lg:hidden md:hidden sm:block z-1 ">
        <OvalCareerMobile2 className=" absolute bgOvalProject2" />
      </div>
      <div
        className="lg:px-20 md:px-10 sm:px-5 pb-10 relative z-20"
        style={{
          fontFamily: "Spartan-Medium",
        }}
      >
        <p className=" py-2 text-blue200">
          Select a <span className="text-blue100">#hashtag</span> of what you
          are looking for:{" "}
        </p>
        <div className="flex flex-wrap text-xs text-blue ">
          {indexContent.hashtags.map((hashtag) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <span
              key={hashtag}
              className={` py-2 px-5 border border-blue my-2 mr-3 cursor-pointer
            rounded-2xl hover:bg-blue hover:text-blue100 ${
              hashtag === activeHashtag ? "bg-blue" : ""
            } 
            ${hashtag === activeHashtag ? "text-blue100" : ""} 
            `}
              onClick={handleClick}
            >
              {hashtag}{" "}
            </span>
          ))}
        </div>

        <div
          className="grid lg:grid-cols-2 md:grid-cols-2 
        sm:grid-cols-1 gap-10 py-10 relative z-10"
        >
          {showCards.map((card) => (
            <div key={card.page}>
              <Link to={card.page}>
                <img src={card.cards[0]} alt={card.cardAlt} />
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="">
        <ContactUs />
      </div>
    </div>
  );
}

export default Projects;
