/* eslint-disable react/no-unescaped-entities */
function DataFromAttendees() {
  return (
    <div
      className="text-sm text-blue200 leading-5 "
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <h2 className="text-blue100 text-4xl pb-10">
        7. Data we gather from the attendees of our events
      </h2>
      <div>
        <h2 className="text-blue100 text-3xl ">
          7.1. We collect the following categories of data
        </h2>
        <p>
          When you register or attend an event organized by Protofire, you share
          the following information with us:
        </p>
        <ul className="dotList">
          <li>Name/surname of contact persons;</li>
          <li>Positions in company;</li>
          <li>Phone numbers;</li>
          <li>E-mails;</li>
          <li>LinkedIn profile links;</li>
          <li>Skype IDs;</li>
          <li>Сompany name/address;</li>
          <li>
            Any other information you provided to us during service delivery;
          </li>
          <li>History of our communication, etc.;</li>
          <li>Business cards, etc.</li>
        </ul>
      </div>

      <div>
        <h2 className="text-blue100 text-3xl pt-10">
          7.2. How we process the data gathered
        </h2>
        <p>
          Data about users who filled out a contact form is stored in our
          internal CRM and Hubspot (HubSpot, Inc.{" "}
          <a
            href="https://legal.hubspot.com/privacy-policy"
            target="_blank"
            className="text-blue"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          ) - by our employees manually or automatically on receiving a contact
          form—for further processing a customer request and providing relevant
          services, as well as developing recommendations on improving the
          services we deliver.
        </p>
        <p>
          To share contact information, as well as information related to the
          events and services that may be of interest to a customer, Protofire
          may use the following:
        </p>
        <ul className="dotList">
          <li>
            Messengers: Skype (
            <a
              href="https://www.skype.com/en/legal/"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ), Viber (
            <a
              href="https://www.viber.com/terms/viber-privacy-policy/"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ), WhatsApp (
            <a
              href="https://www.whatsapp.com/legal/"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ), or Telegram (
            <a
              href="https://telegram.org/privacy"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            )
          </li>
          <li>
            E-mail services Gmail ({" "}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ) or Outlook ({" "}
            <a
              href="https://privacy.microsoft.com/en-gb/privacystatement"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            )
          </li>
          <li>
            Social media platforms: LinkedIn ({" "}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            )
          </li>
          <li>
            VOIP phone and conferencing services: GoToMeeting ({" "}
            <a
              href="https://www.logmeininc.com/legal/privacy"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ), Hangouts ({" "}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ) or Zoom ({" "}
            <a
              href="https://zoom.us/privacy"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            )
          </li>
        </ul>
        <p>
          To provide users with a possibility to register to an event organized
          by Protofire and acquire tickets, we use Eventbrite (
          <a
            href="https://www.eventbrite.com/privacypolicy/"
            target="_blank"
            className="text-blue"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          ).
        </p>
        <p>
          To store and share information about attendees of the events organized
          by Protofire, as well as to improve all the online activities related
          to such events, Protofire makes use of the services by Google (
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            className="text-blue"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          ) and Microsoft ({" "}
          <a
            href="https://privacy.microsoft.com/en-gb/privacystatement"
            target="_blank"
            className="text-blue"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          ).
        </p>
        <p>
          To enable marketing activities and share information about relevant
          services provided by our company, we use remarketing and advertising
          instruments available through Google Adwords (
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            className="text-blue"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          ).
        </p>
        <p>
          To build a strong community around the events organized by Protofire
          and to interact with those interested in our services, we use
          Meetup.com (
          <a
            href="https://www.meetup.com/privacy/"
            target="_blank"
            className="text-blue"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          ).
        </p>
        <p>
          To optimize internal processes and improve communication channels, we
          may use Atlassian (
          <a
            href="https://www.atlassian.com/legal/privacy-policy-may-25th"
            target="_blank"
            className="text-blue"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          ) and Trello (
          <a
            href="https://help.trello.com/article/1118-trello-and-gdpr-our-commitment-to-data-privacy"
            target="_blank"
            className="text-blue"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          ).
        </p>
      </div>
      <div>
        <h2 className="text-blue100 text-3xl pt-10">
          7.3. Purposes and legal basis for data processing
        </h2>
        <p>
          To establish efficient communication with customers about our
          services, we may use the following data:
        </p>
        <ol className="">
          <li>
            To fulfill and comply with our contractual obligations or answer to
            your request. To maintain contract development, we use your contact
            data to send transactional information via e-mail, Skype, or any
            other communication means or services. Your contact data is also
            used to confirm your request, respond to any of your questions,
            inquiries, or requests, provide support, as well as send you any
            updates on the services we deliver.
          </li>
          <li>
            To fulfill our legitimate interest, we use your contact information
            and information about your interaction with our services to send
            promotional materials that we find relevant to you via e-mail,
            Skype, or any other communication means or services. Our
            interactions are aimed at driving engagement and maximizing value
            you get through our services.These interactions may include
            information about our new events, commercial offers, newsletters,
            content, and events we believe may be relevant to you. To fulfill
            our legitimate interest, we use your contact information which is
            stored at our CRM system in order to maintain and support our CRM
            system and carry on any related activities.
          </li>
        </ol>
      </div>
      <div>
        <h2 className="text-blue100 text-3xl pt-10">
          7.4. Data retention period
        </h2>
        <p>
          We set retention period for your data about our customer to 6 years
          from the last event you have been registered. We keep it to be able to
          reach you when we have something relevant to your initial request (for
          example, updated information on calls, e-mail, etc.).
        </p>
      </div>
      <div>
        <h2 className="text-blue100 text-3xl pt-10">7.5. Data recipients</h2>
        <p>
          We do not share personal data with third parties, apart from the
          cases, which imply Protofire is to provide a list of registrars to the
          organizer of the event with a view to ensure an acceptable level of
          organization and security.
        </p>
      </div>
    </div>
  );
}

export default DataFromAttendees;
