/* eslint-disable import/prefer-default-export */
import smartContract from "../../assets/services/safe/smart_contract.png";
import customSafe from "../../assets/services/safe/custom_safe.png";

import safeDeployment from "../../assets/services/safe/safeDeployment.png";
import safeDeploymentCircle from "../../assets/services/safe/safeDeploymentCircle.png";
import safeProvide1 from "../../assets/services/safe/safeProvide1.png";
import safeProvide2 from "../../assets/services/safe/safeProvide2.png";
import education from "../../assets/services/safe/education.png";
import prototyping from "../../assets/services/prototyping.png";
import wallets from "../../assets/services/safe/wallets.png";
import webmobile from "../../assets/services/safe/webmobile.png";
import harmony from "../../assets/services/safe/harmony.png";
import astarSafe from "../../assets/services/safe/astarSafe.png";
import cronosSafe from "../../assets/services/safe/cronosSafe.png";
import iotexSafe from "../../assets/services/safe/iotexSafe.png";
import oasisSafe from "../../assets/services/safe/oasisSafe.png";
import moonbeamSafe from "../../assets/services/safe/moonbeamSafe.png";
import velasSafe from "../../assets/services/safe/velasSafe.png";
import thunderCoreSafe from "../../assets/services/safe/thunderCoreSafe.png";
import telosSafe from "../../assets/services/safe/telosSafe.png";
import evmosSafe from "../../assets/services/safe/evmosSafe.png";
import tenetSafe from "../../assets/services/safe/tenetSafe.png";
import neonEVMSafe from "../../assets/services/safe/neonEVMSafe.png";
import lineaSafe from "../../assets/services/safe/lineaSafe.png";
import zkScrollSafe from "../../assets/services/safe/zkScrollSafe.png";
import mantleSafe from "../../assets/services/safe/mantleSafe.png";

export const images = {
  safeDeployment,
  safeDeploymentCircle,
};
export const networksColOne = [
  "Moonbeam",
  "Evmos",
  "Velas",
  "Cronos",
  "Astar",
  "IoTeX",
  "ThunderCore",
  "Tenet",
  "Telos",
  "Oasis",
  "Sei",
  "Kava",
  "Morph",
  "Saakuru",
  "Etherlink",
  "Taiko",
];
export const networksColTwo = [
  "Harmony One",
  "Neon EVM",
  "Linea",
  "Rootstock",
  "Mantle",
  "Cascadia",
  "zkScroll",
  "Holesky",
  "Zetachain",
  "zkSync",
  "WEMIX",
  "Kroma",
  "BOB",
  "Zilliqa",
  "Immutable",
  "Superchain",
];

export const whatWeProvide1 = {
  icon: smartContract,
  name: "Turn-key deployment/Maintenance of custom Safe forks",
  description: `
      We continuously help Safe to improve and upscale its trusted platform for digital asset management, accelerating its transformation into a dynamic ecosystem. Having deployed Safe on more than 45 mainnets and testsnets, we actively engage with the Safe community for prompt feedback and enhancement suggestions. Both deployment and maintenance are performed under the supervision of the Safe team to ensure the integrity of the network and its current and upcoming features.
    `,
  image: safeProvide1,
};
export const whatWeProvide2 = {
  icon: customSafe,
  name: "Custom integrations of Safe Apps",
  description: `
      Safe is an application with integrations we call Safe Apps. Some are native, but we also implement custom Safe Apps. An example we have is Starlay Finance; it's an app listed in Astar Safe, allowing direct interaction with the protocol from the Safe screen.
    `,
  image: safeProvide2,
};

export const aboutSafe = [
  {
    name: "Compatible with any EVM network",
    icon: education,
  },
  {
    name: "Mutisignature for individuals and teams of any size",
    icon: prototyping,
  },
  {
    name: "ETH, ERC 20 (basic tokens), and ERC 721/1155 (NFTs) support",
    icon: smartContract,
  },
  {
    name: "Non-custodial wallets",
    icon: wallets,
  },
  {
    name: "Web and Mobile versions",
    icon: webmobile,
  },
];

export const technologyStack = {
  title: "Technology stack ",
  columns: [
    {
      title: "PROGRAMMING LANGUAGES",
      list: ["TypeScript", "Python", "Solidity"],
    },
    {
      title: "FRAMEWORKS AND LIBRARIES",
      list: ["Node.js", "React", "Styled Components", "Ethers", "Django"],
    },
    {
      title: "INFRASTRUCTURE",
      list: [
        "AWS",
        "CloudFormation",
        "Terraform",
        "Ansible",
        "Redis / ElastiCache",
        "RDS / PostgreSQL",
      ],
    },
    {
      title: "PLATFORMS",
      list: [
        "L1 blockchains",
        "L2 blockchains",
        "Zk-rollups",
        "Optimistic rollups",
        "App-chains",
      ],
    },
    {
      title: "TOOLS",
      list: ["Storybook", "Hardhat"],
    },
  ],
};

export const trackRecord = [
  {
    title: "Harmony Multisig Wallet",
    description:
      "Protofire has deployed a fork of Gnosis Safe multisig on Harmony, enabling stronger security standards through multisig transactions.",
    img: harmony,
    target: "_blank",
    safeUrl: "https://multisig.harmony.one",
    mediaUrl:
      "https://medium.com/harmony-one/gnosis-safe-multisig-now-available-on-harmony-56f83b7222c5",
  },
  {
    title: "Astar Safe",
    description:
      "Astar Safe has been deployed on Astar to improve the network's transaction security. The multisig wallet runs on Astar EVM.",
    img: astarSafe,
    target: "_blank",
    safeUrl: "https://safe.astar.network",
    mediaUrl:
      "https://medium.com/astar-network/protofire-is-building-a-safe-astar-324c73333a06",
  },
  {
    title: "Cronos Safe",
    description:
      "Protofire has successfully deployed Cronos Safe on Cronos. It is a perfect tool for dApp developers to enhance the security of their dApps.",
    img: cronosSafe,
    target: "_blank",
    safeUrl: "https://cronos-safe.org",
    mediaUrl:
      "https://docs.cronos.org/for-dapp-developers/dev-tools-and-integrations/cronos-safe",
  },
  {
    title: "IoTeX Safe",
    description:
      "Protofire has deployed a Gnosis Safe fork on the IoTeX network enabling robust tri-party tools for the IoTeX developers.",
    img: iotexSafe,
    target: "_blank",
    safeUrl: "https://safe.iotex.io",
    mediaUrl: "https://developers.iotex.io/posts/gnosis-safe-integration",
  },
  {
    title: "Oasis Safe",
    description:
      "Protofire has successfully deployed Gnosis Safe on Oasis Sapphire, a trusted custody protocol and collective asset management platform.",
    img: oasisSafe,
    target: "_blank",
    safeUrl: "https://safe.oasis.io",
    mediaUrl:
      "https://medium.com/protofire-blog/oasis-safe-on-oasis-sapphire-286f3bcf2d73",
  },
  {
    title: "Moonbeam Safe",
    description:
      "Safe has been successfully deployed by Protofire on Moonbeam, a smart contract platform for building cross-chain connected applications.",
    img: moonbeamSafe,
    target: "_blank",
    safeUrl: "https://multisig.moonbeam.network",
    mediaUrl:
      "https://medium.com/protofire-blog/protofire-secures-assets-and-transactions-in-moonbeam-with-a-fork-of-gnosis-safe-26b185a30cd1 ",
  },
  {
    title: "Velas Safe",
    description:
      "We've deployed and maintained a fork of Gnosis Safe on Velas, a trusted decentralized platform for digital assets management.",
    img: velasSafe,
    target: "_blank",
    safeUrl: "https://velasafe.com",
    mediaUrl: "https://github.com/protofire/velas-transaction-service",
  },
  {
    title: "ThunderCore Safe",
    description:
      "A Gnosis Safe-forked multisig service has been deployed on the ThunderCore ecosystem to provide a secure and simple way to store and access crypto funds through the power of multisig security.",
    img: thunderCoreSafe,
    target: "_blank",
    safeUrl: "https://safe.thundercore.com",
    mediaUrl:
      "https://coinmarketcap.com/community/articles/6451c49c0d1ac45ba873d3ab/",
  },
  {
    title: "Telos Safe",
    description:
      "We have deployed and maintained Gnosis Safe on Telos to deliver a secure cross-functional multisig wallet to the network participants.",
    img: telosSafe,
    target: "_blank",
    safeUrl: "https://safe.telos.net",
    mediaUrl: "https://www.telos.net/news/introducing-telos-safe",
  },
  {
    title: "Evmos Safe",
    description:
      "Protofire has deployed Safe on Evmos enabling its users to securely store and transfer EVM-based tokens and ERC-20 Cosmos coins.",
    img: evmosSafe,
    target: "_blank",
    safeUrl: "https://safe.evmos.org/",
    mediaUrl:
      "https://medium.com/evmos/protofire-deploys-a-gnosis-safe-fork-to-evmos-cc377bfdf572",
  },
  {
    title: "Tenet Safe",
    description:
      "With Safe, Tenet makes the staking process as seamless as possible both for staking TENET tokens or LSDs derived from other native gas tokens.",
    img: tenetSafe,
    target: "_blank",
    safeUrl: "https://safe.tenet.org/",
    mediaUrl: "https://safe.tenet.org/",
  },
  {
    title: "Neon EVM Safe",
    description:
      "Protofire has integrated Safe into Neon EVM’s secure environment allowing users smooth access to the Safe’s multi-signature asset management system.",
    img: neonEVMSafe,
    target: "_blank",
    safeUrl: "https://safe.neonevm.org",
    mediaUrl:
      "https://p2p.org/economy/everything-you-need-to-know-about-neon-evm/ ",
  },
  {
    title: "Linea Safe",
    description:
      "Deployed by Protofire, a key partner within the Linea ecosystem, Linea Safe is a secure multi-signature wallet, supported on Linea Goerli and Linea mainnet.",
    img: lineaSafe,
    target: "_blank",
    safeUrl: "https://safe.linea.build",
    mediaUrl:
      "https://linea.mirror.xyz/iiLnw4AS643gNngn79zugoh-qCAqR5oen9iEzxEKqzA",
  },
  {
    title: "Mantle Safe",
    description:
      "Mantle Network and Protofire have crafted the Mantle Safe multisig expanding the digital asset opportunities of Mantle’s users.",
    img: mantleSafe,
    target: "_blank",
    safeUrl: "https://multisig.mantle.xyz",
    mediaUrl:
      "https://twitter.com/0xMantle/status/1717525533758779807?t=ss6zd93HjXgNIfZinhIWHA&s=19",
  },
  {
    title: "zkScroll Safe",
    description:
      "Scroll Safe has been launched on Scroll Mainnet letting the network users access the Safe’s collective asset management platform.",
    img: zkScrollSafe,
    target: "_blank",
    safeUrl: "https://safe.scroll.xyz",
    mediaUrl: "https://twitter.com/BuildWithScroll/status/1717224595651203546",
  },
];
