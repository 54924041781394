/* eslint-disable react/no-unescaped-entities */
function DataSecurity() {
  return (
    <div
      className="text-sm text-blue200 leading-5 py-10"
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <h2 className="text-blue100 text-4xl">
        10. Data security and protection
      </h2>
      <p>
        We use data hosting service providers in the United States and Ireland
        to store the information we collect, and we do use extra technical
        measures to secure your data.
      </p>
      <p>
        These measures include without limitation: data encryption,
        password-protected access to personal information, limited access to
        sensitive data, encrypted transfer of sensitive data (HTTPS, IPSec, TLS,
        PPTP, and SSH) firewalls and VPN, intrusion detection, and antivirus on
        all the production servers.
      </p>
      <p>
        The data collected by third party providers is protected by them and is
        subject to their terms and privacy policies.
      </p>
      <p>
        The data collected on our websites by Protofire, as well as the data,
        which you entrust us under NDAs and contracts, is protected by us. We
        follow the technical requirements of GDPR and ensure security standards
        are met without exception.
      </p>
      <p>
        Though we implement safeguards designed to protect your information, no
        security system is impenetrable and due to the inherent nature of the
        Internet, we cannot guarantee that data is absolutely safe from
        intrusion by others during transmission through the Internet, or while
        stored on our systems, or otherwise in our care.
      </p>
    </div>
  );
}

export default DataSecurity;
