import renat from "../../assets/team/renat.png";
import andrei from "../../assets/team/andrei.png";
import cristian from "../../assets/team/cristian.png";
import diego from "../../assets/team/diego.png";
import ivan from "../../assets/team/ivan.png";
import luis from "../../assets/team/luis.png";
import arsenii from "../../assets/team/arsenii.png";
import alejandro from "../../assets/team/alejandro.png";

// eslint-disable-next-line import/prefer-default-export
export const content = [
  {
    name: "Renat Khasanshyn",
    role: "Co-founder at Protofire",
    description:
      "Vision, Mission, Strategy & Expansion, Executive & Operational orchestration",
    linkedIn: "https://www.linkedin.com/in/renatco/",
    image: renat,
    imageAlt: "Renat Khasanshyn, Co-Founder of Protofire",
    key: 1,
    borderRadius: "58px 70px 20px 70px",
  },
  {
    name: "Andrei Yurkevich",
    role: "Co-founder at Protofire",
    description: "Strategy, leadership, executive & operational orchestration",
    linkedIn: "https://www.linkedin.com/in/andreiyurkevich/",
    image: andrei,
    imageAlt: "Andrei Yurkevich, Co-Founder of Protofire",
    key: 2,
    borderRadius: "40px 70px 60px 70px",
  },
  {
    name: "Cristian Malfesi",
    role: "Director of Engineering",
    description:
      "Nodes orchestration, blockchain architectures, data, digital asset solutions",
    linkedIn: "https://www.linkedin.com/in/cristian-malfesi/",
    image: cristian,
    imageAlt: "Cristian Malfesi, Director of Engineering at Protofire",
    key: 3,
    borderRadius: "63px 30px 66px 36px",
  },
  {
    name: "Alejandro Losa",
    role: "Blockchain Solution Architect",
    description:
      "Blockchain solutions architecture, Web3 advisory & account management",
    linkedIn: "https://www.linkedin.com/in/alejandrolosa/",
    image: alejandro,
    imageAlt: "Alejandro Losa",
    key: 4,
    borderRadius: "90px 55px 10px 55px",
  },
  {
    name: "Ivan Bandaryk",
    role: "Field CTO",
    description: "Gnosis deployments, wallets & digital asset solutions",
    linkedIn: "https://www.linkedin.com/in/ivan-bandaryk/",
    image: ivan,
    imageAlt: "Ivan Bandaryk, Field CTO at Protofire",
    key: 5,
    borderRadius: "70px 64px 92px 33px",
  },
  {
    name: "Arsenii Petrovich",
    role: "Field CTO",
    description: "DevOps, data, integrations, infrastructure & cloud solutions",
    linkedIn: "https://www.linkedin.com/in/arsenii-petrovich/",
    image: arsenii,
    imageAlt: "Arsenii Petrovich, Field CTO at Protofire",
    key: 6,
    borderRadius: "90px 50px 60px 40px",
  },
  {
    name: "Luis Medeiros",
    role: "Field CTO",
    description: "TVL growth, data & asset management solutions",
    linkedIn: "https://www.linkedin.com/in/lvgmedeiros/",
    image: luis,
    imageAlt: "Luis Medeiros, Field CTO at Protofire",
    key: 7,
    borderRadius: "50px 70px 90px 40px",
  },
  {
    name: "Diego Torres",
    role: "Field CTO",
    description:
      "Subgraphs, middleware, lending, infrastructure management solutions",
    linkedIn: "https://www.linkedin.com/in/diego-torres-borda-94b70912/",
    image: diego,
    imageAlt: "Diego Torres, Project Manager at Protofire",
    key: 8,
    borderRadius: "92px 40px 70px 92px",
  },
];
