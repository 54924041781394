import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ImageGallery from "react-image-gallery";
import { project } from "./content";
import { ReactComponent as OvalCareerMobile2 } from "../../assets/career/ovalCareerMobile2.svg";
import { ReactComponent as OvalCareerBig } from "../../assets/career/ovalCareerBig.svg";
import { ReactComponent as BgMiddle } from "../../assets/career/bgMiddle.svg";

import ContactUs from "../../contactUs/contactUs";
import "../project.css";
import "../../services/tab.css";
import "react-image-gallery/styles/css/image-gallery.css";

function DappPayroll() {
  const [active, setActive] = useState("page1");
  const [currentTrack, setCurrentTrack] = useState(
    project.ourTrackRecord[0][2]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(function (entries) {
      const ele: IntersectionObserverEntry[] = entries.filter(
        (entry) => entry.isIntersecting
      );
      if (ele.length > 0) {
        const ele1 = ele[0].target;

        setActive(ele1.id);
      }
    });
    document
      .querySelectorAll(".myCount [id^=page]")
      .forEach((ele) => observer.observe(ele));
  }, []);

  return (
    <div className="lg:px-0  md:px-10 py-20">
      <Helmet>
        <title>A Payment Application for Aragon DAO | Protofire</title>
        <meta
          name="description"
          content="Protofire has developed the Aragon application to help users manage staffing and assets. Looking for a payment dApp for your project? Contact us."
        />
        <meta
          name="keywords"
          content="crypto dao, payroll services, blockchain app, payroll services for small business, payroll options, ledger app"
        />
      </Helmet>
      <section
        className="lg:flex md:flex lg:px-60 md:px-10 text-blue200 relative z-10
        text-xs   sm:px-5"
        style={{
          fontFamily: "Spartan-Medium",
        }}
      >
        <Link to="/" className=" mr-1 text-blue200">
          Home
        </Link>
        /
        <Link to="/projects" className="ml-1 mr-1 text-blue200 ">
          Projects
        </Link>
        /
        <Link to="/services" className="ml-1 text-blue100 ">
          dApp Payroll
        </Link>
      </section>
      <div className="relative lg:block md:hidden sm:hidden z-1 ">
        <OvalCareerBig className=" absolute ovalBigProjectPages1" />
      </div>
      <div className="relative lg:hidden md:hidden sm:block z-1 ">
        <OvalCareerMobile2 className=" absolute bgOvalProjectPages2" />
      </div>
      <section
        className="md:pl-10 lg:px-0 
       lg:pb-20 sm:px-5 py-10 lg:pl-60 m-auto relative z-10"
      >
        <div
          className="grid lg:grid-cols-2 
         md:grid-cols-1 sm:grid-cols-1 gap-10"
        >
          <div className="lg:w-6/7 lg:pr-14 sm:w-full md:w-full">
            <h1 className="text-blue100 text-4xl md:text-5xl xl:text-6xl">
              {project.name}
            </h1>
            <div className="flex flex-wrap text-xs text-blue ">
              {project.hashtags.map((hashtag) => (
                <span
                  key={hashtag}
                  className=" py-1 px-3 border border-blue my-2 mr-3 rounded-3xl"
                >
                  {hashtag}{" "}
                </span>
              ))}
            </div>
            <p
              className="py-5 text-blue200 leading-7"
              style={{
                fontFamily: "Spartan-Medium",
              }}
            >
              {project.description}
            </p>
          </div>
          <div className="w-full  lg:block sm:hidden md:block">
            <img
              src={project.thumbnail}
              alt={project.thumbnailAlt}
              className="w-full"
            />{" "}
          </div>
        </div>
      </section>

      <div
        className="lg:px-60  md:px-10 sm:px-5 pb-20 grid lg:grid-cols-3  z-10
      md:grid-cols-3 sm:grid-cols-1 relative  overflow-auto bg-darkBackground"
        style={{
          height: "600px",
        }}
      >
        <div
          className="col-span-2 myCount"
          style={{
            fontFamily: "Spartan-Medium",
          }}
        >
          <section className="py-5" id="page1">
            <div className=" lg:col-span-2 md:col-span-2">
              <p
                className="text-blue100 text-3xl "
                style={{
                  fontFamily: "Spartan",
                }}
              >
                {" "}
                {project.aboutProject.title}
              </p>
              <div className="dotList text-sm leading-5">
                {project.aboutProject.lists.map((list) => (
                  <li key={list} className="pb-1 text-blue200">
                    {list}
                  </li>
                ))}
              </div>
              <div>
                <div
                  className="grid md:gap-4 sm:gap-0 lg:grid-cols-3 md:grid-cols-3 lg:gap-10 z-10 
            sm:grid-cols-1"
                >
                  <div className=" ">
                    <hr className="text-3xl w-7 border-4 my-5 text-yellow" />
                    <p
                      className="text-2xl text-blue100"
                      style={{
                        fontFamily: "Spartan",
                      }}
                    >
                      {project.aboutProject.sellVolume[0]}
                    </p>
                    <p className="text-xs tracking-wider  text-blue200">
                      {project.aboutProject.sellVolume[1]}
                    </p>
                  </div>
                  <div className=" relative z-20">
                    <hr className="text-3xl w-7 border-4 my-5 text-orange" />
                    <p
                      className="text-2xl text-blue100"
                      style={{
                        fontFamily: "Spartan",
                      }}
                    >
                      {project.aboutProject.batches[0]}
                    </p>
                    <p className="text-xs tracking-wider  text-blue200">
                      {project.aboutProject.batches[1]}
                    </p>
                  </div>
                  <div className="">
                    <hr className="text-3xl w-7 border-4 my-5 text-primary" />
                    <p
                      className="text-2xl text-blue100"
                      style={{
                        fontFamily: "Spartan",
                      }}
                    >
                      {project.aboutProject.totalTrades[0]}
                    </p>
                    <p className="text-xs tracking-wider  text-blue200">
                      {project.aboutProject.totalTrades[1]}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-5" id="page2">
            <div className=" lg:col-span-2 md:col-span-2">
              <p
                className="text-blue100 text-3xl py-3"
                style={{
                  fontFamily: "Spartan",
                }}
              >
                {project.theClient.title}{" "}
              </p>
              <p className="text-blue200 py-2 text-sm leading-6">
                {project.theClient.firstParagraph}{" "}
              </p>
            </div>
          </section>
          <section
            className="py-5"
            id="page3"
            style={{
              fontFamily: "Spartan-Medium",
            }}
          >
            <div className=" lg:col-span-2 md:col-span-2">
              <p
                className="text-blue100 text-3xl py-3"
                style={{
                  fontFamily: "Spartan",
                }}
              >
                {project.theProblem.title}{" "}
              </p>
              <p className="text-blue200 py-2 text-sm leading-6">
                {project.theProblem.firstParagraph}{" "}
              </p>
            </div>
          </section>
          <section className="py-5" id="page4">
            <div className=" lg:col-span-2 md:col-span-2">
              <p
                className="text-blue100 text-3xl py-3"
                style={{
                  fontFamily: "Spartan",
                }}
              >
                {project.theSolution.title}{" "}
              </p>
              <p className="text-blue200 py-2 text-sm leading-6">
                {project.theSolution.firstParagraph}{" "}
              </p>
              <div className="dotList text-sm leading-5">
                {project.theSolution.lists.map((list) => (
                  <li key={list[0]} className="pb-1 text-blue200">
                    {list[0]}
                  </li>
                ))}
              </div>
            </div>
          </section>
          <section className="pb-5" id="page5">
            <div className=" text-sm">
              <p
                className="text-blue100 text-3xl py-3"
                style={{
                  fontFamily: "Spartan",
                }}
              >
                {project.code.text}{" "}
              </p>
              {project.code.codes.map((list) => (
                <div className=" " key={list.text}>
                  <p className="text-blue200  text-sm ">{list.text} </p>
                  <a
                    href={list.link}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue"
                  >
                    Github
                  </a>
                </div>
              ))}
            </div>
          </section>
        </div>
        <aside
          className="text-xs text-blue200 lg:ml-20 md:ml-20  py-10 
          sticky top-5 right-5  h-60 sm:hidden lg:block md:block"
        >
          <ul className="border-l border-blue300 pl-5">
            <li className="pb-2">
              <a
                className={` ${active === "page1" ? "text-blue" : ""}`}
                href="#page1"
                onClick={() => setActive("page1")}
              >
                {" "}
                ABOUT THE PROJECT
              </a>
            </li>
            <li className="pb-2">
              <a
                className={` ${active === "page2" ? "text-blue" : ""}`}
                href="#page2"
                onClick={() => setActive("page2")}
              >
                {" "}
                THE CLIENT/PARTNER
              </a>
            </li>
            <li className="pb-2">
              <a
                className={` ${active === "page3" ? "text-blue" : ""}`}
                href="#page3"
                onClick={() => setActive("page3")}
              >
                {" "}
                THE PROBLEM
              </a>
            </li>
            <li className="pb-2">
              <a
                className={` ${active === "page4" ? "text-blue" : ""}`}
                href="#page4"
                onClick={() => setActive("page4")}
              >
                {" "}
                THE SOLUTION
              </a>
            </li>{" "}
            <li className="pb-2">
              <a
                className={` ${active === "page5" ? "text-blue" : ""}`}
                href="#page5"
                onClick={() => setActive("page5")}
              >
                {" "}
                CODE
              </a>
            </li>{" "}
          </ul>
        </aside>
      </div>
      <section
        className="lg:px-40 w-3/4 m-auto lg:py-10 md:py-20 sm:py-10 
      sm:hidden lg:block md:block "
      >
        <p className="text-blue100 text-3xl py-3">Images</p>
        <div>
          <ImageGallery items={project.images} thumbnailPosition="bottom" />
        </div>
      </section>
      <section className="bg-darkBackground">
        <div className="pb-20 relative lg:px-60  ">
          <p className="text-3xl text-blue100 py-5 "> Our track record</p>
          <div
            className="grid lg:grid-cols-3 lg:gap-10 md:grid-cols-3
                md:grid md:gap-5 sm:hidden "
          >
            {project.ourTrackRecord.map((record) => (
              <div className="content " key={record[2]}>
                <a
                  target={`${record[3] === "newWindow" ? "_blank" : ""}`}
                  href={record[2]}
                  rel="noreferrer"
                >
                  <img src={record[0]} alt={record[4]} className="front " />
                </a>
                <a
                  target={`${record[3] === "newWindow" ? "_blank" : ""}`}
                  href={record[2]}
                  rel="noreferrer"
                >
                  <img src={record[1]} alt={record[4]} className="back" />
                </a>
              </div>
            ))}
          </div>
          <div className="lg:hidden md:hidden relative sm:slider slider">
            <div className=" slides">
              {project.ourTrackRecord.map((record, i) => (
                <div key={record[i]}>
                  {currentTrack === record[2] && (
                    <div
                      className="content slide"
                      id={record[2]}
                      key={record[2]}
                    >
                      <a
                        target={`${record[3] === "newWindow" ? "_blank" : ""}`}
                        href={record[2]}
                        rel="noreferrer"
                      >
                        <img
                          src={record[0]}
                          alt={record[4]}
                          className="front "
                        />
                      </a>
                      <a
                        target={`${record[3] === "newWindow" ? "_blank" : ""}`}
                        href={record[2]}
                        rel="noreferrer"
                      >
                        <img src={record[1]} alt={record[4]} className="back" />
                      </a>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {project.ourTrackRecord.length > 1 && (
              <div className="slider_div mt-3">
                {project.ourTrackRecord.map((record) => (
                  <button
                    className={`image_slider ${
                      currentTrack === record[2] ? "bg-blue" : "bg-blue200"
                    }`}
                    type="button"
                    onClick={() => setCurrentTrack(record[2])}
                  >
                    {}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
      <div className="relative lg:block md:hidden sm:hidden z-10 ">
        <BgMiddle className=" absolute bgMiddleProject" />
      </div>
      <section
        className="lg:bg-square-bg md:bg-square-bg sm:bg-mobile-bg sm:px-5
       bg-contain bg-center z-10 globeProject"
      >
        <div
          className="bg-circle-bg relative 
          h-full m-auto 
        sm:w-full lg:w-1/3 md:w-2/3  bg-cover bg-center bg-no-repeat z-0"
        >
          <div
            className="text-center m-0, w-full top-1/2 left-1/2 absolute "
            style={{
              transform: "translate(-50%, -50%)",
            }}
          >
            <h1 className="text-blue100 lg:text-4xl md:text-4xl  leading-10 sm:text-2xl ">
              <span
                style={{
                  fontFamily: "Spartan-Light",
                }}
              >
                In case you are interested in{" "}
              </span>
              DAO or dApp development
            </h1>
            <p className="text-blue100 pt-5 Spartan-Medium text-sm w-9/12 m-auto">
              {" "}
              <a
                href="#contact"
                className="pr-2 underline  "
                style={{
                  background:
                    "linear-gradient(to right, #F54703 50%, #FAC902 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Get in touch with us
              </a>
              so that we can discuss your ideas.
            </p>
          </div>
          {/* <CircleBg /> */}
        </div>
      </section>
      <section className="lg:px-40 m-auto" id="contact">
        <ContactUs />
      </section>
    </div>
  );
}

export default DappPayroll;
