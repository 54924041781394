import { useEffect } from "react";
import { toast } from "react-toastify";
import ReactGA from "react-ga";

// eslint-disable-next-line react/prop-types
function MailchimpEmailForm({ status, message, onValidated }) {
  let email;

  useEffect(() => {
    if (status === "error") {
      toast.error(message);
    }
    if (status === "success") {
      toast.success(message);
      ReactGA.event({
        category: "Button",
        action: `Contact form submitted`,
      });
    }
  }, [status]);
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <div className="flex flex-col lg:flex-row flex-nowrap flex-grow gap-8 lg:items-end">
      <input
        className="lg:w-3/6 h-12 px-[10px] bg-transparent autofill:bg-transparent text-white border-[#ffffff] placeholder-[#6b7f87] border-b-[3px]"
        ref={(node) => {
          email = node;
        }}
        type="email"
        placeholder="Email"
      />
      <button
        type="button"
        className="text-white bg-gradient-to-r from-[#f54703] to-[#f88701] text-[16px] leading-[24px] py-8 px-20 inline-block cursor-pointer"
        onClick={submit}
      >
        JOIN
      </button>
    </div>
  );
}

export default MailchimpEmailForm;
