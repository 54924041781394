/* eslint-disable react/no-unescaped-entities */

function OtherWidgets() {
  return (
    <div
      className="text-sm text-blue200 leading-5 pt-10"
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <h2 className="text-blue100 text-2xl">
        2.2.3. Other widgets and scripts provided by partner third parties
      </h2>
      <p>
        In addition to advertising partners and analytics partners mentioned
        above, we are using widgets, which act as an intermediary between
        third-party websites (Facebook, Twitter, LinkedIn, etc.) and our website
        and allow us to provide additional information about us or our services
        or authorize you as our website user to share content on third-party
        websites.{" "}
      </p>
      <p>
        <span className="text-blue100 mr-3 ">Disqus (Disqus, Inc.):</span>
        is a blog comment hosting service for websites and online communities
        that use a networked platform. Disqus integration into a corporate blog
        enables website users to submit a comment to any article posted on the
        blog after he/she authorizes it into a personal Disqus account.{" "}
        <a
          href="https://help.disqus.com/terms-and-policies/disqus-privacy-policy"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Disqus Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">WordPress (WordPress.org):</span>
        is a free and open-source content management system (CMS). WordPress
        Stats is the CMS’s analytics module, which gathers the following
        statistics: views and unique visitors, likes, followers, references,
        location, terms, words, and phrases people use on search engines (e.g.,
        Google, Yahoo, or Bing) to find posts and pages on our website. The
        service also allows for gathering such data as clicks on external links,
        cookies, etc. The service is subject to{" "}
        <a
          href="https://wordpress.org/about/privacy/"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          WordPress privacy policy.
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">
          Twitter Button and Twitter Syndication (Twitter, Inc.):
        </span>
        allows you to quickly share the webpage you are viewing with all of your
        followers. Twitter Syndication enables users to implement a widget,
        which gathers information about the company’s Twitter profile and
        tweets. The services are provided by Twitter Inc. and are subject to the
        company’s privacy policy{" "}
        <a
          href="https://twitter.com/en/privacy"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Twitter’s Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">
          Graph API (Meta Platforms, Inc.):
        </span>
        is used to implement widgets to get data into and out of the Facebook
        platform. In our case, this widget is used to enable content sharing and
        display the number of sharings by Facebook users. The service is
        provided by Meta Platforms, Inc. and is subject to the company’s privacy
        policy{" "}
        <a
          href="https://www.facebook.com/about/privacy/"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Meta Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">
          LinkedIn Widgets (LinkedIn Corporation):
        </span>
        are a quick way to infuse LinkedIn functionality into our website. We
        use this widget to enable content sharing and display the number of
        sharings by LinkedIn users. The service is provided by LinkedIn
        Corporation and is subject to the company’s privacy policy{" "}
        <a
          href="https://www.linkedin.com/legal/privacy-policy"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          LinkedIn’s Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">OneSignal (OneSignal, Inc):</span>
        is a push notification service{" "}
        <a
          href="https://onesignal.com/privacy_policy"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          OneSignal’s Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">ShareThis (ShareThis, Inc.):</span>
        is a share button service{" "}
        <a
          href="https://www.sharethis.com/privacy/"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          ShareThis Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">
          AddThis (acquired by Oracle):
        </span>
        is a share button service{" "}
        <a
          href="http://www.addthis.com/privacy"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          AddThis Privacy Policy,
        </a>
        <a
          href="https://www.oracle.com/legal/privacy/addthis-privacy-policy.html"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Oracle Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">Wistia (Wistia, Inc.):</span>
        is a video hosting platform used at Protofire to store videos shot at
        events organized by our company to be further embedded on our corporate
        blog{" "}
        <a
          href="https://wistia.com/privacy"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Wistia’s Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">Tawk (Tawk.to inc.):</span>
        is a live chat app{" "}
        <a
          href="https://www.tawk.to/privacy-policy/"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Tawk’s Privacy Policy
        </a>
      </p>
    </div>
  );
}

export default OtherWidgets;
