// eslint-disable-next-line import/prefer-default-export
export const tableContent = [
  {
    youHave: "The need to hire, train and manage devs",
    canHave: "You build a team of experts ready to work",
    key: 0,
  },
  {
    youHave: "The need to develop a software infrastructure",
    canHave: "Complete backend development",
    key: 1,
  },
  {
    youHave: "The need to develop a user interface",
    canHave: "Complete frontend development",
    key: 2,
  },
  {
    youHave: "Proprietary indexing server",
    canHave: "Subgraph development",
    key: 3,
  },
  {
    youHave: "Limited access to data feeds",
    canHave: "Oracle integration",
    key: 4,
  },
  {
    youHave: "Regular wallets",
    canHave: "Multisignature wallets",
    key: 5,
  },
  {
    youHave: "Web-only wallets",
    canHave: "Mobile wallets",
    key: 6,
  },
  {
    youHave: "Smart contract development challenges for the DeFi ecosystem",
    canHave:
      "DeFi development in several areas, such as DEX, Bridge, Lending, Pools, Swaps",
    key: 7,
  },
  {
    youHave: "A new market with few experienced professionals",
    canHave: "NFT marketplace development",
    key: 8,
  },
  {
    youHave: "Uncertainty about the state of your systems",
    canHave: "Thorough infrastructure audit",
    key: 9,
  },
];

// eslint-disable-next-line func-names
export const youHave = tableContent.map(function (item) {
  return item.youHave;
});

// eslint-disable-next-line func-names
export const canHave = tableContent.map(function (item) {
  return item.canHave;
});
