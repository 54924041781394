import swarm from "../../assets/projectsImages/swarmNew.png";
import swarm1 from "../../assets/projectsImages/swarm1.png";
import swarm2 from "../../assets/projectsImages/swarm2.png";
import maker1 from "../../assets/services/69.png";
import maker11 from "../../assets/services/70.png";
import maker2 from "../../assets/services/99.png";
import maker22 from "../../assets/services/101.png";
import maker3 from "../../assets/services/100.png";
import maker33 from "../../assets/services/102.png";

// eslint-disable-next-line import/prefer-default-export
export const project = {
  name: "Swarm Markets DEX",
  hashtags: ["dApp", "Dashboard", "DeFi", "DEX", "KYO", "OTC"],
  description: `Protofire team has worked on the architecture, smart contracts, 
  and front end for Swarm, which provides compliant multi-asset DeFi infrastructure. 
  KYC services are inbuilt within the platform. The team has implemented several 
  DEX functionalities - such as swapping and pooling, as well as a Multi-Tier 
  permissioning system and an integrated distributed OTC trading module`,
  thumbnail: swarm,
  thumbnailAlt: `Protofire team has worked on the Swarm Markets DEX, which provides compliant multi-asset DeFi infrastructure, to enable its swapping and pooling, as well as a Multi-Tier permissioning system.`,
  aboutProject: {
    title: "About the project",
    lists: [
      `Compliant multi-asset infrastructure`,
      `Operates in a regulated environment in Germany`,
      `Provides KYC services`,
      `Multi-Tier permissioning system`,
      `Automated market-making (AMM)`,
      `Decentralized exchange with Swap, Pool and decentralized over-the-counter 
      block trading functionalities`,
      `Development of: Architecture, Smart contracts, Front end`,
      `A compliant environment with the core DeFi benefits: self-custody, on-chain 
      transparency, and networked liquidity`,
    ],
  },
  theClient: {
    title: "The client/partner",
    firstParagraph: `Swarm provides compliant multi-asset DeFi infrastructure  for 
    both institutions and retail investors. For the first time ever, people will 
    be able to invest in crypto assets and tokenized securities in a single DeFi
     venue. Swarm combines the innovation of DeFi with investor protection. Its 
     trading and investing tools enable investors to retain full control of assets 
     in their wallet, giving them direct access to capital and crypto markets 24/7, 
     without paying for costly intermediaries`,
  },
  theProblem: {
    title: "The problem",
    firstParagraph: `Lack of regulation has meant participants and capital remain 
    sidelined from digital markets. The industry is bifurcating into regulated and 
    non-regulated entities, as more members of the crypto industry believe its 
    long-term success lies in regulation.`,
    secondParagraph: `Swarm aims to be the nucleus of a transparent financial culture 
    that creates value for many. It steps up to build a world where everyone can 
    benefit from financial opportunities. This aspiration is reflected across its 
    ever growing portfolio of unique investment products, which empower people to 
    take control of their personal wealth in a secure environment and overcome 
    compliance barriers for institutional investors.`,
  },
  theSolution: {
    title: "The solution",
    firstParagraph: ``,
    lists: [
      [
        `Customized AMM: Creation of pools with a customizable number of assets and weights`,
      ],
      [
        `Swap functionality: Swapping assets in real-time`,
        "https://app.swarm.com/pools",
      ],

      [`Pool functionality: Adding/removing liquidity from pools`],
      [`Using the platform native token to get discounts on the protocol fees`],
      [
        `Multi-Tier permissioning system: The user gradually gets access to a wider 
      range of services after completing several KYC steps`,
      ],
      [
        `Batched auctions: The ability to batch multiple transactions into one to 
      reduce costs, combined with a vesting contract for Swarm’s token 
      distribution and price discovery`,
      ],
      [`Implementation on Polygon`],
      [
        `Swarm dOTC: A decentralized permissioned Over-the-Counter P2P 
      trading module at Swarm`,
        "https://app.swarm.com/dotc",
      ],
    ],
  },
  comment: {
    body: `Access to DeFi needs to be at a price point that isn't cost-prohibitive 
    to Main Street investors, which is why expanding our licensed AMM to layer 2 
    solutions is crucial. Web3 is coming for financial markets and we're building 
    trustless infrastructure that gives people autonomy to build wealth while 
    retaining control of their assets at all times.`,

    author: "Philipp Pieper",
    title: "co-founder of Swarm Markets",
  },
  technologyStack: {
    title: "Technology stack ",
    lauguages: [],
    database: [],
    tools: [],
  },
  images: [
    {
      original: swarm1,
      originalAlt:
        "Protofire team has worked on the Swarm Markets DEX, which provides compliant multi-asset DeFi infrastructure.",
      thumbnail: swarm1,
      thumbnailAlt:
        "Protofire team has worked on the Swarm Markets DEX, which provides compliant multi-asset DeFi infrastructure.",
    },
    {
      original: swarm2,
      originalAlt:
        "Protofire team has worked on the Swarm Markets DEX for multi-asset DeFi infrastructure to enable swapping and pooling, as well as a Multi-Tier permissioning system.",
      thumbnail: swarm2,
      thumbnailAlt:
        "Protofire team has worked on the Swarm Markets DEX for multi-asset DeFi infrastructure to enable swapping and pooling, as well as a Multi-Tier permissioning system.",
    },
  ],
  ourTrackRecord: [
    [
      maker1,
      maker11,
      "https://makerdao.com/",
      "newWindow",
      "Protofire contributed to the MakerDAO Keeper Pool that programmatically optimizes its capital efficiency through stabilizing mechanisms. ",
    ],
    [
      maker2,
      maker22,
      "/projects/cowswap-dex",
      "sameWindow",
      "Protofire developed the CowSwap DEX for trading tokens via gas-less orders and helped to build the CoW SDK and the Explorer, along with the integration of the Batch Viewer and The Graph.",
    ],
    [
      maker3,
      maker33,
      `https://medium.com/protofire-blog/protofire-delivers-the-first-bidder-ui-for-the-dutchx-decentralized-exchange-protocol-dccd260a1436`,
      "newWindow",
      "Protofire delivered the first Bidder UI for the FairDEX exchange protocol which enables users to monitor auction details and charts, place bids, deposits, and withdraws tokens.",
    ],
  ],
};
