import kyberDAO from "../../assets/projectsImages/kyberswapNew.png";
import kyber1 from "../../assets/projectsImages/kyberswap1.png";
import kyber2 from "../../assets/projectsImages/kyberswap2.png";
import kyber3 from "../../assets/projectsImages/kyberswap3.png";
import tractRecord1 from "../../assets/services/35.png";
import tractRecord11 from "../../assets/services/36.png";

// eslint-disable-next-line import/prefer-default-export
export const project = {
  name: "Kyber DAO Smart Contract Proxy",
  hashtags: ["Governance", "Smart Contract"],
  description: `In partnership with Kyber Network, Protofire created a trustless 
  smart contract proxy - a non-custodial delegation service - for the KyberDAO 
  governance. This service enables anyone to become a pool operator and perform 
  token staking/voting without the need to create new smart contracts. Now, token 
  holders can delegate governance to operators, while gaining interest from rewards`,
  thumbnail: kyberDAO,
  thumbnailAlt:
    "Protofire created a trustless smart contract proxy for the KyberDAO governance which enables anyone to become a pool operator and perform token staking and voting without the need to create new smart contracts.",
  aboutProject: {
    title: "About the project",
    lists: [
      `Creation of a trustless smart contract proxy`,
      `A non-custodial delegation service for the KyberDAO governance`,
      `Governance:  1) Token holders can become pool operators and stake/vote
    2) Token holders can delegate governance to operators, and gain interest 
    from rewards`,
    ],
  },
  theClient: {
    title: "The client/partner",
    firstParagraph: `Kyber Network is an on-chain liquidity protocol that allows 
    for integrating token swaps into any decentralized application (DApp). Using 
    Kyber Network, developers can build innovative apps, including instant token 
    swap services, decentralized payment flows, and financial DApps`,
  },
  theProblem: {
    title: "The problem",
    firstParagraph: `The governance process in a decentralized protocol is prone 
    to transparency, engagement, and participation issues. As voting and staking 
    are time- and effort-intensive, multiple projects with assets worth millions 
    of dollars rely on a limited number of voters, which is quite risky.`,
    secondParagraph: `Protofire and Kyber Network aimed to create a mechanism that 
    would help to eliminate these challenges. The service needed to enable any KNC 
    tokens to participate in KyberDAO - a community governance platform - 
    by delegating voting power to pool operators, while maintaining their stake in 
    a decentralized manner. Pool operators become highly involved in the voting 
    process, sharing rewards among delegators.`,
  },
  theSolution: {
    title: "The solution",
    lists: [
      [`Rewards are stored trustlessly in the smart contract`],
      [`Allow users to claim rewards from the smart contracts`],
      [`Track rewards that have been claimed by users`],
    ],
  },
  theMedia: {
    title: "Media",
    firstMedia: {
      text: "Protofire Developed a Trustless Delegation Solution for KyberDAO: ",
      link: "https://medium.com/protofire-blog/protofire-developed-a-trustless-delegation-solution-for-kyberdao-68dd3a1a0d89",
    },
  },
  comment: {
    body: `Thanks to Protofire’s support, we now have a trustless smart contract 
    proxy that any pool operator can utilize for non-custodial KNC staking and 
    voting delegation. The team has been very professional and we’re glad to work 
    with them as part of our KyberDAO launch preparations.`,

    author: "Shane Hong",
    title: "Marketing Manager, Kyber Network",
  },
  images: [
    {
      original: kyber1,
      originalAlt:
        "A trustless smart contract proxy for the KyberDAO governance was created by Protofire. The solution enables anyone to become a pool operator and perform token staking and voting with no need to create new smart contracts.",
      thumbnail: kyber1,
      thumbnailAlt:
        "A trustless smart contract proxy for the KyberDAO governance was created by Protofire. The solution enables anyone to become a pool operator and perform token staking and voting with no need to create new smart contracts.",
    },
    {
      original: kyber2,
      originalAlt:
        "A trustless smart contract proxy for the KyberDAO governance was created by Protofire. The solution enables anyone to become a pool operator and perform token staking and voting with no need to create new smart contracts.",
      thumbnail: kyber2,
      thumbnailAlt:
        "A trustless smart contract proxy for the KyberDAO governance was created by Protofire. The solution enables anyone to become a pool operator and perform token staking and voting with no need to create new smart contracts.",
    },
    {
      original: kyber3,
      originalAlt:
        "A trustless smart contract proxy for the KyberDAO governance was created by Protofire. The solution enables anyone to become a pool operator and perform token staking and voting with no need to create new smart contracts.",
      thumbnail: kyber3,
      thumbnailAlt:
        "A trustless smart contract proxy for the KyberDAO governance was created by Protofire. The solution enables anyone to become a pool operator and perform token staking and voting with no need to create new smart contracts.",
    },
  ],
  ourTrackRecord: [
    [
      tractRecord1,
      tractRecord11,
      "#",
      "sameWindow",
      "Protofire helped to integrate Augur with bZx smart contracts that facilitate on-chain margin lending, monitoring, and liquidation of token trades.",
    ],
  ],
};
