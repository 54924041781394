// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface TableInterface {
  otherDev: string;
  protofire: string;
  key: number;
}
function AboutTable({ rows }: { rows: TableInterface[] }) {
  return (
    <div className="  ">
      <table className="table ">
        <tbody>
          <tr className="  text-sm tracking-wider">
            <th className="text-left text-blue200">
              OTHER DEV SHOPS <span className="float-right mx-3">VS</span>{" "}
            </th>
            <th className="text-left gradient pl-10">PROTOFIRE:</th>
          </tr>

          {rows.map((row) => (
            <tr
              className={`text-xs   ${
                row.key % 2 === 0 ? "bg-background" : ""
              }`}
              style={{
                fontFamily: "Spartan-Medium",
              }}
              key={row.key}
            >
              <td className="firstList text-blue200">
                <li className=" l">{row.otherDev}</li>
              </td>
              <td className="secondList text-blue100 pl-10">
                <li className="">{row.protofire}</li>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AboutTable;
