import cowswap from "../../assets/projectsImages/cowswapDEXNew.png";
import cowswap1 from "../../assets/projectsImages/cowswap1.png";
import cowswap2 from "../../assets/projectsImages/cowswap2.png";
import cowswap3 from "../../assets/projectsImages/cowswap3.png";
import dex1 from "../../assets/services/107.png";
import dex11 from "../../assets/services/108.png";
import dex2 from "../../assets/services/47.png";
import dex22 from "../../assets/services/48.png";
import dex3 from "../../assets/services/69.png";
import dex33 from "../../assets/services/70.png";

// eslint-disable-next-line import/prefer-default-export
export const project = {
  name: "Cowswap DEX",
  hashtags: ["dApp", "DeFi", "DEX", "Interface", "SDK"],
  description: `Protofire has been working in sync with the CoW Protocol team in the 
    rebranding, development, and testing of a web trading interface built 
    on top of the protocol, which allows users to buy and sell tokens using 
    gas-less orders, as well as the improvement of an affiliate program to 
    promote the referrals in the community. Additionally, Protofire has assisted 
    in the development of the SDK, the Explorer, the Batch Viewer 
    integration (CowSwap and CoW Protocol Explorer), UI maintenance and 
    UX improvements, and The Graph integration.`,
  thumbnail: cowswap,
  thumbnailAlt:
    "Protofire helped CoW Protocol to develop the CowSwap DEX for trading tokens via gas-less orders and helped to build the CoW SDK and the Explorer, along with the integration of the Batch Viewer and The Graph.",
  aboutProject: {
    title: "About the project",
    lists: [
      `Protofire has provided a dedicated team to work on several tasks 
      alongside the CoW Protocol team for an unlimited period of time`,
      `Support for the rebranding of Gnosis Protocol to CoW Protocol`,
      `Front end development of the explorer`,
      `Maintenance and improvements`,
      `Providing QA services`,
      `Promoting network growth through an affiliate program`,
    ],
    sellVolume: ["$8.00B", "Sell Volume GPv2 (Q1/2022)"],
    batches: ["169.4K", "Batches Solved (Q1/2022)"],
    totalTrades: ["236.2K", "Total Trades (Q1/2022)"],
  },
  theClient: {
    title: "The client/partner",
    firstParagraph: `CowSwap is the first trading interface built on top of CoW 
    Protocol. CowSwap is a fully permissionless decentralized trading protocol that 
    leverages Batch Auctions as its price-finding mechanism.`,
    secondParagraph: `It is the first trading venue to operate with the CoW Protocol 
    (Coincidence Of Wants), in which investors buy and sell tokens using 
    gasless orders that are settled peer-to-peer among its users, or into 
    any on-chain liquidity source while providing MEV protection.`,
  },
  theProblem: {
    title: "The problem",
    firstParagraph: `Gnosis Protocol has decided to both update the technology 
    and rebrand to CoW Protocol. CoW has the reference “Coincidence of Wants” 
    in mind - explained below -, a core aspect of the protocol’s 
    algorithm. Rebranding a company implies:`,
    lists: [
      "Evaluating past works",
      "Testing the audience",
      "Conducting market research",
      "Analyzing the competition",
      "Rethinking core values",
    ],
  },
  theSolution: {
    title: "The solution",
    firstParagraph:
      "We have directly helped with the development and testing of the:",
    lists: [
      [
        `Affiliate program: The objective of the program is to bring new users 
      to the platform and generate more financial volume`,
        "https://cowswap.exchange/#/profile",
      ],
      [
        `Explorer: We have been working on extending functionalities like user details, 
      token values, transaction view (list and batch views), markets traded and other 
      CoW protocol information in supported networks (Ethereum Mainnet, Gnosis 
      Chain and Rinkeby) `,
        "https://explorer.cow.fi/",
      ],
      [`View batched transactions and transaction map.`],
      [
        `SDK: The development of a devkit to create a set of tools to improve the 
      synchronization of the code in the Gnosis DApps as well as adding the ability 
      to interact with the CoW Protocol in an easy way`,
        "https://github.com/gnosis/cow-sdk",
      ],
      [
        `Subgraph: Indexing Mainnet, Rinkeby, and Gnosis Chain. Fully working version:`,
        "https://github.com/cowprotocol/subgraph",
      ],
      [
        `Batch Viewer Integration: Both for the CowSwap and CoW Protocol Explorer.`,
      ],
      [
        `UI maintenance and UX improvements to the interface: Such as adding additional
       details to the profile page, visualization of the orders execution process, 
       increasing the number of supported wallets and L2 chains, etc.`,
      ],
    ],
  },
  technologyStack: {
    title: "Technology stack ",
    lauguages: ["React", "TypeScript"],
    database: ["Ehers (in Explorer)", "Web3"],
    tools: ["Storybook", "Styled Components", "Jest Rust"],
  },
  images: [
    {
      original: cowswap1,
      originalAlt: `Protofire helped CoW Protocol to develop the CowSwap DEX, the CoW SDK, and the Explorer, along with the integration of the Batch Viewer and The Graph.`,
      thumbnail: cowswap1,
      thumbnailAlt: `Protofire helped CoW Protocol to develop the CowSwap DEX, the CoW SDK, and the Explorer, along with the integration of the Batch Viewer and The Graph.`,
    },
    {
      original: cowswap2,
      originalAlt: `Protofire helped CoW Protocol to build the CowSwap DEX, the CoW SDK, and the Explorer, along with the integration of the Batch Viewer and The Graph.`,
      thumbnail: cowswap2,
      thumbnailAlt: `Protofire helped CoW Protocol to build the CowSwap DEX, the CoW SDK, and the Explorer, along with the integration of the Batch Viewer and The Graph.`,
    },
    {
      original: cowswap3,
      originalAlt: `Protofire helped CoW Protocol to develop the CoW SDK, the Explorer, and CowSwap DEX for trading tokens via gas-less orders, along with the integration of the Batch Viewer and The Graph.`,
      thumbnail: cowswap3,
      thumbnailAlt: `Protofire helped CoW Protocol to develop the CoW SDK, the Explorer, and CowSwap DEX for trading tokens via gas-less orders, along with the integration of the Batch Viewer and The Graph.`,
    },
  ],
  ourTrackRecord: [
    [
      dex1,
      dex11,
      "/projects/swarm-dex",
      "sameWindow",
      "Protofire team has worked on the Swarm Markets DEX, which provides compliant multi-asset DeFi infrastructure.",
    ],
    [
      dex2,
      dex22,
      "https://medium.com/protofire-blog/protofire-delivers-the-first-bidder-ui-for-the-dutchx-decentralized-exchange-protocol-dccd260a1436",
      "newWindow",
      "Protofire delivered the first Bidder UI for the FairDEX exchange protocol which enables users to monitor auction details and charts, place bids, deposits, and withdraws tokens.",
    ],
    [
      dex3,
      dex33,
      " https://makerdao.com/",
      "newWindow",
      "Protofire contributed to the MakerDAO Keeper Pool that programmatically optimizes its capital efficiency through stabilizing mechanisms.",
    ],
  ],
};
