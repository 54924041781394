import chainlink from "../../assets/projectsImages/chainLinkNew.png";
import chainlink1 from "../../assets/projectsImages/chainlink1.png";
import chainlink2 from "../../assets/projectsImages/chainlink2.png";
import chainlink3 from "../../assets/projectsImages/chainlink3.png";
import chainlink4 from "../../assets/projectsImages/chainlink4.png";

// eslint-disable-next-line import/prefer-default-export
export const project = {
  name: "Chainlink Integration and External Adapters",
  hashtags: ["API", "DevOps", "Integration", "Oracle"],
  description: `Supported by Chainlink Community Grants, we have built a team 
  that works with Chainlink infrastructure in order to bring oracle infrastructure
  to wherever it may be needed in the Web3 ecosystem. We have promoted Chainlink 
  node deployment, as well as tested integrations on EVM and non-EVM blockchains, 
  and we have developed Chainlink External Adapters that connect smart contracts 
  to premium web APIs. We have established different mechanisms and workflows to 
  validate the new chains, identified the right approaches to achieve the 
  integrations, and produced accompanying documentation for the ecosystem community.`,
  thumbnail: chainlink,
  thumbnailAlt: `Protofire works with Chainlink to bring oracle infrastructure to wherever it is needed in Web3.`,
  aboutProject: {
    title: "About the project",
    firstParagraph: "Chainlink promotes:",
    lists: [
      `The industry standard oracle network`,
      `A reliable, sybil-resistant, and tamper-proof network`,
      `Seamless connection to APIs`,
      `Proven, ready-made services`,
      `Secure off-chain computation`,
    ],
    secondParagraph:
      "What we have worked on thanks to support from Chainlink Community Grants:",
    list2: [
      `Integrating Chainlink across several networks`,
      `Developing repositories with files required to run a Chainlink node in a network.`,
      `Testing integrations on EVM-compatible blockchains.`,
      `Testing integrations on non-EVM-compatible blockchains.`,
      `Building a cross-testnet LINK faucet.`,
      `Developing Chainlink External Adapters to enable access to high-quality data and enable
      extreme flexibility to connect smart contracts to premium web APIs.`,
    ],
    sellVolume: ["$56B+", "Total Value Secured (Q1/2022)"],
    batches: ["910+", "Total Oracle Networks (Q1/2022)"],
    totalTrades: ["2B+", "On-chain Data Points (Q1/2022)"],
    totalIntegrations: ["1230+", "Total Integrations (Q1/2022)"],
  },
  theClient: {
    title: "The client/partner",
    firstParagraph: `Chainlink decentralized oracle networks provide tamper-proof 
    inputs, outputs, and computations to support advanced smart contracts on any 
    blockchain. Chainlink is a technology platform that enables both Web3 and 
    non-blockchain enterprises to securely connect with blockchain platforms. 
    Chainlink middleware connects blockchain-based smart contracts with external 
    data, such as fiat currency prices.`,
  },
  theProblem: {
    title: "The problem",
    firstParagraph: `Chainlink solves “the oracle problem,” which revolves around 
    an innate limitation of blockchains: they cannot pull in data from or push 
    data out to any external system as built-in functionality. As such, blockchains
    are isolated networks, akin to a computer with no Internet connection. However,
    for smart contracts to realize upwards of 90% of their potential use cases, 
    they must connect to the outside world.

    The solution to the blockchain oracle problem is the Chainlink Decentralized 
    Oracle Network. It’s a secure piece of middleware that facilitates communication
    between blockchains and any off-chain system, including data providers, web APIs,
    enterprise backends, cloud providers, IoT devices, e-signatures, payment systems,
    other blockchains, and more.`,
    lists: [],
  },
  theSolution: {
    title: "The solution",
    firstParagraph: ``,
    lists: [
      {
        title: "Chainlink Nodes:",
        desc: `We worked with bridge teams to ensure LINK is correctly bridged 
        (respecting ERC677). The Protofire team has worked on:`,
        list: ["Harmony", "Celo", "Plasm", " Avalanche", "xDAI"],
      },
      {
        title: "Chainlink Blockchain Integration Acceptance Testing:",
        desc: `Development of repositories with all the files required to run 
        a Chainlink Node in a given network. Protofire team has worked on:`,
        list: ["Klaytn", "Celo", "Plasm", " Avalanche", "xDAI"],
      },
      {
        title: "Chainlink Scalable EVM Integration Acceptance Testing",
        desc: `We test integrations on EVM-compatible blockchains. 
        Protofire team has worked on:`,
        list: [
          "Klaytn",
          "Celo",
          "Moonbeam",
          " Avalanche",
          "xDAI",
          "Harmony",
          "IoTex",
          "OKC (OKX)",
          "Kovan Optimism",
        ],
      },
      {
        title: "Chainlink Scalable Non-EVM Integration Analysis:",
        desc: `As the crypto ecosystem continues to grow, Chainlink Price Feeds are needed
        in more diverse environments. This necessitates that work is conducted to integrate
        Chainlink trust-minimized services across different networks such as Polkadot and 
        Solana.`,
        list: [],
      },
      {
        title: "LINK Faucet for testnets:",
        desc: `We built a cross-testnet LINK faucet, enabling developers to test 
        their Chainlink-ed contracts in different environments. We currently support:`,
        list: [
          "Avalanche Fuji",
          "BSC Testnet",
          "POA Sokol",
          "Fantom Testnet",
          "ETH Kovan",
          "Rinkeby Arbitrum",
        ],
      },
      {
        title: "Chainlink External Adapters (EA):",
        desc: `They allow access to high-quality data and enable extreme 
        flexibility to connect smart contracts to premium web APIs. Protofire 
        team has worked on:`,
        list: [
          "Modified EA input parameters for dozens of external adapters",
          "Modified EA Response types for dozens of external adapters",
          "Added http and websocket integration tests for dozens of external adapters",
          `Defidozen:  new EA to get an index value that is calculated with 
          the value of different tokens`,
          `Proof of Reserves address-set and indexer EAs: updated input parameters 
          and response to have unified shape.`,
          "BEA: Consumer price index feed EA. Ported from golang",
          "ETH Balance:  EA that returns ethereum or erc20 balances from addresses",
          "Set-token Index: Defi-pulse EA",
          "Wrapped: EA to get a list of blockchain addresses from wrapped.com",
          "Coinmarketcap EA: New historical api",
          "View-function:  EA that takes ABI and returns the function result of contract",
          "Improved error logging and error response for EAs that use external API",
          "Updated Linear Finance index values",
          "Upvest: EA enabled for gasprice",
          "MyCryptoAPI: EA enabled for gasprice",
          "Batching discovery & upgrade: cryptocompare, cmc, tradermade",
          "Wootrade: feeds with their data only to be used for their private pools on polygon",
          "Cache.gold: Proof of Reserves EA",
          "Etherscan: EA enabled for gasprice",
          "EthGasWatch: EA enabled for gasprice",
          "US CPI Data for FRAX",
          "Feeds for BTC and ETH, adding a circuit breaker",
          "Index Coop Data Economy Index (DATA)",
          "Finage Batch Discovery, Support batch request in several adapters",
          "MyCryptoapi.gasprice: EA enabled for gasprice",
          "EA Masterlist Airtable integration",
          "Changed server dependency from express to fastify",
          "Readme generator enhancement",
        ],
      },
    ],
  },
  technologyStack: {
    title: "Technology stack",
    lauguages: ["Go (golang)", "Typescript", "Node.js"],
    environment: ["GCP", "GKE", "Linux", "Kubernetes"],
    tools: ["Kubernetes", "Geth", "Ginkgo", "Docker"],
    infrastructure: [
      "Bitcoin node",
      "Eth 1.0",
      "Eth 2.0 Node",
      "Oracles, Kubernetes",
    ],
  },
  code: {
    text: "Code",
    codes: [
      {
        text: "External Adapters Example",
        link: "https://github.com/protofire/chainlink-external-adapter-example",
      },
      {
        text: "External Adapters",
        link: "https://github.com/smartcontractkit/external-adapters-js",
      },
      {
        text: "Integrations",
        link: "https://github.com/smartcontractkit",
      },
    ],
  },
  images: [
    {
      original: chainlink1,
      originalAlt:
        "Protofire has developed Chainlink External Adapters that connect smart contracts to premium web APIs and continues its work with Chainlink to bring oracle infrastructure to wherever it is needed in Web3.",
      thumbnail: chainlink1,
      thumbnailAlt:
        "Protofire has developed Chainlink External Adapters that connect smart contracts to premium web APIs and continues its work with Chainlink to bring oracle infrastructure to wherever it is needed in Web3.",
    },
    {
      original: chainlink2,
      originalAlt:
        "Protofire has developed Chainlink External Adapters to connect smart contracts to premium web APIs and brings oracle infrastructure to wherever it is needed in Web3.",
      thumbnail: chainlink2,
      thumbnailAlt:
        "Protofire has developed Chainlink External Adapters to connect smart contracts to premium web APIs and brings oracle infrastructure to wherever it is needed in Web3.",
    },
    {
      original: chainlink3,
      originalAlt:
        "Protofire has developed Chainlink External Adapters to connect smart contracts to premium web APIs and brings oracle infrastructure to wherever it is needed in Web3, including workflow and user interfaces, backend system and services, messaging, and external APIs and data management tools.",
      thumbnail: chainlink3,
      thumbnailAlt:
        "Protofire has developed Chainlink External Adapters to connect smart contracts to premium web APIs and brings oracle infrastructure to wherever it is needed in Web3, including workflow and user interfaces, backend system and services, messaging, and external APIs and data management tools.",
    },
    {
      original: chainlink4,
      originalAlt:
        "Protofire has developed Chainlink External Adapters to connect smart contracts to premium web APIs and brings oracle infrastructure to Web3, connecting blockchain networks to the Chainlink ecosystem.",
      thumbnail: chainlink4,
      thumbnailAlt:
        "Protofire has developed Chainlink External Adapters to connect smart contracts to premium web APIs and brings oracle infrastructure to Web3, connecting blockchain networks to the Chainlink ecosystem.",
    },
  ],
  ourTrackRecord: [],
};
