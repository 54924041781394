import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import WhatWeDo from "../home/whatWeDo/whatWeDo";
import { whatWeDoContent } from "../home/whatWeDo/content";

import { ReactComponent as JoinUs } from "../assets/images/joinUs.svg";
import { ReactComponent as JoinUsMobile } from "../assets/images/joinUsMobile.svg";
import { ReactComponent as OvalCareerMobile2 } from "../assets/career/ovalCareerMobile2.svg";
import { ReactComponent as OvalCareerBig } from "../assets/career/ovalCareerBig.svg";

import yellowArrow from "../assets/images/arrowYsmall.png";
import ContactUs from "../contactUs/contactUs";

const description = `Protofire can build a development team for you in business analysis, architecture design, development, smart contract development, DevOps, QA engineering, UI/UX design, and other relevant areas.`;
const description2 = `We tailor the size and composition of our teams to the specific needs of each project, ensuring optimal efficiency and timely delivery.`;

function Services() {
  return (
    <div className="lg:px-40 m-auto md:px-10 sm:px-0 py-20">
      <Helmet>
        <title>Blockchain Development Services | Protofire</title>
        <meta
          name="description"
          content="Need help with smart contract development, decentralized apps (dApps), DEXes, NFTs, oracle integration, subgraphs, node management, or wallets? Contact us."
        />
        <meta
          name="keywords"
          content="development tools, application development software, dev tools, application development software, develop a blockchain, crypto website, blockchain app"
        />
      </Helmet>
      <div
        className="flex lg:px-20 md:10 sm:px-5 text-blue200 text-xs relative z-10"
        style={{
          fontFamily: "Spartan-Medium",
        }}
      >
        <Link to="/" className=" mr-1 text-blue200">
          Home
        </Link>
        /
        <Link to="/services" className="ml-1 text-blue100 ">
          Services
        </Link>
      </div>
      <div className="relative lg:block md:hidden sm:hidden z-1 ">
        <OvalCareerBig className=" absolute ovalBigService1" />
      </div>
      <div
        className="lg:px-20 lg:py-20 md:py-20 md:px-10  sm:py-10 sm:px-5  
      relative z-10"
      >
        <p className="text-3xl text-blue100  ">Services</p>
        <p
          className=" text-blue200 lg:w-2/3 leading-6"
          style={{
            fontFamily: "Spartan-Medium",
          }}
        >
          {description}
        </p>
        <p
          className=" text-blue200 lg:w-2/3 leading-6"
          style={{
            fontFamily: "Spartan-Medium",
          }}
        >
          {description2}
        </p>
      </div>
      <p className="text-3xl text-blue100 sm:px-5 lg:px-20 lg:py-5 md:px-20">
        {" "}
        What we do
      </p>

      <div className="relative lg:hidden md:hidden sm:block z-1 ">
        <OvalCareerMobile2 className=" absolute bgOvalService2" />
      </div>
      <div
        className="lg:grid md:grid lg:grid-cols-3 md:grid-cols-2 sm:block relative z-10
        sm:py-2lg:gap-8 sm:gap-4 lg:px-20 lg:pb-10 md:pb-20 md:px-10  sm:px-5
      "
      >
        {whatWeDoContent.map((content) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div key={content.number}>
            <WhatWeDo {...content} />{" "}
          </div>
        ))}
        <div
          className="col-span-2 leading-10 mt-10 pl-10 border-l border-blue300 h-1/2"
          style={{ fontFamily: "Spartan-Medium", width: "90%" }}
        >
          <p className="text-2xl text-blue100 pb-5">
            {" "}
            Looking for a solution for your idea? Let’s discuss the best ways
            for you to have it delivered.
          </p>
          <a
            href="#contact"
            className="join px-10 py-5  tracking-wide"
            style={{
              fontFamily: "Spartan",
            }}
          >
            <button type="button" className="btnText">
              GET IN TOUCH
              <img src={yellowArrow} alt="arrow" className="inline ml-2 mb-1" />
            </button>
          </a>
        </div>
      </div>
      <div className="lg:px-20 lg:pb-20 lg:pt-10 sm:py-10 md:px-10 sm:px-5">
        <p
          style={{ fontFamily: "Spartan-SemiBold", fontSize: "14px" }}
          className="tracking-wider text-blue200"
        >
          {" "}
          JOIN US AT ANY STAGE WITH YOU PROJECT
        </p>
        <p className="text-3xl text-blue100 mt-3 mb-3">
          {" "}
          Our lean execution philosophy
        </p>
        <JoinUs className="joinUs sm:hidden lg:block md:block h-full " />
        <JoinUsMobile className="lg:hidden md:hidden mt-10 w-full h-full" />
      </div>
      <div className="" id="contact">
        <ContactUs />
      </div>
    </div>
  );
}

export default Services;
