import { Link } from "react-router-dom";

type SingleProjectProps = {
  clientLogo: any;
  clientLogoAlt?: string;
  description: string;
  firstLine: string[];
  secondLine: string[];
  thirdLine: string[];
  screenshot: any;
  screenshotAlt?: string;
  tags: string[];
  page: string;
  label: string;
};

function SingleProject({
  clientLogo,
  clientLogoAlt,
  description,
  firstLine,
  secondLine,
  thirdLine,
  screenshot,
  screenshotAlt,
  tags,
  page,
  label,
}: SingleProjectProps) {
  return (
    <div
      className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-10 py-5 
      relative z-10 "
    >
      <div className="sm:mb-4">
        <div>
          <div className="flex flex-wrap ">
            {tags.map((tag) => (
              <span
                className="border text-center py-2 my-2 mr-2 
              border-blue text-blue "
                style={{
                  borderRadius: "1.5rem",
                  fontFamily: "Spartan-SemiBold",
                  fontSize: "12px",
                  padding: "0.25rem 0.75rem",
                }}
                key={tag}
              >
                {" "}
                {tag}
              </span>
            ))}
          </div>
        </div>
        <h3
          className="text-blue100 Spartan-SemiBold pt-2 text-3xl sm:text-2xl"
          style={{
            fontFamily: "Spartan-SemiBold",
          }}
        >
          {" "}
          {label}
        </h3>
        <p
          className="text-blue200 mb-10"
          style={{
            fontFamily: "Spartan-Medium",
            fontSize: "14px",
          }}
        >
          {description}
        </p>
        {firstLine.length > 0 && (
          <div className=" pb-5 ">
            <div className="py-2 ">
              <div className="border-b border-blue300 lg:inline-block md:inline-block sm:flex pb-2">
                <span
                  className=" text-blue100 text-2xl "
                  style={{ fontFamily: "Spartan-SemiBold" }}
                >
                  {firstLine[0]}
                </span>
                <span
                  className="pl-4 text-blue200 uppercase text-xs align-text-top  "
                  style={{ fontFamily: "Spartan-Medium" }}
                >
                  {firstLine[1]}
                </span>
              </div>
            </div>
            <div className="py-2 ">
              <div className="border-b border-blue300 lg:inline-block md:inline-block sm:flex pb-2">
                <span
                  className=" text-blue100 text-2xl"
                  style={{ fontFamily: "Spartan-SemiBold" }}
                >
                  {secondLine[0]}
                </span>
                <span
                  className="pl-4 text-blue200 uppercase text-xs align-text-top"
                  style={{ fontFamily: "Spartan-Medium" }}
                >
                  {secondLine[1]}
                </span>
              </div>
            </div>
            <div className="py-2 mb-5 sm:flex">
              <span
                className=" text-blue100 text-2xl"
                style={{ fontFamily: "Spartan-SemiBold" }}
              >
                {thirdLine[0]}
              </span>
              <span
                className="pl-4 text-blue200 uppercase text-xs align-text-top"
                style={{ fontFamily: "Spartan-Medium" }}
              >
                {thirdLine[1]}
              </span>
            </div>
          </div>
        )}
        <Link
          to={page}
          className=" py-5 px-8  text-blue100 btn"
          style={{ fontSize: "12px", fontFamily: "Spartan" }}
        >
          READ MORE
        </Link>
      </div>

      <div className="relative sm:hidden lg:block md:block ">
        <div
          style={{
            position: "absolute",
            top: "-100px",
            right: "0",
          }}
        >
          {label !== "Opyn Decentralized Insurance Platform" && (
            <img
              src={clientLogo}
              alt={clientLogoAlt}
              className=" lg:w-4/5 md:w-1/2"
            />
          )}{" "}
          {label === "Opyn Decentralized Insurance Platform" && (
            <img
              src={clientLogo}
              alt={clientLogoAlt}
              className=" lg:w-2/6 md:w-1/2 float-right mr-10 "
            />
          )}
        </div>
        <img className="ml-auto" src={screenshot} alt={screenshotAlt} />{" "}
      </div>
    </div>
  );
}

SingleProject.defaultProps = {
  clientLogoAlt: "",
  screenshotAlt: "",
};

export default SingleProject;
