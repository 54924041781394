import chainlink from "../../assets/projectsImages/big-data-protocol.png";

export const project = {
  name: "Single-button Solution for Big Data Protocol",
  hashtags: ["Big Data Protocol", "Solution"],
  description: [
    "Protofire has developed a Single-button solution for Big Data Protocol to help accelerate the deployment and testing of new Ocean features for its data marketplace.",
  ],
  thumbnail: chainlink,
  thumbnailAlt: `Protofire has developed a Single-button solution for Big Data Protocol to help accelerate the deployment and testing of new Ocean features for its data marketplace.`,
  technologyStack: {
    title: "Technology stack",
    lauguages: ["Python", "Node.JS", "Terraform"],
    platform: ["Postgres as a part of Chainlink nodes"],
  },
  code: {
    text: "Code",
    codes: [
      {
        text: "GitHub",
        link: "https://github.com/bigdataprotocol/",
      },
      {
        text: "Live Product",
        link: "https://market.bigdataprotocol.com/",
      },
    ],
  },
  ourTrackRecord: [],
};

export const content = [
  {
    title: "About the project",
    anchor: "page-about",
    description: [],
    lists: [
      `The Big Data Protocol’s data marketplace aims to provide fast operation in a user-friendly environment for end users and developers.`,
      `Single-button saves time spent on testing, allowing developers to allocate even more effort to new feature implementation.`,
    ],
  },
  {
    title: "The client/partner",
    anchor: "page-client",
    description: [
      "Big Data Protocol is a data marketplace (a fork of Ocean Protocol marketplace) that sources commercially valuable data from professional data providers, tokenizes it, and makes it liquid.",
      "https://bigdataprotocol.com/",
    ],
  },
  {
    title: "The problem",
    anchor: "page-problem",
    description: [
      "The Ocean Protocol regularly updates its upstream repository, making update tracking somewhat time-consuming for forks running on Ocean. Developers spend time on rebasing the updates. It usually takes more than a day and up to a week to redeploy the updated components of Ocean infrastructure and test the new features.",
    ],
  },
  {
    title: "The solution",
    anchor: "page-solution",
    description: [
      "Protofire has designed and implemented the Single-button Deploy solution that allows development teams to automatically deploy a fully functional version of Ocean components such as provider, subgraph, and marketplace.",
      "The solution is based on Github commits. It drastically simplifies the development process by reducing the implementation and testing time. As such, Single-button can reduce the deployment phase of a release testing from 8-40 hours to 2 hours, saving time and budget and making new Ocean features available for BDP users faster.",
    ],
  },
];
