/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from "react";

import SingleProject from "./singleProject";
import { proContent } from "./proContent";
import { ReactComponent as RightArrowGrey } from "../../assets/images/right_arrow_grey.svg";
import { ReactComponent as LeftArrowGrey } from "../../assets/images/left_arrow_grey.svg";
import { ReactComponent as RightArrowWhite } from "../../assets/images/right_arrow_white.svg";
import { ReactComponent as LeftArrowWhite } from "../../assets/images/left_arrow_white.svg";

function Index() {
  const [displayContent, setDisplayContent] = useState(proContent[0]);
  const [currentContent, setCurrentContent] = useState(0);

  const next = () => {
    if (currentContent < proContent.length - 1)
      setCurrentContent(currentContent + 1);
  };

  const previous = () => {
    if (currentContent > 0) setCurrentContent(currentContent - 1);
  };

  useEffect(() => {
    setDisplayContent(proContent[currentContent]);
  }, [currentContent]);
  return (
    <div
      className="md:px-10 bg-darkBackground z-20 relative lg:p-20 lg:pr-0 lg:mt-20 
       sm:pb-10 sm:px-5"
    >
      {" "}
      <div className="sm:flex flex-row lg:block md:block">
        <div className=" text-3xl text-blue100 py-5 sm:basis-2/3">
          <h1>Projects</h1>
        </div>
        <div className="flex sm:pt-7 sm:basis-1/3 ">
          {currentContent === 0 && (
            <LeftArrowGrey onClick={previous} className="cursor-pointer mr-3" />
          )}
          {currentContent > 0 && (
            <LeftArrowWhite
              onClick={previous}
              className="cursor-pointer mr-3"
            />
          )}
          <span
            className={currentContent + 1 > 1 ? "text-blue100" : "text-blue300"}
          >
            {`${currentContent < 9 ? "0" : ""}${currentContent + 1}`}
          </span>
          <span className="text-blue300 px-1">/</span>
          <span
            className={
              currentContent + 1 < proContent.length
                ? "text-blue100"
                : "text-blue300"
            }
          >{`${proContent.length}`}</span>

          {displayContent.key === 16 && (
            <RightArrowGrey onClick={next} className="cursor-pointer ml-3" />
          )}

          {currentContent < 16 - 1 && (
            <RightArrowWhite onClick={next} className="cursor-pointer ml-3" />
          )}
        </div>
      </div>
      <div className="z-10">
        <SingleProject {...displayContent} />
      </div>
    </div>
  );
}

export default Index;
