/* eslint-disable @typescript-eslint/no-shadow */
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "../tab.css";
import { useState, useCallback } from "react";
import { ReactComponent as JoinUs } from "../../assets/images/joinUs.svg";
import { ReactComponent as JoinUsMobile } from "../../assets/images/joinUsMobile.svg";
import { ReactComponent as OvalCareerMobile2 } from "../../assets/career/ovalCareerMobile2.svg";
import { ReactComponent as OvalCareerBig } from "../../assets/career/ovalCareerBig.svg";
import { ReactComponent as BgTop } from "../../assets/career/bgTop.svg";
import { ReactComponent as BgMiddle } from "../../assets/career/bgMiddle.svg";

import ContactUs from "../../contactUs/contactUs";
import { tabs, whatWeProvide } from "./content";

const description = `
  Protofire builds SDKs, APIs, launch kits, web and mobile components,
  and other developer tools, which help to speed up app integration,
  increase development efficiency, and allow for faster deployment of
  better products. With these tools, ecosystems can have greater control
  and reduced risks during the development process.
`;

const severalDapp = [
  "SDKs",
  "APIs",
  "Launch Kits",
  "Web & App Mobile Components",
  "Solhint",
  "Ethereum CLI",
];

function DeveloperTools() {
  const [activeTab, setActiveTab] = useState(tabs[0].tabName);
  const [currentTrack, setCurrentTrack] = useState(
    tabs[0].tabTrackRecord[0][2]
  );
  const handleActivetab = useCallback((tab: any) => {
    setActiveTab(tab.tabName);
    setCurrentTrack(tab.tabTrackRecord[0][2]);
  }, []);
  return (
    <div className="lg:px-0  md:px-10 py-10">
      <Helmet>
        <title>
          SDK, API, Launch Kit & Developer Tools Development | Protofire
        </title>
        <meta
          name="description"
          content="Do you need help with developer tools for your blockchain project? We develop SDKs, APIs, launch kits, app components, and much more. Contact us for details."
        />
        <meta
          name="keywords"
          content="programming in solidity, solidity programming language, solidity program language, web component, launch kit, debugger, application programming, build app, create an app, create mobile app, app building"
        />
      </Helmet>
      <section
        className="lg:flex md:flex lg:px-60 md:px-20 text-blue200 relative z-10
        text-xs  pt-10 sm:px-5"
        style={{
          fontFamily: "Spartan-Medium",
        }}
      >
        <Link to="/" className=" mr-1 text-blue200">
          Home
        </Link>
        /
        <Link to="/services" className="ml-1 mr-1 text-blue200">
          Services
        </Link>
        /
        <Link to="/services" className="ml-1 text-blue100">
          Developer Tools
        </Link>
      </section>
      <div className="relative lg:block md:hidden sm:hidden z-1 ">
        <OvalCareerBig className=" absolute ovalBigServicePages1" />
      </div>
      <div className="relative lg:hidden md:hidden sm:block z-1 ">
        <OvalCareerMobile2 className=" absolute bgOvalServicePages2" />
      </div>
      <section className="relative z-10">
        <p
          className="sm:w-full sm:text-3xl 
       sm:px-5 md:px-10 lg:px-60 text-4xl pt-20 text-blue100"
        >
          {" "}
          Developer Tools
        </p>
        <p
          className=" text-blue200 sm:w-full lg:w-2/3 sm:px-5 md:px-10 lg:px-60 leading-6 pb-20"
          style={{ fontFamily: "Spartan-Medium" }}
        >
          {description}
        </p>
      </section>
      <div className="lg:mx-40 md:mx-0 sm:mx-0 relative ">
        <section
          className="bg-darkBackground lg:px-20  relative z-10
        text-sm text-blue100 lg:py-10 sm:py-10 sm:w-full"
          style={{
            fontFamily: "Spartan-SemiBold",
          }}
        >
          <div className="lg:grid lg:grid-cols-6">
            <p className=" text-2xl lg:px-0 md:px-10 sm:px-5 lg:col-span-2">
              {" "}
              What we can provide:{" "}
            </p>
            <div
              className="grid lg:grid-cols-3 sm:grid-cols-2 md:grid-cols-3 
             lg:py-0 gap-5 lg:gap-10 lg:col-span-4"
            >
              {whatWeProvide.map((item) => (
                <div
                  className="lg:py-5 lg:p-0 icons lg:ml-5 lg:text-center sm:px-5 md:px-10"
                  key={item.name}
                >
                  <img
                    src={item.icone}
                    alt={item.name}
                    className="lg:m-auto sm:w-full"
                  />
                  <p className="pt-2"> {item.name}</p>
                  <p
                    className="text-blue200"
                    style={{ fontFamily: "Spartan-Medium" }}
                  >
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      <div className="relative lg:block md:hidden sm:hidden z-1 ">
        <BgTop className=" absolute bgtop" />
      </div>
      <section
        className=" lg:px-60 md:px-20 leading-5 sm:w-full sm:px-5 relative z-10
        text-blue100 text-sm lg:py-10 sm:py-10"
        style={{
          fontFamily: " Spartan-SemiBold",
        }}
      >
        <p className="text-2xl text-blue100 leading-8 py-5">
          {" "}
          Dev tools development opportunities
        </p>
        <div className="grid lg:w-4/5 md:w-full sm:w-full lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 lg:gap-10">
          <div className="customList">
            {severalDapp.map((item) => (
              <h6 key={item} className="mb-3">
                {item}
              </h6>
            ))}
          </div>
        </div>
      </section>
      <div className="relative lg:block md:hidden sm:hidden z-10 ">
        <BgMiddle className=" absolute bgMiddle" />
      </div>
      <section className="relative z-8">
        <Tabs
          className=" text-sm text-blue200"
          style={{
            fontFamily: "Spartan-SemiBold",
          }}
        >
          <TabList className=" lg:px-60 sm:px-5 sm:text-xs">
            {" "}
            {tabs.map((tab) => (
              <Tab key={tab.tabName} onClick={() => handleActivetab(tab)}>
                {tab.tabName}
              </Tab>
            ))}
          </TabList>

          {tabs.map((tab) => (
            <TabPanel
              className="bg-darkBackground lg:px-60 sm:px-5 "
              key={tab.tabName}
            >
              <div className="lg:w-3/5 md:w-3/5 sm:w-full">
                <h2 className="text-2xl text-blue100 lg:py-5">{tab.tabName}</h2>
                <p className="leading-6">{tab.tabDrescription}</p>
                <div
                  className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 
                  gap-10 py-10 "
                  style={{
                    fontFamily: "Spartan-Medium",
                  }}
                >
                  <div className="">
                    <details className="dotList" open>
                      <summary
                        className="text-2xl text-blue100 
                      py-2 sm:text-lg "
                      >
                        Benefits:
                      </summary>
                      {tab.tabBenefits.map((item) => (
                        <li className="leading-5" key={item}>
                          {item}{" "}
                        </li>
                      ))}
                    </details>
                  </div>
                </div>
              </div>
              <div className="pb-20 relative">
                <p className="text-2xl text-blue100 py-5 ">
                  {" "}
                  {tab.tabTrackRecord.length > 0 && `Our track record`}
                </p>
                <div
                  className="grid lg:grid-cols-3 lg:gap-10 md:grid-cols-3
                md:grid md:gap-5 sm:hidden "
                >
                  {tab.tabTrackRecord.map((record) => (
                    <div className="content " key={record[2]}>
                      <a
                        href={record[2]}
                        target={`${record[3] === "newWindow" ? "_blank" : ""}`}
                        rel="noreferrer"
                      >
                        <img
                          src={record[0]}
                          alt={record[4]}
                          className="front "
                        />
                      </a>
                      <a
                        href={record[2]}
                        target={`${record[3] === "newWindow" ? "_blank" : ""}`}
                        rel="noreferrer"
                      >
                        <img src={record[1]} alt={record[4]} className="back" />
                      </a>
                    </div>
                  ))}
                </div>

                <div className="lg:hidden md:hidden relative sm:slider slider">
                  <div className=" slides">
                    {tab.tabTrackRecord.map((record) => (
                      <div key={record[2]}>
                        {currentTrack === record[2] && (
                          <div
                            className="content slide"
                            id={record[2]}
                            key={record[2]}
                          >
                            <a
                              target={`${
                                record[3] === "newWindow" ? "_blank" : ""
                              }`}
                              href={record[2]}
                              rel="noreferrer"
                            >
                              <img
                                src={record[0]}
                                alt={record[4]}
                                className="front "
                              />
                            </a>
                            <a
                              target={`${
                                record[3] === "newWindow" ? "_blank" : ""
                              }`}
                              href={record[2]}
                              rel="noreferrer"
                            >
                              <img
                                src={record[1]}
                                alt={record[4]}
                                className="back"
                              />
                            </a>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  {tab.tabTrackRecord.length > 1 && (
                    <div className="slider_div mt-3">
                      {tab.tabTrackRecord.map((record) => (
                        <button
                          key={record[2]}
                          className={`image_slider ${
                            currentTrack === record[2]
                              ? "bg-blue"
                              : "bg-blue200"
                          }`}
                          type="button"
                          onClick={() => setCurrentTrack(record[2])}
                        >
                          {}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <TabList className="lg:inline-flex md:inline-flex text-blue200 sm:hidden">
                <p className="pr-5 mt-4 text-blue100">
                  {" "}
                  Learn more about other services:{" "}
                </p>
                {tabs.map((tab) =>
                  tab.tabName !== activeTab ? (
                    <Tab
                      onClick={() => setActiveTab(tab.tabName)}
                      key={tab.tabName}
                    >
                      {tab.tabName}{" "}
                      {tab.tabName !== "Ethereum CLI" && (
                        <span className="ml-3">|</span>
                      )}
                    </Tab>
                  ) : (
                    ""
                  )
                )}
              </TabList>
            </TabPanel>
          ))}
        </Tabs>
      </section>
      <section className=" py-20 lg:px-60 md:px-10 sm:px-5">
        <p
          style={{ fontFamily: "Spartan-SemiBold", fontSize: "14px" }}
          className="tracking-wider text-blue200 lg:px-0 md:px-10"
        >
          {" "}
          JOIN US AT ANY STAGE WITH YOU PROJECT
        </p>
        <p className="text-3xl text-blue100 mt-3 lg:px-0 md:px-0">
          {" "}
          Our lean execution philosophy
        </p>
        <JoinUs className="w-full mt-5 h-full sm:hidden lg:block md:block" />
        <JoinUsMobile className="lg:hidden md:hidden mt-10 w-full h-full" />
      </section>
      <section className="lg:px-40 m-auto">
        <ContactUs />
      </section>
    </div>
  );
}

export default DeveloperTools;
