/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import linkedin from "../../assets/images/linkedIn.png";
import { ReactComponent as RightArrowGrey } from "../../assets/images/right_arrow_grey.svg";
import { ReactComponent as LeftArrowGrey } from "../../assets/images/left_arrow_grey.svg";
import { ReactComponent as RightArrowWhite } from "../../assets/images/right_arrow_white.svg";
import { ReactComponent as LeftArrowWhite } from "../../assets/images/left_arrow_white.svg";
import { ReactComponent as ArrowYellow } from "../../assets/images/arrowYellow.svg";

import { content } from "./content";
import OurTeamMobile from "./ourTeamMobile";

function Index() {
  const [displayContent, setDisplayContent] = useState(content[0]);
  const [currentContent, setCurrentContent] = useState(0);

  const next = () => {
    if (currentContent < content.length - 1)
      setCurrentContent(currentContent + 1);
  };

  const previous = () => {
    if (currentContent > 0) setCurrentContent(currentContent - 1);
  };

  useEffect(() => {
    setDisplayContent(content[currentContent]);
  }, [currentContent]);
  return (
    <div className="lg:mx-20 lg:px-10  m-auto mb-10 sm:px-5  lg:block md:block">
      <div>
        <p className="text-3xl text-blue100 py-6"> Meet our team</p>
        <p
          className="tracking-widest text-sm  text-blue200 semiFont"
          style={{
            fontFamily: "Spartan-Medium",
          }}
        >
          {" "}
          WE ARE A TEAM OF{" "}
        </p>
        <p
          className="text-lg py-2 text-blue100 mb-6 "
          style={{
            fontFamily: "Spartan-Medium",
          }}
        >
          {" "}
          80+ blockchain specialists, 25+ dev teams & 43+ projects running
        </p>
      </div>
      <div className="lg:grid md:grid lg:grid-cols-4 md:grid-cols-3 sm:hidden gap-10">
        {content.map((item) => (
          <div key={item.name} className="teamMember">
            <img
              src={item.image}
              alt={item.imageAlt}
              style={{ borderRadius: item.borderRadius }}
            />
            <div>
              <p
                className="text-base text-blue100 pt-3 pb-1 mb-2"
                style={{
                  fontFamily: "Spartan-SemiBold",
                }}
              >
                {item.name}
              </p>
              <p
                className="text-sm text-blue200 m-0 p-0"
                style={{
                  fontFamily: "Spartan-Medium",
                }}
              >
                {item.role}
              </p>
              <p
                className="text-xs text-blue200 m-0 p-0 mt-2"
                style={{
                  maxWidth: "183px",
                  fontFamily: "Spartan-Medium",
                }}
              >
                {item.description}
              </p>
              <div className="flex pt-2 ">
                <a href={`${item.linkedIn}`} target="_blank" rel="noreferrer">
                  <img src={linkedin} alt="linkedin" className="mr-2" />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="sm:block lg:hidden, md:hidden">
        <div className="flex  sm:basis-1/3 my-3 smallText ">
          {currentContent === 0 && (
            <LeftArrowGrey
              onClick={previous}
              className="cursor-pointer mr-3 smallArrow"
            />
          )}
          {currentContent > 0 && (
            <LeftArrowWhite
              onClick={previous}
              className="cursor-pointer mr-3 smallArrow"
            />
          )}
          <span
            className={`mt-1 ${
              currentContent + 1 > 1 ? "text-blue100" : "text-blue300"
            }`}
          >
            {`${currentContent < 9 ? "0" : ""}${currentContent + 1}`}
          </span>
          <span className="text-blue300 px-1 mt-1 ">/</span>
          <span
            className={`mt-1 ${
              currentContent + 1 < content.length
                ? "text-blue100"
                : "text-blue300"
            }`}
          >{`0${content.length}`}</span>

          {displayContent.key === content.length && (
            <RightArrowGrey
              onClick={next}
              className="cursor-pointer ml-3 smallArrow"
            />
          )}

          {currentContent < content.length - 1 && (
            <RightArrowWhite
              onClick={next}
              className="cursor-pointer ml-3 smallArrow"
            />
          )}
        </div>
        <div className="z-10">
          <OurTeamMobile {...displayContent} />
        </div>
      </div>
      <div className="my-10">
        <p
          className="text-xs text-blue200 tracking-wider pt-3"
          style={{
            fontFamily: "Spartan-SemiBold",
          }}
        >
          JOIN US
        </p>
        <div className="lg:flex md:flex sm:block">
          <p
            className="text-2xl text-blue100 mr-5 sm:mb-8"
            style={{
              fontFamily: "Spartan-Medium",
            }}
          >
            {" "}
            Do you want to be part of our team?{" "}
          </p>
          <Link
            to="/careers"
            className="join py-5 px-10 tracking-wider lg:h-16 md-h-16"
          >
            <button
              type="button"
              className="btnText tracking-wider"
              style={{ fontSize: "12px", fontFamily: "Spartan" }}
            >
              JOIN
              <ArrowYellow className="inline ml-2 mb-1 h-full pb-0" />
            </button>
          </Link>{" "}
        </div>
      </div>
    </div>
  );
}

export default Index;
