import { ReactComponent as partner1 } from "../assets/community/partner-01.svg";
import { ReactComponent as partner2 } from "../assets/community/partner-02.svg";
import { ReactComponent as partner3 } from "../assets/community/partner-03.svg";
import { ReactComponent as partner4 } from "../assets/community/partner-04.svg";
import { ReactComponent as partner5 } from "../assets/community/partner-05.svg";
import { ReactComponent as partner6 } from "../assets/community/partner-06.svg";
import { ReactComponent as partner7 } from "../assets/community/partner-07.svg";
import Card1 from "../assets/community/cards/card-1.jpg";
import Card2 from "../assets/community/cards/card-2.jpg";
import Card3 from "../assets/community/cards/card-3.jpg";
import Card4 from "../assets/community/cards/card-4.jpg";
import Card5 from "../assets/community/cards/card-5.jpg";
import Card6 from "../assets/community/cards/card-6.jpg";
import Card7 from "../assets/community/cards/card-7.jpg";
import Card8 from "../assets/community/cards/card-8.jpg";
import { ReactComponent as Slider11 } from "../assets/community/slider1/slider1-1.svg";
import { ReactComponent as Slider12 } from "../assets/community/slider1/slider1-2.svg";
import { ReactComponent as Slider13 } from "../assets/community/slider1/slider1-3.svg";
import { ReactComponent as Slider14 } from "../assets/community/slider1/slider1-4.svg";
import { ReactComponent as Slider15 } from "../assets/community/slider1/slider1-5.svg";
import { ReactComponent as Slider21 } from "../assets/community/slider2/slider2-1.svg";
import { ReactComponent as Slider22 } from "../assets/community/slider2/slider2-2.svg";
import { ReactComponent as Slider23 } from "../assets/community/slider2/slider2-3.svg";
import { ReactComponent as Slider24 } from "../assets/community/slider2/slider2-4.svg";
import { ReactComponent as Slider25 } from "../assets/community/slider2/slider2-5.svg";
import { ReactComponent as Slider31 } from "../assets/community/slider3/slider3-1.svg";
import { ReactComponent as Slider32 } from "../assets/community/slider3/slider3-2.svg";
import { ReactComponent as Slider33 } from "../assets/community/slider3/slider3-3.svg";
import { ReactComponent as Slider34 } from "../assets/community/slider3/slider3-4.svg";
import { ReactComponent as Slider35 } from "../assets/community/slider3/slider3-5.svg";

// eslint-disable-next-line import/prefer-default-export
export const content = {
  hero: {
    title: "Protofire Web3 Community",
    description:
      "Become part of the blockchain engineering collective and work with the founders of Gnosis, Graph, IoTeX, Ocean, and more.",
    partners: [
      partner1,
      partner2,
      partner3,
      partner4,
      partner5,
      partner6,
      partner7,
    ],
  },
  whoWeAre: {
    title: "Who We Are",
    text: [
      "We work on Web3 projects and dapps together with The Graph, Chainlink, Gnosis, Aave, MakerDAO, Ocean Protocol, IoTeX and others.",
      "Our community is comprised of project teams and individuals. Join a team, fund a team, propose to launch a project, or stay part of the community as an individual member sharpening your Web3 knowledge, haunting bounties, or just communicating with professionals!",
    ],
    title2: "Who can join?",
    text2: [
      "ANYONE interested in Web3 programming & development can join our Web3 community!",
      "We love meeting people and knowledge sharing, and we definitely will be happy to see you on board!",
    ],
  },
  oppotunities: {
    title: "Opportunities",
    description:
      "We create opportunities for you. Make use of them to the utmost.",
    cards: [
      {
        img: Card1,
        title: "learn news & insights",
        description:
          "Sign up for our community & crypto world news, newsletters, useful GitHub repos, videos, AMAs, and memes. Attend hackathons & webinars from Protofire and Gnosis, Maker & Ocean.",
      },
      {
        img: Card2,
        title: "study at protogardens",
        description:
          "Get toolboxes, access must-have libraries, learn best practices, read articles, go to discounted blockchain courses, and access free events from Protofire!",
      },
      {
        img: Card3,
        title: "contribute to web3",
        description:
          "Jump in challenges for engineers from our teams. Work on the best projects in the industry. Let’s do meaningful things together!",
      },
      {
        img: Card4,
        title: "get paid for referrals",
        description:
          "Invite peers to the Protofire for a reward! If they successfully pass our technical interview and accept the job offer, we will pay you a solid payout via BMC, Avalanche, or Polygon.",
      },
      {
        img: Card5,
        title: "enrich recruiters",
        description:
          "Do you know great recruiters or do you recruit yourself? We have got special offers for them! Invite recruiters to join the community, and let them make easy profits, too.",
      },
      {
        img: Card6,
        title: "accept a most wanted job",
        description:
          "The Most Wanted jobs are our hot-rated vacancies. Be attentive, check your mail for updates regularly. These jobs come with an inspiring welcome bonus. ;)",
      },
      {
        img: Card7,
        title: "join protofire full-time!",
        description:
          "Go through a technical interview to get a full-time job offer! Accept it any time, though we surely wish to connect asap. You get a USDC/ USD/ token salary and a share of tokens, with monthly staking and cash-out options.",
      },
      {
        img: Card8,
        title: "protovtoken and profit-sharing",
        description:
          "ProtoVToken is the Protofire's original token. Any full member of the community gains a share in ProtoVTokens. The earlier you join the community, the larger your portfolio of shares will grow!",
      },
    ],
  },
  teams: {
    title: "Our Teams",
    slider1: [Slider11, Slider12, Slider13, Slider14, Slider15],
    slider2: [Slider21, Slider22, Slider23, Slider24, Slider25],
    slider3: [Slider31, Slider32, Slider33, Slider34, Slider35],
  },
  join: {
    title: "Join Us",
    description:
      "You’re here for a reason. Let’s not waste time and collab! Drop us your contact information, we’ll contact you back as soon as we can to give you the best experience.",
  },
};
