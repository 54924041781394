/* eslint-disable react/no-unescaped-entities */
function Purposes() {
  return (
    <div
      className="text-sm text-blue200 leading-5 py-10"
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <h2 className="text-blue100 text-3xl">
        2.3. Purposes and legal basis for data processing
      </h2>
      <p>
        Protofire is gathering data via this service with a view to improve the
        development of our products or services. Data gathering is conducted on
        the basis of our or third party’s legitimate interests, or with your
        consent.
      </p>
      <p>
        User data collected allow Protofire to provide our Services and is
        employed in a variety of our activities which correspond our legitimate
        interest, including:
      </p>
      <ul className="dotList">
        <li>
          enabling analytics to draw valuable insights for smart decision
          making;
        </li>
        <li>contacting users;</li>
        <li>managing a user database;</li>
        <li>enabling commenting across the content delivered;</li>
        <li>handling payments;</li>
        <li>preventing spam;</li>
        <li>
          improving user experience (e.g., delivering highly personalized
          content suggestions) and the services delivered (e.g., a subscription
          service), etc;
        </li>
        <li>
          providing information related to the changes introduced to our
          Customer Terms of Service, Privacy Policy (including the Cookie
          Policy), or other legal agreements;
        </li>
        <li>
          maintenance and support activities of a database and or data, stored
          in our CRM system.
        </li>
      </ul>

      <h2 className="text-blue100 text-3xl py-10">
        2.4. Data retention period
      </h2>
      <p>
        We set a retention period for your data—collected from our websites—to 6
        years. We gather data to improve our services and the products we
        deliver. The retention period from our partners is set forth by them in
        their privacy policies.
      </p>
      <h1 className="text-blue100 text-3xl py-10">2.5. Data recipients</h1>
      <p>
        We do not transfer the gathered data to third parties, apart from the
        cases described in General data processing section or in this Section,
        as well as cases stipulated in our third partners privacy policies.
      </p>
    </div>
  );
}

export default Purposes;
