/* eslint-disable @typescript-eslint/no-shadow */
import "../tab.css";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { ReactComponent as OvalCareerMobile2 } from "../../assets/career/ovalCareerMobile2.svg";
import { ReactComponent as OvalCareerBig } from "../../assets/career/ovalCareerBig.svg";
import {
  aboutSafe,
  images,
  networksColOne,
  networksColTwo,
  technologyStack,
  trackRecord,
  whatWeProvide1,
  whatWeProvide2,
} from "./content";
import { ReactComponent as BgMiddle } from "../../assets/career/bgMiddle.svg";
import ContactUs from "../../contactUs/contactUs";
import ivan from "../../assets/images/IvanB.png";

const description1 = `
  Protofire has successfully deployed Safe to >20 blockchain ecosystems, 
  including Cronos, Moonbeam, Harmony, Astar, Oasis, Mantle, zkSync,
  zkScroll, Linea, Holesky, and others becoming a proven expert in the Safe 
  environment setup and maintenance.
`;

const description2 = `
  Protofire is a leading blockchain collective for fast and secure
  Safe wallet deployment. Potofire has been a trusted partner of
  Safe (previously Gnosis Safe) collaborating with their developers
  on the design and implementation of numerous functionalities for
  Safe.
`;

const description3 = `
  The Protofire developer team has been named the Safe Guardian 🔰
  for stewarding the SAFE ecosystem and protocol and aligning Safe’s
  vision to drive its adoption of smart contract accounts.
`;

function SafeDeployment() {
  const [recordsToShow, setRecordsToShow] = useState(6);
  const [recordsToShowMobile, setRecordsToShowMobile] = useState(2);

  const onShowMore = () => {
    setRecordsToShow(recordsToShow + 6);
  };

  const onShowMoreMobile = () => {
    setRecordsToShowMobile(recordsToShowMobile + 2);
  };

  return (
    <div className="lg:px-0  md:px-10 py-10">
      <Helmet>
        <title>Safe Deployment | Protofire</title>
        <meta
          name="description"
          content="Get a Safe multisignature wallet deployed to your blockchain ecosystem securely within a month. Having questions? Get in touch for technical details."
        />
        <meta
          name="keywords"
          content="programming in solidity, solidity programming language, safe wallet, gnosis safe, blockchain wallet, multisig wallet, crypto wallet, crypto wallet app"
        />
      </Helmet>
      <section
        className=" lg:flex md:flex lg:px-60 md:px-20 text-blue200 relative z-10
        text-xs  pt-10 sm:px-5 "
        style={{
          fontFamily: "Spartan-Medium",
        }}
      >
        <Link to="/" className=" mr-1 text-blue200">
          Home
        </Link>
        /
        <Link to="/services" className="ml-1 mr-1 text-blue200">
          Services
        </Link>
        /
        <Link to="/services" className="ml-1 text-blue100">
          Safe Deployment
        </Link>
      </section>
      <div className="relative lg:block md:hidden sm:hidden z-1 ">
        <OvalCareerBig className=" absolute ovalBigServicePages1" />
      </div>
      <div className="relative lg:hidden md:hidden sm:block z-1 ">
        <OvalCareerMobile2 className=" absolute bgOvalServicePages2" />
      </div>
      <section className="relative z-10 overflow-x-clip	">
        <div className="max-w-3xl">
          <p
            className="sm:w-full sm:text-3xl
        sm:px-5 md:px-10 lg:pl-60 text-4xl pt-20 text-blue100"
          >
            {" "}
            Safe
          </p>
          <div
            className=" text-blue200 sm:w-full sm:px-5 md:px-10 lg:px-60 xl:pr-0 leading-6 pb-20"
            style={{ fontFamily: "Spartan-Medium" }}
          >
            <div>{description1}</div>
            <a
              href="#contact"
              style={{ fontSize: "12px", fontFamily: "Spartan" }}
            >
              <button
                type="button"
                className="px-10 py-5 my-10 tracking-wider text-blue100 btn"
              >
                READY TO DEPLOY?
              </button>
            </a>
          </div>
          <img
            src={images.safeDeployment}
            alt="safe deployment"
            className=" lg:block sm:hidden mix-blend-lighten absolute sm:-right-32 2xl:-right-12 top-0 sm:max-w-5xl 2xl:max-w-7xl"
          />
        </div>
      </section>
      <section
        style={{ fontFamily: "Spartan-Medium" }}
        className="text-blue200 md:pl-10 lg:px-0
       lg:pb-20 sm:px-5 -mt-12 lg:pl-60 m-auto relative z-10"
      >
        <div
          className="grid 2xl:mt-20 lg:grid-cols-2
         md:grid-cols-1 sm:grid-cols-1 sm:mt-0 gap-10"
        >
          <div className="lg:w-4/5 sm:w-full md:w-full">
            <p>{description2}</p>
          </div>
          <div className="lg:w-4/5 sm:w-full md:w-full">
            <p>{description3}</p>
          </div>
        </div>
      </section>
      <div className="relative lg:block md:hidden sm:hidden z-10 ">
        <BgMiddle className=" absolute bgMiddle" />
      </div>
      <div className="text-blue200  m-auto relative z-10 bg-darkBackground">
        <div
          className="md:px-10 md:pl-10
       pb-20 sm:px-5 -mt-12 lg:pl-60"
        >
          <div
            className="grid lg:grid-cols-2 pt-12
         md:grid-cols-1 sm:grid-cols-1 gap-10"
          >
            <div className="lg:w-4/5 sm:w-full md:w-full -mt-12">
              <img
                src={images.safeDeploymentCircle}
                alt="deployed to these networks"
              />
            </div>
            <div
              className="lg:w-4/5 sm:w-full md:w-full"
              style={{ fontFamily: "Spartan-Medium" }}
            >
              <p className="text-xl md:text-3xl text-blue100">
                We deployed Safe to these networks
              </p>
              <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-10">
                <div className="dotList">
                  {networksColOne.map((item) => (
                    <li className="leading-6" key={item}>
                      {item}
                    </li>
                  ))}
                </div>
                <div className="dotList">
                  {networksColTwo.map((item) => (
                    <li className="leading-6" key={item}>
                      {item}{" "}
                    </li>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="text-center mb-5"
          style={{ fontFamily: "Spartan-Medium" }}
        >
          <div className="md:text-xl">FEATURES</div>
          <div className="text-blue100 md:text-3xl">What we can provide</div>
        </div>
        <section
          className=" md:pl-10 lg:px-0
       lg:pb-20 sm:px-5 py-10 lg:pl-60 m-auto relative z-10"
        >
          <div
            className="grid lg:grid-cols-2
         md:grid-cols-1 sm:grid-cols-1 gap-10"
          >
            <div className="lg:w-4/5 sm:w-full md:w-full">
              <div className="flex flex-wrap text-xs text-blue ">
                <img src={whatWeProvide1.icon} alt={whatWeProvide1.name} />
              </div>
              <p
                className="text-blue100 text-xl md:text-xl xl:text-xl"
                style={{
                  fontFamily: "Spartan-Medium",
                }}
              >
                {whatWeProvide1.name}
              </p>

              <p
                className="py-4 text-blue200 leading-7"
                style={{
                  fontFamily: "Spartan-Medium",
                }}
              >
                {whatWeProvide1.description}
              </p>
            </div>
            <div className="w-full  lg:block sm:hidden md:block">
              <img
                src={whatWeProvide1.image}
                alt={whatWeProvide1.name}
                className="w-full"
              />{" "}
            </div>
          </div>
          <div
            className="mt-20 grid lg:grid-cols-2
         md:grid-cols-1 sm:grid-cols-1 gap-20"
          >
            <div className="w-full  lg:block sm:hidden md:block">
              <img
                src={whatWeProvide2.image}
                alt={whatWeProvide2.name}
                className="w-full"
              />{" "}
            </div>
            <div className="lg:w-4/5 sm:w-full md:w-full">
              <div
                className="flex flex-wrap text-xs text-blue "
                style={{
                  fontFamily: "Spartan-Medium",
                }}
              >
                <img src={whatWeProvide2.icon} alt={whatWeProvide2.name} />
              </div>
              <p className="text-blue100 text-xl md:text-xl xl:text-xl">
                {whatWeProvide2.name}
              </p>
              <p
                className="py-4 text-blue200 leading-7"
                style={{
                  fontFamily: "Spartan-Medium",
                }}
              >
                {whatWeProvide2.description}
              </p>
            </div>
          </div>
        </section>
        <section
          className=" md:pl-10
       lg:pb-20 sm:px-5 py-10 lg:px-60 m-auto relative z-10"
          style={{ fontFamily: "Spartan-Medium" }}
        >
          <p className="text-xl md:text-3xl text-blue100">About Safe</p>
          <div
            className="grid lg:grid-cols-5 sm:grid-cols-1 md:grid-cols-5
             lg:py-0 gap-5 lg:gap-10 lg:col-span-4"
          >
            {aboutSafe.map((item) => (
              <div className="lg:py-5 lg:p-0 icons text-start" key={item.name}>
                <img src={item.icon} alt={item.name} className="" />
                <p
                  className="text-blue100 text-start text-sm"
                  style={{ fontFamily: "Spartan-Medium" }}
                >
                  {item.name}
                </p>
              </div>
            ))}
          </div>
        </section>

        <section
          className=" md:pl-10
       lg:pb-20 sm:px-5 lg:px-60 m-auto relative z-10"
          style={{ fontFamily: "Spartan-Medium" }}
        >
          <div className=" lg:col-span-2 md:col-span-2">
            <p className="text-blue100 text-3xl py-3">
              {technologyStack.title}{" "}
            </p>
            <div
              className="grid lg:grid-cols-5 sm:grid-cols-1 md:grid-cols-5
             lg:py-0 gap-5 lg:gap-10 lg:col-span-4"
              style={{
                fontFamily: "Spartan-Medium",
              }}
            >
              {technologyStack.columns.map((column) => (
                <div>
                  <p className="text-sm">{column.title}</p>
                  <div className="dotList  text-sm">
                    {column.list.map((list) => (
                      <li key={list} className="leading-6 text-blue200">
                        {list}
                      </li>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section
          className=" md:pl-10 sm:pb-10
       lg:pb-20 sm:px-5 lg:px-60 m-auto relative z-10"
        >
          <p
            className="text-4xl text-blue100 py-5 "
            style={{ fontFamily: "Spartan-Medium" }}
          >
            {" "}
            {trackRecord.length > 0 && `Our track record`}
          </p>
          <div
            className="grid lg:grid-cols-3 lg:gap-10 md:grid-cols-3
                md:grid md:gap-5 hidden"
          >
            {trackRecord.slice(0, recordsToShow).map((record) => (
              <div className="content " key={record.title}>
                <img
                  src={record.img}
                  alt={record.description}
                  className="front "
                />
                <img
                  src={record.img}
                  alt={record.description}
                  className="back"
                />
                <a
                  className="safeLink"
                  target={record.target}
                  href={record.safeUrl}
                  rel="noreferrer"
                >
                  safe
                </a>
                <a
                  className="mediaLink"
                  target={record.target}
                  href={record.mediaUrl}
                  rel="noreferrer"
                >
                  media
                </a>
              </div>
            ))}
          </div>
          {recordsToShow < trackRecord.length && (
            <div className="md:block sm:hidden ">
              <button
                type="button"
                className="px-10 py-5 my-10 tracking-wider text-blue100 btn"
                onClick={onShowMore}
                style={{ fontSize: "12px", fontFamily: "Spartan" }}
              >
                SHOW MORE
              </button>
            </div>
          )}
          <div className="grid md:hidden grid-cols-1 gap-5">
            {trackRecord.slice(0, recordsToShowMobile).map((record) => (
              <div className="content " key={record.title}>
                <img
                  src={record.img}
                  alt={record.description}
                  className="front "
                />
                <img
                  src={record.img}
                  alt={record.description}
                  className="back"
                />
                <a
                  className="safeLink"
                  target={record.target}
                  href={record.safeUrl}
                  rel="noreferrer"
                >
                  safe
                </a>
                <a
                  className="mediaLink"
                  target={record.target}
                  href={record.mediaUrl}
                  rel="noreferrer"
                >
                  media
                </a>
              </div>
            ))}
          </div>
          {recordsToShowMobile < trackRecord.length && (
            <div className="md:hidden sm:block ">
              <button
                type="button"
                className="px-10 py-5 my-10 tracking-wider text-blue100 btn"
                onClick={onShowMoreMobile}
                style={{ fontSize: "12px", fontFamily: "Spartan" }}
              >
                SHOW MORE
              </button>
            </div>
          )}
        </section>
      </div>
      <section id="contact" className="mt-20 lg:px-40 m-auto">
        <ContactUs
          contactName="Ivan Bandaryk"
          contactRole="Chief Technical Officer"
          linkedin="https://www.linkedin.com/in/ivan-bandaryk/?originalSubdomain=pl"
          telegram="https://telegram.org/"
          image={ivan}
        />
      </section>
    </div>
  );
}

export default SafeDeployment;
