import { useEffect } from "react";
import { generateUniqueID } from "web-vitals/dist/modules/lib/generateUniqueID";
import { ToastContainer } from "react-toastify";
import { content } from "./content";
import { ReactComponent as AboutImg } from "../assets/community/about-img.svg";
import "./community.css";
import { animate1, animate2, animate3 } from "./helper";
import joinBg from "../assets/community/s-join-bg.jpg";
import JoinUs from "./joinUs";
import Join from "./join";

function Community() {
  useEffect(() => {
    animate1();
    animate2();
    animate3();
  }, []);

  return (
    <>
      <section
        id="hero"
        className="bg-community-hero-mobile lg:bg-community-hero bg-right bg-cover bg-no-repeat pt-[175px] pb-[64px]"
      >
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-16">
          <div className="max-w-[777px] mb-[120px] lg:mb-[210px]">
            <h1
              className="text-[36px] leading-[40px] md:test-[72px] md:leading[80px] text-white"
              style={{ fontFamily: "Epilogue-Bold" }}
            >
              {content.hero.title}
            </h1>
            <p className="text-white text-[20px] leading-8 mb-[72px]">
              {content.hero.description}
            </p>
            <Join />
          </div>
          <div className="flex flex-row justify-around flex-wrap gap-8">
            {content.hero.partners.map((Partner) => (
              <a
                href="#join"
                className="color-solhintGray mx-8"
                key={generateUniqueID()}
              >
                <Partner className="fill-white opacity-40 hover:opacity-100 hover:fill-[#01e8f7] transition duration-300" />
              </a>
            ))}
          </div>
        </div>
      </section>
      <section
        id="whoweare"
        className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-16 mt-[164px] mb-8"
      >
        <div className="flex gap-16">
          <div>
            <h2
              className="text-[56px] leading-[64px] mb-8"
              style={{ fontFamily: "Epilogue-Bold" }}
            >
              {content.whoWeAre.title}
            </h2>
            {content.whoWeAre.text.map((text) => (
              <p
                className="text-[18px] leading-[28px] mt-0 mb-4"
                key={generateUniqueID()}
              >
                {text}
              </p>
            ))}
            <h3
              className="text-[32px] leading-[40px] mt-[48px] mb-4"
              style={{ fontFamily: "Epilogue-Bold" }}
            >
              {content.whoWeAre.title2}
            </h3>
            {content.whoWeAre.text2.map((text) => (
              <p
                className="text-[18px] leading-[28px] mt-0 mb-4"
                key={generateUniqueID()}
              >
                {text}
              </p>
            ))}
            <div className="mt-12">
              <a
                href="#join"
                className="bg-gradient-to-r from-[#f54703] to-[#fac902] text-transparent bg-clip-text text-[24px] leading-[32px]"
                rel="noreferrer"
                style={{ fontFamily: "Epilogue-Bold" }}
              >
                JOIN US →
              </a>
            </div>
          </div>
          <div className="hidden lg:block">
            <AboutImg />
          </div>
        </div>
      </section>
      <section
        id="perks"
        className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-16 my-8"
      >
        <div className="flex gap-16 mb-16">
          <div>
            <h3
              className="text-[32px] leading-[40px] mt-[48px] mb-4"
              style={{ fontFamily: "Epilogue-Bold" }}
            >
              {content.oppotunities.title}
            </h3>
            <p className="text-[18px] leading-[28px] mt-0 mb-4">
              {content.oppotunities.description}
            </p>
          </div>
        </div>
        <div className="grid gap-8 grid-cols-1 lg:grid-cols-2">
          {content.oppotunities.cards.map((card) => (
            <a
              href="#join"
              style={{ backgroundImage: `url(${card.img})` }}
              className="bg-cover bg-no-repeat text-white pt-[140px] px-[40px] pb-[80px]"
              key={card.title}
            >
              <h3
                className="uppercase text-[24px] leading-[32px]"
                style={{ fontFamily: "Epilogue-Bold" }}
              >
                {card.title}
              </h3>
              <p className="text-[16px] leading-[24px] mt-4 mb-0">
                {card.description}
              </p>
            </a>
          ))}
        </div>
      </section>
      <section
        id="partners"
        className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-16 my-8"
      >
        <h2
          className="text-[56px] leading-[64px] mb-16"
          style={{ fontFamily: "Epilogue-Bold" }}
        >
          {content.teams.title}
        </h2>
        <div className="marquee">
          <ul className="marquee-content slider1">
            {content.teams.slider1.map((Slide) => (
              <li key={generateUniqueID()}>
                <Slide />
              </li>
            ))}
          </ul>
        </div>
        <div className="marquee">
          <ul className="marquee-content slider2 right">
            {content.teams.slider2.map((Slide) => (
              <li key={generateUniqueID()}>
                <Slide />
              </li>
            ))}
          </ul>
        </div>
        <div className="marquee">
          <ul className="marquee-content slider3">
            {content.teams.slider3.map((Slide) => (
              <li key={generateUniqueID()}>
                <Slide />
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section
        id="join"
        style={{ backgroundImage: `url(${joinBg})` }}
        className="bg-cover bg-no-repeat bg-center py-[128px]"
      >
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <h2
            className="text-[56px] leading-[64px] mb-4 text-white"
            style={{ fontFamily: "Epilogue-Bold" }}
          >
            {content.join.title}
          </h2>
          <p className="text-[18px] leading-[28px] mt-0 mb-8 max-w-[860px] text-white">
            {content.join.description}
          </p>
          <JoinUs />
        </div>
      </section>
      <ToastContainer />
    </>
  );
}

export default Community;
