/* eslint-disable react/no-unescaped-entities */

function AdvertisingPartners() {
  return (
    <div
      className="text-sm text-blue200 leading-5 pt-10"
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <h2 className="text-blue100 text-2xl">2.2.2. Advertising partners</h2>
      <p>
        User data may be employed to customize advertising deliverables, such as
        banners and any other types of advertisements to promote our services.
        Sometimes, these marketing deliverables are developed based on user
        preferences. However, not all personal data is used for this purpose.
        Some of the services provided by Protofire may use cookies to identify
        users. The behavioral retargeting technique may also be used to display
        advertisements tailored to user preferences and online behavior,
        including outside Protofire websites. For more information, please check
        the privacy policies of the relevant services.
      </p>
      <p>
        <span className="text-blue100 mr-3 ">
          Meta Audience Network (Meta Platforms, Inc.):
        </span>
        Meta Audience Network is an advertising service that helps to monitor
        and evaluate the efficiency of advertising campaigns launched via
        Facebook. The service is provided by Meta Platforms, Inc. and is subject
        to the company’s privacy policy{" "}
        <a
          href="https://www.facebook.com/about/privacy/"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Meta Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">
          Bing Ads (Microsoft Corporation):
        </span>
        Bing Ads (Microsoft Corporation):GBing Ads is an advertising platform
        for launching and managing advertising campaigns across Bing search and
        Bing’s partner network. The service is provided by Microsoft Corporation
        and is subject to the company’s privacy policy{" "}
        <a
          href="https://privacy.microsoft.com/en-gb/privacystatement"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Microsoft Corporation’s Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">
          Google AdWords (Google LLC) DoubleClick (Google Inc.) / DoubleClick
          Bid Manager / Google DoubleClick Google AdWords and Doubleclick:
        </span>
        are advertising services that enable efficient interaction with
        potential customers by suggesting relevant advertisements across Google
        Search, as well as Google’s partner networks. Google AdWords and
        Doubleclick are easily integrated with any other Google services—for
        example, Google Analytics—and help to process user data gathered by
        cookies. The services are provided by Google Inc. and are subject to the
        company’s privacy policy{" "}
        <a
          href="https://www.google.com/intl/en/policies/privacy/"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Google’s Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">
          LinkedIn Marketing Solutions / LinkedIn Ads (LinkedIn Corporation):
        </span>
        LinkedIn Ads allows for tracking the efficiency of advertising campaigns
        launched via LinkedIn. The service is provided by LinkedIn Corporation
        and is subject to the company’s privacy policy{" "}
        <a
          href="https://www.linkedin.com/legal/privacy-policy"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          LinkedIn’s Privacy Policy
        </a>
      </p>
      <p>
        <span className="text-blue100 mr-3 ">
          Twitter Advertising / Twitter Conversion Tracking (Twitter, Inc.):
        </span>
        The Twitter Ads network allows for tracking the efficiency of
        advertising campaigns launched via Twitter. The service is provided by
        Twitter Inc. and is subject to the company’s privacy policy{" "}
        <a
          href="https://twitter.com/en/privacy"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Twitter’s Privacy Policy
        </a>
      </p>
    </div>
  );
}

export default AdvertisingPartners;
