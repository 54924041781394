/* eslint-disable import/prefer-default-export */
import aragonFront from "../../assets/services/banners/aragon_dao_payroll-front.png";
import aragonBack from "../../assets/services/banners/aragon_dao_payroll-back.png";
import dAppps2 from "../../assets/services/banners/armaninos_trust_explorer-front.png";
import dAppps22 from "../../assets/services/banners/armaninos_trust_explorer-back.png";
import dAppps3 from "../../assets/services/banners/colu_network_communities-front.png";
import dAppps33 from "../../assets/services/banners/colu_network_communities-back.png";
import ideation from "../../assets/services/ideation.png";
import quality from "../../assets/services/quality.png";
import prototyping from "../../assets/services/prototyping.png";
import deployment from "../../assets/services/deployment.png";
import development from "../../assets/services/development.png";
import maintenance from "../../assets/services/maintenance.png";
import ui1 from "../../assets/services/banners/cow_protocol_explorer-front.png";
import ui11 from "../../assets/services/banners/cow_protocol_explorer-back.png";
import dex1 from "../../assets/services/banners/cowswap_dex-front.png";
import dex11 from "../../assets/services/banners/cowswap_dex-back.png";
import ui2 from "../../assets/services/banners/gnosis_conditional_tokens-front.png";
import ui22 from "../../assets/services/banners/gnosis_conditional_tokens-back.png";
import dasb1 from "../../assets/services/banners/livepeer_tools-front.png";
import dash11 from "../../assets/services/banners/livepeer_tools-back.png";
import dash2 from "../../assets/services/banners/makerdao_governance-front.png";
import dash22 from "../../assets/services/banners/makerdao_governance-back.png";
import dash3 from "../../assets/services/banners/polkadot_mempool-front.png";
import dash33 from "../../assets/services/banners/polkadot_mempool-back.png";
import dex2 from "../../assets/services/banners/makerdao_keeper_pool-front.png";
import dex22 from "../../assets/services/banners/makerdao_keeper_pool-back.png";
import ui3 from "../../assets/services/banners/opyn_decentralized_insurance-front.png";
import ui33 from "../../assets/services/banners/opyn_decentralized_insurance-back.png";
import dex3 from "../../assets/services/banners/swarm_markets-dex-front.png";
import dex33 from "../../assets/services/banners/swarm_markets-dex-back.png";

export const tabs = [
  {
    tabName: "dApps",
    tabDrescription: `Decentralized applications, also known as dApps, are digital 
      applications or programs that exist and run on a blockchain or a peer-to-peer (P2P) 
      network of computers instead of a single computer. Since they are decentralized, their 
      controlling authorities are the algorithms defined on the underlying code. Decentralized 
      applications can be developed for a variety of purposes, such as gaming, finance, and 
      social media. The areas of dApps use are widespread and include but are not limited 
      to exchange, games, finance, development, storage, high-risk management, wallets, governance 
      management, property, identity management, media, security, energy, insurance, and health.
    `,
    tabUseCases: [
      "Decentralized exchanges",
      "Financial transactions",
      "Microtransactions",
      "Decentralized marketplaces",
      "Clearing and settlement",
      "Supply chain tracking",
      "Property registers",
      "Digital rights",
      "NFTs (Collectibles)",
      "Voting",
      "Personal record",
      "Storage",
    ],
    tabBenefits: [
      "Open-source technology at the core",
      "Decentralized environment",
      "Protocol-based backend",
      "High security of assets",
      "Fast transactions",
      "Anonymity opportunities",
      "Reliability of data records",
    ],
    tabTrackRecord: [
      [
        aragonFront,
        aragonBack,
        "/projects/aragon-dapp",
        "sameWindow",
        "Protofire developed the Aragon application that allows users to manage employees, authorize payments in different tokens, and view all the payroll information.",
      ],
      [
        dAppps2,
        dAppps22,
        "/projects/armanino-platform",
        "sameWindow",
        "Protofire helped Armanino in the development of solutions under the TrustExplorer project for accounting, auditing, and reporting that are tailored to the enterprise ecosystem.",
      ],
      [
        dAppps3,
        dAppps33,
        "https://medium.com/protofire-blog/auditing-a-core-smart-contract-of-the-colu-local-network-9fa56da3b09d",
        "newWindow",
        "Protofire developed a dApp for the Colu Network to manage communities that helps to turn financial transactions into new sources of wealth for a community.",
      ],
    ],
  },
  {
    tabName: "DEXes",
    tabDrescription: `A decentralized exchange, or DEX, is a type of cryptocurrency 
      exchange that allows for direct peer-to-peer cryptocurrency transactions without 
      an intermediary. Some examples of DEXes are Uniswap, Curve, and PancakeSwap. There 
      are also centralized exchanges, or CEXes, which happen to have an intermediary 
      company to custody user funds, such as Binance, Coinbase, and Huobi.`,

    tabUseCases: [
      "Decentralized exchanges",
      "Asset management",
      "Analytics and risk management",
      "Compliance and KYC",
      "Decentralized organizations",
      "Derivatives and synthetic assets",
      "Insurance",
      "Borrowing and lending",
      "Payment solutions",
      "Decentralized marketplaces",
    ],
    tabBenefits: [
      "No counterparty risk",
      "An opportunity to trade thousands of tokens",
      "Finances are managed by individuals",
      "Portfolio tracking tools",
      "User and transaction anonymity opportunities",
    ],
    tabTrackRecord: [
      [
        dex1,
        dex11,
        "/projects/cowswap-dex",
        "sameWindow",
        "Protofire helped CoW Protocol to rebrand, develop, and test the CowSwap DEX's interface built on top of the protocol for trading tokens via gas-less orders.",
      ],
      [
        dex2,
        dex22,
        " https://makerdao.com/",
        "newWindow",
        "Protofire contributed to the MakerDAO Keeper Pool that programmatically optimizes its capital efficiency through stabilizing mechanisms. ",
      ],
      [
        dex3,
        dex33,
        "/projects/swarm-dex",
        "sameWindow",
        "Protofire developed the architecture, smart contracts, and front end for Swarm Markets DEX to provide compliant multi-asset DeFi infrastructure.",
      ],
    ],
  },
  {
    tabName: "NFTs",
    tabDrescription: `Non-fungible tokens, also known as NFTs, are financial 
      securities that are digital data stored in a blockchain. NFTs are digital-only 
      objects. The ownership over an NFT is recorded in a blockchain and can be 
      transferred by the owner to another party, allowing NFTs to be traded.
    `,

    tabUseCases: [
      "Artists and content creators in general",
      "Medical records and identity verification",
      "Intellectual property and patents",
      "Academic credentials",
      "Supply chain tracking",
      "Artwork tracking",
      "Gaming industry",
      "Ticketing",
      "Voting",
    ],
    tabBenefits: [
      "Decentralized marketplace for creators",
      "Unique items (Collectibles)",
      "Authenticity of items",
      "Ownership of items",
      "Transferability of items",
      "Immutability of information",
      "Copyright for content creators",
      "Security of the technology",
      "Reselling items for money-making purposes",
    ],
    tabTrackRecord: [],
  },
  {
    tabName: "UX & UI Design",
    tabDrescription: `The goal of User Experience and User Interface design 
      is to improve and simplify the usage of an application. In UX & UI 
      design, we identify and solve issues that users may face in an app. 
      UX design defines the user journey and experience in the product. 
      UI design comes after UX design and involves the creation of 
      user-friendly, aesthetically pleasing interactive interfaces. 
    `,
    tabUseCases: [],
    tabBenefits: [
      "Reduced development costs",
      "Optimized delivery time",
      "Improved customer acquisition",
      "Boosted customer retention and satisfaction",
      "Greater app productivity",
      "User engagement opportunities",
    ],
    tabTrackRecord: [
      [
        ui1,
        ui11,
        "/projects/cowswap-dex",
        "sameWindow",
        "Protofire helped CoW Protocol to rebrand, develop, and test the CowSwap DEX's interface built on top of the protocol for trading tokens via gas-less orders.",
      ],
      [
        ui2,
        ui22,
        "/projects/gnosis-explorer",
        "sameWindow",
        "Protofire helped Gnosis to develop Conditional Tokens Explorer for the Gnosis ecosystem.",
      ],
      [
        ui3,
        ui33,
        "/projects/opyn-platform",
        "sameWindow",
        "Protofire supported Opyn in the development of the user interface of their Decentralized Insurance Platform to enable option sellers and buyers to earn premiums on collateral liquidation.",
      ],
    ],
  },
  {
    tabName: "Dashboards",
    tabDrescription: `Dashboards are visual representations of data 
      that provide critical information on network performance and 
      security. Network monitors identify slow or failing components 
      before they cause problems, while block explorers show the 
      details of all transactions on a blockchain network. Keeping a 
      close eye on these metrics helps users to make informed decisions 
      and keep the network safe.
    `,
    tabUseCases: [],
    tabBenefits: [
      "Maintain full network visibility",
      "Effectively allocate resources",
      "Identify security threats",
      "Deploy new technology",
      "Generate network performance reports",
      "Find performance issues",
    ],
    tabTrackRecord: [
      [
        dasb1,
        dash11,
        "https://medium.com/protofire-blog/protofire-introduces-livepeer-tools-2-0-f5b748a56eb2",
        "newWindow",
        "Protofire developed Livepeer Tools Dashboard for Livepeer.tools2.0 to provide statistical calculations, account summaries, and a variety of status notifications for Delegates.",
      ],
      [
        dash2,
        dash22,
        "/projects/makerdao-dashboard",
        "sameWindow",
        "Protofire built Maker Governance Analytics Dashboard for MakerDAO to provide users with a single location to track the latest updates and view historical data.",
      ],
      [
        dash3,
        dash33,
        "https://medium.com/protofire-blog/monitoring-pending-transactions-in-polkadot-with-mempool-explorer-36e88e8a41ed",
        "newWindow",
        "Protofire delivered Mempool Explorer to Polkadot which monitors transaction hashes, block numbers, extrinsic types, and other temporal data. ",
      ],
    ],
  },
];

export const whatWeProvide = [
  {
    name: "Ideation",
    icone: ideation,
    description: `
      We conceptualize and ideate the architecture, implementation, and productivity of your dApp solution.
    `,
  },
  {
    name: "Prototyping",
    icone: prototyping,
    description: `
      We build a prototype of the solution for you to see what it may look like and make necessary changes.
    `,
  },
  {
    name: "Development",
    icone: development,
    description: `
      We develop the solution based on the agreed prototype under your supervision, known schedule, and budget
    `,
  },
  {
    name: "Quality Assurance",
    icone: quality,
    description: `
      We carry out quality assurance procedures to make sure all features of the decentralized application work as intended.
    `,
  },
  {
    name: "Deployment",
    icone: deployment,
    description: `
      We deploy the dApp to the designated environment under your control, following the technical requirements.
    `,
  },
  {
    name: "Maintenance & Support",
    icone: maintenance,
    description: `
      We maintain the dApp, its features, and related infrastructure and will trigger you if certain decisions are required. 
    `,
  },
];
