// eslint-disable-next-line import/prefer-default-export
export const whatWeDoContent = [
  {
    number: 1,
    title: "Smart Contract Development",
    list: [
      "Ethereum Development",
      "Solana Development",
      "Stablecoin Development",
      "Auditing",
      "Benchmarking",
    ],
    page: "/services/smart-contract",
  },
  {
    number: 2,
    title: "Decentralized Apps",
    list: ["dApps", "DEXes", "NFTs", "UX & UI Design"],
    page: "/services/decentralized-apps",
  },
  {
    number: 3,
    title: "Developer Tools",
    list: [
      "SDKs",
      "APIs",
      "Toolkits",
      "Web & App Mobile Components",
      "Solhint",
      "Ethereum CLI",
    ],
    page: "/services/developer-tools",
  },
  {
    number: 4,
    title: "Integrations",
    list: [
      "Trusted Data Feeds (Oracles)",
      "Indexing and Subgraphs",
      "Dashboard, Network Monitor, Block Explorer",
    ],
    page: "/services/integrations",
  },
  {
    number: 5,
    title: "DevOps",
    list: ["Node Infrastructure Management", "Automation in the Cloud"],
    page: "/services/dev-ops",
  },
  // {
  //   number: 6,
  //   title: "Wallets",
  //   list: [
  //     "Gnosis Safe Deployment",
  //     "Web Wallet Development",
  //     "Mobile Wallet Development",
  //   ],
  //   page: "/services/wallets",
  // },
  {
    number: 6,
    title: "Safe Deployment",
    list: [
      "Custom integrations of Safe Apps",
      "Turn-key deployment",
      "Maintenance of custom Safe forks",
    ],
    page: "/services/safe-deployment",
  },
  {
    number: 7,
    title: "TVL Solutions",
    list: [
      "Research of Liquidity Providers/User Behavior",
      "Audits and Certifications for Oracles and Web2/Web3 Infrastructure",
      "Insurance pools and Safety Module Implementation Using a Whitelabel Solution",
    ],
    page: "/services/tvl-solutions",
  },
];
