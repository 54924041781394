import { Routes, Route } from "react-router-dom";

import Home from "./components/home/home";
import Careers from "./components/careers/Careers";
import About from "./components/about/about";
import Projects from "./components/projects/Projects";
import Services from "./components/services/Services";
import DecentralizedApp from "./components/services/decentralizedApp/DecentralizedApp";
import SmartContract from "./components/services/smartContract/smartContract";
import DeveloperTools from "./components/services/developerTools/developerTools";
import DevOps from "./components/services/devOps/devOps";
import Integrations from "./components/services/integrations/integrations";
// import Wallets from "./components/services/wallets/wallets";
import TvlSolutions from "./components/services/TvlSolutions/TvlSolutions";
import CowswapDex from "./components/projects/cowswapDex/cowswapDex";
import Wallet from "./components/projects/wallet/wallet";
import Indexing from "./components/projects/indexing/indexing";
import Oracle from "./components/projects/oracle/oracle";
import SwarmDex from "./components/projects/swarmDex/swarmDex";
import NodeInfrastructure from "./components/projects/nodeInfrastructure/nodeInfrastructure";
import Dashboard from "./components/projects/dashboard/dashboard";
import Platform from "./components/projects/platform/platform";
import DappInsurance from "./components/projects/dappInsurance/dappInsurance";
import DappPayroll from "./components/projects/dappPayroll/dappPayroll";
import Explorer from "./components/projects/explorer/explorer";
import Smart from "./components/projects/smartContractProxy/smart";
import LauchKit from "./components/projects/lauchKit/lauchKit";
import ContactUs from "./components/contactUs";
import NotFound from "./components/404page/notFound";
import SafeDeployment from "./components/services/safeDeployment/safeDeployment";
import MainLayout from "./layouts/mainLayout/mainLayout";
import SolhintLayout from "./layouts/solhintLayout/solhintLayout";
import Solhint from "./components/projects/solhint/solhint";
import CommunityLayout from "./layouts/communityLayout/communityLayout";
import Community from "./components/community/community";
import ChainlinkDevTools from "./components/projects/chainlink-dev-tools/chainlink-dev-tools";
import SingleButtonSolution from "./components/projects/single-button-solution/single-button-solution";
import MonitoringPlatformForGiga from "./components/projects/monitoring-platform-for-giga/monitoring-platform-for-giga";

function App(): JSX.Element {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="careers" element={<Careers />} />
        <Route path="projects" element={<Projects />} />
        <Route path="services" element={<Services />} />
        <Route path="services/safe-deployment" element={<SafeDeployment />} />
        <Route
          path="services/decentralized-apps"
          element={<DecentralizedApp />}
        />
        <Route path="services/smart-contract" element={<SmartContract />} />
        <Route path="services/developer-tools" element={<DeveloperTools />} />
        <Route path="services/dev-ops" element={<DevOps />} />
        <Route path="services/integrations" element={<Integrations />} />
        {/* <Route path="services/wallets" element={<Wallets />} /> */}
        <Route path="services/tvl-solutions" element={<TvlSolutions />} />
        <Route path="projects/cowswap-dex" element={<CowswapDex />} />
        <Route path="projects/gnosis-safe-wallet" element={<Wallet />} />
        <Route path="projects/the-graph-subgraphs" element={<Indexing />} />
        <Route path="projects/chainlink-integration" element={<Oracle />} />
        <Route
          path="projects/chainlink-dev-tools"
          element={<ChainlinkDevTools />}
        />
        <Route
          path="projects/single-button-solution"
          element={<SingleButtonSolution />}
        />
        <Route
          path="projects/monitoring-for-giga"
          element={<MonitoringPlatformForGiga />}
        />
        <Route path="projects/swarm-dex" element={<SwarmDex />} />
        <Route
          path="projects/filecoin-node-infrastructure"
          element={<NodeInfrastructure />}
        />
        <Route path="projects/makerdao-dashboard" element={<Dashboard />} />
        <Route path="projects/armanino-platform" element={<Platform />} />
        <Route path="projects/opyn-platform" element={<DappInsurance />} />
        <Route path="projects/aragon-dapp" element={<DappPayroll />} />
        <Route path="projects/gnosis-explorer" element={<Explorer />} />
        <Route path="projects/kyber-smart-contract" element={<Smart />} />
        <Route path="projects/ox-launch-kit" element={<LauchKit />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="*" element={<NotFound />} />
        <Route element={<NotFound />} />
      </Route>
      <Route element={<SolhintLayout />}>
        <Route path="projects/solhint" element={<Solhint />} />
      </Route>
      <Route element={<CommunityLayout />}>
        <Route path="community" element={<Community />} />
      </Route>
    </Routes>
  );
}

export default App;
