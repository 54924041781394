import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { content, tableContent } from "./content";
import "./about.css";
import AboutTable from "./aboutTable";
import Numbers from "./Numbers";
import AboutTabs from "./tabs";
import ContactUs from "../contactUs/contactUs";
import MobileTable from "./mobileTable";

import { ReactComponent as OvalCareerMobile2 } from "../assets/career/ovalCareerMobile2.svg";
import { ReactComponent as OvalCareerBig } from "../assets/career/ovalCareerBig.svg";
import { ReactComponent as OvalAbout1 } from "../assets/about/ovalAbout1.svg";
// import { ReactComponent as OvalAbout2 } from "../assets/about/ovalAbout2.svg";

function About() {
  return (
    <div className="lg:px-20 m-auto md:px-10  py-20">
      <div className="lg:px-20">
        <Helmet>
          <title>About Protofire</title>
          <meta
            name="description"
            content="We are crypto and blockchain enthusiasts who love to develop blockchain technology alongside our favorite Web3 projects teams. Let’s collaborate!"
          />
          <meta
            name="keywords"
            content="crypto ledger, blockchain app, blockchain crypto, block chain info, blockchain and development, blockchain dev, blockchain for development, developing on the blockchain, developing a blockchain, dapp"
          />
        </Helmet>
        <div
          className="sm:px-5 flex  text-blue200 text-xs lg:px-20 md:px-10 pb-10"
          style={{
            fontFamily: "Spartan-Medium",
          }}
        >
          <Link to="/" className=" mr-1 text-blue200">
            Home
          </Link>
          /
          <Link to="/about" className="ml-1 text-blue100 ">
            About
          </Link>
        </div>
        <div className="relative lg:block md:hidden sm:hidden z-1 ">
          <OvalCareerBig className=" absolute ovalBigAbout1" />
        </div>
        <section
          className="lg:pl-20 md:pl-10 sm:px-0 grid lg:grid-cols-2 md:grid-cols-1
      sm:grid-cols-1 lg:gap-20 pb-6 z-10 relative"
        >
          <div
            className="text-sm text-blue200 sm:px-5 lg:px-0  md:px-0 relative z-10"
            style={{
              fontFamily: "Spartan-Medium",
            }}
          >
            <div>
              <p className="py-3 tracking-wider uppercase">
                Unleashing the Power of Web3 with Protofire
              </p>
              <p
                className="lg:text-4xl sm:text-3xl text-blue100"
                style={{
                  fontFamily: "Spartan",
                }}
              >
                {content.name}
              </p>
              <p className="pb-3 text-xs leading-6">{content.description}</p>
              <p className="pb-3 text-xs leading-6">{content.description2}</p>
            </div>
            <div>
              <p className="text-blue100 text-lg pt-3">
                {content.ourMission.title}
              </p>
              <ul className="whatWeDoList leading-4 text-blue200 text-xs sm:mb-5">
                {content.ourMission.list.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
            <div>
              <p className="text-blue100 text-lg pt-3">
                {content.ourVision.title}
              </p>
              <div className="whatWeDoList leading-4 text-blue200 text-xs sm:mb-5">
                {content.ourVision.list}
              </div>
            </div>
          </div>

          <div>
            <img
              src={content.aboutImage}
              alt={content.aboutImageAlt}
              className="w-full sm:hidden lg:block md:hidden"
            />
            <img
              src={content.mobileAbout}
              alt={content.aboutImageAlt}
              className="w-full lg:hidden sm:block md:block"
            />
          </div>
        </section>

        <div className="relative lg:hidden md:hidden sm:block z-1 ">
          <OvalCareerMobile2 className=" absolute bgOvalAbout2" />
        </div>
        {/* <div className="relative lg:block md:hidden sm:hidden z-4 ">
          <OvalAbout2 className=" absolute ovalAbout2 z-10" />
        </div> */}
        <div className="relative lg:block md:hidden sm:hidden z-1 ">
          <OvalAbout1 className=" absolute ovalAbout1 z-1" />
        </div>
        <section
          className="bg-darkBackground lg:px-20 lg:py-10 md:p-10 
        text-blue200 text-sm leading-6 z-12 relative"
          style={{
            fontFamily: "Spartan-Medium",
          }}
        >
          <div
            className="grid lg:grid-cols-4 md:grid-cols-4
      sm:grid-cols-1 gap-10 sm:p-5 "
          >
            <div className="col-span-3 ">
              <div className="border-b border-blue300 pb-5">
                <p
                  className="text-3xl text-blue100 pt-5 leading-10"
                  style={{
                    fontFamily: "Spartan",
                  }}
                >
                  {content.protofireExpertise.title}
                </p>
                <p className="py-2 ">{content.protofireExpertise.firstP}</p>
              </div>
              <div className="lg:py-10 sm:py-5">
                <p
                  className="text-blue300 pt-1 text-lg "
                  style={{
                    fontFamily: "Spartan",
                  }}
                >
                  {" "}
                  01{" "}
                </p>
                <p className="text-blue100 text-lg lg:w-5/6 pb-4 ">
                  {content.first.title}
                </p>
                <div
                  className="grid lg:grid-cols-3 md:grid-cols-2
      sm:grid-cols-1 lg:gap-5 "
                >
                  <ul className="whatWeDoList leading-4 text-blue200 text-xs ">
                    {content.first.firstList.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                  <ul className="whatWeDoList leading-4 text-blue200 text-xs ">
                    {content.first.secondList.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div>
                <p
                  className="text-blue300 pt-1 text-lg "
                  style={{
                    fontFamily: "Spartan",
                  }}
                >
                  02
                </p>
                <p className="text-blue100 text-lg lg:w-5/6 pb-1">
                  {content.second.title}
                </p>
                <ul className="whatWeDoList leading-4 text-blue200 text-xs sm:mb-3">
                  {content.second.list.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div>
            <p
              className="text-blue300 pt-1 text-lg sm:px-5 "
              style={{
                fontFamily: "Spartan",
              }}
            >
              03
            </p>
            <p className="text-blue100 text-lg lg:w-5/6 pb-1 sm:px-5 ">
              How does Protofire compare to other devsHOPS?
            </p>
            <div className="lg:hidden md:hidden sm:block">
              {" "}
              <MobileTable />{" "}
            </div>
            <div className="lg:block md:block sm:hidden">
              <AboutTable rows={tableContent} />{" "}
            </div>
          </div>
          <div className="py-10">
            <p
              className="text-blue300 pt-1 text-lg sm:px-5 "
              style={{
                fontFamily: "Spartan",
              }}
            >
              04
            </p>
            <Numbers />
          </div>
        </section>
        <section className="lg:px-20 md:px-0 sm:px-5 pb-10">
          <p className="text-3xl  text-blue100 pt-20 uppercase">
            {" "}
            We build long-term partnerships
          </p>
          <AboutTabs />
        </section>
        <section>
          <ContactUs />
        </section>
      </div>
    </div>
  );
}

export default About;
