import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ImageGallery from "react-image-gallery";
import { project } from "./content";
import legend from "../../assets/about/legend.png";
import { ReactComponent as OvalCareerMobile2 } from "../../assets/career/ovalCareerMobile2.svg";
import { ReactComponent as OvalCareerBig } from "../../assets/career/ovalCareerBig.svg";
import { ReactComponent as BgMiddle } from "../../assets/career/bgMiddle.svg";

import ContactUs from "../../contactUs/contactUs";
import "../project.css";
import "../../services/tab.css";
import "react-image-gallery/styles/css/image-gallery.css";

function Indexing() {
  const [active, setActive] = useState("page1");

  useEffect(() => {
    const observer = new IntersectionObserver(function (entries) {
      const ele: IntersectionObserverEntry[] = entries.filter(
        (entry) => entry.isIntersecting
      );
      if (ele.length > 0) {
        const ele1 = ele[0].target;
        setActive(ele1.id);
      }
    });
    document
      .querySelectorAll(".myCount [id^=page]")
      .forEach((ele) => observer.observe(ele));
  }, []);

  return (
    <div className="lg:px-0 md:px-10 py-20">
      <Helmet>
        <title>Subgraph Development for The Graph | Protofire</title>
        <meta
          name="description"
          content="Protofire has built over 30 subgraphs on The Graph protocol and assist them with smart contract and on-chain data validation. Need a subgraph? Contact us."
        />
        <meta
          name="keywords"
          content="thegraph crypto, the graph subgraphs, erc 20, graph explorer, solidity programing, graph and network, smart contracts, subgraph"
        />
      </Helmet>
      <section
        className=" lg:flex md:flex lg:px-60 md:px-10 text-blue200 relative z-10
        text-xs   sm:px-5 "
        style={{
          fontFamily: "Spartan-Medium",
        }}
      >
        <Link to="/" className=" mr-1 text-blue200">
          Home
        </Link>
        /
        <Link to="/projects" className="ml-1 mr-1 text-blue200 ">
          Projects
        </Link>
        /
        <Link to="/services" className="ml-1 text-blue100 ">
          Indexing
        </Link>
      </section>
      <div className="relative lg:block md:hidden sm:hidden z-1 ">
        <OvalCareerBig className=" absolute ovalBigProjectPages1" />
      </div>
      <div className="relative lg:hidden md:hidden sm:block z-1 ">
        <OvalCareerMobile2 className=" absolute bgOvalProjectPages2" />
      </div>
      <section
        className="md:pl-10 lg:px-0 
       lg:pb-20 sm:px-5 py-10 lg:pl-60 m-auto relative z-10"
      >
        <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-10">
          <div className="lg:w-6/7 lg:pr-10 sm:w-full md:w-full">
            <h1 className="text-blue100 text-4xl md:text-5xl xl:text-6xl">
              {project.name}
            </h1>
            <div className="flex flex-wrap text-xs text-blue ">
              {project.hashtags.map((hashtag) => (
                <span
                  key={hashtag}
                  className=" py-1 px-3 border border-blue my-2 mr-3 rounded-3xl"
                >
                  {hashtag}{" "}
                </span>
              ))}
            </div>
            <p
              className="py-5 text-blue200 leading-7"
              style={{
                fontFamily: "Spartan-Medium",
              }}
            >
              {project.description}
            </p>
          </div>
          <div className="w-full lg:block sm:hidden md:block">
            <img
              src={project.thumbnail}
              alt={project.thumbnailAlt}
              className="w-full "
            />{" "}
          </div>
        </div>
      </section>

      <div
        className="lg:px-60  md:px-10 sm:px-5 pb-20 grid lg:grid-cols-3  z-10
      md:grid-cols-3 sm:grid-cols-1 relative  overflow-auto bg-darkBackground"
        style={{
          height: "600px",
        }}
      >
        <div className="col-span-2  myCount">
          <section className="py-5" id="page1">
            <div className=" lg:col-span-2 md:col-span-2 ">
              <p className="text-blue100 text-3xl py-2">
                {" "}
                {project.aboutProject.title}
              </p>
              <div
                className="dotList leading-5 text-sm"
                style={{
                  fontFamily: "Spartan-Medium",
                }}
              >
                {project.aboutProject.lists.map((list) => (
                  <li className="pb-1 text-blue200" key={list}>
                    {list}
                  </li>
                ))}
              </div>
            </div>
          </section>
          <section className="" id="page2">
            <div className=" lg:col-span-2 md:col-span-2">
              <p className="text-blue100 text-3xl pt-2">
                {project.theClient.title}{" "}
              </p>
              <p
                className="text-blue200 py-2 text-sm leading-6"
                style={{
                  fontFamily: "Spartan-Medium",
                }}
              >
                {project.theClient.firstParagraph}{" "}
              </p>
            </div>
          </section>
          <section className="py-5" id="page3">
            <div className=" lg:col-span-2 md:col-span-2">
              <p className="text-blue100 text-3xl pt-2">
                {project.theProblem.title}{" "}
              </p>
              <p
                className="text-blue200 py-2 text-sm leading-6"
                style={{
                  fontFamily: "Spartan-Medium",
                }}
              >
                {project.theProblem.firstParagraph}{" "}
              </p>
              <div
                className="dotList text-sm leading-5"
                style={{
                  fontFamily: "Spartan-Medium",
                }}
              >
                {project.theProblem.lists.map((list) => (
                  <li className="pb-1 text-blue200" key={list}>
                    {list}
                  </li>
                ))}
              </div>
            </div>
          </section>
          <section className="" id="page4">
            <div className=" lg:col-span-2 md:col-span-2">
              <p className="text-blue100 text-3xl pt-2">
                {project.theSolution.title}{" "}
              </p>
              <div
                className="dotList text-sm leading-5 "
                style={{
                  fontFamily: "Spartan-Medium",
                }}
              >
                <p className="text-blue100 text-lg pt-2">
                  {project.theSolution.firstListTitle}{" "}
                </p>
                {project.theSolution.lists.map((list) => (
                  <li className="pb-1 text-blue200" key={list[0]}>
                    {list[0]}
                    {list[1] !== undefined && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${list[1]}`}
                        className="text-blue pl-3"
                      >
                        Media
                      </a>
                    )}
                  </li>
                ))}
                <p className="text-blue100 text-lg pt-2">
                  {project.theSolution.secondListTitle}{" "}
                </p>
                {project.theSolution.list2.map((list) => (
                  <li className="pb-1 text-blue200" key={list}>
                    {list}
                  </li>
                ))}
              </div>
            </div>
          </section>
          <section className=" text-sm pt-5" id="page7">
            <p className="text-blue100 text-3xl pt-2">List of deliveries</p>
            <div
              className="grid leading-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 dotList"
              style={{
                fontFamily: "Spartan-Medium",
              }}
            >
              {project.deliveries.map((list) => (
                <li className=" text-blue200" key={list}>
                  {list}
                </li>
              ))}
            </div>
          </section>
          <section
            className="py-5 text-sm leading-6 lg:w-5/6 m-auto sm:px-5 sm:w-full"
            id="testimonial"
            style={{
              fontFamily: "Spartan-Medium",
            }}
          >
            <fieldset
              className=" lg:p-10 sm:p-5 my-5 border-blue border 
            text-blue200"
            >
              <legend>
                <img src={legend} alt="legend" />{" "}
              </legend>
              <p>{project.comment.body}</p>
              <p className="pt-8 text-blue100">{project.comment.author}</p>
              <p>{project.comment.title}</p>
            </fieldset>
          </section>
          <section className="" id="page5">
            <div className=" lg:col-span-2 md:col-span-2">
              <p className="text-blue100 text-3xl py-3">
                {project.technologyStack.title}{" "}
              </p>
              <div
                className="grid lg:grid-cols-3
      md:grid-cols-3 sm:grid-cols-2 text-blue200"
                style={{
                  fontFamily: "Spartan-SemiBold",
                }}
              >
                <div>
                  <p className="text-sm">LANGUAGES</p>
                  <div className="dotList text-sm leading-5">
                    {project.technologyStack.lauguages.map((list) => (
                      <li className="pb-1 text-blue200" key={list}>
                        {list}
                      </li>
                    ))}
                  </div>
                </div>
                <div>
                  <p className="text-sm">DATABASES</p>
                  <div className="dotList text-sm leading-5">
                    {project.technologyStack.database.map((list) => (
                      <li className="pb-1 text-blue200" key={list}>
                        {list}
                      </li>
                    ))}
                  </div>
                </div>
                <div>
                  <p className="text-sm">TOOLS</p>
                  <div className="dotList text-sm leading-5">
                    {project.technologyStack.tools.map((list) => (
                      <li className="pb-1 text-blue200" key={list}>
                        {list}
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="py-5"
            id="page6"
            style={{
              fontFamily: "Spartan-Medium",
            }}
          >
            <div className=" lg:col-span-2 md:col-span-2 leading-6">
              <p
                className="text-blue100 text-3xl py-3"
                style={{
                  fontFamily: "Spartan",
                }}
              >
                {project.theMedia.title}{" "}
              </p>
              <div className=" text-sm leading-5">
                {project.theMedia.list.map((list) => (
                  <div key={list.text}>
                    <p className="text-blue200  text-sm ">{list.text} </p>
                    <a
                      href={list.link}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue"
                    >
                      View More
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section className="py-5" id="page7">
            <div className=" text-sm">
              <p className="text-blue100 text-3xl py-3">
                {project.theMedia.lastMedia.text}{" "}
              </p>
              {project.theMedia.lastMedia.codes.map((list) => (
                <div
                  className=" "
                  key={list.text}
                  style={{
                    fontFamily: "Spartan-Medium",
                  }}
                >
                  <p className="text-blue200  text-sm ">{list.text} </p>
                  <a
                    href={list.link}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue"
                  >
                    Github
                  </a>
                </div>
              ))}
            </div>
          </section>
        </div>
        <aside
          className="text-xs  text-blue200 lg:ml-20 py-5 md:ml-20 
          sticky top-5 right-5  h-60 sm:hidden lg:block md:block"
        >
          <ul className="border-l border-blue300 pl-5">
            <li className="pb-2">
              <a
                className={` ${active === "page1" ? "text-blue" : ""}`}
                href="#page1"
                onClick={() => setActive("page1")}
              >
                {" "}
                ABOUT THE PROJECT
              </a>
            </li>
            <li className="pb-2">
              <a
                className={` ${active === "page2" ? "text-blue" : ""}`}
                href="#page2"
                onClick={() => setActive("page2")}
              >
                {" "}
                THE CLIENT/PARTNER
              </a>
            </li>
            <li className="pb-2">
              <a
                className={` ${active === "page3" ? "text-blue" : ""}`}
                href="#page3"
                onClick={() => setActive("page3")}
              >
                {" "}
                THE PROBLEM
              </a>
            </li>
            <li className="pb-2">
              <a
                className={` ${active === "page4" ? "text-blue" : ""}`}
                href="#page4"
                onClick={() => setActive("page4")}
              >
                {" "}
                THE SOLUTION
              </a>
            </li>{" "}
            <li className="pb-2">
              <a
                className={` ${active === "page5" ? "text-blue" : ""}`}
                href="#page5"
                onClick={() => setActive("page5")}
              >
                {" "}
                TECHNOLOGY STACK
              </a>
            </li>
            <li className="pb-2">
              <a
                className={` ${active === "page6" ? "text-blue" : ""}`}
                href="#page6"
                onClick={() => setActive("page6")}
              >
                {" "}
                MEDIA
              </a>
            </li>
            <li className="pb-2">
              <a
                className={` ${active === "page7" ? "text-blue" : ""}`}
                href="#page7"
                onClick={() => setActive("page7")}
              >
                {" "}
                CODE
              </a>
            </li>
          </ul>
        </aside>
      </div>
      <section
        className="lg:px-40 w-3/4 m-auto lg:py-10 md:py-20 sm:py-10 
      sm:hidden lg:block md:block"
      >
        <p className="text-blue100 text-3xl py-3">Images</p>
        <div>
          <ImageGallery items={project.images} thumbnailPosition="bottom" />
        </div>
      </section>
      <div className="relative lg:block md:hidden sm:hidden z-10 ">
        <BgMiddle className=" absolute bgMiddleProject" />
      </div>
      <section
        className="lg:bg-square-bg md:bg-square-bg sm:bg-mobile-bg
       bg-contain bg-center z-10 globeProject"
      >
        <div
          className="bg-circle-bg relative 
          h-full m-auto 
        sm:w-full lg:w-1/3 md:w-2/3  bg-cover bg-center bg-no-repeat z-0  "
        >
          <div
            className="text-center m-0, w-full top-1/2 left-1/2 absolute"
            style={{
              transform: "translate(-50%, -50%)",
            }}
          >
            <h1 className="text-blue100 lg:text-2xl md:text-2xl  leading-10 sm:text-2xl">
              Is your project in need of a subgraph?
            </h1>
            <p className="text-blue100 pt-5 Spartan-Medium text-sm w-9/12 m-auto">
              {" "}
              <a
                href="#contact"
                className="pr-2 underline  "
                style={{
                  background:
                    "linear-gradient(to right, #F54703 50%, #FAC902 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Get in touch with us
              </a>
              so that we can discuss your ideas.
            </p>
          </div>
          {/* <CircleBg /> */}
        </div>
      </section>
      <section className="lg:w-5/6 m-auto" id="contact">
        <ContactUs />
      </section>
    </div>
  );
}

export default Indexing;
