import opyn from "../../assets/projectsImages/opynNew.png";
import opyn1 from "../../assets/projectsImages/opyn1.png";
import opyn2 from "../../assets/projectsImages/opyn2.png";

import dex1 from "../../assets/services/55.png";
import dex11 from "../../assets/services/56.png";
import dex2 from "../../assets/services/91.png";
import dex22 from "../../assets/services/92.png";
// eslint-disable-next-line import/prefer-default-export
export const project = {
  name: "Opyn Decentralized Insurance Platform",
  hashtags: ["Audit", "Insurance", "Integration", "Interface", "Platform"],
  description: `We have supported Opyn on the development of the user interface of 
  their Decentralized Insurance Platform, as well as integrated it with a backend 
  and Web3 calls. The team worked out the platform’s architecture and implemented 
  its business logic. Now, the solution enables option sellers and buyers to earn 
  premiums on collateral liquidation, as well as ensure security against technical, 
  financial, and systemic risks in the DeFi platforms.`,
  thumbnail: opyn,
  thumbnailAlt: `Protofire assisted Opyn in the development of the Decentralized Insurance Platform contributing to the platform architecture and logic.`,
  aboutProject: {
    title: "About the project",
    lists: [
      `Financial management tool for derivatives trading`,
      `It is a tool for investors to manage their risks better`,
      `Users can either hedge or leverage their positions`,
      `The solution can be accessed through a user interface`,
      `A thoroughly audited technology`,
    ],
    sellVolume: ["$98M+", "Total Value Locked (Q3/2022)"],
  },
  theClient: {
    title: "The client/partner",
    firstParagraph: `Opyn is a permissionless, decentralized insurance platform 
    that enables users to protect their DeFi deposits from financial and technical
     risks and hedge against Ether downside, volatility, and flash crashes.`,
  },
  theProblem: {
    title: "The problem",
    firstParagraph: `The nascent blockchain ecosystem leverages the risk of 
    investors, given the great uncertainties still faced by it, such as government
     regulations and adoption concerns. In order to limit such hurdles, financial 
     tools such as derivatives promote a way for investors to protect their invested
      capital against long-term uncertainty. Options provide a way for investors 
      to either hedge or leverage their positions against the market.`,
  },
  theSolution: {
    title: "The solution",
    firstParagraph: `The deliveries have been:`,
    lists: [
      [
        `Frontend Insurance: We have built the user interface and integrated 
        the UI with a backend and Web3 infrastructure`,
      ],
      [
        `Landing Page: We have built a landing page and integrated it
         with the Frontend Insurance`,
        ``,
      ],
      [
        `Smart Contracts QA Assessment: We have developed integration tests 
        for Opyn Smart Contracts before audits by Open Zeppelin`,
      ],
      [
        `OpenZeppelin Audit and Fixations: We have reviewed the OpenZeppelin 
        Audit and applied fixes and improvements to the platform`,
      ],
    ],
  },
  comment: {
    body: `We enjoyed working with Protofire a lot! DeFi thrives because of this team! 
    They have some of the best engineers, really efficient, professional, and, more 
    than all, fun to work with! They deliver high-quality work really quickly!`,

    author: "Aparna Krishnan",
    title: "Co-Founder at Opyn",
  },
  technologyStack: {
    title: "Technology stack ",
    lauguages: [
      "React",
      "Javascript",
      "Web3",
      "GraphQL",
      "Assembly Script for The Graph",
      "Solidity",
    ],

    tools: ["GitHub", "ZenHub", "Netlify"],
  },
  images: [
    {
      original: opyn1,
      originalAlt: `Protofire helped Opyn to develop the Decentralized Insurance Platform, addressing the matters of its architecture and logic and ensuring its security against technical, financial, and systemic risks in the DeFi platforms.`,
      thumbnail: opyn1,
      thumbnailAlt: `Protofire helped Opyn to develop the Decentralized Insurance Platform, addressing the matters of its architecture and logic and ensuring its security against technical, financial, and systemic risks in the DeFi platforms.`,
    },
    {
      original: opyn2,
      originalAlt: `Protofire helped Opyn to develop the Decentralized Insurance Platform, addressing the matters of its architecture and logic and ensuring its security against technical, financial, and systemic risks in the DeFi platforms.`,
      thumbnail: opyn2,
      thumbnailAlt: `Protofire helped Opyn to develop the Decentralized Insurance Platform, addressing the matters of its architecture and logic and ensuring its security against technical, financial, and systemic risks in the DeFi platforms.`,
    },
  ],
  ourTrackRecord: [
    [
      dex1,
      dex11,
      "/projects/gnosis-explorer",
      "sameWindow",
      "Protofire created hierarchical and custom components for the Gnosis’ Conditional Tokens Explorer.",
    ],
    [
      dex2,
      dex22,
      "https://medium.com/protofire-blog/synthetix-launched-a-brand-new-grantsdao-interface-72d9831ad0ae",
      "newWindow",
      "Protofire developed a dapp for minting digital assets and delivered a new user interface for the Synthetix GrantsDAO.",
    ],
  ],
};
