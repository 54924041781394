/// <reference types="react-scripts" />
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useRef, SyntheticEvent } from "react";

import { ReactComponent as Protofire } from "../assets/images/protofire.svg";
import { ReactComponent as MenuMobile } from "../assets/images/menu1.svg";
import { ReactComponent as Cancel } from "../assets/images/cancel.svg";
import { ReactComponent as Sclose } from "../assets/images/sclose.svg";

import { ReactComponent as ArrowClicked } from "../assets/images/arrowDblue.svg";

import MobileNav, { servicepages } from "./mobileMenu";
import Socials from "../socials/socials";

function Menu() {
  const location = useLocation();
  const [active, setActive] = useState("home");
  const [open, setOpen] = useState(false);
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
  const [dropDown, setDropDown] = useState(false);

  const handleOpen = () => {
    setDropDown(!dropDown);
  };

  const handleClick = (page: string) => {
    setActive(page);
  };

  const handleOpenMobileNav = () => {
    setOpen(!open);
    setDisplayMobileMenu(!displayMobileMenu);
  };
  const handleCancelMobileNav = () => {
    setOpen(!open);
    setDisplayMobileMenu(!displayMobileMenu);
  };

  const handleMouseOver = (e: SyntheticEvent) => {
    e.stopPropagation();
    setDropDown(true);
  };

  const handleMouseLeave = (e: SyntheticEvent) => {
    e.stopPropagation();
    setTimeout(handleOpen, 500);
  };

  useEffect(() => {
    setDropDown(false);
  }, [location]);

  const ref = useRef<any>(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className=" ">
      <nav className=" px-2 sm:px-0 lg:pl-5 py-1  z-10 navbar ">
        <div
          className="  sm:px-5 lg:flex flex-wrap justify-between 
        items-center lg:mx-auto "
        >
          <div className="lg:flex  md:order-2 z-10 flex-wrap sm:hidden md:hidden">
            <Link to="/contact-us">
              <button
                type="button"
                className="underline focus:ring-4 textGradient
            
              text-sm px-5 pt-3 text-center mr-3 md:mr-0 hover:text-primary"
              >
                CONTACT US
              </button>
            </Link>
            <Socials />
          </div>
          <div
            className=" justify-between items-center w-full flex 
            md:w-auto md:order-1 z-10"
          >
            <Link className="  mr-10 pr-10" to="/">
              <Protofire
                onClick={() => setActive("home")}
                className="sm:w-4/5 sm:text-right"
              />
            </Link>

            {!open && (
              <MenuMobile
                className="lg:hidden cursor-pointer"
                onClick={handleOpenMobileNav}
              />
            )}
            {open && (
              <Cancel className="lg:hidden" onClick={handleCancelMobileNav} />
            )}

            <ul
              className=" sm:hidden  lg:flex text-xs flex-col pt-3 md:flex-row text-blue200
            md:space-x-8 md:mt-0 md:font-medium tracking-widest"
              style={{
                fontSize: "10px",
                fontFamily: "Spartan-SemiBold",
              }}
            >
              <li>
                <Link
                  to="/about"
                  className={`block pt-2 pr-4 pl-3 hover:text-blue100 
                  md:p-0 ${active === "about" ? "text-blue" : ""}`}
                  onClick={() => setActive("about")}
                >
                  ABOUT
                </Link>
              </li>
              <li
                style={{
                  marginTop: "4px",
                }}
              >
                <Link
                  to="/services"
                  className={`  pr-1 pl-3  inline  hover:text-blue100 
                  md:p-0 ${active === "services" ? "text-blue" : ""}`}
                  onClick={() => handleClick("services")}
                  onMouseEnter={handleMouseOver}
                >
                  SERVICES{" "}
                  {active === "services" && !dropDown && (
                    <ArrowClicked className="inline mb-2 mt-1 cursor-pointer" />
                  )}
                  {dropDown && (
                    <ArrowClicked className="inline mb-2 mt-1 cursor-pointer" />
                  )}
                  {!dropDown && active !== "services" && (
                    <Sclose className=" inline mb-2 mt-1 cursor-pointer" />
                  )}
                </Link>
                {dropDown && (
                  <div
                    ref={ref}
                    className="absolute top-12 bg-darkBackground px-5 py-2"
                    onMouseLeave={handleMouseLeave}
                  >
                    {servicepages.map((pages) => (
                      <li
                        key={pages.name}
                        className="py-2  tracking-wide"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Spartan-Medium",
                        }}
                      >
                        <Link
                          to={pages.link}
                          className={`block  hover:text-blue100 
                            md:p-0 ${active === pages.name ? "text-blue" : ""}`}
                          onClick={() => handleClick(pages.name)}
                        >
                          {pages.name}
                        </Link>
                      </li>
                    ))}
                  </div>
                )}
              </li>

              <li>
                <Link
                  to="/projects"
                  className={`block pt-2 pr-4 pl-3   hover:text-blue100 
                  md:p-0 ${active === "projects" ? "text-blue" : ""}`}
                  onClick={() => setActive("projects")}
                >
                  PROJECTS
                </Link>
              </li>
              <li>
                <Link
                  to="/careers"
                  className={`block pt-2 pr-4 pl-3   hover:text-blue100 
                  md:p-0 ${active === "careers" ? "text-blue" : ""}`}
                  onClick={() => setActive("careers")}
                >
                  CAREERS
                </Link>
              </li>
              <li>
                <a
                  href="/community"
                  className={`block pt-2 pr-4 pl-3   hover:text-blue100 
                  md:p-0 ${active === "community" ? "text-blue" : ""}`}
                  onClick={() => setActive("community")}
                  rel="noreferrer"
                >
                  COMMUNITY
                </a>
              </li>
            </ul>
          </div>
          {displayMobileMenu && (
            <MobileNav
              open={open}
              setOpen={setOpen}
              setDisplayMobileMenu={setDisplayMobileMenu}
              displayMobileMenu={displayMobileMenu}
            />
          )}
        </div>
      </nav>
    </div>
  );
}

export default Menu;
