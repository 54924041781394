import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import CookiesSetup from "../../cookiesSetup";
import CommunityLayoutHeader from "./communityLayoutHeader";
import CommunityLayoutFooter from "./communityLayoutFooter";

function CommunityLayout() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="format-detection" content="telephone=no" />
        <link rel="canonical" href="protofire.io" />
        <title>Custom Blockchain Development | Protofire.io</title>
        <meta
          name="description"
          content="Protofire is a team of engineers which helps token-based protocols and developer platforms accelerate growth of their ecosystems."
        />
        <meta
          name="keywords"
          content="blockchain, token, dbc, blockchain developer, software engineer, blockchain infrastructure, coding, token-based protocols, blockchain development"
        />
      </Helmet>

      <CommunityLayoutHeader />

      <main style={{ fontFamily: "RobotoMono-Regular" }}>
        <Outlet />
      </main>

      <CommunityLayoutFooter />

      <CookiesSetup />
    </>
  );
}

export default CommunityLayout;
