import armanino from "../../assets/projectsImages/armaninoNew.png";
import armanino2 from "../../assets/projectsImages/armanino2.png";
import armanino3 from "../../assets/projectsImages/armanino3.png";
import armanino4 from "../../assets/projectsImages/armanino4.png";

// eslint-disable-next-line import/prefer-default-export
export const project = {
  name: "Armanino’s TrustExplorer",
  hashtags: ["Platform"],
  description: `Protofire has been working with Armanino for more than three years 
  and has been assisting in the development of four solutions under the TrustExplorer 
  project: a blockchain-based assurance platform (Real-Time Audit), a tool to manage
   digital asset reserves (Proof of Reserves), a data query service (Trusted Node), 
   and a dashboard to show token ownership and distribution (Treasury). Those are 
   accounting, auditing, and reporting solutions that are tailored for the enterprise 
   ecosystem and auditing mechanisms.`,
  thumbnail: armanino,
  thumbnailAlt: `Protofire assisted Armanino in the development of solutions under the TrustExplorer project: a blockchain-based assurance platform (Real-Time Audit), a tool to manage digital asset reserves (Proof of Reserves), a data query service (Trusted Node), and a dashboard to show token ownership and distribution (Treasury).`,
  theClient: {
    title: "The client/partner",
    firstParagraph: `Armanino is among Top 20 providers of audit, tax, business 
    management, and consulting services in the USA. Operating in 100+ countries 
    globally, the company serves such industries as information technology,
     manufacturing, logistics, education, real estate, insurance, healthcare, etc`,
  },
  entry: [
    {
      title: "TrustExplorer Real-Time Audit",
      aboutProject: {
        title: "About",
        lists: [
          `A blockchain-based platform for financial accounting, auditing, and reporting 
      in real-time. The solution allows users to dynamically pull data on a 
      circulating supply of tokens, as well as aggregate a total balance of 
      assets supporting the tokens.`,
        ],
        site: "https://www.armaninollp.com/software/trustexplorer/real-time-attest/",
      },

      theProblem: {
        title: "Problem",
        lists: [
          `Lack of trust and transparency in the enterprise ecosystem and 
      auditing mechanisms.`,
          "Slow auditing process",
        ],
      },
      theSolution: {
        title: "Solution",
        lists: [
          `A blockchain-based platform`,
          `Independent accountant reports in real-time`,
          `Users: Increased transparency`,
          `Regulators: Fulfill international and domestic regulatory requirements`,
          `Management: Enable real-time visibility of assets and liabilities, 
        including historical positions`,
        ],
      },
    },
    {
      title: "TrustExplorer Proof of Reserves",
      aboutProject: {
        title: "About",
        lists: [
          `A tool (platform) that provides users of centralized exchanges 
          with transparency and assurance over digital asset reserves held 
          custodial on their behalf`,
        ],
        site: "https://www.armaninollp.com/software/trustexplorer/proof-of-reserves/",
      },

      theProblem: {
        title: "Problem",
        lists: [
          `Lack of standards to ensure digital asset service providers hold enough 
          assets to cover customer deposits.`,
          "Gaps in trust result in lower rates of adoption and higher counterparty risks",
        ],
      },
      theSolution: {
        title: "Solution",
        lists: [
          `Users can verify that their digital assets are fully reserved on their exchanges`,
          `Platform users: Enhance their reputation and confidence with their user base`,
          `Regulators: Fulfill international and domestic regulatory requirements`,
          `Management: Increase confidence in their own internal systems by having
           a team of industry CPA’s inspect their processes`,
        ],
      },
    },
    {
      title: "TrustExplorer Trusted Node",
      aboutProject: {
        title: "About",
        lists: [
          `A service to query data. Using this solution, the participants of 
          the Hedera Hashgraph network can access transactional data. Meanwhile, 
          financial statement auditors can acquire information related to
           balance sheet accounting of material assets stored in a blockchain`,
        ],
        site: "https://www.armaninollp.com/software/trustexplorer/trusted-node/",
      },

      theProblem: {
        title: "Problem",
        lists: [
          `Burden on ecosystem participants of hosting their own full, 
          archival or mirror nodes`,
          "Lack of access to transactional and wallet balance data",
          `Lack of blockchain solutions for the enterprise ecosystem 
          participants and auditors`,
        ],
      },
      theSolution: {
        title: "Solution",
        lists: [
          `Solution for the enterprise ecosystem participants and auditors`,
          `Built and operated under a robust compliance program`,
          `The API allows an auditor to query data from a node maintained 
          by a reputable party and the ability to obtain industry-standard 
          assurance reporting`,
        ],
      },
    },
    {
      title: "TrustExplorer Treasury",
      aboutProject: {
        title: "About",
        lists: [
          `Treasury is a token management solution (dashboard) that provides 
          all participants with accurate information on token ownership and 
          distribution in an easily digestible format. That promotes growth of 
          token usage, expands the user base and builds trust in the marketplace`,
        ],
        site: "https://www.armaninollp.com/software/trustexplorer/treasury/",
      },

      theProblem: {
        title: "Problem",
        lists: [
          `Lack of trust in governance platforms due to a lack of transparency 
          regarding token ownership and distribution`,
          "Information may sometimes be inaccurate",
          `Information may sometimes be hard to understand`,
        ],
      },
      theSolution: {
        title: "Solution",
        lists: [
          `Ecosystem Development: Increased trust in treasury 
          management through real-time, accurate data`,
          `Token Issuers: Enhance their reputation with current and prospective holders`,
          `Token Holders: Verify token disbursement aligns with established agreements`,
          `Foundations & DAOs: Utilize trusted third-party verification and attest 
          reporting of their treasury`,
        ],
      },
    },
  ],
  comment: {
    body: `The applications we design and deliver to clients provide assurance over 
    10’s of billions of dollars to marketplaces, retail and institutional customers, 
    as well as regulators across the globe. The stakes are high and the information 
    produced from our applications must also meet multiple strict professional CPA 
    standards. Protofire has been a long-term partner that has risen to this 
    challenge; their team have provided high continuity, collaborative spirit, 
    excellent communication and outstanding customer service.`,

    author: "Noah Buxton",
    title: "Partner, Practice Leader, Armanino",
  },
  images: [
    {
      original: armanino2,
      originalAlt:
        "Protofire helped Armanino in the development of solutions under the TrustExplorer project for accounting, auditing, and reporting that are tailored to the enterprise ecosystem.",
      thumbnail: armanino2,
      thumbnailAlt:
        "Protofire helped Armanino in the development of solutions under the TrustExplorer project for accounting, auditing, and reporting that are tailored to the enterprise ecosystem.",
    },
    {
      original: armanino3,
      originalAlt:
        "Protofire helped Armanino to develop solutions under the TrustExplorer project for accounting, auditing, and reporting that are tailored to the enterprise ecosystem.",
      thumbnail: armanino3,
      thumbnailAlt:
        "Protofire helped Armanino to develop solutions under the TrustExplorer project for accounting, auditing, and reporting that are tailored to the enterprise ecosystem.",
    },
    {
      original: armanino4,
      originalAlt:
        "Protofire helped Armanino to develop solutions under the TrustExplorer project for accounting, auditing, real-time attest and reporting tailored to the enterprise ecosystem.",
      thumbnail: armanino4,
      thumbnailAlt:
        "Protofire helped Armanino to develop solutions under the TrustExplorer project for accounting, auditing, real-time attest and reporting tailored to the enterprise ecosystem.",
    },
  ],
  ourTrackRecord: [],
};
