/// <reference types="react-scripts" />
import { useEffect } from "react";

import { ReactComponent as JoinUs } from "../assets/images/joinUs.svg";
import { ReactComponent as OvalBig } from "../assets/images/ovalBig.svg";
import { ReactComponent as JoinUsMobile } from "../assets/images/joinUsMobile.svg";
import { ReactComponent as OvalMainBig } from "../assets/about/ovalMainBig.svg";
import teamPhoto from "../assets/images/teamPhoto.png";
import "./home.css";

import { animate } from "./animate";
import Index from "./whatWeDo";
import { partnersLogos } from "./partnersLogos";

import Projects from "./projects";
import NumbersThatMatters from "./someNumbers/numbers";
import AboutHome from "./about";
import OurTeam from "./ourTeam";
import Countries from "./countries/countries";
import ContactUs from "../contactUs/contactUs";
import WhoTalk from "./whoTalkAboutUs/whoTalk";
import RandomTitle from "./randomTitle/randomTitle";

function Home() {
  useEffect(() => {
    animate();
  }, []);

  return (
    <div>
      <div className="lg:ml-10 md:ml-10 sm:ml-0">
        <section
          className="lg:bg-square-bg md:bg-square-bg sm:bg-none
       bg-contain bg-center z-10 globe relative sm:pt-0 lg:mt-0"
        >
          <div
            className="lg:text-center sm:text-left md:text-center z-10
            m-0, w-full m-auto  absolute  middleText sm:px-5 sm:pt-14"
            // style={{
            //   transform: "translate(-50%, -50%)",
            // }}
          >
            <div>
              <h1
                className=" text-blue100  md:text-3xl lg:hidden md:block md:w-3/4 lg:m-auto
            sm:text-2xl leading-12   header1 sm:mb-12 md:mb-0 sm:block md:m-auto  "
              >
                We grow TVL and usage of web3 projects through:
              </h1>
              <RandomTitle
                className={`text-blue100  md:text-3xl lg:hidden md:block md:w-3/4 lg:m-auto
            sm:text-2xl leading-12   header1 sm:mb-12 md:mb-0 sm:block md:m-auto  `}
              />
              <div
                className="text-blue100 lg:text-4xl mb-5 leading-12
            lg:block sm:hidden md:hidden "
              >
                <h1>We grow TVL and usage of web3 projects through:</h1>
                <RandomTitle />
              </div>
              <p
                className="text-blue200 text-sm lg:pt-0 md:pt-5 lg:w-1/2 md:w-2/3
             lg:px-4 m-auto mb-16 lg:block md:block sm:hidden xl:w-2/5 lg:pr-8"
                style={{
                  fontFamily: "Spartan-Medium",
                  fontSize: "14px",
                }}
              >
                We design and deploy blockchain solutions for L1/2 blockchains
                and DeFi protocols, leveraging strategic planning,
                compatibility, and marketing to drive their long-term TVL and
                usage growth.
              </p>

              <a
                href="#contactUs"
                className=" px-8 py-6  text-blue100 btn sm:mt-10  md:mt-2 "
                style={{ fontSize: "12px" }}
              >
                GET IN TOUCH
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div
              className="lg:bg-circle-bg md:bg-circle-bg sm:bg-circle-bg relative 
           m-auto 
         middleBg bg-cover bg-center bg-no-repeat z-0 p-2 "
            >
              {" "}
            </div>
          </div>
        </section>
      </div>
      <section className="partnersLogo1 ">
        <div className="lg:m-0 md:mt-0 sm:mt-10 ">
          <div
            className="  pb-2 lg:text-center md:text-center sm:text-left
             text-blue200 tracking-widest sm:px-5 "
            style={{
              fontSize: "18px",
              fontFamily: "Spartan-SemiBold",
            }}
          >
            OUR TEAMS ARE WORKING WITH
          </div>
          <div className="marquee ">
            <ul className="marquee-content" id="parners">
              {partnersLogos.map((logo) => (
                <li key={logo.name}>
                  <img src={logo.src} alt="protofire partners logos" />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <section className="sm:pt-0 lg:pl-20 md:pl-10 " id="what-we-do">
        <div className="lg:px-10">
          <Index />
          <Projects />
        </div>
      </section>
      <section className=" md:px-10 sm:px-5 overflow-hidden">
        <NumbersThatMatters />
      </section>
      <section className="relative z-20 lg:px-00 md:px-10 ">
        <AboutHome />
      </section>
      <div className="relative z-1 sm:hidden md:hidden lg:block">
        <OvalMainBig className="absolute ovalAbout4" />
      </div>
      <section className="mt-20 relative lg:px-20 md:px-10 sm:px-5 z-10 ">
        <div className=" m-auto lg:px-20 ">
          <div className=" sm:hidden ovalBigDiv ">
            <OvalBig className="ovalBig " />
          </div>
          <p
            style={{ fontFamily: "Spartan-SemiBold", fontSize: "12px" }}
            className="tracking-widest text-blue200 "
          >
            {" "}
            JOIN US AT ANY STAGE WITH YOU PROJECT
          </p>
          <p className="text-3xl text-blue100 mt-3">
            {" "}
            Our lean execution philosophy
          </p>

          <JoinUs className="joinUs sm:hidden lg:block md:block md:h-full lg:h-full" />
          <JoinUsMobile className="lg:hidden md:hidden mt-10 w-full h-full" />
        </div>
      </section>
      <section className="md:px-10">
        <OurTeam />
      </section>
      <section className="my-10">
        <img
          src={teamPhoto}
          alt="Join Protofire and work with the world’s top protocols and ecosystems."
        />
      </section>
      <section className=" md:px-10 sm:px-5">
        <Countries />
      </section>

      <section className="my-20 lg:mx-20 md:mx-10" id="contactUs">
        <div className="">
          <ContactUs />
        </div>
      </section>
      <section className="my-20 lg:mx-20 md:mx-10">
        <WhoTalk />
      </section>
    </div>
  );
}

export default Home;
