function Numbers() {
  return (
    <div
      className="  relative z-10 sm:px-5"
      style={{
        margin: "auto",
      }}
    >
      <div className="z-30 relative">
        <h1 className="text-lg  text-blue100 "> Some numbers that matter </h1>
      </div>
      <div
        className="grid  lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 lg:gap-10 
      md:gap-10 sm:gap-1"
      >
        <div className="mr-20 ">
          <hr className="text-3xl w-7 border-4 my-5 text-yellow" />
          <p
            className="text-4xl text-blue100"
            style={{
              fontFamily: "Spartan",
            }}
          >
            100+
          </p>
          <p className="text-xs tracking-widest py-2 text-blue200">
            PROJECTS DELIVERED
          </p>
        </div>
        <div className="mr-20 relative z-20">
          <hr className="text-3xl w-7 border-4 my-5 text-orange" />
          <p
            className="text-4xl text-blue100"
            style={{
              fontFamily: "Spartan",
            }}
          >
            50+
          </p>
          <p className="text-xs tracking-widest py-2 text-blue200">
            PARTNERS AND CLIENTS
          </p>
        </div>
        <div className="mr-20 ">
          <hr className="text-3xl w-7 border-4 my-5 text-primary" />
          <p
            className="text-4xl text-blue100"
            style={{
              fontFamily: "Spartan",
            }}
          >
            80+
          </p>
          <p className="text-xs tracking-widest py-2 text-blue200">
            BLOCKCHAIN SPECIALISTS
          </p>
        </div>
      </div>
    </div>
  );
}

export default Numbers;
