/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/dot-notation */
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import dark from "../assets/career/dark.png";
import blue from "../assets/career/blue.png";
import { content, partners, sections } from "./content";
import { ReactComponent as OvalCareerMobile } from "../assets/career/ovalCareerMobile.svg";
import { ReactComponent as OvalCareerMobile2 } from "../assets/career/ovalCareerMobile2.svg";
import { ReactComponent as OvalCareerBig } from "../assets/career/ovalCareerBig.svg";
import { ReactComponent as OvalCareerBig2 } from "../assets/career/ovalCareerBig2.svg";

import "./careers.css";

function Careers() {
  // const navigate = useNavigate();
  const location = useLocation();
  const [vacancies, setVacancies] = useState<any>([]);
  const [activeVacancy, setActiveVacancy] = useState("");
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    fetch("https://welcome.altoros.com/vacancies-protofire/")
      .then((res) => res.json())
      .then(
        (result) => {
          setVacancies(result);
        },
        () => {}
      );
  }, []);

  const handletoggle = (title: string) => {
    setToggle(!toggle);
    setActiveVacancy(title);
  };

  useEffect(() => {
    if (toggle === false) setActiveVacancy("");
  }, [toggle]);

  const goToViolation = (id: string) => {
    const violation = document.getElementById(id);
    window.scrollTo({
      top: violation?.offsetTop,
      behavior: "smooth",
    });
    setActiveVacancy(id);
  };

  useEffect(() => {
    if (location.search.length > 0) {
      const id = location.search.split("=")[1];
      goToViolation(id);
      if (vacancies.length > 0)
        setActiveVacancy(vacancies[parseInt(id, 10) - 1]["title"]);
    }
  }, [vacancies]);

  const displayVacancies = () => {
    return vacancies.map((item: any, index: number) => {
      const desc = item["desc"]["body"];
      // eslint-disable-next-line prefer-destructuring
      const hr = item["hr"];
      // replace skype: from the links
      const replacedSkype = hr.replaceAll("skype:", "");
      const parser = new DOMParser();
      const doc = parser.parseFromString(replacedSkype, "text/html");
      const div = doc.querySelector("div.vacancy-manager");
      const img = doc.querySelector("img.attachment-avatar");

      if (img instanceof HTMLImageElement) {
        img.alt = `Looking for a position in a challenging Web3 project? Join Protofire and contribute to the world's top blockchain protocols and networks.`;
      }

      const divHtml = div! && div.outerHTML;

      return (
        <div key={item["title"]}>
          <Link
            to={`/careers?vacancyid=${index + 1}`}
            state={{ name: item["title"] }}
          >
            <div
              id={`${index + 1}`}
              className={`${
                activeVacancy === item["title"]
                  ? "text-blue"
                  : "text-blue100 my-1"
              } py-5 px-5 bg-darkBackground  cursor-pointer text-sm flex`}
              onClick={() => handletoggle(item["title"])}
              style={{
                fontFamily: "Spartan-SemiBold",
              }}
            >
              {activeVacancy === item["title"] ? (
                <img src={blue} alt="blue" className="mr-2 h-full" />
              ) : (
                <img src={dark} alt="dark" className="mr-2 h-full" />
              )}{" "}
              {item["title"]}
            </div>
          </Link>
          <div
            className={`${
              activeVacancy === item["title"] ? "grid" : "hidden"
            }  lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 text-sm 
          text-blue200 lg:gap-20 sm:gap-8 bg-darkBackground lg:px-10  sm:px-5 mb-5 pb-5`}
            style={{
              fontFamily: "Spartan-Medium",
            }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: desc }}
              className="leftDiv col-span-2"
            />
            <div
              dangerouslySetInnerHTML={{ __html: divHtml }}
              className="rightDiv"
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="lg:px-20 m-auto   py-20">
      <div className="lg:px-20 sm:px-0">
        <Helmet>
          <title>Career Opportunities | Protofire</title>
          <meta
            name="description"
            content="Join the Protofire’s team of experienced blockchain developers and work on the best DeFi projects. Let’s build the future in Web3 together!"
          />
          <meta
            name="keywords"
            content="best remote careers, web3 remote jobs, code jobs, careers in fintech, job developer, full stack developer careers, developer job, software developer jobs, software engineering job, jobs in defi"
          />
        </Helmet>
        <div
          className="flex lg:px-20 pb-10 sm:px-5 md:px-10 text-blue200 text-xs"
          style={{
            fontFamily: "Spartan-Medium",
          }}
        >
          <Link to="/" className=" mr-1 text-blue200">
            Home
          </Link>
          /
          <Link to="/services" className="ml-1 text-blue100 ">
            Careers
          </Link>
        </div>
        <div className="relative lg:block md:block sm:hidden z-1 ">
          <OvalCareerBig className=" absolute ovalBig1" />
        </div>
        <div className="lg:px-20  sm:px-5 md:px-10 relative z-10">
          <p
            className="tracking-widest text-blue200 text-sm"
            style={{
              fontFamily: "Spartan-SemiBold",
            }}
          >
            {" "}
            WE VE BEEN LOOKING FOR YOU
          </p>
          <p className="text-blue100 text-4xl"> Join our team</p>
        </div>

        <div className="relative lg:hidden md:hidden sm:block z-1 ">
          <OvalCareerMobile2 className=" absolute bgOval2" />
        </div>
        <div className="lg:px-20 pt-10 sm:pt-0 sm:px-5 md:px-10 relative z-10">
          <p
            className="text-blue100 text-2xl sm:text-lg"
            style={{
              fontFamily: "Spartan-SemiBold",
            }}
          >
            What is Protofire DAO
          </p>
          <p
            className="text-sm text-blue200  lg:w-2/3 sm:w-full"
            style={{
              fontFamily: "Spartan-Medium",
            }}
          >
            We help the world transition into web3 in a collaborative
            environment with aligned incentives. We accelerate crypto mass
            adoption by deploying solutions that unlock organic growth in web3,
            DAOs, and protocols.
          </p>
        </div>
        <div
          className="lg:px-20 py-3 sm:px-5  md:px-10  "
          style={{
            fontFamily: "Spartan-SemiBold",
          }}
        >
          <p className="text-blue100">Where you can participate?</p>
          <div className="grid lg:grid-cols-8 py-1 md:grid-cols-8 sm:grid-cols-4">
            {partners.map((partner) => (
              <div
                key={partner}
                className="lg:p-8 md:p-4 sm:p-4 border border-blue300"
              >
                {" "}
                <img src={partner} alt={partner} />{" "}
              </div>
            ))}
          </div>
          <p className="text-blue200 text-xs">
            Check other projects on our github page:
            <a
              target="_blank"
              href="https://github.com/protofire"
              rel="noreferrer"
              className="text-blue ml-1"
            >
              {" "}
              View on Github
            </a>
          </p>
        </div>
        <div className="lg:px-20  sm:px-5  md:px-10 lg:mb-14 ">
          <p className="text-blue100 text-2xl">{sections.knowUs.title}</p>
          <ul className="whatWeDoList  leading-4 text-blue200 text-sm sm:mb-5">
            {sections.knowUs.list.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
        <div className="lg:px-20  sm:px-5  md:px-10  lg:mb-14">
          <p className="text-blue100 text-2xl">{sections.ourGoal.title}</p>
          <p className="text-blue200 text-sm sm:mb-5">is to help onboard:</p>
          <ul className="whatWeDoList  leading-4 text-blue200 text-sm sm:mb-5">
            {sections.ourGoal.list.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
        <div className="lg:px-20 sm:px-5 md:px-10">
          <p className="text-blue100 text-2xl">{sections.ourValues.title}</p>
          <ul className="whatWeDoList leading-4 text-blue200 text-sm sm:mb-5">
            {sections.ourValues.list.map((item) => (
              <li key={item.heading} className="text-base">
                {item.heading}
                <p
                  className="text-sm "
                  style={{
                    fontFamily: "Spartan-Medium",
                  }}
                >
                  {item.description}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div className="lg:px-20 pt-10 sm:px-5  md:px-10 ">
          <p className="text-2xl text-blue100 pb-5">Our Benefits</p>
          <div
            className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 sm:gap-2 lg:gap-5
        lg:text-sm md:text-sm sm:text-xs text-blue100"
            style={{
              fontFamily: "Spartan-SemiBold",
            }}
          >
            {content.map((item) => (
              <div
                key={item.desc}
                className="text-center lg:w-2/3 md:w-4/5 sm:w-full"
              >
                {/* <img src={item.icon} alt={item.desc} className="m-auto mb-2" /> */}
                <item.icon className="m-auto mb-2 sm:w-10" />
                <p className="block lg:py-2 sm:text-tiny ">{item.desc}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="relative lg:hidden md:hidden sm:block z-1 ">
          <OvalCareerMobile className=" absolute bgOval" />
        </div>
        <div className="relative lg:block md:block sm:hidden z-1 ">
          <OvalCareerBig2 className=" absolute ovalBig2" />
        </div>
        <div className="lg:px-20 py-10 sm:px-0 md:px-10 z-10 relative">
          <p className="text-blue100 text-4xl sm:px-5 py-5 sm:text-2xl">
            {" "}
            Open Opportunities
          </p>
          {vacancies.length > 0 && displayVacancies()}
        </div>
      </div>
    </div>
  );
}

export default Careers;
