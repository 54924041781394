/* eslint-disable import/prefer-default-export */

import ideation from "../../assets/services/ideation.png";
import quality from "../../assets/services/quality.png";
import prototyping from "../../assets/services/prototyping.png";
import deployment from "../../assets/services/deployment.png";
import development from "../../assets/services/development.png";
import maintenance from "../../assets/services/maintenance.png";

export const tabs = [
  {
    tabName: "Research of LP Behavior",
    tabDrescriptionP1: `Researching the behavior of liquidity providers, 
      such as their unique needs, wants, pains, and fears can unlock the next 
      wave of TVL growth. As a result, DAO core-dev, product, and marketing teams 
      will receive the clear signal on how to attract HNWI and institutions and 
      increase deposits per LP, increasing TVL.
    `,
    protofireCan: {
      head: ``,
      title: "Protofire can:",
      list: [
        "Develop and execute a survey",
        "Design and handle a rewards program for participants",
        "Support users in the process",
        "Conduct interviews",
        "Provide the Report Analysis including User profiles, Empathy Map, Recommendations",
      ],
    },
    tabTrackRecord: [],
  },
  {
    tabName: "Infra Audits & Certifications",
    tabDrescriptionP1: ``,
    protofireCan: {
      head: `Dapp Infrastructure Security Audit`,
      title: "The scope of the audit covers:",
      list: [
        "Source code analysis of Web2/Web3 frontend (web pages and mobile apps)",
        "Analysis against OWASP top 10 vulnerabilitie",
        "Analysis of deployment scripts and repository structure",
        `Review of Dapp architecture, including integration between frontend 
        (web2/web3) and backend (blockchain)`,
        "Analysis of wallet integration methods",
        `Review of hosting infrastructure architecture (on prem or cloud) and 
        best practices usage:`,
        "Review of network architecture and configuration",
        `Review of infrastructure deployment automation (IaC) scripts and 
        scalability configuration`,
      ],
    },
    protofireCan2: {
      head: `Oracle Security Audit`,
      title: "The scope of the audit covers:",
      list: [
        `Review of Oracle selection based on application requirements 
        (functional and non-functional) and cost constraints`,
        `Economic risk analysis: a) Availability impact 
        (downtime, DDoS, redundancy, multiple sources) 
        b)  Timeliness impact (time to turn around, precision, dynamic fees) 
        c)  Reliability impact (trustworthiness of data, resilience to outliers) 
        d)  Social threats (front running, flash loans, Sybil attacks, etc.) 
        e)  Profit-from-Corruption (PfC) versus Cost-of-Corruption (CoC)`,
        "Analysis of reliability and trustworthiness of data sources",
        `Analysis of DAO governance rules regarding Oracles:   
        a)  Consensus mechanism 
        b)  Oracle fees policy (static/dynamic, barriers to front running) 
        c)  Oracle whitelisting model 
        d)  Dynamic model (rounds, disputes, resolution, Commit/Reveal scheme, etc.)`,
        "Optional analysis of source code and centralized infrastructure (technical analysis)",
      ],
    },
    tabTrackRecord: [],
  },
  {
    tabName: "Insurance Pool & Safety Module",
    tabDrescriptionP1: `Most DeFi users are concerned about exposure to the risks of first-party 
    smart contract hacks, stablecoin depeg, or third-party attacks against smart contracts, 
    oracle and bridge attacks, etc.
    `,
    tabDrescriptionP2: `Protofire can enable insured deposits for users where Premiums are automatically calculated, 
    based on the market price (% of the deposits to be insured, and % of the amount for payment). 
    Deposits will be insured by the underwriting capital of liquidity providers of Atomica DAO, 
    an algorithmic protocol built for DeFi protocols and investors to launch their own protection 
    cover markets, offer protection to their users, or earn a yield on their capital.
    `,
    tabDrescriptionP3: `Protofire is ready to create multiple risk pools for you to protect all kinds of markets, 
    where liquidity providers can provide liquidity, earn incentives/yields, and guarantee the markets.
    `,
    protofireCan: {
      head: `Problem:`,
      title:
        "DeFi users are always concerned about risk exposure to main risks:",
      list: [
        `1st party smart contract hacks`,
        `Stablecoin depeg risk`,
        "3rd party attacks (smart contracts, oracle & bridge attacks)",
      ],
    },
    protofireCan2: {
      head: `Solution:`,
      title: "",
      firstParagraph: `
      Protofire can enable insured deposits for users. Premiums are automatically 
      calculated, based on the market price (% of the deposits to be insured, and 
      % of the amount for payment). Deposits will be insured by underwriting capital 
      of liquidity providers of Atomica DAO, an algorithmic protocol built for DeFi 
      protocols and investors to launch their own protection cover markets, offer 
      protection to their users, or earn yield on their capital. We can 
      create multiple risk pools to protect all kinds of markets, where LP 
      can provide liquidity, earn incentives/yields, and guarantee those markets.

      `,
    },
    tabTrackRecord: [],
  },
];

export const whatWeProvide = [
  {
    name: "Ideation",
    icone: ideation,
    description: `We research the best TVL growth opportunities for your project, and suggest architecture and deployment plans for your solution.`,
  },
  {
    name: "Prototyping",
    icone: prototyping,
    description: `We create a prototype of the solution for you to see what it may look like and make necessary changes.`,
  },
  {
    name: "Development",
    icone: development,
    description: `We develop the TVL solution under your supervision, known schedule, and budget.`,
  },
  {
    name: "Quality Assurance",
    icone: quality,
    description: `We carry out quality assurance procedures to make sure all features of the solution work as intended.`,
  },
  {
    name: "Deployment",
    icone: deployment,
    description: `We deploy the solution to the designated environment under your control, following technical requirements.`,
  },
  {
    name: "Maintenance & Support",
    icone: maintenance,
    description: `We maintain the solution and its infrastructure and will trigger you if certain decisions are required. `,
  },
];

export const headings = {
  problem: {
    title: "The problem behind TVL",
    list1: [
      `High-net-worth individuals, funds, and big whales go for DeFi solutions that 
      can guarantee their funds and yields. At the same time, there are investors willing 
      to invest $1M+.`,
      `Most liquidity providers have the capacity to increase their deposits by 2-10x.`,
      `To address this challenge, your solution should clearly demonstrate transparency, 
      risk-mitigation measures, and risk-transfer options to evaporate the fear of high 
      risk and uncetainty from the liquidity providers' minds.`,
    ],
    firstParagraph: "Major risks in the DeFi space:",
    list2: [
      "Smart contract logic errors",
      "Reentrancy attacks",
      "Oracle manipulation",
      "Web2/Web3 frontend exploits",
      "Rugpull risk",
      "Stablecoin depeg",
    ],
  },
  solution: {
    title: "TVL growth solution opportunities",
    firstParagraph: `Protofire can be your strategic partner in the path to unlocking TVL's 
      exponential growth by improving security and providing a safer environment for liquidity providers.
    `,
    list1: [
      `Surveys + TVL readiness assessment will help you to understand how to unlock TVL growth.`,
      `Oracle security implementation and usage audits allow you to assess economical exploit 
      threats and technical aspects of implementation.`,
      `Comprehensive dApp infrastructure security audits for Dapp Infrastructure assess Web2/Web3
       website frontend, network, and cloud infrastructure to protect your project from the most 
       common types of attacks and threats.`,
      `Protofire Certification Badges are given to projects upon audit completion in the form of 
      NFTs (ERC-721) with the description of verification services provided.`,
    ],
  },
};
