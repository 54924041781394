/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from "react";
import { whatWeDoContent } from "./content";
import WhatWeDo from "./whatWeDo";
import { ReactComponent as RightArrowGrey } from "../../assets/images/right_arrow_grey.svg";
import { ReactComponent as LeftArrowGrey } from "../../assets/images/left_arrow_grey.svg";
import { ReactComponent as RightArrowWhite } from "../../assets/images/right_arrow_white.svg";
import { ReactComponent as LeftArrowWhite } from "../../assets/images/left_arrow_white.svg";

import { ReactComponent as ArrowYellow } from "../../assets/images/arrowYellow.svg";

function Index() {
  const [displayContent, setDisplayContent] = useState(whatWeDoContent[0]);
  const [currentContent, setCurrentContent] = useState(0);
  const next = () => {
    if (currentContent < whatWeDoContent.length - 1)
      setCurrentContent(currentContent + 1);
  };

  const previous = () => {
    if (currentContent > 0) setCurrentContent(currentContent - 1);
  };

  useEffect(() => {
    setDisplayContent(whatWeDoContent[currentContent]);
  }, [currentContent]);
  return (
    <div className="md:px-10 lg:mt-0 md:mt-0 sm:mb-20 lg:px-20 sm:mt-0 ">
      <div>
        <h1 className="text-3xl text-blue100 pt-10 pb-5 sm:px-5 lg:px-0">
          {" "}
          What we do
        </h1>
      </div>
      <div
        className="lg:grid md:grid lg:grid-cols-3 lg:gap-6 sm:gap-4 md:grid-cols-2 
      sm:hidden"
      >
        {whatWeDoContent.map((content) => (
          <div key={content.number}>
            {" "}
            <WhatWeDo {...content} />{" "}
          </div>
        ))}
        <div
          className="col-span-2 leading-10 mt-10 pl-10 border-l border-blue300 h-1/2"
          style={{ fontFamily: "Spartan-Medium", width: "90%" }}
        >
          <p className="text-2xl text-blue100 pb-5">
            {" "}
            If you have any other ideas, just get in touch. We are open to do
            more
          </p>
          <a
            href="#contactUs"
            className="join px-10 py-5  tracking-wider"
            style={{
              fontFamily: "Spartan",
            }}
          >
            <button
              type="button"
              className="btnText tracking-wider"
              style={{ fontSize: "12px", fontFamily: "Spartan" }}
            >
              GET IN TOUCH
              <ArrowYellow className="inline ml-2 mb-1" />
            </button>
          </a>
        </div>
      </div>
      <div className="lg:hidden, md:hidden sm:block bg-darkBackground p-5 mx-5">
        <div className="flex ">
          {currentContent === 0 && (
            <LeftArrowGrey onClick={previous} className="cursor-pointer mr-3" />
          )}
          {currentContent > 0 && (
            <LeftArrowWhite
              onClick={previous}
              className="cursor-pointer mr-3"
            />
          )}
          <span
            className={currentContent + 1 > 1 ? "text-blue100" : "text-blue300"}
          >
            {`${currentContent < 9 ? "0" : ""}${currentContent + 1}`}
          </span>
          <span className="text-blue300 px-1">/</span>
          <span
            className={
              currentContent + 1 < whatWeDoContent.length
                ? "text-blue100"
                : "text-blue300"
            }
          >{`0${whatWeDoContent.length}`}</span>

          {displayContent.number === whatWeDoContent.length && (
            <RightArrowGrey onClick={next} className="cursor-pointer ml-3" />
          )}

          {currentContent < whatWeDoContent.length - 1 && (
            <RightArrowWhite onClick={next} className="cursor-pointer ml-3" />
          )}
        </div>
        <div className="z-10">
          <WhatWeDo {...displayContent} />
        </div>
      </div>
    </div>
  );
}

export default Index;
