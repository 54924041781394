import aragonDAO from "../../assets/projectsImages/aragonNew.png";
import aragon1 from "../../assets/projectsImages/aragon1.png";

import dex1 from "../../assets/services/55.png";
import dex11 from "../../assets/services/56.png";

// eslint-disable-next-line import/prefer-default-export
export const project = {
  name: "AragonDAO Payroll dApp",
  hashtags: ["dApp", "Dashboard", "Payments"],
  description: `The Aragon application allows users to incorporate new employees 
  (integrated with the identity and finance apps), request and pre-authorize payments 
  in different tokens, as well as view all the payroll information, including 
  historic payments, balance, and statistics. The DAO members/employees can manage 
  and expect their payments to arrive on time, error-free, containing the expected 
  tokens`,
  thumbnail: aragonDAO,
  thumbnailAlt:
    "Protofire developed the Aragon application that allows users to manage employees, authorize payments in different tokens, and view all the payroll information.",
  aboutProject: {
    title: "About the project",
    lists: [
      `Development of the official Aragon DAO Payroll decentralized app (dApp)`,
      `It promotes automated management of payments`,
      `DAO members can manage and expect payments to arrive at an agreed time`,
      `There was an improvement in user and developer experience`,
      `There was also an integration of user feedback to create and improve tools 
      for building new types of organizations with the Aragon stack`,
    ],
    sellVolume: ["1.7K", "Number of DAOs using Aragon (Q1/2022)"],
    batches: ["41K", "Number of users (Q1/2022)"],
    totalTrades: ["$900M", "Amount of money managed by such DAOs (Q1/2022)"],
  },
  theClient: {
    title: "The client/partner",
    firstParagraph: `Aragon DAO is an administrative platform for decentralized 
    organizations built on Ethereum. The Aragon project was born to disintermediate 
    the creation and maintenance of decentralized organizations. Aragon helps to 
    create global, bureaucracy-free organizations, companies, and communities, as 
    well as collaborate without borders or intermediaries. Aragon is creating a 
    whole stack of decentralized technologies that power governance mechanisms 
    underlying decentralized autonomous corporations or (DACs).`,
  },
  theProblem: {
    title: "The problem",
    firstParagraph: `The hierarchical power structure and the democratic 
    decision-making capabilities in both governments and corporations have been 
    thoroughly questioned these days. In order to promote a different way of being,
     a decentralized autonomous organization (DAO) is an organization that runs on 
     a blockchain protocol fully and autonomously in accordance with rules encoded 
     via smart contracts and its underlying consensus mechanism. DAOs’ main purpose 
     is to automate and decentralize the governance of organizations, such as 
     corporations. Some tenets of DAOs are decentralization, transparency, 
     automation, and being community-based.`,
  },
  theSolution: {
    title: "The solution",
    firstParagraph: `It allows users to:`,
    lists: [
      [`Manage payments`],
      [`Expect payments to arrive on time`],
      [`Expect payments to be error-free`],
      [`Tokens to be received at expected currency`],
      [
        `Incorporate new employees (integrated with the identity and finance apps)`,
      ],
      [`Filter and sort employees`],
      [`Request and pre-authorize payments in different tokens`],
      [
        `View all the payroll information, including: Historic payments, Balance, 
        Statistics`,
      ],
    ],
  },
  technologyStack: {
    title: "Technology stack",
    lauguages: [
      "React",
      "Javascript",
      "Web3",
      "GraphQL",
      "Assembly Script for The Graph",
      "Solidity",
    ],

    tools: ["GitHub", "ZenHub", "Netlify"],
  },
  code: {
    text: "Code",
    codes: [
      {
        text: "Payroll",
        link: "https://github.com/protofire/aragon-apps/tree/master/future-apps/payroll",
      },
    ],
  },
  images: [
    {
      original: aragon1,
      originalAlt:
        "The Aragon application from Protofire provides tools for staff management, payroll control, and statistics review.",
      thumbnail: aragon1,
      thumbnailAlt:
        "The Aragon application from Protofire provides tools for staff management, payroll control, and statistics review.",
    },
  ],
  ourTrackRecord: [
    [
      dex1,
      dex11,
      "/projects/gnosis-explorer",
      "sameWindow",
      "Protofire created hierarchical and custom components for the Gnosis' Conditional Tokens Explorer.",
    ],
  ],
};
