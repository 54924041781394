import { ReactComponent as Github } from "../assets/images/github.svg";
import { ReactComponent as Linkedin } from "../assets/images/linkedin.svg";
import { ReactComponent as Medium } from "../assets/images/medium.svg";
import { ReactComponent as Twitter } from "../assets/images/twitter.svg";
import { ReactComponent as Discord } from "../assets/images/discord.svg";
import { ReactComponent as Instagram } from "../assets/images/instagram.svg";
import { ReactComponent as Mirror } from "../assets/images/mirror.svg";
import { ReactComponent as Youtube } from "../assets/images/youtube.svg";

function Socials() {
  return (
    <>
      <a
        href="https://github.com/protofire"
        target="_blank"
        className="text-blue100 py-2 sm:px-2 lg:px-3 hover:scale-150 "
        rel="noreferrer"
      >
        <Github />
      </a>
      <a
        href="https://www.linkedin.com/company/protofire-io/"
        target="_blank"
        rel="noreferrer"
        className=" py-2 sm:px-2 lg:px-3 hover:scale-150"
      >
        <Linkedin />
      </a>
      <a
        href="https://medium.com/protofire-blog"
        target="_blank"
        rel="noreferrer"
        className="text-blue100 py-2 sm:px-2 lg:px-3 hover:scale-150"
      >
        <Medium />
      </a>
      <a
        href="https://twitter.com/protofire"
        target="_blank"
        rel="noreferrer"
        className="text-blue100 py-2 sm:px-2 lg:px-3 hover:scale-150"
      >
        <Twitter />
      </a>
      <a
        href="https://discord.gg/y5TE8TcPGn"
        target="_blank"
        rel="noreferrer"
        className="text-blue100 py-2 sm:px-2 lg:px-3 hover:scale-150"
      >
        <Discord />
      </a>
      <a
        href="https://www.instagram.com/protofire.io/"
        target="_blank"
        rel="noreferrer"
        className="text-blue100 py-2 sm:px-2 lg:px-3 hover:scale-150"
      >
        <Instagram />
      </a>
      <a
        href="https://mirror.xyz/protofiredao.eth"
        target="_blank"
        rel="noreferrer"
        className="text-blue100 py-2 sm:px-2 lg:px-3 hover:scale-150"
      >
        <Mirror />
      </a>
      <a
        href="https://www.youtube.com/channel/UCAQJ1nXk7fNNnbbZuJzoH5w"
        target="_blank"
        rel="noreferrer"
        className="text-blue100 py-2 sm:px-2 lg:px-3 hover:scale-150"
      >
        <Youtube />
      </a>
    </>
  );
}

export default Socials;
