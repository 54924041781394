import { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./about.css";
import { tabs } from "./content";
import legend from "../assets/about/legend.png";

function AboutTabs() {
  const [activeTab, setActiveTab] = useState("gnosis");

  return (
    <section>
      <Tabs
        className=" text-sm text-blue200 lg:w-full md:w-full sm:w-full"
        style={{
          fontFamily: "Spartan-Medium",
        }}
      >
        <TabList className=" sm:px-0 ">
          {" "}
          {tabs.map((tab) => (
            <Tab key={tab.key} onClick={() => setActiveTab(tab.name)}>
              {activeTab === tab.name ? (
                <img
                  src={tab.logo[1]}
                  alt={tab.name}
                  className={`${
                    tab.name === "armanino" ? "mb-3" : ""
                  } lg:pr-3 sm:pr-0 `}
                />
              ) : (
                <img
                  src={tab.logo[0]}
                  alt={tab.name}
                  className={`${
                    tab.name === "armanino" ? "mb-3" : ""
                  } lg:pr-3 sm:pr-0 `}
                />
              )}
            </Tab>
          ))}
        </TabList>

        {tabs.map((tab) => (
          <TabPanel className="  " key={tab.key}>
            <p className="text-lg text-blue100 pt-3"> {tab.description}</p>
            <ul className="customListDiv ">
              {tab.list.map((item) => (
                <li key={item.head} className=" sm:my-0 sm:py-0">
                  <p className="text-blue100 sm:p-0 sm:m-0 sm:mt-4 sm:mb-0 ">
                    {" "}
                    {item.head}
                  </p>
                  <p className="sm:p-0 sm:m-0"> {item.body}</p>

                  <a
                    className=" text-blue"
                    href={item.repo.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    {item.repo.name}
                  </a>
                </li>
              ))}
            </ul>
            {tab.name === "graph" ? (
              <fieldset className=" lg:p-10 sm:p-5 md:p-8 my-10 bg-darkBackground">
                <legend>
                  <img src={legend} alt="legend" />{" "}
                </legend>
                <p>{tab.comment?.body}</p>
                <p className="pt-8 text-blue100">{tab.comment?.author}</p>
                <p>{tab.comment?.title}</p>
              </fieldset>
            ) : (
              ""
            )}
            {tab.name === "armanino" ? (
              <fieldset className=" lg:p-10 sm:p-5 md:p-8 my-10 bg-darkBackground">
                <legend>
                  <img src={legend} alt="legend" />{" "}
                </legend>
                <p>{tab.comment?.body}</p>
                <p className="pt-8 text-blue100">{tab.comment?.author}</p>
                <p>{tab.comment?.title}</p>
              </fieldset>
            ) : (
              ""
            )}
          </TabPanel>
        ))}
      </Tabs>
    </section>
  );
}

export default AboutTabs;
