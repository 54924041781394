/* eslint-disable react/no-unescaped-entities */
function EventData() {
  return (
    <div
      className="text-sm text-blue200 leading-5 py-10"
      style={{
        fontFamily: "Spartan-Medium",
      }}
    >
      <h2 className="text-blue100 text-4xl pb-10">
        6. Data we gather if you are our customer
      </h2>
      <div>
        <h2 className="text-blue100 text-3xl ">
          6.1. We collect the following categories of data
        </h2>
        <p>
          If you are our customer, you have already shared the following
          information with us to process:
        </p>
        <ul className="dotList">
          <li>Name/surname of contact persons;</li>
          <li>Positions;</li>
          <li>Phone numbers;</li>
          <li>E-mails;</li>
          <li>LinkedIn profile links;</li>
          <li>Skype IDs;</li>
          <li>Сompany name/address;</li>
          <li>
            Any other information you provided to us during service delivery;
          </li>
          <li>History of our communication, etc.</li>
        </ul>
      </div>

      <div>
        <h2 className="text-blue100 text-3xl pt-10">
          6.2. How we process the data gathered
        </h2>
        <ol>
          <li>
            Information about the existing customers is transferred to our
            internal CRM (by our employees manually or automatically on
            receiving a contact form) and Hubspot (HubSpot, Inc..{" "}
            <a
              href="https://legal.hubspot.com/privacy-policy"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              <a
                href="https://www.atlassian.com/legal/privacy-policy-may-25th"
                target="_blank"
                className="text-blue"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </a>
            ) for further processing a customer request and providing relevant
            services, as well as developing recommendations on improving the
            services we deliver. We may further need any maintenance and support
            activities of our CRM system or any related activities.
          </li>
          <li>
            To share contact information and information related to the services
            a customer is interested in, we may use the following messengers:
            Skype (
            <a
              href="https://www.skype.com/en/legal/"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              <a
                href="https://www.atlassian.com/legal/privacy-policy-may-25th"
                target="_blank"
                className="text-blue"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </a>
            ), Viber (
            <a
              href="https://www.viber.com/terms/viber-privacy-policy/"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ), WhatsApp ({" "}
            <a
              href="https://www.whatsapp.com/legal/"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ), or Telegram (
            <a
              href="https://telegram.org/privacy"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ), as well as e-mail services—Gmail (
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ) or Outlook (
            <a
              href="https://privacy.microsoft.com/en-gb/privacystatement"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ).
          </li>
          <li>
            To store and share project requirements or any other information
            submitted by a customer (e.g., a project budget estimation to
            deliver a suitable commercial offer, UI mockups submitted by a
            customer, test access to a customer system, etc.), we may use
            services of Google (
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ), Adobe (
            <a
              href="https://www.adobe.com/privacy/policy.html"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ), Microsoft Office (
            <a
              href="https://privacy.microsoft.com/en-gb/privacystatement"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ), Atlassian (
            <a
              href="https://www.atlassian.com/legal/privacy-policy-may-25th"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ), and Trello (
            <a
              href="https://trello.com/privacy"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ).
          </li>
          <li>
            To provision phone calls in a distributed manner, Protofire makes
            use of services that store historical data about the activities
            conducted.
          </li>
          <li>
            To establish internal business processes within our departments and
            teams and to ensure timely request processing, we make use of Trello
            (
            <a
              href="https://help.trello.com/article/1118-trello-and-gdpr-our-commitment-to-data-privacy"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ) and Atlassian (
            <a
              href="https://www.atlassian.com/legal/privacy-policy-may-25th"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ). These services may store project information related to a
            technology stack, budget, roadmap and deadlines, Protofire project
            team, etc.
          </li>
          <li>
            To store audio recording of negotiations with a customer in order to
            clarify details if necessary and conduct meetings with previous,
            existing, and potential customers, we makes use of GoToMeeting (
            <a
              href="https://www.logmeininc.com/legal/privacy"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ), Hangouts (
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ) or Zoom (
            <a
              href="https://zoom.us/privacy"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ).
          </li>
          <li>
            To store case studies, describing a delivered project approved by a
            customer, we use an internal web portal—SharePoint Portal (
            <a
              href="https://privacy.microsoft.com/en-gb/privacystatement"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ) — which only employees of Protofire can access.
          </li>
          <li>
            To provision contracts, all the data about the active customers is
            stored in a secured internal network resource with limited access.
            This resource is available only to our account managers or other
            employees concerned for the purpose of improving service delivery
            while establishing communication with a customer, issuing an
            invoice, and generating reports for a customer. Additional services
            Protofire uses for issuing invoices Azets AS (
            <a
              href="https://www.azets.com/privacy-statement/"
              target="_blank"
              className="text-blue"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ). These services process data in compliance with privacy policies
            of the mentioned services.
          </li>
          <li>
            Additionally, by sharing with us this information you are giving
            consent to contact you in order to get your consent for a
            possibility to contact you regarding any other services you might be
            interested in.
          </li>
        </ol>
      </div>
      <div>
        <h2 className="text-blue100 text-3xl pt-10">
          6.3. Purposes and legal basis for data processing
        </h2>
        <p>We use personal data submitted for the following purposes:</p>
        <ol className="">
          <li>
            To fulfill / comply with our contractual obligations or answer to
            your request. For example, we use your name or an e-mail in contact
            to send an invoice or communicate with you at any stage of the
            service delivery life cycle. This way, we may use your personal
            information to provide services to you, as well as process
            transactions related to the services you inquired from us. For
            example, we may use your name or an e-mail address to send an
            invoice or to establish communication throughout the whole service
            delivery life cycle. We may also use your personal information you
            shared with us to connect you with other of our team members seeking
            your subject matter expertise. In case you use multiple services
            offered by our company, we may analyze your personal information and
            your online behavior on our resources to deliver an integrated
            experience. For example, to simplify your search across a variety of
            our services to find a particular one or to suggest relevant product
            information as you navigate across our websites.
          </li>
          <li>
            With an aim to enhance our productivity and improve our
            collaboration, what is our legitimate interest, we may use your
            personal data—such as an an e-mail, name, job title, or activity
            taken on our resources — to provide information we believe may be of
            interest to you and communicate with you in order to get your
            consent for a possibility to contact you regarding any other
            services you might be interested in. Additionally, we may store the
            history of our communication for the legitimate purposes of
            maintaining customer relations and/or service delivery as well as to
            maintain and support our CRM system and related activities.
          </li>
        </ol>
      </div>
      <div>
        <h2 className="text-blue100 text-3xl pt-10">
          6.4. Data retention period
        </h2>
        <p>
          We set retention period for your data about our customer to 6 years
          from last Service delivery. We keep it to be able to reach you when we
          have something relevant to your initial request (for example, updated
          information on related services, news, events, updates, etc).
        </p>
      </div>
      <div>
        <h2 className="text-blue100 text-3xl pt-10">6.5. Data recipients</h2>
        <p>
          We do not share data with third parties, apart from the cases
          described in the General data processing section or in this section.
        </p>
      </div>
    </div>
  );
}

export default EventData;
