import gnosisNew from "../../assets/projectsImages/gnosisNew.png";
import gnosis1 from "../../assets/projectsImages/gnosis1.png";

// eslint-disable-next-line import/prefer-default-export
export const project = {
  name: "Gnosis Conditional Tokens Explorer",
  hashtags: ["dApp", "Explorer", "Interface"],
  description: `Protofire has contributed to the development of CTE’s interface. 
  It was built with a focus on providing a good user experience. We also created 
  hierarchical and custom components with specialized functions. The Conditional 
  Token Explorer (CTE) was created alongside the Gnosis team to help blockchain 
  developers understand the Conditional Tokens Framework (CTF). The CTF is a 
  protocol for creating tokenized logic; tokens can be redeemed for underlying 
  collateral when a specific condition is true`,
  thumbnail: gnosisNew,
  thumbnailAlt:
    "Protofire created hierarchical and custom components with specialized functions for the Gnosis’ Conditional Tokens Explorer.",
  aboutProject: {
    title: "About the project",
    firstParagraph: `The Conditional Token Explorer provides an interface for the 
    CTF, allowing users to interact with the protocol and its features. Specifically, 
    the CTE enables users to perform the following actions:`,
    lists: [
      `Connect to an application using MetaMask or WallectConnect`,
      `Prepare a condition, including an Omen one`,
      `Split positions from collateral, from an existing position, and 
      into different collections`,
      `Merge positions into collateral, into another position, and into a different 
      collection at the same level`,
      `Report payouts`,
      `Redeem a position`,
      `List the user’s positions`,
      `Wrap and unwrap a position into an ERC20`,
    ],
  },
  theClient: {
    title: "The client/partner",
    firstParagraph: `The Conditional Tokens Framework (CTF) is a protocol for 
    creating tokenized logic; tokens redeemable for underlying collateral when a 
    specific condition is true. Created by Gnosis, the protocol enables use cases 
    such as predictions, information gathering, and forecasting. While the CTF makes 
    it possible for developers to create various applications, it is also 
    sophisticated in operation, integration, and extension using traditional 
    blockchain techniques.`,
  },
  theProblem: {
    title: "The problem",
    firstParagraph: ``,
  },
  theMedia: {
    title: "Media",
    firstMedia: {
      text: "Protofire Contributes to the Development of the Conditional Token Explorer: ",
      link: "https://medium.com/protofire-blog/protofire-contributes-to-the-development-of-the-conditional-token-explorer-5fb4f305c1f4",
    },
  },
  theSolution: {
    title: "The solution",
    lists: [
      [
        `Protofire contributed to the development of CTE’s interface`,
        "https://cte.gnosis.io/#/conditions",
      ],
      [
        `To provide a good user experience, the team built the 
        interface with React, Typescript, and style components`,
      ],
      [
        `This enabled us to create hierarchical and custom components 
        with specialized functions`,
      ],
      [
        `CTE’s frontend connects to the blockchain Ethereum 
        network through ether.js and Web3Modal libraries`,
      ],
      [
        `To speed up queries from the blockchain, we implemented a 
        subgraph that consumes smart contract data from a GraphQL service`,
      ],
    ],
  },
  code: {
    text: "Code",
    codes: [
      {
        text: "Conditional Tokens Explorer",
        link: "https://github.com/gnosis/conditional-tokens-explorer",
      },
    ],
  },
  images: [
    {
      original: gnosis1,
      originalAlt:
        "Protofire created hierarchical and custom components with specialized functions for the Gnosis’ Conditional Tokens Explorer that helps developers understand the Conditional Tokens Framework.",
      thumbnail: gnosis1,
      thumbnailAlt:
        "Protofire created hierarchical and custom components with specialized functions for the Gnosis’ Conditional Tokens Explorer that helps developers understand the Conditional Tokens Framework.",
    },
  ],
  ourTrackRecord: [],
};
