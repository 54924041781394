import aave from "../assets/partners/aave.png";
import ant from "../assets/partners/ant.png";
import armanino from "../assets/partners/armanino.jpg";
import avax from "../assets/partners/avax.png";
import bzrx from "../assets/partners/bzrx.png";
import cow from "../assets/partners/cow.png";
import crossfi from "../assets/partners/crossfi.jpg";
import dot from "../assets/partners/dot.png";
import eng from "../assets/partners/eng.png";
import eth from "../assets/partners/eth.png";
import etherics from "../assets/partners/etherics.png";
import fil from "../assets/partners/fil.png";
import gno from "../assets/partners/gno.png";
import grt from "../assets/partners/grt.png";
import iotx from "../assets/partners/iotx.png";
import knc from "../assets/partners/knc.png";
import link from "../assets/partners/link.png";
import lpt from "../assets/partners/lpt.png";
import mkr from "../assets/partners/mkr.png";
import ocean from "../assets/partners/ocean.png";
import safe from "../assets/partners/safe.png";
import smt from "../assets/partners/smt.png";
import snx from "../assets/partners/snx.png";
import zrx from "../assets/partners/zrx.png";

// eslint-disable-next-line import/prefer-default-export
export const partnersLogos = [
  {
    src: aave,
    name: "aave",
  },
  {
    src: ant,
    name: "ant",
  },
  {
    src: armanino,
    name: "armanino",
  },
  {
    src: avax,
    name: "avax",
  },
  {
    src: bzrx,
    name: "bzrx",
  },
  {
    src: cow,
    name: "cow",
  },
  {
    src: dot,
    name: "dot",
  },
  {
    src: eng,
    name: "eng",
  },
  {
    src: etherics,
    name: "etherics",
  },
  {
    src: fil,
    name: "fil",
  },
  {
    src: gno,
    name: "gno",
  },
  {
    src: grt,
    name: "grt",
  },
  {
    src: iotx,
    name: "iotx",
  },
  {
    src: knc,
    name: "knc",
  },
  {
    src: link,
    name: "link",
  },
  {
    src: lpt,
    name: "lpt",
  },
  {
    src: mkr,
    name: "mkr",
  },
  {
    src: ocean,
    name: "ocean",
  },
  {
    src: crossfi,
    name: "crossfi",
  },
  {
    src: safe,
    name: "safe",
  },
  {
    src: smt,
    name: "smt",
  },
  {
    src: snx,
    name: "snx",
  },
  {
    src: zrx,
    name: "zrx",
  },
  {
    src: eth,
    name: "eth",
  },
];
