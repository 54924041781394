/* eslint-disable import/prefer-default-export */
import dAppps1 from "../../assets/services/banners/chainlink_integration-front.png";
import dAppps11 from "../../assets/services/banners/chainlink_integration-back.png";

import ideation from "../../assets/services/ideation.png";
import quality from "../../assets/services/quality.png";
import prototyping from "../../assets/services/prototyping.png";
import deployment from "../../assets/services/deployment.png";
import development from "../../assets/services/development.png";
import maintenance from "../../assets/services/maintenance.png";
import sub1 from "../../assets/services/banners/the_graph_subgraphs-front.png";
import sub11 from "../../assets/services/banners/the_graph_subgraphs-back.png";

export const tabs = [
  {
    tabName: "Oracles",
    tabDrescription: `Oracles are bridges between a blockchain and the outside world. 
      Oracles provide a way for a decentralized ecosystem to access existing data 
      sources, legacy systems, and advanced computations. They act as on-chain APIs 
      you can query to get information into your smart contracts. This could be 
      anything from price information to weather reports.
    `,

    tabUseCases: [
      "Connect smart contracts to:",
      "  Other blockchains",
      "  Off-chain computation",
      "  Payment providers",
      "  Price feeds",
      "  Commodities data",
      "  Weather data",
      "  Medical data",
    ],

    tabBenefits: [
      "Connect blockchain data with outside sources",
      "Provide data feeds",
      "End-to-end decentralization",
      "High-quality data",
      "Crypto-economic security",
      "Off-chain computation",
    ],
    tabTrackRecord: [
      [
        dAppps1,
        dAppps11,
        "/projects/chainlink-integration",
        "sameWindow",
        "Protofire has tested a variety of Chainlink integrations and developed External Adapters for Chainlink that connect smart contracts to premium web APIs, ",
      ],
    ],
  },
  {
    tabName: "Subgraphs",
    tabDrescription: `Indexers are node operators in The Graph Network 
      that stake The Graph tokens (GRT) to provide indexing and query 
      processing services. Indexers earn query fees and indexing rewards 
      for their services. Subgraphs define which data The Graph will index 
      from Ethereum protocols, and how they will store them. Once subgraphs 
      are deployed, they form a part of a global graph of blockchain data.
    `,

    tabBenefits: [
      "No downtime by relying on a network of Indexers to serve data ",
      "A reduced central point of failure in infrastructure and service provider",
      "Innovate faster by building on open-source APIs",
      "Paying query fees only for data that you consume ",
      "Ensure your dApp is built on a fully decentralized infrastructure ",
      "Save on engineering costs, less redundant back-end and indexing work",
    ],
    tabUseCases: [],
    tabTrackRecord: [
      [
        sub1,
        sub11,
        "/projects/the-graph-subgraphs",
        "sameWindow",
        "Protofire has deployed more than 30 subgraphs to The Graph and assists the network in implementing the protocol for its clients. Protofire validates smart contracts and on-chain data and defines the roadmap for subgraph development.",
      ],
    ],
  },
];

export const whatWeProvide = [
  {
    name: "Ideation",
    icone: ideation,
    description: `We conceptualize and advise on the architecture, implementation, and productivity of an integration`,
  },
  {
    name: "Prototyping",
    icone: prototyping,
    description: `We create a prototype of integration for you to see what it may look like and make necessary changes.`,
  },
  {
    name: "Development",
    icone: development,
    description: `We develop tools required for the project and set up the environment to fit them under your supervision, known schedule, and budget.`,
  },
  {
    name: "Quality Assurance",
    icone: quality,
    description: `We carry out quality assurance procedures to make sure all features of an integration work as intended.`,
  },
  {
    name: "Deployment",
    icone: deployment,
    description: `We deploy the integration to the designated environment under your control, following technical requirements.`,
  },
  {
    name: "Maintenance & Support",
    icone: maintenance,
    description: `We maintain the integration, its features, tools, and related infrastructure and will trigger you if certain decisions are required.`,
  },
];
